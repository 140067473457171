import { connect } from 'react-redux';
import AppSettings from '../../components/Settings/AppSettings';
import {bindActionCreators} from "redux";
import {updateSetting} from "../../reducers/auth";

const mapStateToProps = state => {
  const userSettings = state.auth.data?.settings || {};

  const playNotificationSoundValue = userSettings.playNotificationSound;
  const selectedTheme = userSettings.theme;

  return {
    userId: state.auth.data?.id,
    playNotificationSoundValue,
    selectedTheme,
  };
};


const mapDispatchToProps = dispatch => bindActionCreators({
  updateSetting,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppSettings);
