import React, {Component} from "react";
import SettingsFilters from "../../../../containers/GroupModal/SettingsFiltersContainer";
import SavedFilters from "../../../../containers/GroupModal/SavedFiltersContainer";
import SearchComponent from "../../../UIComponents/SearchComponent";
import { withTranslation } from "react-i18next";

class FiltersBar extends Component {
  state = {
    facility: [],
    department: [],
    profession: [],
    specialty: [],
    savedFilters: [],
    selectedSavedFilter: null,
    name: ''
  };

  updateContactsList = () => {
    const {facility, department, profession, specialty, name} = this.state;

    const filterOptions = {};

    if (facility.length) {
      filterOptions.facilityId = facility;
    }

    if (department.length) {
      filterOptions.departmentId = department;
    }

    if (profession.length) {
      filterOptions.professionId = profession;
    }

    if (specialty?.length) {
      filterOptions.specialtyId = specialty;
    }

    if (name) {
      filterOptions.name = name;
    }

    this.props.getContactsList(filterOptions, true)
  };

  handleFilterChange = (name, values) => {
    this.setState({[name]: values}, this.updateContactsList)
  };

  resetFiltersValues = () => {
    this.setState({
      facility: [],
      department: [],
      profession: [],
      specialty: [],
      selectedSavedFilter: null
    }, this.updateContactsList)
  };

  handleKeyPress = event => {
    if (event.charCode === 13) {
      event.target.blur()
    }
  }


  handleSelectedFilterChange = (filter) => {

    const {facility, department, profession, specialty} = filter?.params || {};

    this.setState({facility, department, profession, specialty, selectedSavedFilter: filter}, this.handleFilterChange)
  };

  render() {
    const {selectedTheme, t} = this.props;
    const {facility, department, profession, specialty, selectedSavedFilter} = this.state;

    return (
      <div className='d-flex flex-column filtersContainer'>
        <div className="d-flex justify-content-between flex-wrap align-items-center">
          <SettingsFilters
            selectedTheme={selectedTheme}
            onChange={this.handleFilterChange}
            onReset={this.resetFiltersValues}
            selectedFilters={{facility, department, profession, specialty}}
          />
          <SavedFilters
            selectedTheme={selectedTheme}
            selectedFilters={{facility, department, profession, specialty}}
            onSelectedFilterChange={this.handleSelectedFilterChange}
          />
        </div>
        <div className="divider"/>
        <div className="searchContainer px-0">
          <SearchComponent onSearch={(value) => this.handleFilterChange("name", value)}
                           onKeyPress={this.handleKeyPress}
                           placeholder={t("Search...")}
                           className={"inputField"}
                           hideIcon
          />
        </div>
      </div>
    )
  }
}

export default withTranslation('common')(FiltersBar)