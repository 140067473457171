import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { getPageMembers, removePageMember, updatePageMembers } from "../../../reducers/contacts";
import { updateUser, changeUserStatus } from "../../../reducers/user";
import { deleteSuffix, getSuffixById, updateSuffix } from "../../../reducers/suffixes";
import EditSuffixComponent from "../../../components/Admin/Components/Suffixes/EditSuffixComponent";


const mapStateToProps = state => {
  const suffixId = state.suffixes.suffix.id;
  const selectedTheme = state.auth.data?.settings?.theme;
  return {
    selectedTheme,
    users: state.users.users,
    usersLength: state.users.total,
    suffix: state.suffixes.suffix,
    members: state.contacts.pageMembers[suffixId],
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getSuffixById,
  updateSuffix,
  getPageMembers,
  removePageMember,
  updateUser,
  updatePageMembers,
  changeUserStatus,
  deleteSuffix
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditSuffixComponent);
