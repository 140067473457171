import React, { PureComponent } from 'react';
import Select from 'react-select'
import _ from 'lodash';
import {appTheme} from "../../config";
import ModalSearchIcon from "../../containers/ContactsListContainer/ModalSearchIconContainer";
import { withTranslation } from 'react-i18next';

class AdvancedSearchComponent extends PureComponent {
  state = {
    typedValue: "",
    selectedOptions: []
  }

  generateOptions = () => {
    const { typedValue, selectedOptions = [] } = this.state;

    const trimValue = _.trim(typedValue || '');

    const options = [];

    if (!!trimValue) {

      const isDepartmentSelected = !!selectedOptions.find(option => option.value.includes('department:'));
      const isFacilitySelected = !!selectedOptions.find(option => option.value.includes('facility:'));
      const isProfessionSelected = !!selectedOptions.find(option => option.value.includes('profession:'));
      const isSpecialtySelected = !!selectedOptions.find(option => option.value.includes('specialty:'));
      const isSuffixSelected = !!selectedOptions.find(option => option.value.includes('suffix:'));
      const isNameSelected = !!selectedOptions.find(option => option.value.includes('name:'));

      if (!isDepartmentSelected) {
        options.push(
          {value: `department:${trimValue}`, label: `Department: ${trimValue}`},
        )
      }

      if (!isFacilitySelected) {
        options.push(
          {value: `facility:${trimValue}`, label: `Facility: ${trimValue}`},
        )
      }

      if (!isProfessionSelected) {
        options.push(
          {value: `profession:${trimValue}`, label: `Profession: ${trimValue}`},
        )
      }

      if (!isSpecialtySelected) {
        options.push(
          {value: `specialty:${trimValue}`, label: `Specialty: ${trimValue}`},
        )
      }

      if (!isSuffixSelected) {
        options.push(
          {value: `suffix:${trimValue}`, label: `Suffix: ${trimValue}`}
        )
      }

      if (!isNameSelected) {
        options.push(
          {value: `name:${trimValue}`, label: `Name: ${trimValue}`}
        )
      }
    }

    return options;
  }

  handleInputValueChange = (typedValue) => {
    this.setState({typedValue});
  }

  handleOptionsChange = (selectedOptions) => {
    this.setState({selectedOptions: selectedOptions || []});
    this.submitSearch(selectedOptions);
  }

  submitSearch = selectedOptions => {
    const data = {};
    selectedOptions && selectedOptions.forEach(selectedOption => {
      const selectedValue = selectedOption.value.split(":");
      data[selectedValue[0]] = selectedValue[1]
    });
    this.props.onSearch(data)
  }

  render() {
    const {theme, t} = this.props;
    const options = this.generateOptions();

    return (
      <div className="advancedSearchComponentContainer">
        <label><img alt="" src={`/assets/images/Oval${theme === appTheme.DARK ? '-dark' : ''}.svg`} /></label>
        <Select
          options={options}
          isMulti
          onInputChange={this.handleInputValueChange}
          onChange={this.handleOptionsChange}
          placeholder={t('chat.search_contact')}
          className="advancedSearchContainer"
          classNamePrefix="advancedSearch"
          components={{ IndicatorsContainer:() => null }}
        />
        <ModalSearchIcon />
      </div>
    )
  }

}

export default withTranslation('common')(AdvancedSearchComponent)