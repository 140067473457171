import _ from "lodash";
import { createLocalVideoTrack, createLocalAudioTrack } from 'twilio-video';
import {
  CallApiService,
  NotificationService,
  ToastrService,
  SoundService,
  TwilioVideoChatService
} from '../services';
import {
  appSounds,
  callStatuses,
  constantMessages,
  callStatusesForParticipant,
  systemMessages,
  routes,
  callRoomEventType
} from '../config';
import logo from "../assets/icons/ECS-logo-in1.png";
import { sendSystemMessage } from "./twilio";
import moment from "moment";
import { getIsShared } from "../utils/function-utils";
import history from "../helpers/history";
import { SocketService } from "../services";
import store from '../store';
import { getChatRoomsList, UPDATE_CHAT_ROOM_SUCCESS_ACTION } from "./chatRooms";
import { isFirefox, isSafari } from "../helpers/function";
import i18n from "../i18n";

export const HANDLE_RECEIVED_INITIALIZED_CALL = "calls/HANDLE_RECEIVED_INITIALIZED_CALL";
export const DECLINE_CALL = "calls/DECLINE_CALL";
export const ACCEPT_CALL = "calls/ACCEPT_CALL";
export const UPDATE_CALL = "calls/UPDATE_CALL";
export const END_CALL = "calls/END_CALL";
export const END_SCREEN_SHARING = "calls/END_SCREEN_SHARING";
export const UPDATE_CALL_TWID = "calls/UPDATE_CALL_TWID";

export const TOGGLE_SCREEN_TRACK_SUCCESS_ACTION = 'calls/TOGGLE_SCREEN_TRACK_SUCCESS_ACTION';

export const TOGGLE_VOICE_TRACK_PREFIX = 'calls/TOGGLE_VOICE_TRACK';
export const TOGGLE_VOICE_TRACK_REQUEST_ACTION = TOGGLE_VOICE_TRACK_PREFIX + '_REQUEST_ACTION';
export const TOGGLE_VOICE_TRACK_SUCCESS_ACTION = TOGGLE_VOICE_TRACK_PREFIX + '_SUCCESS_ACTION';
export const TOGGLE_VOICE_TRACK_FAILURE_ACTION = TOGGLE_VOICE_TRACK_PREFIX + '_FAILURE_ACTION';

export const TOGGLE_VIDEO_TRACK_PREFIX = 'calls/TOGGLE_VIDEO_TRACK';
export const TOGGLE_VIDEO_TRACK_REQUEST_ACTION = TOGGLE_VIDEO_TRACK_PREFIX + '_REQUEST_ACTION';
export const TOGGLE_VIDEO_TRACK_SUCCESS_ACTION = TOGGLE_VIDEO_TRACK_PREFIX + '_SUCCESS_ACTION';
export const TOGGLE_VIDEO_TRACK_FAILURE_ACTION = TOGGLE_VIDEO_TRACK_PREFIX + '_FAILURE_ACTION';

export const TOGGLE_CALLS_REJECT = 'calls/TOGGLE_CALLS_REJECT';

const timerValue = 60 * 1000
let ringingTimer

const initialState = {
  calls: [],
  localTracks: [],
  areCallsRejected: false,
  callTwId: null,
}

//REDUCER

export default (state = initialState, action) => {
  switch (action.type) {
    case HANDLE_RECEIVED_INITIALIZED_CALL:
      return {
        ...state,
        calls: [...state.calls, action.payload.data]
      }
    case DECLINE_CALL:
      return {
        ...state,
        calls: state.calls.filter(call => call.twId !== action.payload.twId)
      }
    case ACCEPT_CALL:
      const acceptCallIndex = state.calls.findIndex(call => call.twId === action.payload.data.twId);
      if (acceptCallIndex === -1) {
        return {
          ...state,
          calls: [...state.calls, action.payload.data]
        };
      }
      return {
        ...state,
        calls: [
          ...state.calls.slice(0, acceptCallIndex),
          action.payload.data,
          ...state.calls.slice(acceptCallIndex + 1)
        ]
      }
    case UPDATE_CALL:
      const updateCallIndex = state.calls.findIndex(call => call.twId === action.payload.twId);
      if (updateCallIndex === -1) {
        return state;
      }
      return {
        ...state,
        calls: [
          ...state.calls.slice(0, updateCallIndex),
          _.merge(state.calls[updateCallIndex], action.payload),
          ...state.calls.slice(updateCallIndex + 1)
        ]
      }
    case END_CALL:
      const endedCallIndex = state.calls.findIndex(call => call.twId === action.payload.data?.twId);
      if (endedCallIndex === -1) {
        return state;
      }
      return {
        ...state,
        calls: [
          ...state.calls.slice(0, endedCallIndex),
          ...state.calls.slice(endedCallIndex + 1)
        ],
        localTracks: []
      }
    case UPDATE_CALL_TWID:
      return {
        ...state,
        callTwId: action.payload
      }
    case TOGGLE_SCREEN_TRACK_SUCCESS_ACTION:
    case TOGGLE_VOICE_TRACK_SUCCESS_ACTION:
    case TOGGLE_VIDEO_TRACK_SUCCESS_ACTION:
      if (action.payload?.track) {
        return {
          ...state,
          localTracks: [...state.localTracks, action.payload.track]
        }
      }

      const trackIndex = state.localTracks.findIndex(track => track.kind === action.payload.type);

      return {
        ...state,
        localTracks: [
          ...state.localTracks.slice(0, trackIndex),
          ...state.localTracks.slice(trackIndex + 1)
        ]
      }
    case END_SCREEN_SHARING:
      const screenShareTrackIndex = state.localTracks.findIndex(track => getIsShared([track]));
      return {
        ...state,
        localTracks: [
          ...state.localTracks.slice(0, screenShareTrackIndex),
          ...state.localTracks.slice(screenShareTrackIndex + 1)
        ]
      }
    case TOGGLE_CALLS_REJECT:
      return {
        ...state,
        areCallsRejected: action.payload
      }
    default:
      return state
  }
}

//ACTIONS

export function initializeDirectScreenShare({ twId }) {
  return (dispatch, getState) => {
    const { id } = getState().auth.data;

    const callData = {
      twId,
      type: callRoomEventType.SCREEN_SHARE,
      eventOwnerId: id,
      status: callStatuses.INITIALIZED,
      participants: [
        {
          id,
          video: false,
          voice: false,
          screenShare: false,
          directScreenShare: true
        }
      ]
    };

    const callApiService = new CallApiService();

    try {
      callApiService.initializeDirectScreenShare(callData);

      // system message for the direct screen share initializer
      const outgoingDirectScreenShareEventName = systemMessages.DIRECT_SCREEN_SHARE_OUT_INIT;
      const outgoingDirectScreenShareEvent = {
        name: outgoingDirectScreenShareEventName,
        data: { twId: twId, initiator: id }
      };

      dispatch(sendSystemMessage(outgoingDirectScreenShareEventName, twId, outgoingDirectScreenShareEvent));

    } catch (err) {
      const message = err.message || constantMessages.defaultErrorMessage;
      ToastrService.error(message)
    }
  }
}

export function initializeCall({ twId, video }) {
  return (dispatch, getState) => {
    const { id } = getState().auth.data;
    const { callTwId } = getState().calls;
    if (callTwId === twId) {
      return
    } else {
      dispatch({ type: UPDATE_CALL_TWID, payload: twId });
    }
    const callData = {
      twId,
      eventOwnerId: id,
      status: callStatuses.INITIALIZED,
      type: video ? callRoomEventType.VIDEO : callRoomEventType.VOICE,
      participants: [
        {
          id,
          video,
          voice: true,
          voiceDuringScreenShare: true,
          screenShare: false
        }
      ]
    };

    const callApiService = new CallApiService();

    try {
      callApiService.initializeCall(callData);

      // system message for the call initializer
      const outgoingCallEventName = video ? systemMessages.VIDEO_OUT_CALL_INIT : systemMessages.CALL_OUT_INIT;
      const outgoingCallEvent = { name: outgoingCallEventName, data: { twId: twId, initiator: id } };

      dispatch(sendSystemMessage(outgoingCallEventName, twId, outgoingCallEvent));

    } catch (err) {
      const message = err.message || constantMessages.defaultErrorMessage;
      ToastrService.error(message)
    }
  }
}

export function declineCall(twId, isTimedOut) {
  return (dispatch, getState) => {
    const { id } = getState().auth.data;
    const { calls } = getState().calls;

    const call = calls.find(call => call.twId === twId);

    if (!call) {
      return
    }

    const participants = isTimedOut
      ? call.participants.map(participant => ({
        ...participant, status: callStatusesForParticipant.REJECTED
      }))
      : call.participants.map(participant => {
        if (participant.id === id) {
          return {
            ...participant,
            status: callStatusesForParticipant.REJECTED
          }
        }
        return participant
      })

    const callData = {
      ...call,
      eventOwnerId: id,
      participants
    }

    const callApiService = new CallApiService();

    const isDirectScreenShare = call.type === callRoomEventType.SCREEN_SHARE;

    try {
      delete callData.callRoom
      callApiService.declineCall(callData)
      SoundService.stop(appSounds.CALL_SOUND);

      // system message
      const isVideoCall = call.type === callRoomEventType.VIDEO;
      const isCallAborted = callData.status === callStatuses.INITIALIZED && callData.initializerId === id;
      let eventName;

      if (!isTimedOut) {
        if (isCallAborted) {
          eventName = isDirectScreenShare ? systemMessages.DIRECT_SCREEN_SHARE_ABORTED :
            isVideoCall ? systemMessages.VIDEO_CALL_ABORTED : systemMessages.CALL_ABORTED
        } else {
          eventName = isDirectScreenShare ? systemMessages.DIRECT_SCREEN_SHARE_DECLINED :
            isVideoCall ? systemMessages.VIDEO_CALL_DECLINED : systemMessages.CALL_DECLINED
        }
      }
      const event = { name: eventName, data: { twId: callData.twId, initiator: id } };

      dispatch(sendSystemMessage(eventName, callData.twId, event))
      dispatch(getChatRoomsList())
    } catch (err) {
      const message = err.message || constantMessages.defaultErrorMessage;
      ToastrService.error(message)
    }
  }
}

export function acceptCall(twId, onlyVoice, ongoingCall) {
  return (dispatch, getState) => {
    const { id } = getState().auth.data;
    const { calls } = getState().calls;

    const activeCall = calls.find(call => call.participants?.find(participant => participant.id === id && participant.status === callStatusesForParticipant.ACCEPTED))

    if (activeCall && activeCall.twId !== twId) {
      dispatch(endCall(activeCall.twId))
    }

    const call = ongoingCall || calls.find(call => (call.twId || call.callRoomId) === twId);

    if (!call) {
      return
    }

    const isDirectScreenShare = call.type === callRoomEventType.SCREEN_SHARE;

    const callData = {
      ...call,
      eventOwnerId: id,
      participants: call.participants.map(participant => {
        if (participant.id === id) {
          return {
            ...participant,
            status: callStatusesForParticipant.ACCEPTED,
            video: !isDirectScreenShare && !onlyVoice,
            voice: !isDirectScreenShare,
            voiceDuringScreenShare: !isDirectScreenShare
          }
        }
        return participant
      }),
      twId: call.twId || call.callRoomId,
      initializerId: call.initializerId || call.author
    }

    const callApiService = new CallApiService();

    try {
      callApiService.acceptCall(callData)

      // system message
      let eventName = "";

      if (isDirectScreenShare) {
        eventName = (callData.status === callStatuses.STARTED ? systemMessages.DIRECT_SCREEN_SHARE_JOINED : systemMessages.DIRECT_SCREEN_SHARE_ACCEPTED);
      } else {
        const isVideoCall = call.type === callRoomEventType.VIDEO;
        eventName = isVideoCall ?
          (callData.status === callStatuses.STARTED ? systemMessages.VIDEO_CALL_JOINED : systemMessages.VIDEO_CALL_ACCEPTED) :
          (callData.status === callStatuses.STARTED ? systemMessages.CALL_JOINED : systemMessages.CALL_ACCEPTED);
      }


      const event = { name: eventName, data: { twId: callData.twId, initiator: id } };

      dispatch(sendSystemMessage(eventName, callData.twId, event))

      if (ringingTimer) {
        clearTimeout(ringingTimer)
      }

      history.push({
        pathname: routes.recent.path,
        state: { selectedChatRoomTWId: callData.twId }
      });
    } catch (err) {
      const message = err.message || constantMessages.defaultErrorMessage;
      ToastrService.error(message)
    }
  }
}

export function endCall(twId) {
  return async (dispatch, getState) => {
    const { id } = getState().auth.data;
    const { calls } = getState().calls;
    const call = calls.find(call => call.twId === twId);

    if (!call) {
      return
    }

    const callData = {
      ...call,
      eventOwnerId: id,
      participants: call.participants.map(participant => {
        if (participant.id === id) {
          return {
            ...participant,
            status: callStatusesForParticipant.DROPPED
          }
        }
        return participant
      })
    }

    const acceptedParticipants = callData.participants.filter(p => p.status === callStatusesForParticipant.ACCEPTED)

    if (acceptedParticipants.length === 1) {
      callData.status = callStatuses.ENDED
    }

    const callApiService = new CallApiService();

    const isDirectScreenShare = callData.type === callRoomEventType.SCREEN_SHARE;

    try {
      delete callData.callRoom
      callApiService.endCall(callData)
      await call.callRoom?.disconnect();

      // system message
      let eventName = "";

      if (isDirectScreenShare) {
        eventName = systemMessages.DIRECT_SCREEN_SHARE_LEFT;
      } else {
        const isVideoCall = callData.type === callRoomEventType.VIDEO;
        eventName = isVideoCall ? systemMessages.VIDEO_CALL_LEFT : systemMessages.CALL_LEFT;
      }

      const event = { name: eventName, data: { twId: callData.twId, initiator: id } };

      dispatch(sendSystemMessage(eventName, callData.twId, event))
    } catch (err) {
      const message = err.message || constantMessages.defaultErrorMessage;
      ToastrService.error(message)
    }
  }
}

export function endCallForAll(twId) {
  return async (dispatch, getState) => {
    const { id } = getState().auth.data;
    const { calls } = getState().calls;

    const call = calls.find(call => call.twId === twId);

    if (!call) {
      return
    }

    const callData = {
      ...call,
      eventOwnerId: id,
      participants: call.participants.map(participant => (
        {
          ...participant,
          status: callStatusesForParticipant.DROPPED
        }
      ))
    }

    const callApiService = new CallApiService();
    const isDirectScreenShare = callData.type === callRoomEventType.SCREEN_SHARE;
    try {
      delete callData.callRoom
      callApiService.endCallForAll(callData)
      await call.callRoom?.disconnect();

      // system message
      let eventName = "";

      if (isDirectScreenShare) {
        eventName = systemMessages.DIRECT_SCREEN_SHARE_LEFT;
      } else {
        const isVideoCall = callData.type === callRoomEventType.VIDEO;
        eventName = isVideoCall ? systemMessages.VIDEO_CALL_LEFT : systemMessages.CALL_LEFT;
      }
      const event = { name: eventName, data: { twId: callData.twId, initiator: id } };

      dispatch(sendSystemMessage(eventName, callData.twId, event))
    } catch (err) {
      const message = err.message || constantMessages.defaultErrorMessage;
      ToastrService.error(message)
    }
  }
}

export function startShareScreen(twId, settings) {
  return async (dispatch, getState) => {
    const { id } = getState().auth.data;
    const { calls } = getState().calls;

    const call = calls.find(call => call.twId === twId);

    if (!call) {
      return
    }

    const stream = await navigator.mediaDevices
      .getDisplayMedia({
        audio: false,
        video: {
          frameRate: 10,
          ...settings
        },
      });

    const track = stream.getVideoTracks()[0];

    const truckPublication = await call.callRoom?.localParticipant?.publishTrack(track, {
      name: 'screen',
      priority: 'low'
    });

    const isDirectScreenShare = call.type === callRoomEventType.SCREEN_SHARE;

    if (isDirectScreenShare) {
      track.addEventListener('ended', () => dispatch(endCallForAll(call.twId)));
      truckPublication.onended = () => dispatch(endCallForAll(call.twId));

      dispatch({ type: TOGGLE_SCREEN_TRACK_SUCCESS_ACTION, payload: { track } })
      return
    }

    track.addEventListener('ended', () => dispatch(endShareScreen(call.twId)));
    truckPublication.onended = () => dispatch(endShareScreen(call.twId));


    const callData = {
      ...call,
      eventOwnerId: id,
      participants: call.participants.map(participant => {
        if (participant.id === id) {
          return {
            ...participant,
            screenShare: true
          }
        }
        return participant
      })
    }

    updateCallRoomParticipant(callData.twId, id, {
      screenShare: true
    })

    const callApiService = new CallApiService();

    track.truckPublication = truckPublication;

    delete callData.callRoom

    try {
      callApiService.startScreenShare(callData)
      dispatch({ type: TOGGLE_SCREEN_TRACK_SUCCESS_ACTION, payload: { track } })

      const eventName = systemMessages.SCREEN_SHARING_STARTED
      const event = { name: eventName, data: { twId: callData.twId, initiator: id } };

      dispatch(sendSystemMessage(eventName, callData.twId, event))
    } catch (err) {
      const message = err.message || constantMessages.defaultErrorMessage;
      ToastrService.error(message)
    }
  }
}

export function endShareScreen(twId) {
  return async (dispatch, getState) => {
    const { localTracks } = getState().calls;
    const { calls } = getState().calls;
    const { id } = getState().auth.data;

    const call = calls.find(call => call.twId === twId);
    const localParticipant = call.callRoom?.localParticipant;
    const track = localTracks.find(track => getIsShared([track]));


    if (!call || !localParticipant) {
      return
    }

    const callData = {
      ...call,
      eventOwnerId: id,
      participants: call.participants.map(participant => {
        if (participant.id === id) {
          return {
            ...participant,
            screenShare: false
          }
        }
        return participant
      })
    }

    updateCallRoomParticipant(callData.twId, id, {
      screenShare: false
    })

    const callApiService = new CallApiService();

    try {
      localParticipant.unpublishTrack(track);
      localParticipant.emit('trackUnpublished', track.truckPublication);
      track.removeEventListener('ended', endShareScreen);
      track.stop();

      delete callData.callRoom;
      callApiService.endScreenShare(callData)
      dispatch({ type: END_SCREEN_SHARING })

      const eventName = systemMessages.SCREEN_SHARING_STOPPED
      const event = { name: eventName, data: { twId: callData.twId, initiator: id } };

      dispatch(sendSystemMessage(eventName, callData.twId, event))
    } catch (err) {
      const message = err.message || constantMessages.defaultErrorMessage;
      ToastrService.error(message)
    }
  }
}

// handle received socket events

export function handleReceivedInitializedCall(data) {
  return (dispatch, getState) => {
    const { id, settings } = getState().auth.data;
    const { settings: defaultSettings } = getState().auth;

    const { calls } = getState().calls;
    const { list: chatRoomsList } = getState().chatRooms;

    const chatRoom = chatRoomsList.find(chatRoom => chatRoom.twId === data.callRoomId) || data.chatRoom;

    if (!chatRoom) {
      return
    }

    const playSoundOnNotification = settings?.playNotificationSound || defaultSettings?.playNotificationSound;

    if (!chatRoom.isMuted && playSoundOnNotification) {
      const isRingingCall = calls.some(call => call.participants?.find(participant => participant.id === id)?.status === callStatusesForParticipant.RINGING);

      NotificationService.show({ title: "ECS", body: "Incoming Call", icon: logo })

      if (!isRingingCall) {
        SoundService.play(appSounds.CALL_SOUND);
      }
    }

    const callData = {
      ...data,
      twId: data.twId || data.callRoomId,
      initializerId: data.initializerId || data.author
    }

    dispatch({ type: HANDLE_RECEIVED_INITIALIZED_CALL, payload: { data: callData } });

    if (callData.initializerId !== id) {
      // system message for the call receiver
      const isVideoCall = data.type === callRoomEventType.VIDEO;
      const eventName = isVideoCall ? systemMessages.VIDEO_CALL_INIT : systemMessages.CALL_INIT;
      const event = { name: eventName, data: { twId: callData.twId, initiator: id } };

      dispatch(sendSystemMessage(eventName, callData.twId, event))
      ringingTimer = setTimeout(() => dispatch(declineCall(callData.twId, true)), timerValue)
    }
  }
}

export function handleReceivedInitializedDirectScreenShare(data) {
  return (dispatch, getState) => {
    const { id, settings } = getState().auth.data;
    const { settings: defaultSettings } = getState().auth;

    const { calls } = getState().calls;
    const { list: chatRoomsList } = getState().chatRooms;

    const chatRoom = chatRoomsList.find(chatRoom => chatRoom.twId === data.callRoomId) || data.chatRoom;

    if (!chatRoom) {
      return
    }

    const playSoundOnNotification = settings?.playNotificationSound || defaultSettings?.playNotificationSound;

    if (!chatRoom.isMuted && playSoundOnNotification) {
      const isRingingCall = calls.some(call => call.participants?.find(participant => participant.id === id)?.status === callStatusesForParticipant.RINGING);

      NotificationService.show({ title: "ECS", body: i18n.t('misc.incoming_screen_share'), icon: logo })

      if (!isRingingCall) {
        SoundService.play(appSounds.CALL_SOUND);
      }
    }

    const callData = {
      ...data,
      twId: data.twId || data.callRoomId,
      initializerId: data.initializerId || data.author
    }

    dispatch({ type: HANDLE_RECEIVED_INITIALIZED_CALL, payload: { data: callData } });

    if (callData.initializerId !== id) {
      // system message for the direct screen share receiver
      const eventName = systemMessages.DIRECT_SCREEN_SHARE_INIT;
      const event = { name: eventName, data: { twId: callData.twId, initiator: id } };

      dispatch(sendSystemMessage(eventName, callData.twId, event))
      ringingTimer = setTimeout(() => dispatch(declineCall(callData.twId, true)), timerValue)
    }
  }
}

export function handleDeclinedCall(twId) {
  return (dispatch, getState) => {
    const { id } = getState().auth.data;
    const { calls } = getState().calls;

    const isRingingCall = calls.some(call => call.participants?.find(participant => participant.id === id)?.status === callStatusesForParticipant.RINGING)

    if (isRingingCall) {
      SoundService.stop(appSounds.CALL_SOUND);
    }

    const call = calls.find(call => call.twId === twId);
    if (call && !call.participants?.find(participant => participant.status === callStatusesForParticipant.ACCEPTED) && call.initializerId === id) {
      const eventName = systemMessages.CALL_MISSED;
      const event = { name: eventName, data: { twId, initiator: id } };
      dispatch(sendSystemMessage(eventName, twId, event));
    }

    dispatch({ type: UPDATE_CALL_TWID, payload: null })
    dispatch({ type: DECLINE_CALL, payload: { twId } })
    setTimeout(() => { dispatch(getChatRoomsList()) }, 1500)
  }
}

function createTwilioCallRoom(token, name) {
  return TwilioVideoChatService.create(token, { name, tracks: [] })
}

export function handleAcceptedCall(callData) {
  return async (dispatch, getState) => {
    const { id } = getState().auth.data;
    const { calls } = getState().calls;

    const isRingingCall = calls.some(call => call.participants?.find(participant => participant.id === id)?.status === callStatusesForParticipant.RINGING)

    if (isRingingCall) {
      SoundService.stop(appSounds.CALL_SOUND);
    }

    const currentParticipant = callData.participants?.find(participant => participant.id === id);

    const isDirectScreenShare = callData.type === callRoomEventType.SCREEN_SHARE;

    // case when the call was accepted by the user and user already has the call in the store, i.e the user was re-connected to the call
    if (callData.eventOwnerId === id && calls.find(call => call.twId === callData.twId)?.callRoom?.state === "connected") {
      return
    }

    if (callData.eventOwnerId === id || (callData.initializerId === id && callData.participants.filter(participant => participant.status === callStatusesForParticipant.ACCEPTED).length === 2)) {


      // Case when user accepts the call but from another device, browser or browser's tab
      if (currentParticipant?.socketId !== SocketService.connection?.io?.opts?.query?.uuid) {
        return dispatch({ type: UPDATE_CALL, payload: callData })
      }

      const { videoToken } = getState().twilio;

      const callRoom = await createTwilioCallRoom(videoToken, callData.twId)

      const data = {
        ...callData,
        callRoom
      }

      dispatch({ type: ACCEPT_CALL, payload: { data } })

      callRoom.participants.forEach(participant => {
        dispatch(updateCallRoomParticipant(data.twId, participant.identity, participant))
      });

      if (!isDirectScreenShare) {
        const usersSettings = data.participants?.find(participant => participant.id === id);
        if (usersSettings?.voice) {
          dispatch(toggleTrack('audio'))
        }
        if (usersSettings?.video) {
          dispatch(toggleTrack('video'))
        }
      } else if (callData.initializerId === id && !isSafari && !isFirefox) {

        let stream;

        try {
          stream = await navigator.mediaDevices
            .getDisplayMedia({
              audio: false,
              video: {
                frameRate: 10
              },
            });
        } catch (e) {
          ToastrService.error(i18n.t('misc.need_to_share_sreen'));
          dispatch(endCallForAll(callData.twId))
        }

        if (stream) {
          const track = stream.getVideoTracks()[0];
          track.truckPublication = await callRoom?.localParticipant?.publishTrack(track, {
            name: 'screen',
            priority: 'low'
          });

          track.addEventListener('ended', () => dispatch(endCallForAll(callData.twId)));
          track.truckPublication.onended = () => dispatch(endCallForAll(callData.twId));

          dispatch({ type: TOGGLE_SCREEN_TRACK_SUCCESS_ACTION, payload: { track } })
        }
      }

      callRoom.on('participantConnected', connectedParticipant => {
        dispatch(updateCallRoomParticipant(data.twId, connectedParticipant.identity, connectedParticipant))
      });

      callRoom.on('participantDisconnected', disconnectedParticipant => {
        console.log("TWILIO: PARTICIPANT DISCONNECTED", disconnectedParticipant)
        const currentCallData = getState().calls.calls.find(c => c.twId === callData.twId)
        const updatedParticipants = currentCallData?.participants.filter(p => p.id !== disconnectedParticipant.identity)
        if (updatedParticipants?.length < 2) {
          return dispatch(endCall(callData.twId))
        }
        dispatch(updateCallRoomParticipant(data.twId, disconnectedParticipant.identity, disconnectedParticipant))
      });

      callRoom.on('trackPublished', (track, participant) => {
        dispatch(updateCallRoomParticipant(data.twId, participant.identity, participant))
      });

      callRoom.on('trackUnpublished', (track, participant) => {
        dispatch(updateCallRoomParticipant(data.twId, participant.identity, participant))
      });

    } else {
      const newParticipant = callData.participants.find(participant => participant.id === callData.eventOwnerId);
      dispatch(updateCallRoomParticipant(callData.twId, newParticipant.id, newParticipant));
      const { list } = getState().contacts;
      const newJoinedContact = list.find(contact => contact.id === callData.eventOwnerId);
      if (newJoinedContact?.fullName) {
        ToastrService.info(i18n.t('misc.has_joined', { fullName: newJoinedContact.fullName }))
      }
    }
  }
}

export function updateCallRoomParticipant(twId, participantId, participantData) {
  return (dispatch, getState) => {
    const { calls } = getState().calls;
    const callData = calls.find(call => call.twId === twId);
    const chatRoom = getState().chatRooms.list.find(cr => cr?.twId === callData?.twId)
    if (!callData) {
      return
    }

    if (chatRoom && chatRoom.call) {
      const updatedParticipants = chatRoom.call.participants.map(p => {
        if (p.id === participantId) {
          const status = participantData.status || (participantData.state === 'connected' && 'accepted')
          return { ...p, status }
        }
        return p
      })
      dispatch({
        type: UPDATE_CHAT_ROOM_SUCCESS_ACTION,
        payload: { chatRoom: { ...chatRoom, call: { ...chatRoom.call, participants: updatedParticipants } } }
      })
    }

    dispatch({
      type: UPDATE_CALL, payload: {
        twId: callData.twId,
        participants: callData.participants.map(participant => {
          if (participant.id === participantId) {
            return {
              ...participant,
              status: participantData.state === 'connected' && 'accepted',
              voice: participantData.voice || !!participantData?.audioTracks?.size,
              ...participantData
            }
          }
          return participant
        })
      }
    })
  }
}

export function handleSocketConnectionReconnectForAllOngoingCalls() {
  return (dispatch, getState) => {
    const { calls } = getState().calls;
    if (calls?.length) {
      const { id } = getState().auth.data;

      const ongoingCall = calls.find(call => call.status === callStatuses.STARTED &&
        call.participants?.find(p => p.id === id)?.status === callStatusesForParticipant.ACCEPTED);

      if (
        ongoingCall?.callRoom &&
        ongoingCall.callRoom.state === "connected" &&
        ongoingCall.callRoom.localParticipant?.state === "connected"
      ) {
        const callData = { ...ongoingCall }
        delete callData.callRoom;
        const callApiService = new CallApiService();
        callApiService.acceptCall({ ...callData, eventOwnerId: id })
      } else {
        dispatch({ type: UPDATE_CALL_TWID, payload: null })
        dispatch({ type: END_CALL, payload: { data: { twId: ongoingCall.twId } } });
      }
    }
  }
}

export function forceVoiceToggle(twId, all, eventName, participantId) {
  return (dispatch, getState) => {
    const { calls } = getState().calls;
    const { id } = getState().auth.data;

    const call = calls.find(call => call.twId === twId);

    if (!call) {
      return
    }

    const isUserSharingScreen = call.participants?.find(participant => participant.id === id && participant.status === callStatusesForParticipant.ACCEPTED && participant.screenShare);

    if (isUserSharingScreen) {

      const callApiService = new CallApiService();

      const callData = {
        ...call,
        eventOwnerId: id,
        participants: call.participants.map(participant => {
          delete participant.audioTracks;
          delete participant.dataTracks;
          delete participant.videoTracks;
          delete participant.tracks;
          if (all || participant.id === participantId) {
            return {
              ...participant,
              voiceDuringScreenShare: eventName !== "mute"
            }
          }
          return participant
        })
      }

      delete callData.callRoom
      eventName === "mute" ? callApiService.muteParticipantDuringScreenShare(callData, all, participantId) : callApiService.unMuteParticipantDuringScreenShare(callData, all, participantId)
    }
  }
}

export function handleForceVoiceToggle(twId) {
  return (dispatch, getState) => {
    const { calls, localTracks } = getState().calls;

    const call = calls.find(call => call.twId === twId);

    if (!call) {
      return
    }

    const isSomeoneSharingScreen = call.participants?.find(participant => participant.status === callStatusesForParticipant.ACCEPTED && participant.screenShare);

    if (isSomeoneSharingScreen) {
      const track = localTracks.find(track => track.kind === "audio");
      if (!track) {
        dispatch(toggleTrack("audio"))
      }
    }
  }
}

export function handleVoiceToggle(twId) {
  return (dispatch, getState) => {
    const { calls, localTracks } = getState().calls;
    const { id } = getState().auth.data;

    const call = calls.find(call => call.twId === twId);

    if (!call) {
      return
    }

    const isSomeoneSharingScreen = call.participants?.find(participant => participant.status === callStatusesForParticipant.ACCEPTED && participant.screenShare);

    const callData = {
      ...call,
      eventOwnerId: id,
      participants: call.participants.map(participant => {
        delete participant.audioTracks;
        delete participant.dataTracks;
        delete participant.videoTracks;
        delete participant.tracks;
        return participant
      })
    }

    delete callData.callRoom;
    if (isSomeoneSharingScreen) {
      const callApiService = new CallApiService();
      const track = localTracks.find(track => track.kind === "audio");
      if (!track) {
        dispatch(toggleTrack("audio"))
        callApiService.unMuteParticipantDuringScreenShare(callData, false, id)
        return;
      }
      call.participants?.find(participant => participant.id === id)?.voiceDuringScreenShare ? callApiService.muteParticipantDuringScreenShare(callData, false, id) : callApiService.unMuteParticipantDuringScreenShare(callData, false, id);
    } else {
      dispatch(toggleTrack("audio", true))
    }
  }
}

export function toggleTrack(type, sendUpdate) {
  return async (dispatch, getState) => {

    const toggleTrackActions = type === 'audio' ? {
      prefix: TOGGLE_VOICE_TRACK_PREFIX,
      request: TOGGLE_VOICE_TRACK_REQUEST_ACTION,
      success: TOGGLE_VOICE_TRACK_SUCCESS_ACTION,
      fail: TOGGLE_VOICE_TRACK_FAILURE_ACTION,
    } : {
      prefix: TOGGLE_VIDEO_TRACK_PREFIX,
      request: TOGGLE_VIDEO_TRACK_REQUEST_ACTION,
      success: TOGGLE_VIDEO_TRACK_SUCCESS_ACTION,
      fail: TOGGLE_VIDEO_TRACK_FAILURE_ACTION,
    };

    const { [toggleTrackActions.prefix]: isToggleTrackProcess } = getState().loading;
    if (isToggleTrackProcess) {
      return
    }

    dispatch({ type: toggleTrackActions.request })

    const { id } = getState().auth.data;
    const { calls, localTracks } = getState().calls;

    const track = localTracks.find(track => track.kind === type);

    const startedCall = calls.find(call => call.status === callStatuses.STARTED);

    const isSomeoneSharingScreen = startedCall.participants?.find(participant => participant.status === callStatusesForParticipant.ACCEPTED && participant.screenShare);

    const participantSettingName = type === 'audio' ? isSomeoneSharingScreen ? "voiceDuringScreenShare" : 'voice' : 'video';

    const callData = {
      ...startedCall,
      eventOwnerId: id,
      participants: startedCall.participants.map(participant => {
        delete participant.audioTracks;
        delete participant.dataTracks;
        delete participant.videoTracks;
        if (participant.id === id) {
          return {
            ...participant,
            [participantSettingName]: true
          }
        }
        return participant
      })
    }
    delete callData.callRoom

    if (track) {
      try {
        if (track) {
          dispatch({
            type: UPDATE_CALL, payload: {
              twId: startedCall.twId,
              participants: startedCall.participants.map(participant => {
                if (participant.id === id) {
                  return {
                    ...participant,
                    [participantSettingName]: false
                  }
                }
                return participant
              })
            }
          })

          if (startedCall.callRoom?.localParticipant) {
            await startedCall.callRoom.localParticipant.unpublishTracks([track])
          }
        }

        track.stop();
        dispatch({ type: toggleTrackActions.success, payload: { type } })
        if (sendUpdate && type === "audio") {
          const callApiService = new CallApiService();
          callApiService.muteParticipant(callData, id)
        }
      } catch (err) {
        const message = err?.message || constantMessages.defaultErrorMessage;
        dispatch({ type: toggleTrackActions.fail, payload: { message } });
        ToastrService.error(message)
      }
      return
    }

    return (type === 'audio' ? createLocalAudioTrack : createLocalVideoTrack)()
      .then(async track => {
        const { calls } = getState().calls;
        const startedCall = calls.find(call => call.status === callStatuses.STARTED);
        if (track && startedCall && startedCall.callRoom?.localParticipant) {
          await startedCall.callRoom.localParticipant.publishTrack(track)
          dispatch({
            type: UPDATE_CALL, payload: {
              twId: startedCall.twId,
              participants: callData.participants
            }
          })
          dispatch({ type: toggleTrackActions.success, payload: { track } })
          if (sendUpdate && type === "audio") {
            const callApiService = new CallApiService();
            callApiService.unMuteParticipant(callData, id)
          }
        } else {
          track.stop()
        }
      })
      .catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: toggleTrackActions.fail, payload: { message } });
        ToastrService.error(message)
      })

  }
}

export function checkMediaPermissions() {
  if (navigator.mediaDevices) {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(mediaStream => mediaStream.getTracks().forEach(track => {
        track.stop()
      }))
      .catch(e => {
        ToastrService.error(i18n.t('chat.provide_mic_permission'))
      });
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(mediaStream => mediaStream.getTracks().forEach(track => {
        track.stop()
      }))
      .catch(e => {
        ToastrService.error(i18n.t('misc.provide_camera_permission'))
      });
  } else {
    store.dispatch({ type: TOGGLE_CALLS_REJECT, payload: true })
  }
}

export function handleEndedCall(data) {
  return async (dispatch, getState) => {

    const { calls, localTracks } = getState().calls;
    const { id } = getState().auth.data;

    const call = calls.find(call => call.twId === data.twId);

    if (!call) {
      return
    }

    try {
      await call.callRoom?.disconnect();
      SoundService.stop(appSounds.CALL_SOUND);
      localTracks.forEach(localTrack => localTrack.stop());
      dispatch({ type: UPDATE_CALL_TWID, payload: null })
      dispatch({ type: END_CALL, payload: { data } });

      const contact = data.participants.find(c => c.id === id)
      const sendMessage = contact?.state === "connected" || (data.participants.every(p => p.status === 'dropped') && data.initializerId === id)

      if (data.status === callStatuses.ENDED && sendMessage) {
        if (call.status === callStatuses.INITIALIZED) {
          const eventName = systemMessages.CALL_MISSED;
          const event = { name: eventName, data: { twId: data.twId, initiator: id } };
          dispatch(sendSystemMessage(eventName, data.twId, event));
          return
        }

        const isDirectScreenShare = call.type === callRoomEventType.SCREEN_SHARE;
        // system message
        const callTimeInSeconds = data.startedAt ? moment.duration(moment(new Date()).diff(moment(data.startedAt)))?.asSeconds() : 0;
        const callDuration = moment.utc(callTimeInSeconds * 1000).format(callTimeInSeconds >= 3600 ? "HH:mm:ss" : "mm:ss");

        let eventName = "";

        if (isDirectScreenShare) {
          eventName = systemMessages.DIRECT_SCREEN_SHARE_ENDED;
        } else {
          const isVideoCall = call.type === callRoomEventType.VIDEO;
          eventName = isVideoCall ? systemMessages.VIDEO_CALL_ENDED : systemMessages.CALL_ENDED;
        }

        const event = { name: eventName, data: { twId: data.twId, initiator: id, callDuration } };

        dispatch(sendSystemMessage(eventName, data.twId, event))
      }
    } catch (err) {
      const message = err.message || constantMessages.defaultErrorMessage;
      ToastrService.error(message)
    }

  }
}
