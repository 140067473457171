/* eslint-disable react/style-prop-object */
import React, { Component } from "react";

import "./EULA.scss";
import { LanguageSelector } from "../../components/UIComponents/LanguageSelector";
import i18n from "../../i18n";

export default class EULA extends Component {
  render() {
    return (
      <div className="EULA">
        <LanguageSelector onChange={i18n.changeLanguage} value={i18n.language} />

        <div dangerouslySetInnerHTML={{ __html: i18n.language === 'en' ? html_en : html_fr }}>
        </div>
      </div>
    );
  }
}

const html_en = ` <div class="WordSection1">
<p
  class="MsoNormal"
  align="center"
  style="margin-bottom:0in;margin-bottom:.0001pt;
text-align:center;line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:16.0pt;font-family:"Times New Roman",serif'>
      END USER LICENSE AGREEMENT
    </span>
  </b>
</p>

<p
  class="MsoNormal"
  align="center"
  style="margin-bottom:0in;margin-bottom:.0001pt;
text-align:center;line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      &nbsp;
    </span>
  </b>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    The herein contained End User License Agreement (the "
    <b
      style="mso-bidi-font-weight:
normal"
    >
      Agreement
    </b>
    " or "<b style="mso-bidi-font-weight:normal">License</b>" or "
    <b style="mso-bidi-font-weight:normal">EULA</b>") shall be
    considered a legally binding agreement between you (an individual
    or an entity, hereinafter "
    <b style="mso-bidi-font-weight:normal">Licensee</b>" or "
    <b
      style="mso-bidi-font-weight:
normal"
    >
      You
    </b>
    " or "<b style="mso-bidi-font-weight:normal">Your</b>") and 
    <span
      id="static_software_product_license_name"
      class="saved-var software_product_license_name step-1"
    >
      ECS Clinical, LLC
    </span>
    <span style="mso-spacerun:yes">&nbsp;</span>for the use of the
    specified software: 
    <span
      id="static_software_product"
      class="saved-var software_product step-0"
    >
      ECS Clinical
    </span>
    , which may include related printed material, media and any other
    components and/or software modules, including but not limited to
    required drivers (the "
    <b style="mso-bidi-font-weight:normal">Product</b>"). Other
    aspects of the Product may also include, but are not limited to,
    software updates and any upgrades necessary that 
    <span
      id="static_software_product_license_name"
      class="saved-var software_product_license_name step-1"
    >
      ECS Clinical, LLC
    </span> 
    may supply to You or make available to You, or that You obtain
    after the initial copy of the Product, and as such that said items
    are not accompanied by a separate license agreement or terms of
    use.
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      BY WAY OF THE INSTALLATION, COPYING, DOWNLOADING, ACCESSING OR
      OTHERWISE USE OF THIS PRODUCT, YOU ARE AGREEING TO BE LEGALLY
      BOUND BY THE HEREIN CONTAINED TERMS OF THIS LICENSE AGREEMENT.
      IF YOU DO NOT AGREE TO BE BOUND BY THE TERMS OF THIS EULA, YOU
      THEN HAVE NO RIGHTS TO THE PRODUCT AND SHOULD THEREFORE NOT
      INSTALL, COPY, DOWNLOAD, ACCESS NOR USE THE PRODUCT.
    </span>
  </b>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      THIS PRODUCT IS PROTECTED BY COPYRIGHT LAWS, AS WELL AS ANY
      OTHER INTELLECTUAL PROPERTY LAWS. THIS PRODUCT IS LICENSED AND
      NOT SOLD.
    </span>
  </b>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      1.
      <span style="mso-tab-count:1">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
      </span>
      DEFINITIONS AND INTERPRETATIONS
    </span>
  </b>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    1.01<span style="mso-tab-count:1">&nbsp;&nbsp;&nbsp;&nbsp; </span>
    "Agreement" or "License" or "EULA" shall mean this End User
    License Agreement.
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    1.02<span style="mso-tab-count:1">&nbsp;&nbsp;&nbsp;&nbsp; </span>
    "Licensee" or "You" or "Your" shall mean You, the individual or
    business entity licensing the Product under of the terms of this
    Agreement.
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    1.03<span style="mso-tab-count:1">&nbsp;&nbsp;&nbsp;&nbsp; </span>
    "Intellectual Property" means current and future worldwide rights
    under patent law, copyright law, trade secret law, trademark law,
    moral rights law, and other similar rights.
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    1.04<span style="mso-tab-count:1">&nbsp;&nbsp;&nbsp;&nbsp; </span>
    "Update" means maintenance of, or a fix to, a version of Product,
    including, but not limited to: a hot fix, patch, or enhancement,
    none of which function as a standalone service or other software
    package and which do not have an additional cost for any existing
    Licensee.
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    1.05<span style="mso-tab-count:1">&nbsp;&nbsp;&nbsp;&nbsp; </span>
    "Upgrade" means a major, standalone version of Product, which may
    include additional applications, features, or functionality.
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    1.06<span style="mso-tab-count:1">&nbsp;&nbsp;&nbsp;&nbsp; </span>
    A person includes a natural person, corporate or unincorporated
    body (whether or not having separate legal personality) and that
    person's legal and personal representatives, successors and
    permitted assigns.<span style="mso-spacerun:yes">&nbsp; </span>
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    1.07<span style="mso-tab-count:1">&nbsp;&nbsp;&nbsp;&nbsp; </span>
    Words in the singular shall include the plural and vice versa.
    <span style="mso-spacerun:yes">&nbsp; </span>
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    1.08<span style="mso-tab-count:1">&nbsp;&nbsp;&nbsp;&nbsp; </span>
    A reference to one gender shall include a reference to the other
    genders. 
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    1.09<span style="mso-tab-count:1">&nbsp;&nbsp;&nbsp;&nbsp; </span>
    A reference to a statute, statutory provision or subordinate
    legislation is a reference to it as it is in force from time to
    time, taking account of any amendment or reenactment and includes
    any statute, statutory provision or subordinate legislation which
    it amends or re-enacts; provided that, as between the Parties, no
    such amendment or re-enactment shall apply for the purposes of
    this Agreement to the extent that it would impose any new or
    extended obligation, liability or restriction on, or otherwise
    adversely affect the rights of, any Party. 
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    1.10<span style="mso-tab-count:1">&nbsp;&nbsp;&nbsp;&nbsp; </span>
    A reference to writing or written includes e-mail.
    <span style="mso-spacerun:yes">&nbsp; </span>
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    1.11<span style="mso-tab-count:1">&nbsp;&nbsp;&nbsp;&nbsp; </span>
    Any obligation in this Agreement on a person not to do something
    includes an obligation not to agree or allow that thing to be
    done. 
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    1.12<span style="mso-tab-count:1">&nbsp;&nbsp;&nbsp;&nbsp; </span>
    Any phrase introduced by the terms "including", "include", "in
    particular" or any similar expression shall be construed as
    illustrative and shall not limit the sense of the words preceding
    those terms. 
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    1.13<span style="mso-tab-count:1">&nbsp;&nbsp;&nbsp;&nbsp; </span>
    References to articles, sections, or clauses are to the articles,
    sections, and clauses of this Agreement.
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    1.14<span style="mso-tab-count:1">&nbsp;&nbsp;&nbsp;&nbsp; </span>
    "We", "us", and "our", means
    <span
      id="static_software_product_license_name"
      class="saved-var software_product_license_name step-1"
    >
      ECS Clinical, LLC
    </span>
    .
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      2.
      <span style="mso-tab-count:1">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
      </span>
      LICENSE GRANT.
    </span>
  </b>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
     
    <span
      id="static_software_product_license_name"
      class="saved-var software_product_license_name step-1"
    >
      ECS Clinical, LLC
    </span>
    <span style="mso-spacerun:yes">&nbsp;</span>shall grant to You a
    non-exclusive license for the use and installation of the Product
    subject to all the terms and conditions set forth herein.
    Furthermore, this EULA shall also govern any and all software
    Updates and Upgrades provided by 
    <span
      id="static_software_product_license_name"
      class="saved-var software_product_license_name step-1"
    >
      ECS Clinical, LLC
    </span>
    <span style="mso-spacerun:yes">&nbsp;</span>that would replace,
    overwrite and/or supplement the original installed version of the
    Product, unless those other Updates and Upgrades are covered under
    a separate license, in which case the terms of that license will
    govern.
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      3.
      <span style="mso-tab-count:1">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
      </span>
      TERMINATION.
    </span>
  </b>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    Should You breach this EULA, Your right to the use of the Product
    will immediately terminate and shall terminate without any notice
    being given. However, all provisions of this EULA, with the
    exception of the License grant, shall survive termination and will
    remain in effect. Upon termination of the License grant, You 
    <b style="mso-bidi-font-weight:normal">
      <u>MUST</u>
    </b> 
    destroy any and all copies of the Product.
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      4.
      <span style="mso-tab-count:1">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
      </span>
      INTELLECTUAL PROPERTY
    </span>
  </b>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'></span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      4.01
      <span style="mso-tab-count:1">&nbsp;&nbsp;&nbsp;&nbsp; </span>
      PROTECTED PRODUCT
    </span>
  </b>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    . The Product is protected by copyright and other Intellectual
    Property laws and treaties, and as such all rights, title, and
    interest in and to the content offered, including but not limited
    to, any photographs, images, video animation, text, and music,
    that may be incorporated as part of the offered content. Such
    offered content is protected by copyright laws and international
    treaty provisions. Therefore, offered content must be treated as
    any other copyrighted material, with the exception that it is
    allowable for You to make copies as provided by the License.
    However, printed material, which may accompany any offered
    content, may not be copied. 
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      4.02 <span style="mso-tab-count:1">&nbsp;&nbsp;&nbsp; </span>NO
      GRANT OF RIGHTS
    </span>
  </b>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    . Except as expressly stated herein, this Agreement does not grant
    either Party any rights to the other's content or any of the
    other's Intellectual Property. Specifically, 
    <span
      id="static_software_product_license_name"
      class="saved-var software_product_license_name step-1"
    >
      ECS Clinical, LLC
    </span> 
    owns all Intellectual Property rights in Product.
  </span>
</p>

<p
  class="MsoListParagraph"
  style="margin-bottom:0in;margin-bottom:.0001pt;
mso-add-space:auto;text-align:justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      4.03
      <span style="mso-tab-count:1">&nbsp;&nbsp;&nbsp;&nbsp; </span>
      RIGHT OF PUBLICITY
    </span>
  </b>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    . You agree, with respect to publicity that: (a) 
    <span
      id="static_software_product_license_name"
      class="saved-var software_product_license_name step-1"
    >
      ECS Clinical, LLC
    </span> 
    may include Licensee or its brand in a list of Licensees, online,
    or in promotional materials; and (b) 
    <span
      id="static_software_product_license_name"
      class="saved-var software_product_license_name step-1"
    >
      ECS Clinical, LLC
    </span> 
    may verbally reference Licensee as a licensee of Product.
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      5.
      <span style="mso-tab-count:1">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
      </span>
      RESTRICTIONS ON USE
    </span>
  </b>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    . As a Licensee, You may not: (a) Make use of the offered content
    on more than one computer at a time, without prior purchase of
    additional licenses; (b) You may not share, distribute, lend,
    lease, sublicense or otherwise make available, in any manner
    whatsoever, to any third party the offered content; (c) Modify,
    adapt, create derivative works from or translate any part of the
    offered content other than what may be used within Your work in
    accordance with this License; (d) Reverse engineer, decompile or
    disassemble the offered content, nor attempt to locate or obtain
    its source code; (e) Attempt to alter or remove any trademark,
    copyright or other proprietary notice contained within the offered
    content; or (f) Make use of any offered content in any manner not
    stipulated within this EULA or the documentation accompanying the
    offered content.
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;line-height:normal;tab-stops:40.5pt"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      6.
      <span style="mso-tab-count:1">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      UPDATES/UPGRADES
    </span>
  </b>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    . 
    <span
      id="static_software_product_license_name"
      class="saved-var software_product_license_name step-1"
    >
      ECS Clinical, LLC
    </span>
    <span style="mso-spacerun:yes">&nbsp;</span>may find the need to
    make available Updates or Upgrades for the Product, in accordance
    with the herein contained terms and conditions of this EULA. It
    shall be at the sole discretion of 
    <span
      id="static_software_product_license_name"
      class="saved-var software_product_license_name step-1"
    >
      ECS Clinical, LLC
    </span>
    to make conditional releases of said Updates or Upgrades to You
    upon Your acceptance of another EULA or execution of another
    separate agreement. Should You elect to install and make use of
    these updates, You are therefore agreeing to be subject to all
    applicable license, terms and conditions of this EULA and/or any
    other agreement.<b style="mso-bidi-font-weight:normal"></b>
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      7.
      <span style="mso-tab-count:1">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
      </span>
      DISCLAIMER OF WARRANTY. 
    </span>
  </b>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
     
    <span
      id="static_software_product_license_name"
      class="saved-var software_product_license_name step-1"
    >
      ECS Clinical, LLC
    </span>
    <span style="mso-spacerun:yes">&nbsp;</span>shall use reasonable
    efforts consistent with prevailing industry standards to maintain
    Product in a manner which minimizes errors and interruptions.
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span
      style='font-size:12.0pt;font-family:
"Times New Roman",serif'
    >
      HOWEVER, 
      <span
        id="static_software_product_license_name"
        class="saved-var software_product_license_name step-1"
      >
        ECS Clinical, LLC
      </span> 
      DOES NOT WARRANT THAT PRODUCT WILL BE UNINTERRUPTED OR ERROR
      FREE; NOR DOES IT MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY
      BE OBTAINED FROM USE OF PRODUCT.
      <span style="mso-spacerun:yes">&nbsp; </span>
    </span>
  </b>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      &nbsp;
    </span>
  </b>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span
      style='font-size:12.0pt;font-family:
"Times New Roman",serif'
    >
      TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, EXCEPT AS
      EXPRESSLY PROVIDED FOR HEREIN AND NOTWITHSTANDING ANYTHING TO
      THE CONTRARY, NEITHER PARTY OR ANY OFFICER, DIRECTOR,
      SUBSIDIARY, AFFILIATE, OR EMPLOYEE OF EITHER PARTY, MAKES ANY
      OTHER WARRANTY OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY
      OR OTHERWISE, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF
      MERCHANTABILITY, FITNESS FOR A PARTICULAR USE, AND
      NONINFRINGEMENT. NEITHER 
      <span
        id="static_software_product_license_name"
        class="saved-var software_product_license_name step-1"
      >
        ECS Clinical, LLC
      </span> 
      <span style="mso-spacerun:yes">&nbsp;</span>NOR ANY OFFICER,
      DIRECTOR, SUBSIDIARY, AFFILIATE, OR EMPLOYEE OF 
      <span
        id="static_software_product_license_name"
        class="saved-var software_product_license_name step-1"
      >
        ECS Clinical, LLC
      </span> 
      <span style="mso-spacerun:yes">&nbsp;</span>MAKES ANY
      REPRESENTATION OR WARRANTY ABOUT ANY CONTENT OR INFORMATION MADE
      ACCESSIBLE BY OR THROUGH PRODUCT.
    </span>
  </b>
</p>

<p
  class="MsoListParagraph"
  style="margin-bottom:0in;margin-bottom:.0001pt;
mso-add-space:auto;text-align:justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-indent:-.5in;line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span
      style='font-size:12.0pt;font-family:
"Times New Roman",serif'
    >
      8.
      <span style="mso-tab-count:1">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      LIMITATION OF LIABILITY AND REMEDIES
    </span>
  </b>
  <span
    style='font-size:12.0pt;font-family:
"Times New Roman",serif'
  >
    . In spite of any damages that You may or may not incur for any
    reason, which may include, but are not limited to, any and all
    direct or general damages, the entire liability of 
    <span
      id="static_software_product_license_name"
      class="saved-var software_product_license_name step-1"
    >
      ECS Clinical, LLC
    </span>
    <span style="mso-spacerun:yes">&nbsp;</span>and/or any of the
    aforementioned suppliers covered under the herein contained
    provisions of this EULA, along with Your exclusive remedy with
    regards to all of the foregoing, shall hereby be limited to 
    <b style="mso-bidi-font-weight:normal">
      <span
        style="color:#C0504D;
mso-themecolor:accent2"
      >
         
      </span>
    </b>
    the amount actually paid by You for this Product 
    <b style="mso-bidi-font-weight:normal">
      <span style="color:#C0504D;mso-themecolor:accent2"></span>
    </b>
    . The aforementioned limitations, exclusions and any disclaimers
    shall apply to the maximum extent allowable by law, even should
    any proposed remedy fail its essential purpose.
    <b style="mso-bidi-font-weight:normal">
      <span style="color:#C0504D;mso-themecolor:accent2"></span>
    </b>
  </span>
</p>

<p
  class="MsoListParagraph"
  style="margin-bottom:0in;margin-bottom:.0001pt;
mso-add-space:auto;text-align:justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      9.
      <span style="mso-tab-count:1">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
      </span>
      EXPORT CONTROLS
    </span>
  </b>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    . By installing the Product, You hereby agree that You will comply
    with any and all applicable export laws, restrictions and all
    regulations of the U.S. Department of Commerce, U.S. Department of
    Treasury, and any other U.S. or foreign agency or authority with
    regards to this provision of the EULA. You expressly agree not to
    export or re-export, nor allow the export or re-export of the
    offered content in violation of any such law, restriction or
    regulation, including without limitation, export or re-export to
    any country subject to any and all applicable U.S. trade embargoes
    or to any prohibited destination, in any group specified in the
    current "
    <span style="color:black;background:white">
      Supplement No. 1 to Part 740 or the Commerce Control List
      specified in the then current Supplement No. 1 to Part 738 of
      the U.S. Export Administration Regulations (or any successor
      supplement or regulations)."
    </span>
  </span>
</p>

<p
  class="MsoListParagraph"
  style="margin-bottom:0in;margin-bottom:.0001pt;
mso-add-space:auto;text-align:justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      10.
      <span style="mso-tab-count:1">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
      </span>
      U.S. GOVERNMENT END USERS
    </span>
  </b>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    . The offered content is licensed by the U.S. Government with
    RESTRICTED RIGHTS. The use, duplication of, or the disclosure by
    the U.S. Government, shall be subject to restrictions in
    accordance with DFARS 252.227-7013 of the Technical Data and
    Computer Software clause, and 48 DCR 52.227-19 of the Commercial
    Computer Software clause, as applicable.
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      11.
      <span style="mso-tab-count:1">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
      </span>
      MISCELLANEOUS
    </span>
  </b>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      &nbsp;
    </span>
  </b>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      11.01<span style="mso-tab-count:1">&nbsp;&nbsp; </span>
      SUCCESSORS AND ASSIGNS
    </span>
  </b>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    . This EULA, in its entirety, shall be legally binding upon and
    inure to the benefit of 
    <span
      id="static_software_product_license_name"
      class="saved-var software_product_license_name step-1"
    >
      ECS Clinical, LLC
    </span>
    <span style="mso-spacerun:yes">&nbsp;</span>and You, our
    respective successors and permitted assigns. 
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      11.02<span style="mso-tab-count:1">&nbsp;&nbsp; </span>
      SEVERABILITY
    </span>
  </b>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    . If any provision of this Agreement is held to be illegal,
    invalid or unenforceable by a court of competent jurisdiction, the
    remaining provisions shall not be affected.
    <b style="mso-bidi-font-weight:normal"></b>
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      11.03<span style="mso-tab-count:1">&nbsp;&nbsp; </span>WAIVER
    </span>
  </b>
  <span
    style='font-size:12.0pt;font-family:"Times New Roman",serif'
  >
    .<b style="mso-bidi-font-weight:normal"> </b>If there is any
    waiver of any breach or failure to enforce any of the provisions
    contained herein, it shall not be deemed as a future waiver of
    said terms or a waiver of any other provision of this EULA. 
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      11.04 AMENDMENTS
    </span>
  </b>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    . Any waiver, supplementation, modification or amendment to any
    provision of this EULA, shall only be effective when done so in
    writing and signed off by 
    <span
      id="static_software_product_license_name"
      class="saved-var software_product_license_name step-1"
    >
      ECS Clinical, LLC
    </span>
    <span style="mso-spacerun:yes">&nbsp;</span>and You. 
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      11.05<span style="mso-tab-count:1">&nbsp;&nbsp; </span>GOVERNING
      LAW
    </span>
  </b>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    . This EULA shall be governed solely by the laws of the State of 
    <span
      id="static_state_law_applies"
      class="saved-var state_law_applies step-4"
    >
      Louisiana
    </span> 
    and of the United States. Should any action arise out of or in
    relation to this EULA, such action may be brought exclusively in
    the appropriate federal or state court in 
    <span
      id="static_licensing_jurisdiction_city"
      class="saved-var licensing_jurisdiction_city step-5"
    >
      Baton Rouge
    </span>
    ,
    <span
      id="static_licensing_jurisdiction_state"
      class="saved-var licensing_jurisdiction_state step-5"
    >
      Louisiana
    </span>
    , and as such, You and 
    <span
      id="static_software_product_license_name"
      class="saved-var software_product_license_name step-1"
    >
      ECS Clinical, LLC
    </span>
    <span style="mso-spacerun:yes">&nbsp;</span>irrevocably consent to
    the jurisdiction of said court and venue for 
    <span
      id="static_licensing_jurisdiction_city"
      class="saved-var licensing_jurisdiction_city step-5"
    >
      Baton Rouge
    </span>
    , 
    <span
      id="static_licensing_jurisdiction_state"
      class="saved-var licensing_jurisdiction_state step-5"
    >
      Louisiana
    </span>
    .
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      11.06<span style="mso-tab-count:1">&nbsp;&nbsp; </span>
      ASSIGNMENTS
    </span>
  </b>
  <span
    style='font-size:
12.0pt;font-family:"Times New Roman",serif'
  >
    . You may not assign or transfer any part of this Licensee without
    the written consent of 
    <span
      id="static_software_product_license_name"
      class="saved-var software_product_license_name step-1"
    >
      ECS Clinical, LLC
    </span>
    , except that, if a change of control occurs (including a sale or
    merger), the Party experiencing the change of control may ensure
    this License remains in full force and effect by providing written
    notice to the other Party within thirty (30) days after the change
    of control.
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      11.07<span style="mso-tab-count:1">&nbsp;&nbsp; </span>VALID AND
      BINDING
    </span>
  </b>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    . This Agreement constitutes a valid and legally binding
    obligation of the Parties, enforceable against the Parties in
    accordance with its terms, subject in all respects to the effects
    of bankruptcy, insolvency, fraudulent conveyance, reorganization,
    moratorium and other laws relating to or affecting creditors'
    rights generally and general equitable principles.
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      11.08<span style="mso-tab-count:1">&nbsp;&nbsp; </span>EFFECT OF
      TITLE AND HEADINGS
    </span>
  </b>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    . The title of the Agreement and the headings of Sections, and
    Clauses are included for convenience and shall not affect the
    meaning of the Agreement or the Section.
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      11.09<span style="mso-tab-count:1">&nbsp;&nbsp; </span>FORCE
      MAJEURE
    </span>
  </b>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    . Except for payment obligations, if either Party is prevented
    from performing or is unable to perform any of its obligations
    under this License due to causes beyond the reasonable control of
    the Party invoking this provision, including but not limited to
    acts of God, acts of civil or military authorities, riots or civil
    disobedience, wars, strikes or labor disputes (each, a "
    <b style="mso-bidi-font-weight:normal">Force Majeure Event</b>"),
    such Party's performance shall be excused and the time for
    performance shall be extended accordingly provided that the Party
    immediately takes all reasonably necessary steps to resume full
    performance.
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span
      style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#C0504D;
mso-themecolor:accent2'
    ></span>
  </b>
</p>
</div>`

const html_fr = ` <div class="WordSection1">
<p
  class="MsoNormal"
  align="center"
  style="margin-bottom:0in;margin-bottom:.0001pt;
text-align:center;line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:16.0pt;font-family:"Times New Roman",serif'>
    ACCORD DE LICENCE POUR L'UTILISATEUR FINAL
    </span>
  </b>
</p>

<p
  class="MsoNormal"
  align="center"
  style="margin-bottom:0in;margin-bottom:.0001pt;
text-align:center;line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      &nbsp;
    </span>
  </b>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
  Le présent contrat de licence d'utilisateur final (le "
    <b style="mso-bidi-font-weight normal">Contrat</b>
    " ou "<b style="mso-bidi-font-weight:normal">Licence</b>" ou "
    <b style="mso-bidi-font-weight:normal">CLUF</b>") est considéré comme un accord juridiquement contraignant entre vous (un individu ou une entité, ci-après "
    <b style="mso-bidi-font-weight:normal">Licencié</b>" ou "
    <b style="mso-bidi-font-weight: normal">vous</b>
    " ou "<b style="mso-bidi-font-weight:normal">Votre</b>") et 
    <span
      id="static_software_product_license_name"
      class="saved-var software_product_license_name step-1"
    >
       ECS Clinical, LLC
    </span>
    <span style="mso-spacerun:yes">&nbsp;</span>pour l'utilisation du logiciel spécifié:
    <span
      id="static_software_product"
      class="saved-var software_product step-0"
    >
      ECS Clinical
    </span>
    , qui peut inclure des documents imprimés, des supports et tout autre composant et/ou module logiciel, y compris, mais sans s'y limiter, les pilotes requis (le "<b style="mso-bidi-font-weight:normal">Produit</b>"). Other
    D'autres aspects du Produit peuvent également inclure, mais sans s'y limiter, les mises à jour logicielles et toutes les mises à niveau nécessaires que
    <span
      id="static_software_product_license_name"
      class="saved-var software_product_license_name step-1"
    >
      ECS Clinical, LLC
    </span> 
    peut vous fournir ou mettre à votre disposition, ou que vous obtenez après la copie initiale du Produit, et en tant que tels, ces éléments ne sont pas accompagnés d'un accord de licence distinct ou de conditions d'utilisation.
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    PAR L'INSTALLATION, LA COPIE, LE TÉLÉCHARGEMENT, L'ACCÈS OU L'UTILISATION DE CE PRODUIT, VOUS ACCEPTEZ D'ÊTRE LÉGALEMENT LIÉ PAR LES TERMES DE CE CONTRAT DE LICENCE. SI VOUS N'ACCEPTEZ PAS D'ÊTRE LIÉ PAR LES TERMES DE CE CONTRAT DE LICENCE, VOUS N'AVEZ AUCUN DROIT SUR LE PRODUIT ET NE DEVEZ DONC PAS L'INSTALLER, LE COPIER, LE TÉLÉCHARGER, Y ACCÉDER OU L'UTILISER.
    </span>
  </b>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    CE PRODUIT EST PROTÉGÉ PAR LES LOIS SUR LES DROITS D'AUTEUR, AINSI QUE PAR TOUTE AUTRE LOI SUR LA PROPRIÉTÉ INTELLECTUELLE. CE PRODUIT EST CONCÉDÉ SOUS LICENCE ET N'EST PAS VENDU.
    </span>
  </b>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      1.
      <span style="mso-tab-count:1">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
      </span>
      DÉFINITIONS ET INTERPRÉTATIONS
    </span>
  </b>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    1.01<span style="mso-tab-count:1">&nbsp;&nbsp;&nbsp;&nbsp; </span>
    "Contrat" ou "Licence" ou "CLUF" désigne le présent Contrat de licence pour l'utilisateur final.
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    1.02<span style="mso-tab-count:1">&nbsp;&nbsp;&nbsp;&nbsp;</span>"Licencié" ou "Vous" ou "Votre" désigne Vous, la personne physique ou morale qui concède une licence sur le Produit conformément aux termes du présent Accord.
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    1.03<span style="mso-tab-count:1">&nbsp;&nbsp;&nbsp;&nbsp; </span>
    "Propriété intellectuelle" : les droits mondiaux actuels et futurs en vertu du droit des brevets, du droit d'auteur, du droit des secrets commerciaux, du droit des marques, du droit moral et d'autres droits similaires.
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    1.04<span style="mso-tab-count:1">&nbsp;&nbsp;&nbsp;&nbsp; </span>
    "Mise à jour" désigne la maintenance ou la correction d'une version du Produit, y compris, mais sans s'y limiter : une correction à chaud, un correctif ou une amélioration, dont aucun ne fonctionne comme un service autonome ou un autre progiciel et qui n'entraîne pas de coût supplémentaire pour un titulaire de licence existant.
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    1.05<span style="mso-tab-count:1">&nbsp;&nbsp;&nbsp;&nbsp;</span>
    "Mise à niveau" : une version majeure et autonome du produit, qui peut inclure des applications, des caractéristiques ou des fonctionnalités supplémentaires.
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    1.06<span style="mso-tab-count:1">&nbsp;&nbsp;&nbsp;&nbsp;</span>
    Une personne comprend une personne physique, une société ou un organisme non constitué en société (qu'il ait ou non une personnalité juridique distincte), ainsi que les représentants légaux et personnels, les successeurs et les ayants droit autorisés de cette personne.
    <span style="mso-spacerun:yes">&nbsp; </span>
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    1.07<span style="mso-tab-count:1">&nbsp;&nbsp;&nbsp;&nbsp; </span>
    Les mots au singulier comprennent le pluriel et vice versa.
    <span style="mso-spacerun:yes">&nbsp; </span>
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    1.08<span style="mso-tab-count:1">&nbsp;&nbsp;&nbsp;&nbsp; </span>
    Une référence à un sexe inclut une référence aux autres sexes.
    genders. 
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    1.09<span style="mso-tab-count:1">&nbsp;&nbsp;&nbsp;&nbsp; </span>
    Une référence à une loi, à une disposition statutaire ou à une législation subordonnée est une référence à celle-ci telle qu'elle est en vigueur de temps à autre, compte tenu de toute modification ou réadoption, et inclut toute loi, disposition statutaire ou législation subordonnée qu'elle modifie ou réadopte ; à condition que, entre les parties, aucune modification ou réadoption ne s'applique aux fins du présent accord dans la mesure où elle imposerait une obligation, une responsabilité ou une restriction nouvelle ou étendue à l'une des parties ou porterait atteinte à ses droits de toute autre manière.
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    1.10<span style="mso-tab-count:1">&nbsp;&nbsp;&nbsp;&nbsp; </span>
    Une référence à l'écrit ou à l'écrit inclut le courrier électronique.
    <span style="mso-spacerun:yes">&nbsp; </span>
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    1.11<span style="mso-tab-count:1">&nbsp;&nbsp;&nbsp;&nbsp; </span>
    Toute obligation imposée par le présent accord à une personne de ne pas faire quelque chose inclut l'obligation de ne pas accepter ou permettre que cette chose soit faite.
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    1.12<span style="mso-tab-count:1">&nbsp;&nbsp;&nbsp;&nbsp; </span>
    Toute phrase introduite par les termes "y compris", "inclure", "en particulier" ou toute expression similaire doit être interprétée comme une illustration et ne doit pas limiter le sens des mots qui précèdent ces termes.
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    1.13<span style="mso-tab-count:1">&nbsp;&nbsp;&nbsp;&nbsp; </span>
    Les références à des articles, sections ou clauses renvoient aux articles, sections et clauses du présent accord.
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    1.14<span style="mso-tab-count:1">&nbsp;&nbsp;&nbsp;&nbsp; </span>
    "Nous", "notre" et "nos" désignent 
    <span
      id="static_software_product_license_name"
      class="saved-var software_product_license_name step-1"
    >
      ECS Clinical, LLC
    </span>
    .
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      2.
      <span style="mso-tab-count:1">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      OCTROI DE LA LICENCE.
    </span>
  </b>
  <br />
  <br />
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
     
    <span
      id="static_software_product_license_name"
      class="saved-var software_product_license_name step-1"
    >
      ECS Clinical, LLC
    </span>
    <span style="mso-spacerun:yes">&nbsp;</span>vous accorde une licence non exclusive pour l'utilisation et l'installation du Produit, sous réserve de tous les termes et conditions énoncés dans le présent document. En outre, le présent CLUF régit également toutes les mises à jour et mises à niveau du logiciel fournies par
    <span
      id="static_software_product_license_name"
      class="saved-var software_product_license_name step-1"
    >
      ECS Clinical, LLC
    </span>
    <span style="mso-spacerun:yes">&nbsp;</span>qui remplaceraient, écraseraient et/ou compléteraient la version originale installée du Produit, à moins que ces autres mises à jour et mises à niveau ne soient couvertes par une licence distincte, auquel cas les conditions de cette licence s'appliqueront.
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      3.
      <span style="mso-tab-count:1">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
      </span>
      RÉSILIATION.
    </span>
  </b>
  <br />
  <br />
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
  En cas de violation du présent CLUF, votre droit d'utilisation du produit sera immédiatement résilié et prendra fin sans qu'aucun préavis ne soit donné. Toutefois, toutes les dispositions du présent CLUF, à l'exception de l'octroi de la licence, survivront à la résiliation et resteront en vigueur. À la fin de l'octroi de la licence, vous 
    <b style="mso-bidi-font-weight:normal">
      <u>DEVEZ</u>
    </b> 
    détruire toutes les copies du produit.
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      4.
      <span style="mso-tab-count:1">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
      </span>
      PROPRIÉTÉ INTELLECTUELLE
    </span>
  </b>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'></span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      4.01
      <span style="mso-tab-count:1">&nbsp;&nbsp;&nbsp;&nbsp; </span>
      PRODUIT PROTÉGÉ.
    </span>
  </b>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
  Le produit est protégé par le droit d'auteur et d'autres lois et traités relatifs à la propriété intellectuelle, et à ce titre tous les droits, titres et intérêts relatifs au contenu proposé, y compris, mais sans s'y limiter, toutes les photographies, images, animations vidéo, textes et musiques, qui peuvent être incorporés dans le contenu proposé. Ce contenu est protégé par les lois sur les droits d'auteur et les dispositions des traités internationaux. Par conséquent, le contenu proposé doit être traité comme tout autre matériel protégé par le droit d'auteur, à l'exception du fait qu'il vous est permis de faire des copies conformément aux dispositions de la licence. Toutefois, les documents imprimés qui peuvent accompagner le contenu proposé ne peuvent pas être copiés.
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      4.02 <span style="mso-tab-count:1">&nbsp;&nbsp;&nbsp; </span>
      ABSENCE D'OCTROI DE DROITS.
    </span>
  </b>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
  Sauf mention expresse dans les présentes, le présent accord n'accorde à aucune des parties des droits sur le contenu ou la propriété intellectuelle de l'autre partie. En particulier,
    <span
      id="static_software_product_license_name"
      class="saved-var software_product_license_name step-1"
    >
      ECS Clinical, LLC
    </span> 
    détient tous les droits de propriété intellectuelle sur le produit.
  </span>
</p>

<p
  class="MsoListParagraph"
  style="margin-bottom:0in;margin-bottom:.0001pt;
mso-add-space:auto;text-align:justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      4.03
      <span style="mso-tab-count:1">&nbsp;&nbsp;&nbsp;&nbsp; </span>
      DROIT DE PUBLICITÉ
    </span>
  </b>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
  Vous acceptez, en ce qui concerne la publicité, que : (a)
    <span
      id="static_software_product_license_name"
      class="saved-var software_product_license_name step-1"
    >
      ECS Clinical, LLC
    </span> 
    peut inclure le licencié ou sa marque dans une liste de licenciés, en ligne ou dans des documents promotionnels ; et (b)
    <span
      id="static_software_product_license_name"
      class="saved-var software_product_license_name step-1"
    >
      ECS Clinical, LLC
    </span> 
    peut faire référence verbalement au licencié en tant que détenteur d'une licence du produit.
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      5.
      <span style="mso-tab-count:1">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
      </span>
      RESTRICTIONS D'UTILISATION.
    </span>
  </b>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
  En tant que titulaire d'une licence, vous n'êtes pas autorisé à (a) utiliser le contenu proposé sur plus d'un ordinateur à la fois, sans achat préalable de licences supplémentaires ; (b) partager, distribuer, prêter, louer, sous-licencier ou mettre à la disposition d'un tiers, de quelque manière que ce soit, le contenu proposé ; (c) modifier, adapter, créer des œuvres dérivées ou traduire toute partie du contenu proposé autre que ce qui peut être utilisé dans le cadre de votre travail conformément à la présente licence ; (d) faire de l'ingénierie inverse, décompiler ou désassembler le contenu proposé, ni tenter de trouver ou d'obtenir son code source ; (e) tenter de trouver ou d'obtenir le code source du contenu proposé (d) faire de l'ingénierie inverse, décompiler ou désassembler le contenu proposé, ni tenter de localiser ou d'obtenir son code source ; (e) tenter de modifier ou de supprimer toute marque, tout droit d'auteur ou tout autre avis de propriété figurant dans le contenu proposé ; ou (f) utiliser le contenu proposé d'une manière non stipulée dans le présent CLUF ou dans la documentation accompagnant le contenu proposé.
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;line-height:normal;tab-stops:40.5pt"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      6.
      <span style="mso-tab-count:1">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      MISES À JOUR/AMÉLIORATIONS.
    </span>
  </b>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    . 
    <span
      id="static_software_product_license_name"
      class="saved-var software_product_license_name step-1"
    >
      ECS Clinical, LLC
    </span>
    <span style="mso-spacerun:yes">&nbsp;</span>peut juger nécessaire de mettre à disposition des mises à jour ou des mises à niveau pour le Produit, conformément aux termes et conditions du présent CLUF.
    <span
      id="static_software_product_license_name"
      class="saved-var software_product_license_name step-1"
    >
      ECS Clinical, LLC
    </span>
    pourra, à sa seule discrétion, mettre à votre disposition lesdites mises à jour ou mises à niveau sous réserve de votre acceptation d'un autre CLUF ou de l'exécution d'un autre contrat distinct. Si vous choisissez d'installer et d'utiliser ces mises à jour, vous acceptez par conséquent d'être soumis à toutes les licences, conditions et modalités applicables du présent CLUF et/ou de tout autre accord.<b style="mso-bidi-font-weight:normal"></b>
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      7.
      <span style="mso-tab-count:1">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
      </span>
      EXCLUSION DE GARANTIE.
    </span>
  </b>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
     
    <span
      id="static_software_product_license_name"
      class="saved-var software_product_license_name step-1"
    >
      ECS Clinical, LLC
    </span>
    <span style="mso-spacerun:yes">&nbsp;</span> déploiera des efforts raisonnables, conformes aux normes industrielles en vigueur, pour maintenir le produit de manière à minimiser les erreurs et les interruptions.
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span
      style='font-size:12.0pt;font-family:"Times New Roman",serif;text-transform:uppercase;'
    >
    Toutefois, 
      <span
        id="static_software_product_license_name"
        class="saved-var software_product_license_name step-1"
      >
        ECS Clinical, LLC
      </span> 
      ne garantit pas que le produit sera ininterrompu ou exempt d'erreurs, et ne donne aucune garantie quant aux résultats pouvant être obtenus par l'utilisation du produit.
      <span style="mso-spacerun:yes">&nbsp; </span>
    </span>
  </b>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      &nbsp;
    </span>
  </b>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span
      style='font-size:12.0pt;font-family:
"Times New Roman",serif;text-transform:uppercase'
    >
    DANS TOUTE LA MESURE PERMISE PAR LA LOI APPLICABLE, SAUF DANS LES CAS EXPRESSÉMENT PRÉVUS PAR LES PRÉSENTES ET NONOBSTANT TOUTE DISPOSITION CONTRAIRE, AUCUNE PARTIE NI AUCUN DIRIGEANT, ADMINISTRATEUR, FILIALE, AFFILIÉ OU EMPLOYÉ DE L'UNE OU L'AUTRE PARTIE NE DONNE D'AUTRE GARANTIE DE QUELQUE NATURE QUE CE SOIT, QU'ELLE SOIT EXPRESSE, IMPLICITE, LÉGALE OU AUTRE, Y COMPRIS, MAIS SANS S'Y LIMITER, LES GARANTIES DE QUALITÉ MARCHANDE, D'ADÉQUATION À UN USAGE PARTICULIER ET DE NON-CONTREFAÇON. Ni ECS Clinical, LLC ni aucun dirigeant, administrateur, filiale, affilié ou employé d'ECS Clinical, LLC ne fait de déclaration ou de garantie concernant tout contenu ou toute information rendue accessible par le biais du produit.
    </span>
  </b>
</p>

<p
  class="MsoListParagraph"
  style="margin-bottom:0in;margin-bottom:.0001pt;
mso-add-space:auto;text-align:justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-indent:-.5in;line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span
      style='font-size:12.0pt;font-family:
"Times New Roman",serif'
    >
      8.
      <span style="mso-tab-count:1">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      LIMITATION DE LA RESPONSABILITÉ ET DES RECOURS.
    </span>
  </b>
  <span
    style='font-size:12.0pt;font-family:
"Times New Roman",serif'
  >
  En dépit de tout dommage que Vous pouvez ou ne pouvez pas subir pour quelque raison que ce soit, qui peut inclure, mais sans s'y limiter, tout dommage direct ou général, l'entière responsabilité d'
    <span
      id="static_software_product_license_name"
      class="saved-var software_product_license_name step-1"
    >
      ECS Clinical, LLC
    </span>
    <span style="mso-spacerun:yes">&nbsp;</span>
    et/ou de tout fournisseur susmentionné couvert par les dispositions contenues dans le présent CLUF, ainsi que Votre recours exclusif en ce qui concerne tout ce qui précède, seront par la présente limités au montant que Vous avez effectivement payé pour ce Produit. Les limitations, exclusions et clauses de non-responsabilité susmentionnées s'appliquent dans toute la mesure permise par la loi, même si l'un des recours proposés n'atteint pas son objectif essentiel.
    <b style="mso-bidi-font-weight:normal">
      <span style="color:#C0504D;mso-themecolor:accent2"></span>
    </b>
  </span>
</p>

<p
  class="MsoListParagraph"
  style="margin-bottom:0in;margin-bottom:.0001pt;
mso-add-space:auto;text-align:justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      9.
      <span style="mso-tab-count:1">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
      </span>
      CONTRÔLE DES EXPORTATIONS.
    </span>
  </b>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
  En installant le produit, vous acceptez par la présente de vous conformer à toutes les lois et restrictions applicables en matière d'exportation et à toutes les réglementations du ministère américain du commerce, du ministère américain des finances et de toute autre agence ou autorité américaine ou étrangère en ce qui concerne cette disposition du CLUF. Vous acceptez expressément de ne pas exporter ou réexporter, ni de permettre l'exportation ou la réexportation du contenu proposé en violation d'une telle loi, restriction ou réglementation, y compris, sans limitation, l'exportation ou la réexportation vers tout pays soumis à un embargo commercial américain ou vers toute destination interdite, dans tout groupe spécifié dans le "Supplément n° 1 de la partie 740 ou la liste de contrôle du commerce spécifiée dans le supplément n° 1 de la partie 738 des règlements d'administration des exportations des États-Unis (ou tout supplément ou règlement qui lui succéderait)" en vigueur.
  </span>
</p>

<p
  class="MsoListParagraph"
  style="margin-bottom:0in;margin-bottom:.0001pt;
mso-add-space:auto;text-align:justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      10.
      <span style="mso-tab-count:1">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
      </span>
      UTILISATEURS FINAUX DU GOUVERNEMENT DES ÉTATS-UNIS
    </span>
  </b>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
  Le contenu proposé est concédé sous licence par le gouvernement des États-Unis avec des DROITS RESTREINTS. L'utilisation, la duplication ou la divulgation par le gouvernement américain sont soumises à des restrictions conformément à la clause DFARS 252.227-7013 relative aux données techniques et aux logiciels informatiques, et à la clause 48 DCR 52.227-19 relative aux logiciels informatiques commerciaux, selon le cas.
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      11.
      <span style="mso-tab-count:1">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
      </span>
      DIVERS
    </span>
  </b>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      &nbsp;
    </span>
  </b>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      11.01<span style="mso-tab-count:1">&nbsp;&nbsp; </span>
      SUCCESSEURS ET AYANTS DROIT
    </span>
  </b>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    Le présent CLUF, dans son intégralité, lie légalement 
    <span
      id="static_software_product_license_name"
      class="saved-var software_product_license_name step-1"
    >
      ECS Clinical, LLC
    </span>
    <span style="mso-spacerun:yes">&nbsp;</span> et Vous, ainsi que nos successeurs et ayants droit respectifs, et s'applique à leur profit.
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      11.02<span style="mso-tab-count:1">&nbsp;&nbsp; </span>
      SÉVERABILITÉ.
    </span>
  </b>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
  Si une disposition du présent accord est jugée illégale, invalide ou inapplicable par un tribunal compétent, les autres dispositions n'en seront pas affectées.
    <b style="mso-bidi-font-weight:normal"></b>
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      11.03<span style="mso-tab-count:1">&nbsp;&nbsp; </span>RENONCIATION.
    </span>
  </b>
  <span
    style='font-size:12.0pt;font-family:"Times New Roman",serif'
  >
  Toute renonciation à une violation ou à un défaut d'application de l'une des dispositions des présentes ne doit pas être considérée comme une renonciation future auxdites conditions ou à toute autre disposition du présent CLUF
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      11.04<span style="mso-tab-count:1">&nbsp;&nbsp; </span>AMENDEMENTS.
    </span>
  </b>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
  Toute renonciation, tout complément, toute modification ou tout amendement à l'une des dispositions du présent CLUF ne sera effectif que s'il est effectué par écrit et signé par 
    <span
      id="static_software_product_license_name"
      class="saved-var software_product_license_name step-1"
    >
      ECS Clinical, LLC
    </span>
    <span style="mso-spacerun:yes">&nbsp;</span>et Vous. 
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      11.05<span style="mso-tab-count:1">&nbsp;&nbsp; </span>
      
      DROIT APPLICABLE.
    </span>
  </b>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
  Le présent CLUF est régi exclusivement par les lois de l'État de Louisiane et des États-Unis. En cas d'action découlant du présent CLUF ou en relation avec celui-ci, cette action peut être intentée exclusivement devant le tribunal fédéral ou d'État compétent à Baton Rouge, en Louisiane, et à ce titre, vous et 
    <span
      id="static_software_product_license_name"
      class="saved-var software_product_license_name step-1"
    >
      ECS Clinical, LLC
    </span>
    acceptez irrévocablement la compétence dudit tribunal et le lieu de Baton Rouge, en Louisiane.
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      11.06<span style="mso-tab-count:1">&nbsp;&nbsp; </span>
      CESSIONS
    </span>
  </b>
  <span
    style='font-size:
12.0pt;font-family:"Times New Roman",serif'
  >
  Vous n'êtes pas autorisé à céder ou à transférer une quelconque partie du présent Licencié sans le consentement écrit d'
    <span
      id="static_software_product_license_name"
      class="saved-var software_product_license_name step-1"
    >
      ECS Clinical, LLC
    </span>
    Toutefois, en cas de changement de contrôle (y compris une vente ou une fusion), la partie qui subit le changement de contrôle peut s'assurer que le présent Licencié reste pleinement en vigueur en adressant une notification écrite à l'autre partie dans les trente (30) jours qui suivent le changement de contrôle.
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      11.07<span style="mso-tab-count:1">&nbsp;&nbsp;</span>VALIDITÉ ET FORCE OBLIGATOIRE.
    </span>
  </b>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
  Le présent accord constitue une obligation valide et juridiquement contraignante des parties, opposable aux parties conformément à ses dispositions, sous réserve, à tous égards, des effets de la faillite, de l'insolvabilité, du transfert frauduleux, de la réorganisation, du moratoire et d'autres lois relatives aux droits des créanciers ou les affectant de manière générale, ainsi que des principes généraux d'équité.
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      11.08<span style="mso-tab-count:1">&nbsp;&nbsp;</span>EFFET DU TITRE ET DES EN-TÊTES
    </span>
  </b>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
  Le titre de l'accord et les intitulés des sections et des clauses sont inclus pour des raisons de commodité et n'affectent pas la signification de l'accord ou de la section.
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
      11.09<span style="mso-tab-count:1">&nbsp;&nbsp; </span>CAS DE FORCE MAJEUR
    </span>
  </b>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
  À l'exception des obligations de paiement, si l'une des parties est empêchée d'exécuter ou n'est pas en mesure d'exécuter l'une de ses obligations au titre de la présente licence en raison de causes échappant au contrôle raisonnable de la partie invoquant la présente disposition, y compris, mais sans s'y limiter, les cas de force <b>majeure</b>, les actes des autorités civiles ou militaires, les émeutes ou la désobéissance civile, les guerres, les grèves ou les conflits du travail (chacun étant un "<b>cas de force majeure</b>"), l'exécution de cette partie est excusée et le délai d'exécution est prolongé en conséquence, à condition que la partie prenne immédiatement toutes les mesures raisonnablement nécessaires pour reprendre l'exécution intégrale.
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;margin-bottom:.0001pt;text-align:justify;text-indent:-.5in;
line-height:normal"
>
  <span style='font-size:12.0pt;font-family:"Times New Roman",serif'>
    &nbsp;
  </span>
</p>

<p
  class="MsoNormal"
  style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal"
>
  <b style="mso-bidi-font-weight:normal">
    <span
      style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#C0504D;
mso-themecolor:accent2'
    ></span>
  </b>
</p>
</div>`