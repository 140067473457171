import {ChatRoomAPIService, ToastrService} from "../services";
import {constantMessages} from "../config";

import {RESET_SEARCHED_FOR_GROUP_RESULTS} from "./contacts";

export const FETCH_GROUPS_LIST_PREFIX = 'group/FETCH_GROUPS_LIST';
export const FETCH_GROUPS_LIST_REQUEST_ACTION = FETCH_GROUPS_LIST_PREFIX + '_REQUEST_ACTION';
export const FETCH_GROUPS_LIST_SUCCESS_ACTION = FETCH_GROUPS_LIST_PREFIX + '_SUCCESS_ACTION';
export const FETCH_GROUPS_LIST_FAILURE_ACTION = FETCH_GROUPS_LIST_PREFIX + '_FAILURE_ACTION';

const initialState = {
  isOpen: false,
  isOnlySearchMode: false,
  list: [],
  selectedGroup: {}
};

export const OPEN_MODAL = "group/OPEN_MODAL";
export const CLOSE_MODAL = "group/CLOSE_MODAL";

export const CHANGE_ONLY_SEARCH_MODE = "group/CHANGE_ONLY_SEARCH_MODE";

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_GROUPS_LIST_SUCCESS_ACTION:
      return {
        ...state,
        list: action.payload.groups,
      };
    case CHANGE_ONLY_SEARCH_MODE:
      return {
        ...state,
        isOnlySearchMode: action.payload.isOnlySearchMode,
      };
    case OPEN_MODAL:
      return {
        ...state,
        isOpen: true,
        selectedGroup: action.payload.selectedGroup,
        isOnlySearchMode: action.payload.isOnlySearchMode
      };
    case CLOSE_MODAL:
      return {
        ...state,
        isOpen: false,
        selectedGroup: {}
      };
    default:
      return state
  }
}

export function getGroupsList(keyword) {
  return dispatch => {
    dispatch({ type: FETCH_GROUPS_LIST_REQUEST_ACTION });
    const chatRoomService = new ChatRoomAPIService();
    return chatRoomService.getGroupsList(keyword)
      .then(({ data = {} }) => {
        const groups = data.chatRooms || [];
        dispatch({ type: FETCH_GROUPS_LIST_SUCCESS_ACTION, payload: { groups } });
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: FETCH_GROUPS_LIST_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message)
      })
  }
}

export const openModal = (data) => dispatch => {
  const {selectedGroup, isOnlySearchMode} = data || {};
  dispatch({type: OPEN_MODAL, payload: {selectedGroup, isOnlySearchMode}});
}

export const closeModal = () => dispatch => {
  dispatch({type: CLOSE_MODAL});
  dispatch({type: RESET_SEARCHED_FOR_GROUP_RESULTS});
}

export const changeOnlySearchMode = (isOnlySearchMode) => dispatch => dispatch({type: CHANGE_ONLY_SEARCH_MODE, payload: {isOnlySearchMode}});
