import React from 'react'
import {Container, Col, Button} from "reactstrap";
import { DateRangePicker, defaultInputRanges, defaultStaticRanges } from 'react-date-range';
import { TableComponent } from "../../../UIComponents";
import ReactPaginate from 'react-paginate';
import moment from 'moment'
import SearchComponent from "../../../UIComponents/SearchComponent";
import _ from "lodash";
import { withTranslation } from 'react-i18next';
import { fr, enUS } from 'date-fns/locale'
import i18n from '../../../../i18n';

class UserLogsComponent extends React.Component {
  state = {
    pageSize: 15,
    keyword: "",
    to: "",
    from: "",
    selectionRange: {
      startDate: null,
      endDate: new Date(),
      key: 'selection',
    }
  };

  componentDidMount() {
    this.props.getUserLogsList({})
  }

  componentDidUpdate(prevProps) {
    if (prevProps.page !== this.props.page) {
      this.updateLogsList()
    }
  }

  updateLogsList = () => {
    const { pageSize, page } = this.props;
    const { keyword, to, from } = this.state;

    this.props.getUserLogsList({
      keyword: _.trim(keyword || ""),
      page,
      pageSize,
      to,
      from
    })
  }

  handlePageClick = data => {
    let page = data.selected;
    this.props.setUserLogsPage(page);
  };

  renderTableRow = log => {
    const { user } = log
    return (
      <>
        <td>{`${user.firstName} ${user.lastName}`}</td>
        <td>{user?.email}</td>
        <td>
          {log.event}
        </td>
        <td>
          {moment(log.createdAt * 1000).format('YYYY-MM-DD hh:mm A')}
        </td>
      </>
    )
  }

  updateSearchKeyword = (keyword) => {
    this.setState({keyword}, () => {
      this.props.setUserLogsPage(0);
      this.updateLogsList();
    })
  };

  handleDateRangeChange = (ranges) => {
    const { selection } = ranges || {};
    const from = selection.startDate ? moment(selection.startDate).startOf('day').unix() : "";
    const to = selection.endDate ? moment(selection.endDate).endOf('day').unix() : "";
    this.setState({selectionRange: selection, to, from}, () => {
      this.props.setUserLogsPage(0);
      this.updateLogsList();
    })
  };

  exportLogs = () => {
    const { pageSize, page } = this.props;
    const { keyword, to, from } = this.state;

    this.props.exportUserLogsList({
      keyword: _.trim(keyword || ""),
      page,
      pageSize,
      to,
      from
    })
  }

  render() {
    const { userLogs, pageCount, page, t } = this.props;
    console.log('defaultStaticRanges', defaultInputRanges);
    return (
      <Container fluid className='userLogsContainer'>
        <div className='userLogsPage'>
          <div className="bodyHeader">
            <h2>{t('admin.user_logs')}</h2>
            <Button onClick={this.exportLogs} className="submitButton" >{t('admin.exp_logs')}</Button>
          </div>
          <div className="searchContainer px-0">
            <SearchComponent onSearch={this.updateSearchKeyword}
                             placeholder={t("Search...")}
                             className={"inputField"}
            />
          </div>
          <DateRangePicker
            locale={i18n.language === 'fr' ? fr : enUS}
            ranges={[this.state.selectionRange]}
            onChange={this.handleDateRangeChange}
            maxDate={new Date()}
            months={2}
            className={"ecsDateRangePicker"}
            direction="horizontal"
            staticRanges={defaultStaticRanges.map(staticRange => {
              if (staticRange.label === "Yesterday") {
                return {
                  ...staticRange,
                  label: t("All Time"),
                  range: () => ({
                    startDate: null,
                    endDate: new Date(),
                  })
                }
              } else {

              }
              return {...staticRange, label: t(staticRange.label)}
            }).sort((a,b) => a.label === t("All Time") ? -1 : b.label === t("All Time") ? 1 : 0)}
            inputRanges={[{...defaultInputRanges[0], label: t(defaultInputRanges[0].label)}]}
          />
          <div className="userLogsList">
            <TableComponent
              className={'userLogsTable'}
              listData={userLogs}
              tableHeaders={[t('Name'), t('Email'), t('Event'), t('Date')]}
              renderTableRow={this.renderTableRow}
            />
            {pageCount > 1 && (
              <Col container alignItems="center" justify="flex-end">
                <ReactPaginate
                  previousLabel={t('PREVIOUS PAGE')}
                  nextLabel={t('NEXT_PAGE')}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={this.handlePageClick}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                  initialPage={0}
                  forcePage={page}
                  disableInitialCallback={true}
                />
              </Col>
            )}
          </div>
        </div>
      </Container>
    )
  }
}

export default withTranslation('common')(UserLogsComponent)