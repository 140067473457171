import React from 'react'
import { Button, Container, Col } from "reactstrap";
import { TableComponent } from "../../../UIComponents";
import SearchComponent from "../../../UIComponents/SearchComponent";
import CreateItemModal from "../Modals/CreateItemModal";
import {appTheme, routes} from "../../../../config";
import { withRouter } from "react-router-dom";
import ReactPaginate from "react-paginate";
import _ from "lodash";
import { withTranslation } from 'react-i18next';

class FacilitiesComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      isCreateModalOpen: false,
      page: 1,
      pageSize: 10
    }
  }

  componentDidMount() {
    this.props.getFacilitiesList()
  }

  handleCreateFacility = name => {
    const {keyword, page, pageSize} = this.state;
    this.props.createFacility(name, {keyword, page, pageSize})
    this.setState({ isCreateModalOpen: false })
  }

  handleDeleteFacility = id => {
    const {keyword, page, pageSize} = this.state;
    const {facilitiesList} = this.props
    let updatedPage
    if (facilitiesList.length === 1) {
      updatedPage = page - 1
      this.setState({ page: page - 1})
    } else {
      updatedPage = page
    }
    this.props.deleteFacility(id, {keyword, page: updatedPage, pageSize})
  }

  handleEditFacility = id => {
    this.props.history.push(`${routes.facilities.path}/${id}`)
  }

  toggleCreateModal = () => {
    this.setState({ isCreateModalOpen: !this.state.isCreateModalOpen })
  }

  updateFacilitiesList = () => {
    const {keyword, page, pageSize} = this.state;

    this.props.getFacilitiesList({
      keyword: _.trim(keyword || ""),
      page,
      pageSize
    })
  }

  handlePageClick = data => {
    let page = data.selected;

    this.setState({ page: page + 1 }, this.updateFacilitiesList);
  };

  updateSearchKeyword = (keyword) => {
    this.setState({keyword}, this.updateFacilitiesList)
  };

  renderTableRow = item => {
    const { selectedTheme } = this.props;
    const facility = this.props.facilitiesList.find(f => f.id === item.id)
    return (
      <>
        <td>{facility.name || '-'}</td>
        <td>{facility.members || '0'}</td>
        <td>
          <div className="d-flex actions">
            <i
              title="Delete Facility"
              className="mr-3"
              onClick={() => this.handleDeleteFacility(item.id)}
            >
              <img alt="" src={"/assets/images/icn-delete-user.svg"}/>
            </i>
            <i
              title="Edit Facility"
              color='info'
              onClick={() => this.handleEditFacility(item.id)}
            >
              <img alt="" src={`/assets/images/edit${selectedTheme === appTheme.DARK ? "-dark" : ""}.svg`}/>
            </i>
          </div>
        </td>
      </>
    )
  }

  render() {
    const { isCreateModalOpen, page } = this.state
    const { facilitiesList, pageCount = 1, t } = this.props
    return (
      <Container fluid className='facilitiesContainer'>
        <div className='facilitiesPage'>
          <div className="bodyHeader">
            <h2>{t('admin.Facilities')}</h2>
            <Button onClick={() => this.toggleCreateModal()} className="submitButton">{t('admin.add_facility')}</Button>
          </div>
          <div className="facilitiesList">
            <div className="searchContainer px-0">
              <SearchComponent onSearch={this.updateSearchKeyword}
                               placeholder={t("Search...")}
                               className={"inputField"}
              />
            </div>
            <TableComponent
              className={'facilityTable'}
              listData={facilitiesList}
              tableHeaders={[t('Name'), t('Members'), t('Actions')]}
              renderTableRow={this.renderTableRow}
            />
            {pageCount > 1 && (
              <Col container alignItems="center" justify="flex-end">
                <ReactPaginate
                  previousLabel={t('PREVIOUS_PAGE')}
                  nextLabel={t('NEXT_PAGE')}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={this.handlePageClick}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                  forcePage={page - 1}
                  disableInitialCallback={true}
                />
              </Col>
            )}
            <CreateItemModal
              isOpen={isCreateModalOpen}
              toggle={this.toggleCreateModal}
              submit={this.handleCreateFacility}
              pageName={'Facility'}
            />
          </div>
        </div>
      </Container>
    )
  }
}

export default withTranslation('common')(withRouter(FacilitiesComponent))
