import { connect } from 'react-redux';
import GroupsTab from '../../components/GroupModal/GroupsTab';
import {bindActionCreators} from "redux";
import { getGroupsList, FETCH_GROUPS_LIST_PREFIX } from '../../reducers/group';
import {createLoadingSelector} from "../../selectors/loading";

const mapStateToProps = state => {
  return {
    groupsList: state.group.list || [],
    loadingGroups: createLoadingSelector([FETCH_GROUPS_LIST_PREFIX])(state),
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getGroupsList
}, dispatch);


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupsTab);
