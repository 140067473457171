import React from 'react';
import {appTheme} from "../../config";

const ModalSearchIcon = ({theme, openGroupModal}) => (
  <label className={"modalSearchIcon"} onClick={() => openGroupModal({isOnlySearchMode: true})}>
    <img alt="" src={`/assets/images/extended-search${theme === appTheme.LIGHT ? "" : "-dark"}.svg`}/>
  </label>
);

export default ModalSearchIcon;
