import { SettingApiService, ToastrService } from '../services';
import {
  constantMessages,
} from "../config";

// ACTION_TYPES ////////////////////////////////////////////////////////////////

export const FETCH_CALLING_CODES_PREFIX = 'setting/FETCH_CALLING_CODES_LIST';
export const FETCH_CALLING_CODES_REQUEST_ACTION = FETCH_CALLING_CODES_PREFIX + '_REQUEST_ACTION';
export const FETCH_CALLING_CODES_SUCCESS_ACTION = FETCH_CALLING_CODES_PREFIX + '_SUCCESS_ACTION';
export const FETCH_CALLING_CODES_FAILURE_ACTION = FETCH_CALLING_CODES_PREFIX + '_FAILURE_ACTION';

export const FETCH_SELF_REGISTRATION_PREFIX = 'setting/FETCH_SELF_REGISTRATION';
export const FETCH_SELF_REGISTRATION_REQUEST_ACTION = FETCH_SELF_REGISTRATION_PREFIX + '_REQUEST_ACTION';
export const FETCH_SELF_REGISTRATION_SUCCESS_ACTION = FETCH_SELF_REGISTRATION_PREFIX + '_SUCCESS_ACTION';
export const FETCH_SELF_REGISTRATION_FAILURE_ACTION = FETCH_SELF_REGISTRATION_PREFIX + '_FAILURE_ACTION';


// INITIAL STATE ///////////////////////////////////////////////////////////////

const initialState = {
  callingCodes: [],
  isSelfRegistrationEnabled: null
}

// STATE ///////////////////////////////////////////////////////////////////////
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CALLING_CODES_SUCCESS_ACTION:
      return {
        ...state,
        callingCodes: action.payload.results,
      };
    case FETCH_SELF_REGISTRATION_SUCCESS_ACTION:
      return {
        ...state,
        isSelfRegistrationEnabled: action.payload.isSelfRegistrationEnabled,
      };
    default:
      return state
  }
}

// ACTIONS /////////////////////////////////////////////////////////////////////

export function getCallingCodesList() {
  return dispatch => {
    dispatch({type: FETCH_CALLING_CODES_REQUEST_ACTION});
    const settingService = new SettingApiService();
    return settingService.getCallingCodes()
      .then(({data = {}}) => {
        const {data: results} = data;
        dispatch({type: FETCH_CALLING_CODES_SUCCESS_ACTION, payload: {results}});
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({type: FETCH_CALLING_CODES_FAILURE_ACTION, payload: {message}});
        ToastrService.error(message)
      })
  }
}

export function getSelfRegistrationStatus() {
  return dispatch => {
    dispatch({type: FETCH_SELF_REGISTRATION_REQUEST_ACTION});
    const settingService = new SettingApiService();
    return settingService.getSelfRegistrationStatus()
      .then(({data = {}}) => {
        const {isSelfRegistrationEnabled} = data;
        dispatch({type: FETCH_SELF_REGISTRATION_SUCCESS_ACTION, payload: {isSelfRegistrationEnabled: JSON.parse(isSelfRegistrationEnabled)}});
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({type: FETCH_SELF_REGISTRATION_FAILURE_ACTION, payload: {message}});
        ToastrService.error(message)
      })
  }
}
