import React, {useState, useMemo} from 'react';
import {Tooltip} from 'reactstrap';

import ThreeDotsIcon from './ThreeDotsIcon';

const ITEM_MAX_LENGTH = 20;

const ShowMore = ({items = [], placeholder = null, name = "", setIsOpen = () => {}}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
    setTimeout(() => setIsOpen(!tooltipOpen));
  };

  const allItems = useMemo(() => items.join(', '), [items]);

  if (!items.length) {
    return placeholder;
  }

  if (items.length === 1 && items[0].length < ITEM_MAX_LENGTH) {
    return items[0];
  }

  return (
    <>
      {items[0].slice(0, ITEM_MAX_LENGTH)}
      <span className={"ml-1"}
            id={`allItemsTooltip-${name}`}
      >
                    <ThreeDotsIcon/>
      </span>
      <Tooltip placement="top"
               isOpen={tooltipOpen}
               autohide={false}
               target={`allItemsTooltip-${name}`}
               toggle={toggleTooltip}
      >
        {allItems}
      </Tooltip>
    </>
  );
}

export default ShowMore;
