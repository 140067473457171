import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import AdminComponent from "../../components/Admin";


const mapStateToProps = state => {
  return {
    userRole: state.auth.role,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({

}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminComponent);
