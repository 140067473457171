import React, { Fragment, PureComponent } from 'react';
import HeaderComponent from '../../containers/ContactsListContainer/HeaderContainer';
import StatusComponent from '../../containers/ContactsListContainer/StatusContainer';
import ContactsComponent from '../../containers/ContactsListContainer/ContactsContainer';
import AdvancedSearchComponent from '../../containers/ContactsListContainer/AdvancedSearchContainer';
import { Loader } from '../UIComponents';
import SearchComponent from "../../containers/UIComponents/SearchContainer";
import './index.scss';
import { useAppSettings } from '../../services/app-setting.hook';
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n';

class ContactsList extends PureComponent {
  render() {
    const {
      onSearch,
      items,
      onContactClick,
      selectedItem,
      loadingContacts,
      className,
      advancedSearch,
      withModalSearchIcon,
      isRecents, t
    } = this.props;


    return (
      <div className={`contactsListContainer ${className}`}>
        <HeaderComponent />
        <div className="divider" />
        <BannerMessage />
        <div className="divider" />
        <StatusComponent />
        <div className="divider" />
        {advancedSearch ?
          <AdvancedSearchComponent onSearch={onSearch} /> :
          <SearchComponent withModalSearchIcon={withModalSearchIcon} onSearch={onSearch} placeholder={t('chat.search_contact')} />
        }
        <div className="userItem">
          {loadingContacts ? <Loader /> : <ContactsComponent isRecents={isRecents} selectedItem={selectedItem} onContactClick={onContactClick} items={items} />}
        </div>
      </div>
    )
  }
}

const BannerMessage = () => {
  const { settings: { bannerMessage } } = useAppSettings();
  const messgae = bannerMessage[`text_${i18n.language}`] || "";

  if (!bannerMessage.show || !messgae.trim()) return null;
  
  return <div className='banner-message' style={{
    '--gap': messgae.length + 'rem'
  }}>
    {messgae.length <= 30 ?
      <div>{messgae}</div> :
      <div className='animate' style={{
        animationDuration: messgae.length / 2 + 's'
      }}>{[0, 1, 2, 3, 4, 5].map((x) => <Fragment key={x}>{messgae}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Fragment>)}
      </div>
    }
  </div>
}

export default withTranslation('common')(ContactsList)