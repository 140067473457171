import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Col, Container, Button } from "reactstrap";
import { routes } from '../../config';
import "./index.scss";

import { InputField, Loader } from '../UIComponents';
import { withTranslation } from 'react-i18next';

class ResetPasswordComponent extends Component {
  state = {
    password: '',
    confirmPassword: '',
    resetPasswordToken: '',
    forceResetPasswordToken: '',
    errors: {},
  };

  onFieldValueChange = (name, value) => {
    this.setState({ [name]: value })
  };

  componentDidMount() {
    const { token, location: { search } } = this.props;
    if (search && search.includes('token')) {
      this.setState({ forceResetPasswordToken: search.split("?token=")[1] })
    }
    !!token && this.props.history.push(routes.recent.path)
  }

  componentDidUpdate(prevProps) {
    const { token, history, loading, error } = this.props, { loading: prevLoading } = prevProps;
    if (!!token) {
      history.push(routes.recent.path);
      return
    }
    if (!loading && prevLoading && !error) {
      history.push(routes.login.path)
    }
  }

  handleResetClick = () => {
    const { t } = this.props;
    const { password, confirmPassword, resetPasswordToken, forceResetPasswordToken } = this.state, errors = {};
    let isValid = true;

    if (!resetPasswordToken && !forceResetPasswordToken) {
      errors.resetPasswordToken = t('Required');
      isValid = false;
    }

    if (!password) {
      errors.password = t('Required');
      isValid = false;
    } else if (password.length < 6) {
      errors.password = t('auth.err_pass_lent');
      isValid = false;
    } else if (!(/.*[A-Z].*/).test(password)) {
      errors.password = t('auth.err_pass_uppercase');
      isValid = false;
    } else if (!(/.*\d.*/).test(password)) {
      errors.password = t('auth.err_pass_num');
      isValid = false;
    }

    if (!confirmPassword) {
      errors.confirmPassword = t('Required');
      isValid = false;
    } else if (confirmPassword !== password) {
      errors.confirmPassword = t('auth.mismatch');
      isValid = false;
    }

    this.setState({ errors });
    isValid && this.props[forceResetPasswordToken ? 'setPasswordAction' : 'resetPasswordAction']({ token: forceResetPasswordToken || resetPasswordToken, password });
  };

  render() {
    const { errors, resetPasswordToken, password, confirmPassword, forceResetPasswordToken } = this.state;
    const { loading, history, t } = this.props;

    if (loading) {
      return (
        <Container fluid className="pageContainer bg-light">
          <Loader />
        </Container>
      )
    }

    return (
      <Container fluid className="authPageContainer resetPasswordPage pageContainer align-items-center pt-64 bg-light">
        <div className="contentContainer w-100 py-20 py-5 justify-content-center d-flex flex-column align-items-center">
          <h1 className="authPageTitle">{t('auth.set_new_pass')}</h1>
          {!forceResetPasswordToken && <h1 className="authPageSubTitle">{t('auth.enter_code_email')}</h1>}
          <Col lg={10} xs={12} className="d-flex flex-column align-items-center">
            {!forceResetPasswordToken &&
              <Col md={6} xs={12} className="px-0 ieCol">
                <InputField
                  id="resetPasswordToken"
                  type="text"
                  placeholder={t("please_enter_token")}
                  value={resetPasswordToken}
                  error={errors.resetPasswordToken}
                  required
                  onChange={(event) => this.onFieldValueChange('resetPasswordToken', event.target.value)}
                  onKeyPress={(event) => (event.charCode === 13) && this.handleResetClick()}
                />
              </Col>
            }
            <Col md={6} xs={12} className="px-0 ieCol">
              <InputField
                id="password"
                type="password"
                placeholder={t('auth.new_pass')}
                value={password}
                error={errors.password}
                required
                onChange={(event) => this.onFieldValueChange('password', event.target.value)}
                onKeyPress={(event) => (event.charCode === 13) && this.handleResetClick()}
              />
            </Col>
            <Col md={6} xs={12} className="px-0 ieCol">
              <InputField
                id="confirmPassword"
                type="password"
                placeholder={t('auth.confirm_new_pass')}
                value={confirmPassword}
                error={errors.confirmPassword}
                onChange={(event) => this.onFieldValueChange('confirmPassword', event.target.value)}
                onKeyPress={(event) => (event.charCode === 13) && this.handleResetClick()}
              />
            </Col>

            <Col md={6} xs={12} className="d-flex px-0 ieCol w-100 mb-5">
              <Button className="submitButton w-100" onClick={this.handleResetClick}>{t('admin.RESET_PASS')}</Button>
            </Col>
            <Col md={6} xs={12} className="d-flex px-0 ieCol w-100">
              <Button className="cancelButton w-100" onClick={() => history.push(routes.login.path)}>{t('auth.back_to_login')}</Button>
            </Col>

          </Col>
        </div>
      </Container>
    );
  }
}

export default withTranslation()(withRouter(ResetPasswordComponent))
