import React, { Component } from "react";
import _ from "lodash";
import { Loader, UserAvatar } from '../../UIComponents';
import FiltersBar from "../../../containers/GroupModal/FiltersContainer";
import Contacts from "../../../containers/GroupModal/ContactsContainer";
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { routes } from "../../../config";
import history from "../../../helpers/history";
import SearchComponent from "../../../containers/UIComponents/SearchContainer";
import { withTranslation } from "react-i18next";

class PeopleTab extends Component {
  state = {
    selectedContacts: [],
    groupName: "",
    showConfirmationModal: false
  };

  componentDidMount() {
    const { contacts } = this.props;
    if (!contacts.length) {
      this.props.getContactsList()
    } else {
      this.updateSelectedContacts()
    }

    this.updateDefaultData();
  }

  updateDefaultData = () => {
    const { participants, groupName } = this.props;

    this.setState({ selectedContacts: participants || [], groupName: groupName || "" })
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.creatingConversation && !this.props.creatingConversation && !this.props.creatingConversationError) {
      this.props.closeModal();
      history.push({
        pathname: routes.recent.path,
        state: { selectedChatRoomTWId: this.props.selectedChatRoomTWId }
      });
    }
    if (prevProps.updatingConversation && !this.props.updatingConversation && !this.props.updatingConversationError) {
      this.props.closeModal();
    }

    if (prevProps.gettingChatRoomsWithTheSameParticipants && !this.props.gettingChatRoomsWithTheSameParticipants && !this.props.gettingChatRoomsWithTheSameParticipantsError) {
      if (!this.props.chatRoomsBySameParticipants?.length) {
        this.handleCreateChatRoom();
      } else {
        this.toggleConfirmationPopUp()
      }
    }
  }

  toggleConfirmationPopUp = () => {
    this.setState({ showConfirmationModal: !this.state.showConfirmationModal })
  }

  handleCreateChatRoom = () => {
    const { groupName, selectedContacts } = this.state;
    this.props.createChatConversation(groupName, groupName, selectedContacts, "group");
  }

  updateChatRoom = () => {
    const { user, twId } = this.props;
    const { groupName, selectedContacts } = this.state;
    this.props.updateChatRoom({
      twId,
      participants: [...selectedContacts, user.id],
      type: "group",
      name: groupName,
    });
  }

  updateSelectedContacts = (data) => {
    const selectedContacts = _.cloneDeep(this.state.selectedContacts);

    if (!data) {
      !!selectedContacts.length && this.setState({ selectedContacts: [] });
      return;
    }

    if (_.isArray(data)) {
      this.setState({ selectedContacts: [...selectedContacts, ...data] });
      return;
    }

    const contactIndex = selectedContacts.findIndex(contact => contact === data);

    if (contactIndex === -1) {
      this.setState({ selectedContacts: [...selectedContacts, ...[data]] })
    } else {
      selectedContacts.splice(contactIndex, 1);
      this.setState({ selectedContacts })
    }

  };

  checkForExistedGroups = () => {
    this.props.getChatRoomsByParticipants({
      participants: this.state.selectedContacts
    })
  };

  renderFooterButtons() {
    const { closeModal, creatingConversation, updatingConversation, isOnlySearchMode, changeOnlySearchMode, isEditMode, t } = this.props;
    const { selectedContacts, groupName } = this.state;

    if (isOnlySearchMode) {
      return (
        <ModalFooter className="d-flex justify-content-end">
          <Button color="primary"
            onClick={() => changeOnlySearchMode(false)}
          >
            {t('chat.CREATE_GROUP')}
          </Button>
        </ModalFooter>
      )
    }

    if (isEditMode) {
      return (
        <ModalFooter className="d-flex">
          <Button color="secondary" onClick={closeModal}>{t('CANCEL')}</Button>
          <Button color="primary"
            onClick={this.updateChatRoom}
            disabled={!_.trim(groupName || "") || !selectedContacts?.length || updatingConversation}
          >
            {t('SAVE')}
          </Button>
        </ModalFooter>
      )
    }

    return (
      <ModalFooter className="d-flex">
        <Button color="secondary" onClick={closeModal}>{t('CANCEL')}</Button>
        <Button color="primary"
          onClick={this.checkForExistedGroups}
          disabled={!_.trim(groupName || "") || !selectedContacts?.length || creatingConversation}
        >
          {t('chat.CREATE_GROUP')}
        </Button>
      </ModalFooter>
    )
  }

  render() {
    const { creatingConversation, updatingConversation, contacts, loadingContacts, closeModal, chatRoomsBySameParticipants, isOnlySearchMode, isEditMode, t } = this.props;
    const { selectedContacts, groupName, showConfirmationModal } = this.state;

    return (
      <>
        {((creatingConversation && !showConfirmationModal) || updatingConversation) && <div className="processingLoader"><Loader /></div>}
        <div className="d-flex flex-column justify-content-between flex-grow-1">
          <div className="d-flex flex-column groupModalContent">
            <FiltersBar />
            {loadingContacts ? <Loader /> :
              <Contacts
                data={contacts.filter(contact => selectedContacts.findIndex(selectedContact => selectedContact === contact) === -1)}
                selectedList={false}
                emptyListMessage={"No Results"}
                onChange={this.updateSelectedContacts}
              />
            }
            {!isOnlySearchMode &&
              <>
                <SearchComponent
                  value={groupName}
                  className='inputField groupNameInputField mb-4'
                  placeholder={t('chat.enter_grp_name')}
                  onSearch={value => this.setState({ groupName: value })}
                  onKeyPress={event => (event.charCode === 13) && event.target.blur()}
                  hideIcon
                />
                <Contacts
                  data={selectedContacts}
                  selectedList={true}
                  onChange={this.updateSelectedContacts}
                />
              </>
            }
          </div>
          {this.renderFooterButtons()}
          {showConfirmationModal &&
            <Modal isOpen={showConfirmationModal} className={"groupsWithTheSameParticipantModal"} size="sm">
              <ModalHeader toggle={this.toggleConfirmationPopUp}>{t('chat.similar_groups')}</ModalHeader>
              <div className="position-relative">
                {creatingConversation && <div className="processingLoader"><Loader /></div>}
                <ModalBody className="px-4 pb-0">
                  <p className="groupsWithTheSameParticipantModalDescription">{t('chat.group_exists')}</p>
                  {chatRoomsBySameParticipants?.map(chatRoom => (
                    <div className="groupsContainer"
                      key={chatRoom.twId}
                      onClick={() => {
                        closeModal();
                        history.push({
                          pathname: routes.recent.path,
                          state: { selectedChatRoomTWId: chatRoom.twId }
                        });
                      }}
                    >
                      <UserAvatar user={chatRoom} className={`mr-2`} />
                      <div className="dataContainer">
                        <p>{chatRoom.name}</p>
                        <span>{chatRoom.participants?.length} {t('Members')}</span>
                      </div>
                    </div>
                  ))}
                </ModalBody>
                <ModalFooter className="d-flex justify-content-center">
                  <Button color="secondary" onClick={this.toggleConfirmationPopUp}>{t('CANCEL')}</Button>
                  <Button disabled={creatingConversation} color="primary" onClick={() => !creatingConversation && this.handleCreateChatRoom()}>{t('create_anyway')}</Button>
                </ModalFooter>
              </div>
            </Modal>
          }
        </div>
      </>
    )
  }
}

export default withTranslation('common')(PeopleTab)
