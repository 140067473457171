import React, { Component, Fragment } from "react";
import { Container, Input, Button } from "reactstrap";
import _ from "lodash";
import { contactDisplayName } from "../../helpers/chat";
import { ChatRoomAPIService, ToastrService } from "../../services";
import { v4 as uuidv4 } from "uuid";
import {
  ArrowUp,
  ImportantIcon,
  UrgentIcon,
} from "./helpers/icons";
import XCircle from '../../assets/icons/x-circle.svg'
import { ReactComponent as VoiceIcon } from '../../assets/icons/voice-message-icon.svg'
import XCircleDark from '../../assets/icons/x-circle-dark.svg'
import PlusOvalDark from '../../assets/icons/plus-oval-dark.svg'
import PlusOval from '../../assets/icons/plus-oval.svg'
import ImageIcon from '../../assets/icons/image-icon.svg'
import ImageIconDark from '../../assets/icons/image-icon-dark.svg'
import PaperClip from '../../assets/icons/paper-clip.svg'
import microphone from '../../assets/icons/microphone-2.svg'
import microphoneDark from '../../assets/icons/microphone-2-dark.svg'
import PaperClipDark from '../../assets/icons/paper-clip-dark.svg'
import pasteIcon from '../../assets/icons/paste-icon.svg'
import pasteIconDark from '../../assets/icons/paste-icon-dark.svg'
import ImportanceModal from "./ImportanceModal/ImportanceModal";
import AudioRecorder from "./VoiceMessage/AudioRecorder";
import { withTranslation } from "react-i18next";
import AudioRecordViewer from "./VoiceMessage/AudioRecordViewer";
import { ClipLoader } from "react-spinners";
import SpeechToText from "./SpeechToText/SpeechToText";

const authorizedImages = [
  'image/jpeg', 'image/png', 'image/x-icon', 'image/bmp', 'image/svg+xml',
  'image/gif', 'image/apng'
]

class FooterComponent extends Component {
  state = {
    message: "",
    files: {},
  };

  componentDidMount() {
    this.checkForUnsentMessage();
  }

  componentDidUpdate(prevProps) {
    if (this.props.chatRoom?.twId !== prevProps.chatRoom?.twId) {
      this.checkForUnsentMessage();
    }
  }

  checkForUnsentMessage() {
    const { chatRoom, unsentMessages } = this.props;

    if (chatRoom?.twId) {
      const unsentMessage = unsentMessages.find(
        (unsentMessage) =>
          unsentMessage?.twId === chatRoom.twId && unsentMessage?.message
      );
      if (unsentMessage) {
        this.setState({ message: unsentMessage.message?.text, files: unsentMessage.message?.files });
      }
    }
  }

  onMessageChange = (e) => {
    const { value } = e.target;
    let finalValue = value;
    let files = {};
    let copiedVoice;

    if (value.split("::::").length === 3) {
      const splits = value.split("::::");
      const jsonStr = splits[1];
      let decoded;
      try {
        decoded = JSON.parse(jsonStr);
      } catch { }

      if (decoded.images) {
        finalValue = splits[0] + (decoded.text || "") + splits[2];
        decoded.images.forEach((file) => {
          files[file.id] = file;
        });
      }

      if (decoded.voice) {
        finalValue = splits[0] + (decoded.text || "") + splits[2];
        copiedVoice = {
          key: decoded.voice,
          duration: decoded.duration
        }
      }
    }

    this.saveUnsentMessage(finalValue, { ...this.state.files, ...files })

    this.setState({
      message: finalValue,
      files: { ...this.state.files, ...files },
      copiedVoice
    });
  };

  handleVoiceToTextChange = (value) => {
    this.saveUnsentMessage()
    this.setState({ message: value })
    this.saveUnsentMessage(value, { ...this.state.files })
  }

  saveUnsentMessage = (text, files) => {
    if (this.props.conversationsList) {
      const conversation = this.props.conversationsList.find(
        (conversation) => conversation.sid === this.props.chatRoom.twId
      );
      if (conversation) {
        conversation.typing();
        this.props.updateUnsentMessage(conversation.sid, { text, files });
      }
    }
  }

  handleFilePaste = (e) => {
    const clipboard = e.clipboardData || e.originalEvent.clipboardData;
    const items = clipboard.items;

    const files = [];
    for (let index in items) {
      const item = items[index];
      if (item.kind === "file" || item.type?.includes("image")) {
        files.push(item.getAsFile());
      }
    }

    if (files.length > 0) {
      e.preventDefault();
      this.buildStateFiles(files);
    }
  };

  trigerFileError = () => {
    this.setState({ fileError: true });
    setTimeout(() => {
      this.setState({ fileError: false });
    }, 4000);
  };

  buildStateFiles = (allFiles) => {
    const files = [];
    const _10Mb = 1024 * 1024 * 10
    for (let file of allFiles) {
      if (authorizedImages.includes(file.type)) {

        if (file.size > _10Mb) {
          ToastrService.info(
            `File size of ${file.name} exceeds maximum limit of 10Mb`
          );
        } else {
          files.push(file);
        }
      } else {
        ToastrService.info('This is an unsupported file format.')
      }
    }

    const filstUploaded = Object.values(this.state.files).filter((x) => !!x)

    if (
      files.length + filstUploaded.length > 10) {
      ToastrService.error("Up to 10 files can be uploaded at a time.");
      this.trigerFileError();
      return;
    }

    const uplodedSize = filstUploaded.reduce((sum, item) => sum + item.size, 0)
    const currentFilesSize = files.reduce((sum, item) => sum + item.size, 0)
    if (uplodedSize + currentFilesSize > _10Mb) {
      ToastrService.info(
        `Files uploaded exceeds maximum limit of 10Mb`
      );
      return;
    }

    const stateFiles = {};

    files.forEach((file) => {
      const id = uuidv4();
      stateFiles[id] = {
        id,
        loading: true,
        name: file.name,
        type: file.type,
        size: file.size,
        progress: 0,
        file,
      };
    });

    this.setState({ files: { ...this.state.files, ...stateFiles } });

    this.uploadAllImages(stateFiles);
  };

  uploadAllImages = (stateFiles) => {
    for (const fileObject of Object.values(stateFiles)) {
      const { id, file } = fileObject;

      new ChatRoomAPIService()
        .sendChatImage(file, (progressEvent) => {
          let { progress } = this.state;
          progress = (progressEvent.loaded / progressEvent.total) * 98;
          if (this.state.files[id]) {
            this.setState({
              files: {
                ...this.state.files,
                [id]: { ...this.state.files[id], progress },
              },
            });
          }
        })
        .then((res) => {
          const resFile = res.data.files[0];
          if (this.state.files[id]) {
            this.setState({
              files: {
                ...this.state.files,
                [id]: {
                  id,
                  name: this.state.files[id].name,
                  type: this.state.files[id].type,
                  key: resFile.Key,
                  size: this.state.files[id].size,
                },
              },
            }, () => {
              this.saveUnsentMessage(this.state.message, this.state.files)
            });
          }
        })
        .catch(() => {
          this.setState({ files: { ...this.state.files, [id]: undefined } });
        });
    }
  };

  sendMessage = (importance = undefined) => {
    const { message, files, recording, copiedVoice } = this.state;
    let voice;

    if (recording) {
      this.setState({ saveRecord: true, importance, isOpen: false, });
      return;
    }

    const { chatRoom = {} } = this.props;
    const stateFiles = Object.values(files).filter((x) => !!x && x.name);
    const filesHaveLoaded = stateFiles.every((x) => x.key);

    if (!filesHaveLoaded) {
      ToastrService.info("All images are not completely uploaded!!");
      return;
    }

    if (!!message || filesHaveLoaded || voice) {
      this.props.sendMessage(message, chatRoom.twId, {
        files: stateFiles,
        voice: copiedVoice,
        ...importance,
        seen: [], // important to ease update on elastic search
        acknowledge: [], // important to ease update on elastic search
      });

      this.setState({
        message: "",
        files: {},
        importance: undefined,
        isOpen: false,
        copiedVoice: undefined,
        showSpeechToText: false
      });
    }
  };

  sendImportantMessage = (value) => {
    this.sendMessage(value);
  };

  getTypingMembersNames = (typingMembers) => {
    const { contacts } = this.props;
    const splicedMembers =
      typingMembers.length > 3 ? typingMembers.splice(0, 3) : typingMembers;
    return splicedMembers
      .map((member) => {
        const contact = contacts.find((item) => item.id === member);
        return contactDisplayName(contact);
      })
      .join(", ");
  };

  renderTypingMembers() {
    const { chatRoom } = this.props;
    const typingMembers = chatRoom.typingMembers;
    const membersNames = this.getTypingMembersNames(typingMembers);
    const activeVerb = typingMembers.length === 1 ? "is" : "are";
    const typingKey = typingMembers.join("&");
    const text =
      typingMembers.length > 3 ? (
        <p className="mb-0 ml-3">
          {membersNames} and {typingMembers.length - 3} other people are
          typing...
        </p>
      ) : (
        <p className="mb-0 ml-3">
          {membersNames} {activeVerb} typing...
        </p>
      );

    return (
      <div className="typingStatusContainer" key={typingKey}>
        <img src="/assets/images/typing-chat.gif" width="40" alt="typing" />
        {text}
      </div>
    );
  }

  pasteClipboard = async () => {
    try {
      const items = await navigator.clipboard.read();
      const text = await navigator.clipboard.readText();
      const files = [];

      for (const item of items) {
        if (item.types.includes("image/png")) {
          const blob = await item.getType("image/png");

          files.push(new File([blob], `${uuidv4()}.png`, { type: "image/png" }));
        }
      }

      if (files.length > 0) {
        this.buildStateFiles(files)
      }

      if (text) {
        const { message } = this.state;
        this.onMessageChange({ target: { value: message + text } })
      }

    } catch {
      ToastrService.error('Please provide clipboard permission!')
    }

  }

  handleDeleteImage = (file, objectKey) => {
    this.setState({ files: { ...this.state.files, [objectKey]: undefined } }, () => {
      this.saveUnsentMessage(this.state.message, this.state.files)
    });
    new ChatRoomAPIService().deleteChatImage(file.key);
  };

  showImportanceModal = (importance) => {
    this.props.fetchGroupMembers();
    this.setState({ isOpen: true, importance });
  };

  handleRecordAudio = () => {
    this.setState({ recording: true })
  }

  handleSpeechToText = () => {
    this.setState({ showSpeechToText: true })
  }

  handleVoiceTotextExit = () => {
    this.setState({ showSpeechToText: false })
  }

  handleRecordComplete = (voiceData) => {
    if (voiceData) {
      const { chatRoom = {} } = this.props;
      const { importance, audioPreview } = this.state;
      if (audioPreview && !audioPreview.loading) {
        this.props.sendMessage(undefined, chatRoom.twId, {
          files: [],
          voice: {
            key: audioPreview.key,
            duration: audioPreview.duration
          },
          ...importance,
          seen: [], // important to ease update on elastic search
        });
    
        this.setState({ recording: false, saveRecord: false, importance: undefined, audioPreview: undefined, loading: false })
      } else {
        new ChatRoomAPIService().sendChatVoiceFile(voiceData.blob).then(res => {
          this.props.sendMessage(undefined, chatRoom.twId, {
            files: [],
            voice: {
              key: res.data.voice.Key,
              duration: voiceData.duration
            },
            ...importance,
            seen: [], // important to ease update on elastic search
          });
        })
          .catch(err => {
            ToastrService.error('Network error, please try again!!')
          }).finally(() => {
            this.setState({ loading: false })
          })
        this.setState({ recording: false, saveRecord: false, importance: undefined, audioPreview: undefined, loading: true })
      }
    } else {
      this.setState({ recording: false, saveRecord: false, importance: undefined, audioPreview: undefined, loading: false })
    }
  }

  handleSetAudioPreview = ({ blob, duration }) => {
    this.setState({ audioPreview: { loading: true } });
    new ChatRoomAPIService().sendChatVoiceFile(blob).then(res => {
      this.setState({
        audioPreview: {
          loading: false,
          key: res.data.voice.Key,
          duration: duration
        }
      })
    })
      .catch(err => {
        ToastrService.error('Network error, please try again!!')
      })
  }

  fileInputRef;

  render() {
    const { chatRoom, isChatDisabled, participants, user, contacts, theme, t } =
      this.props;
    const { message, files: fileObject, recording, saveRecord, loading, copiedVoice, showSpeechToText, audioPreview } = this.state;
    const files = Object.values(fileObject).filter((x) => !!x && x.name);
    const invalidMessage = !_.trim(message) && !files.length;

    const canNotSendSMS = !recording && (isChatDisabled || invalidMessage) && !copiedVoice
    const disableSMS = !audioPreview && (isChatDisabled || invalidMessage) && !copiedVoice

    return (
      <Container fluid className="chatFooterContainer col-11">
        <input
          hidden
          multiple
          type="file"
          accept={authorizedImages.join(', ')}
          ref={(ref) => (this.fileInputRef = ref)}
          onChange={(e) => {
            this.buildStateFiles(Array.from(e.target.files));
            e.target.value = "";
          }}
        />
        <div className="before_input">
          {this.state.fileError && (
            <div className="file_error">
              {t('chat.error_upload_limit')}
            </div>
          )}
          <div className="sms_types">
            {user?.permissions?.sms !== false && <div>
              <Button
                disabled={disableSMS || canNotSendSMS}
                onClick={() => this.showImportanceModal("URGENT")}
              >
                <UrgentIcon /> {t('chat.SMS_URGENT')}
              </Button>
              <Button
                disabled={disableSMS || canNotSendSMS}
                onClick={() => this.showImportanceModal("IMPORTANT")}
              >
                <ImportantIcon /> {t('chat.SMS_IMPORTANT')}
              </Button>
            </div>}
            <Button
              disabled={showSpeechToText}
              onClick={this.handleRecordAudio}
            >
              <VoiceIcon /> {t('chat.VOICE_MESSAGE')}
            </Button>
          </div>
        </div>
        <div className="text_container">
          {files.length > 0 && (
            <div className="files">
              <div className="number">{files.length}/10</div>
              {files.map((file, i) => {
                const progress = file.progress || (!file.key ? 95 : 0);
                return (
                  <div key={i} className="file_item">
                    <img src={theme === 'dark' ? ImageIconDark : ImageIcon} alt='' />
                    <span title={file.name}>{file.name}</span>
                    <button
                      onClick={() => this.handleDeleteImage(file, file.id)}
                    >
                      <img src={theme === 'dark' ? XCircleDark : XCircle} alt='' />
                    </button>
                    <div
                      className="progress"
                      style={{
                        width: `${progress}%`,
                        backgroundColor:
                          file.progress >= 100 && file.key
                            ? "transparent"
                            : "#ff9494",
                      }}
                    />
                  </div>
                );
              })}
              {files.length < 10 && (
                <button onClick={() => this.fileInputRef.click()}>
                  <img src={theme === 'dark' ? PlusOvalDark : PlusOval} alt='' />
                </button>
              )}
            </div>
          )}

          <div className="input">
            {!recording && <Fragment>
              {!copiedVoice && <Input
                onKeyPress={(event) => {
                  if (!invalidMessage && event.charCode === 13) {
                    event.preventDefault();
                    this.sendMessage();
                  }
                }}
                onChange={_.throttle(this.onMessageChange, 500)}
                className={`messageTextArea${!!message ? "" : "  empty"}${files.length > 0 ? ' with_files' : ''}`}
                onPaste={this.handleFilePaste}
                value={message}
                disabled={isChatDisabled}
                type={"textarea"}
                rows={1}
                placeholder={t('chat.your_msg')}
              />}
              {copiedVoice && <AudioRecordViewer
                onSend
                isDarkTheme={theme === 'dark'}
                copiedVoice={copiedVoice}
                onDiscard={() => {
                  this.setState({ copiedVoice: undefined })
                }} />}
            </Fragment>}

            {recording && <AudioRecorder
              onRecordingComplete={this.handleRecordComplete}
              audioTrackConstraints={{
                echoCancellation: true,
                noiseSuppression: true
              }}
              preview={this.state.audioPreview}
              setPreview={this.handleSetAudioPreview}
              saveRecord={saveRecord}
            />}

            {showSpeechToText && <SpeechToText onVoiceTotextExit={this.handleVoiceTotextExit} message={message} onTextChange={this.handleVoiceToTextChange} />}
            {!showSpeechToText && <div className="buttons" style={{ display: recording ? 'none' : undefined }}>
              <button
                className="upload_btn"
                onClick={this.pasteClipboard}
                title="Paste"
              >
                <img src={theme === 'dark' ? pasteIconDark : pasteIcon} alt='' />
              </button>
              <button
                className="upload_btn"
                onClick={() => this.fileInputRef.click()}
                title="Upload images"
              >
                <img src={theme === 'dark' ? PaperClipDark : PaperClip} alt='' />
              </button>
              {user?.permissions?.speechToText !== false && <button
                className="microphone"
                title="Speech to text"
                onClick={this.handleSpeechToText}
              >
                <img src={theme === 'dark' ? microphoneDark : microphone} alt='' />
              </button>}
            </div>}
            <Button
              disabled={canNotSendSMS}
              className="sendMessageButton"
              onClick={() => this.sendMessage()}
            >
              {loading ? <ClipLoader size={17} color={'#fff'} loading={true} /> : <ArrowUp />}
            </Button>
          </div>
        </div>
        {!!chatRoom.typingMembers?.length && this.renderTypingMembers()}
        {this.state.isOpen && (
          <ImportanceModal
            toggle={() => {
              this.setState({ isOpen: !this.state.isOpen });
            }}
            isOpen={this.state.isOpen}
            importance={this.state.importance}
            setImportance={(importance) => this.setState({ importance })}
            user={user}
            participants={participants}
            contacts={contacts}
            isGroup={chatRoom.type === "group"}
            textMessage={message}
            files={files}
            theme={theme}
            submitMessage={this.sendImportantMessage}
            audioPreview={audioPreview}
            copiedVoice={copiedVoice}
          />
        )}
      </Container>
    );
  }
}

export default withTranslation('common')(FooterComponent)