import React, {Component} from "react";
import {Button} from "reactstrap";
import _ from "lodash";
import MultiSelectField from "../../../../containers/UIComponents/MultiSelectField";
import {convertUserSettingsIntoMultiSelectOptions, uncheckAllOptions, checkProvidedOptions} from "../../../../utils/function-utils";
import { withTranslation } from "react-i18next";

class SettingsFilters extends Component {

  state = {
    facility: [],
    department: [],
    profession: [],
    specialty: [],
  };

  componentDidMount() {
    this.props.getFacilitiesList({all: 1});
    this.props.getDepartmentsList({all: 1});
    this.props.getProfessions({all: 1});
    this.props.getSpecialties({all: 1});

    const { selectedFilters: {facility, department, profession, specialty} } = this.props;
    if(facility.length || department.length || profession.length || specialty.length) {
      this.handleFilterChange()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loadingFacilities && !this.props.loadingFacilities) {
      this.prepareFacilitiesMultiSelectOptions()
    }
    if (prevProps.loadingDepartments && !this.props.loadingDepartments) {
      this.prepareDepartmentsMultiSelectOptions()
    }
    if (prevProps.loadingProfessions && !this.props.loadingProfessions) {
      this.prepareProfessionsMultiSelectOptions()
    }
    if (prevProps.loadingSpecialities && !this.props.loadingSpecialities) {
      this.prepareSpecialitiesMultiSelectOptions()
    }

    if(!_.isEqual(this.props.selectedFilters, prevProps.selectedFilters)) {
      this.handleForceFiltersChange()
    }
  }

  handleForceFiltersChange = () => {
    const { selectedFilters: {facility, department, profession, specialty} } = this.props;

    this.setState({
      facility: checkProvidedOptions(this.state.facility, facility),
      department: checkProvidedOptions(this.state.department, department),
      profession: checkProvidedOptions(this.state.profession, profession),
      specialty: checkProvidedOptions(this.state.specialty, specialty),
    });

  };

  prepareFacilitiesMultiSelectOptions = () => {
    const { facilitiesList } = this.props;

    const facility = convertUserSettingsIntoMultiSelectOptions(facilitiesList);

    this.setState({facility})
  };

  prepareDepartmentsMultiSelectOptions = () => {
    const { departmentsList } = this.props;
    const department = convertUserSettingsIntoMultiSelectOptions(departmentsList);

    this.setState({department})
  };

  prepareProfessionsMultiSelectOptions = () => {
    const { professionsList } = this.props;

    const profession = convertUserSettingsIntoMultiSelectOptions(professionsList);

    this.setState({profession})
  };

  prepareSpecialitiesMultiSelectOptions = () => {
    const { specialtiesList } = this.props;

    const specialty = convertUserSettingsIntoMultiSelectOptions(specialtiesList);

    this.setState({specialty})
  };

  handleFilterChange = (name, options) => {
    this.setState({[name]: options});
    const values = options.filter(option => option.checked).map(option => option.value);
    this.props.onChange(name, values)
  };

  resetFiltersOptions = () => {
    const { facility, department, profession, specialty } = this.state;

    this.setState({
      facility: uncheckAllOptions(facility),
      department: uncheckAllOptions(department),
      profession: uncheckAllOptions(profession),
      specialty: uncheckAllOptions(specialty),
    }, this.props.onReset)
  };

  resetButtonStatus() {
    const { facility, department, profession, specialty } = this.state;

    return !facility.some(item => item.checked) &&
      !department.some(item => item.checked) &&
      !profession.some(item => item.checked) &&
      !specialty.some(item => item.checked);
  }

  render() {
    const { facility, department, profession, specialty } = this.state;
    const { t } = this.props;

    return (
      <div className='settingsFiltersSectionContainer flex-wrap d-flex align-items-center justify-content-between mb-3'>
        <div className={"settingsFiltersContainer d-flex flex-wrap"}>
          <MultiSelectField
            title={t('chat.All_Facilities')}
            options={facility}
            onChange={(newOptions) => this.handleFilterChange('facility', newOptions)}
          />
          <MultiSelectField
            title={t('chat.All_Departments')}
            options={department}
            onChange={(newOptions) => this.handleFilterChange('department', newOptions)}
          />
          <MultiSelectField
            title={t('chat.All_Professions')}
            options={profession}
            onChange={(newOptions) => this.handleFilterChange('profession', newOptions)}
          />
          <MultiSelectField
            title={t('chat.All_Specializations')}
            options={specialty}
            onChange={(newOptions) => this.handleFilterChange('specialty', newOptions)}
          />
        </div>
        <Button disabled={this.resetButtonStatus()}
                className={'settingsFiltersSubmitButton'}
                onClick={this.resetFiltersOptions}
        >
          {t('RESET')}
        </Button>
        <div className="d-none divider mt-3"/>
      </div>
    )
  }
}

export default withTranslation('common')(SettingsFilters)
