import React from "react";
import { appTheme } from "../../config";
import { LogoImage } from "../../services/app-setting.hook";
import './HeaderComponent.scss'

const HeaderComponent = ({ selectedTheme }) => (
  <div className="headerContainer">
    <LogoImage
      alt="ECS Logo"
      src={`${selectedTheme === appTheme.DARK ? "recentLogoDark" : "recentLogo"}`}
      width={143}
    />
  </div>
);

export default HeaderComponent;
