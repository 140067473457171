import { connect } from 'react-redux';
import UpdatePhoneNumberComponent from '../../components/Auth/UpdatePhoneNumberComponent';
import { bindActionCreators } from "redux";
import {createLoadingSelector} from "../../selectors/loading";
import {
  FETCH_CALLING_CODES_PREFIX,
  getCallingCodesList
} from "../../reducers/setting";
import {changePhoneNumber, CHANGE_PHONE_NUMBER_PREFIX} from "../../reducers/auth";
import {createErrorMessageSelector} from "../../selectors/error";

const mapStateToProps = state => {
  return {
    callingCodes: state.setting.callingCodes,
    loading: createLoadingSelector([FETCH_CALLING_CODES_PREFIX])(state),
    changingPhoneNumber: createLoadingSelector([CHANGE_PHONE_NUMBER_PREFIX])(state),
    changingPhoneNumberError: createErrorMessageSelector([CHANGE_PHONE_NUMBER_PREFIX])(state),
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getCallingCodesList,
  changePhoneNumber,
}, dispatch);


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdatePhoneNumberComponent);
