import BaseApiService from './base';
import { SocketMessageType } from "../../config";
import SocketService from "../socket";

export default class ChatRoomApiService extends BaseApiService {
  getMessages(twId, before, limit, msgId) {
    return this.request({
      method: 'post',
      url: '/chat-room/messages',
      data: { twId, before, limit, msgId },
      withCredentials: true
    });
  }

  getChatRoomsList(keyword = '') {
    return this.request({
      method: 'get',
      url: '/chat-room',
      withCredentials: true,
      params: { keyword }
    });
  }

  getGroupsList(keyword = '') {
    return this.request({
      method: 'post',
      url: '/chat-room/search-groups',
      withCredentials: true,
      data: { keyword }
    });
  }

  getChatRoomsByParticipantsList(data = []) {
    return this.request({
      method: 'post',
      url: '/chat-room/chat-rooms-by-participants',
      withCredentials: true,
      data
    });
  }

  createChatRoom(data) {
    return this.request({
      method: 'post',
      url: '/chat-room',
      data,
      withCredentials: true
    });
  }

  updateChatRoom(data) {
    return this.request({
      method: 'post',
      url: '/chat-room/update-chat-room',
      data,
      withCredentials: true
    });
  }

  markMessageImportant(data) {
    return this.request({
      method: 'post',
      url: '/chat-room/message-important',
      data,
      withCredentials: true
    })
  }

  deleteChatRoom(twId) {
    return this.request({
      method: 'delete',
      url: '/chat-room',
      params: { twId },
      withCredentials: true
    })
  }

  muteChatRoom(twId) {
    return this.request({
      method: 'post',
      url: '/chat-room/mute',
      data: { twId },
      withCredentials: true
    });
  }

  unmuteChatRoom(twId) {
    return this.request({
      method: 'post',
      url: '/chat-room/unmute',
      data: { twId },
      withCredentials: true
    });
  }

  setChatRoomToFavorite = (chatRoom) => (
    this.request({
      method: 'post',
      url: `/chat-room/set-favorite`,
      data: { id: chatRoom.id, twId: chatRoom.twId },
      withCredentials: true
    })
  )

  unsetChatRoomToFavorite = (chatRoom) => (
    this.request({
      method: 'post',
      url: `/chat-room/unset-favorite`,
      data: { id: chatRoom.id, twId: chatRoom.twId },
      withCredentials: true
    })
  )

  leaveChatRoom = (id, twId) => (
    this.request({
      method: 'post',
      url: `/chat-room/leave`,
      params: { id, twId },
      withCredentials: true
    })
  )

  removeMessage = (data) => (
    this.request({
      method: 'delete',
      url: `/chat-room/remove-message`,
      data,
      withCredentials: true
    })
  )

  acknowledgeMessage = (data) => (
    this.request({
      method: 'post',
      url: `/chat-room/acknowledge-message`,
      data,
      withCredentials: true
    })
  )

  sendChatImage(files, onUploadProgress) {
    const formData = new FormData();
    if (Array.isArray(files)) {
      for (const file of files) {
        formData.append("files", file);
      }
    } else {
      formData.append("files", files);
    }

    return this.request({
      method: "POST",
      url: "/chat-room/upload-chat-images",
      withCredentials: true,
      data: formData,
      onUploadProgress
    });
  }

  sendChatVoiceFile(file, onUploadProgress) {
    const formData = new FormData();

    formData.append("file", file);

    return this.request({
      method: "POST",
      url: "/chat-room/upload-chat-audio",
      withCredentials: true,
      data: formData,
      onUploadProgress
    });
  }

  getChatRoomMessageLogs(logId = '') {
    return this.request({
      method: 'get',
      url: '/chat-room/message-logs',
      withCredentials: true,
      params: { logId },
      query: { logId }
    });
  }


  deleteChatImage(fileKey) {
    return this.request({
      method: "DELETE",
      url: "/chat-room/upload-chat-images?key=" + fileKey,
      withCredentials: true
    });
  }

  readMessage(data) {
    return SocketService.send(SocketMessageType.READ_MESSAGE, data);
  }
}
