import React, { Component } from "react";
import history from "../../../helpers/history";
import { routes } from "../../../config";
import { Loader, UserAvatar } from "../../UIComponents";
import SearchComponent from "../../UIComponents/SearchComponent";
import { withTranslation } from "react-i18next";

class GroupsTab extends Component {

  componentDidMount() {
    this.props.getGroupsList();
  }

  render() {
    const { groupsList, closeModal, loadingGroups, t } = this.props;

    return (
      <div className="tabContainer">
        <div className="searchContainer px-0 mt-0">
          <SearchComponent onSearch={(value) => this.props.getGroupsList(value)}
            placeholder={t("Search...")}
            className={"inputField"}
            hideIcon
          />
        </div>
        {
          loadingGroups ? <div className="d-flex h-100 align-items-center justify-content-center"><Loader /></div> :
            groupsList?.map(chatRoom => (
              <div className="groupsContainer"
                key={chatRoom.twId}
                onClick={() => {
                  closeModal();
                  history.push({
                    pathname: routes.recent.path,
                    state: { selectedChatRoomTWId: chatRoom.twId }
                  });
                }}
              >
                <UserAvatar user={chatRoom} className={`mr-2`} />
                <div className="dataContainer">
                  <p>{chatRoom.name}</p>
                  <span>{chatRoom.participants?.length} {t('Members')}</span>
                </div>
              </div>
            ))
        }
      </div>
    )
  }
}

export default withTranslation('common')(GroupsTab)