import BaseApiService from './base';
import { SocketMessageType } from "../../config";
import SocketService from "../socket";

export default class CallService extends BaseApiService {
  service = SocketService || {}

  initializeCall(data) {
    return this.service.send(SocketMessageType.CALL_INITIALIZED_CLIENT, data);
  }
  initializeDirectScreenShare(data) {
    return this.service.send(SocketMessageType.DIRECT_SCREEN_SHARE_INITIALIZED_CLIENT, data);
  }
  declineCall(data) {
    return this.service.send(SocketMessageType.CALL_DECLINED_CLIENT, data);
  }
  acceptCall(data) {
    return this.service.send(SocketMessageType.CALL_ACCEPTED_CLIENT, data);
  }
  endCall(data) {
    return this.service.send(SocketMessageType.CALL_ENDED_CLIENT, data);
  }
  endCallForAll(data) {
    return this.service.send(SocketMessageType.CALL_END_FOR_ALL_CLIENT, data);
  }
  startScreenShare(data) {
    return this.service.send(SocketMessageType.START_SCREEN_SHARE_CLIENT, data);
  }
  endScreenShare(data) {
    return this.service.send(SocketMessageType.END_SCREEN_SHARE_CLIENT, data);
  }
  muteParticipant(callData, participantId) {
    return this.service.send(SocketMessageType.CALL_PARTICIPANT_MUTE_CLIENT, {callData, participantId});
  }
  unMuteParticipant(callData, participantId) {
    return this.service.send(SocketMessageType.CALL_PARTICIPANT_UNMUTE_CLIENT, {callData, participantId});
  }
  muteParticipantDuringScreenShare(callData, all, participantId) {
    return this.service.send(SocketMessageType.CALL_PARTICIPANT_MUTE_DURING_SCREEN_SHARE_CLIENT, {callData, participantId, all});
  }
  unMuteParticipantDuringScreenShare(callData, all, participantId) {
    return this.service.send(SocketMessageType.CALL_PARTICIPANT_UNMUTE_DURING_SCREEN_SHARE_CLIENT, {callData, participantId, all});
  }
  sendPing(data) {
    return this.service.send(SocketMessageType.PING, data)
  }
}
