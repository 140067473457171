import React, { Component } from 'react';
import { Container, Col } from 'reactstrap';
import ManagePasswordsComponent from "../../../containers/AccountSettings/ManagePasswordsContainer";
import { routes } from "../../../config";
import { SettingsIconDropdown, Loader, UserAvatar } from "../../UIComponents";
import PersonalInformationComponent from "../../../containers/AccountSettings/PersonalInformationContainer";
import './AccountSettings.scss'
import LanguageSelectorContainer from '../../../containers/AccountSettings/LanguageSelectorContainer';
import PhoneAndEmailContainer from '../../../containers/AccountSettings/PhoneAndEmailContainer';
export default class AccountSettingsComponent extends Component {

  componentDidUpdate(prevProps) {
    const { history, loggingOut } = this.props, { loading: prevLoggingOut } = prevProps;
    if (!loggingOut && prevLoggingOut) {
      history.push(routes.login.path)
    }
  }

  handleUpdateAvatar(file) {
    this.props.setUserAvatar(file);
  }

  handleDeleteAvatar() {
    this.props.removeUserAvatar();
  }

  render() {
    const { user, changingAvatar, removingAvatar } = this.props;

    return (
      <Container fluid className="AccountSettings">
        <div className="contentContainer d-flex flex-column px-0">
          <Container fluid className="accountSettingsAvatarContainer">
            <div className="contentContainer d-flex align-items-start flex-wrap">
              <Col lg={4} sm={12} className="px-0">
                <h1 className="personal-info">Avatar</h1>
              </Col>
              <Col className="accountSettingFieldContainer px-0 ieCol d-flex align-items-center">
                <p className={'mr-3 inputLabel fake'} />
                <div className="avatar_">
                  {changingAvatar || removingAvatar ? <div className="avatarContainer big loadingState"><Loader /></div> : <UserAvatar user={user} className={'big'} />}
                  <div className="avatarSettingsButton">
                    <SettingsIconDropdown
                      hasAvatar={!!user.avatar}
                      onUpdate={this.handleUpdateAvatar.bind(this)}
                      onDelete={this.handleDeleteAvatar.bind(this)}
                    />
                  </div>
                </div>
              </Col>
            </div>
          </Container>

          <div className="divider" />
          <LanguageSelectorContainer />
          <div className="divider" />
          <PersonalInformationComponent />
          <div className="divider" />
          <ManagePasswordsComponent />
          <div className="divider" />
          <PhoneAndEmailContainer />
        </div>
      </Container>
    );
  }
}
