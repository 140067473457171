/**
 * Storage Service is for handling data saving, reading and removing from the localhost
 */

export default class StorageService {
  constructor() {
    this.storage = localStorage;
  }

  saveUserToken(token) {
    return this.storage.setItem(
      'jwt-esc',
      token
    );
  }

  updateUserSessions(data) {
    return this.storage.setItem(
      'user-sessions',
       JSON.stringify(data)
    )
  }

  removeUserToken() {
    return this.storage.removeItem('jwt-esc');
  }

  checkUserToken() {
    return this.storage.getItem('jwt-esc')
  }

  checkUserSessions() {
    return JSON.parse(this.storage.getItem('user-sessions'))
  }
}
