import React, { Fragment, useEffect, useState } from "react"
import "./PasswordUpdateModal.scss";
import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button
} from "reactstrap";
import { InputField } from '../UIComponents';
import EyeIcon from '../../assets/icons/remove_red_eye.svg'
import { useTranslation } from "react-i18next";

const PasswordUpdateModalComponent = ({ changePasswordAction, toggleUpdatePasswordModal, isPaswordUpdateModalOpen, isPaswordUpdated }) => {
	const [state, setStat_] = useState({ errors: {} });
	const { t } = useTranslation('common')
	const setState = (obj) => {
		setStat_({ ...state, ...obj })
	}

	useEffect(() => {
		setStat_({ errors: {} })
	}, [isPaswordUpdateModalOpen])


	const handleSubmit = () => {
		const { currentPassword, newPassword, confirmPassword } = state, errors = {};
		let isValid = true;

		if (!currentPassword) {
			errors.currentPassword = t('Required');
			isValid = false;
		}

		if (!newPassword) {
			errors.newPassword = t('Required');
			isValid = false;
		} else if (newPassword.length < 6) {
			errors.newPassword = t('auth.err_pass_lent');
			isValid = false;
		} else if (!(/.*[A-Z].*/).test(newPassword)) {
			errors.newPassword = t('auth.err_pass_uppercase');
			isValid = false;
		} else if (!(/.*[a-z].*/).test(newPassword)) {
			errors.newPassword = t('auth.err_password_lower');
			isValid = false;
		} else if (!(/.*\d.*/).test(newPassword)) {
			errors.newPassword = t('auth.err_pass_num');
			isValid = false;
		}

		if (!confirmPassword) {
			errors.confirmPassword = t('Required');
			isValid = false;
		} else if (confirmPassword !== newPassword) {
			errors.confirmPassword = t('auth.mismatch');
			isValid = false;
		}

		setState({ errors });
		isValid && changePasswordAction({ currentPassword, newPassword });
	};

	const onFieldValueChange = (name, value) => {
		setState({ [name]: value })
	};

	const { errors, currentPassword, newPassword, confirmPassword } = state;

	return <Modal
		isOpen={isPaswordUpdateModalOpen}
		toggle={toggleUpdatePasswordModal}
		className={`PasswordUpdateModalComponent`}
		centered
	>
		<ModalHeader toggle={toggleUpdatePasswordModal}>
			{isPaswordUpdated ? 'Password has been updated' : <Fragment><img src={EyeIcon} alt='' />	{t('auth.reset_pass_reminder')}</Fragment>}
		</ModalHeader>
		{!isPaswordUpdated && <Fragment>
			<ModalBody className="update">
				<div className="form-group">
					<span>{t('auth.current_password')}</span>
					<InputField
						id="currentPassword"
						type="password"
						placeholder={t('auth.current_password')}
						value={currentPassword}
						error={errors.currentPassword}
						onChange={(event) => onFieldValueChange('currentPassword', event.target.value)}
						onKeyPress={(event) => (event.charCode === 13) && handleSubmit()}
					/>
				</div>
				<div className="form-group">
					<span>{t('auth.New_Pass')}</span>
					<InputField
						id="newPassword"
						type="password"
						placeholder={t('auth.New_Pass')}
						value={newPassword}
						error={errors.newPassword}
						onChange={(event) => onFieldValueChange('newPassword', event.target.value)}
						onKeyPress={(event) => (event.charCode === 13) && handleSubmit()}
					/>
					<InputField
						id="confirmPassword"
						type="password"
						placeholder={t('auth.Confirm_New_Pass')}
						value={confirmPassword}
						error={errors.confirmPassword}
						onChange={(event) => onFieldValueChange('confirmPassword', event.target.value)}
						onKeyPress={(event) => (event.charCode === 13) && handleSubmit()}
					/>
				</div>
			</ModalBody>
			<ModalFooter className="d-flex">
				<Button
					className="cancel_btn"
					color="primary"
					onClick={toggleUpdatePasswordModal}
				>
					{t('auth.remind_me')}
				</Button>
				<Button color="primary" onClick={handleSubmit} >
					{t('Update')}
				</Button>
			</ModalFooter>
		</Fragment>}
		{isPaswordUpdated && <Fragment>
			<ModalBody className="success">
				<p>{t('auth.success_pass_update')}</p>
			</ModalBody>
			<ModalFooter className="d-flex">
				<Button color="primary" onClick={toggleUpdatePasswordModal}>
					OK
				</Button>
			</ModalFooter>
		</Fragment>}
	</Modal>
}

export default PasswordUpdateModalComponent
