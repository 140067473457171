import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import SideNav from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import "./index.scss";
import {appTheme, routes, userRoles} from "../../config";
import { UserAvatar } from "../UIComponents";
import { LogoImage } from '../../services/app-setting.hook';
import { withTranslation } from 'react-i18next';


class SideBarComponent extends Component {
  state = {
    expanded: false,
    navRightPartClass: 'sideBarRightPartContainer'
  };

  componentDidUpdate(prevProps) {
    const { history, loggingOut, logOutError } = this.props, { loading: prevLoggingOut } = prevProps;
    if (!loggingOut && prevLoggingOut && !logOutError) {
      history.push(routes.login.path)
    }
  }

  openSideNav = () => {
    this.setState({ expanded: true, navRightPartClass: 'sideBarRightPartContainer open' });
  };

  closeSideNav = () => {
    this.setState({ expanded: false, navRightPartClass: 'sideBarRightPartContainer' });
  }

  toggleMobileNav = (expanded) => {
    if (expanded) {
      this.setState({ expanded: false, navRightPartClass: 'sideBarRightPartContainer' });
    } else {
      this.setState({ expanded: true, navRightPartClass: 'sideBarRightPartContainer open' });
    }
  }

  openGroupModal = () => {
    this.props.openGroupModal();
    this.closeSideNav()
  }

  render() {
    const {expanded, navRightPartClass: rightNavClass} = this.state;
    const { token, trialLogInLoading, user = {}, userRole, isChatting, newMessagesInRecentTab, theme, t } = this.props;

    if (trialLogInLoading) {
      return null;
    }

    return (
      <>
        <SideNav
          className={isChatting ? "sideBarContainer chat-hide" : "sideBarContainer"}
          expanded={expanded}
          onToggle={this.handleToggle}
        >
          <SideNav.Nav className={!token ? 'justify-content-between' : 'hideMobile'} defaultSelected="home">
            {!token && (
              <NavLink exact to={routes.login.path} onClick={this.closeSideNav} className={"sideBarItemContainer logoContainer"}>
                <LogoImage src={`${theme === appTheme.LIGHT || !token ? "squareLogo" : "squareLogoDark"}`} width="45" alt="logo" className="logo" />
              </NavLink>
            )}
            {token && (
              <div className="d-flex flex-column align-items-center w-100">
                <NavLink exact to={routes.recent.path} onClick={this.closeSideNav} className={"sideBarItemContainer logoContainer"}>
                  <LogoImage src={`${theme === appTheme.LIGHT || !token ? "squareLogo" : "squareLogoDark"}`} width="45" alt="logo" className="logo" />
                </NavLink>
                <NavLink exact to={routes.settings.path} role={"menuitem"} onClick={this.closeSideNav} className={"sideBarItemContainer"}>
                  <div className="avatarContainer">
                    <UserAvatar user={user} />
                  </div>
                </NavLink>
                <NavLink exact to={routes.recent.path} role={"menuitem"} onClick={this.closeSideNav} className={"sideBarItemContainer"}>
                  <img src="/assets/images/recent.svg" width="22" alt="recent" />
                  <p className={"sideBarText"}>{t('Recent')}</p>
                  {!!newMessagesInRecentTab && <p className="menuItemBadge">{newMessagesInRecentTab}</p>}
                </NavLink>
                <NavLink exact to={routes.contacts.path} role={"menuitem"} onClick={this.closeSideNav} className={"sideBarItemContainer"}>
                  <img src="/assets/images/contacts.svg" width="18" alt="contacts" />
                  <p className={"sideBarText"}>{t('Contacts')}</p>
                </NavLink>
                <div role={"menuitem"} className={`sideBarItemContainer clickable`} onClick={this.openGroupModal}>
                  <img src="/assets/images/group.svg" width="20" alt="group" />
                  <p className={"sideBarText"}>{t('Group')}</p>
                </div>
              </div>
            )}
            <div className="d-flex flex-column align-items-center w-100">
              {(userRole === userRoles.ADMIN || userRole === userRoles.FACILITY_ADMIN) &&
                <NavLink exact to={routes.users.path} role={"menuitem"} onClick={this.closeSideNav} className={"sideBarItemContainer"}>
                  <img src="/assets/images/admin.svg" width="24" alt="admin" />
                  <p className={"sideBarText"}>{t('Admin')}</p>
                </NavLink>
              }
              <div role={"menuitem"} onClick={expanded ? this.closeSideNav : this.openSideNav} className={`sideBarItemContainer clickable ${expanded ? "active" : ""}`}>
                <img src="/assets/images/more.svg" width="24" alt="admin" />
                <p className={"sideBarText"}>{t('More')}</p>
              </div>
            </div>
          </SideNav.Nav>
        </SideNav>
        {(expanded || !token) &&
            <div className={rightNavClass}>
              <LogoImage src={theme === appTheme.LIGHT ? 'moreLogo' : "moreLogoDark"} width="244" alt="banner" className={"banner"} />
              <button className="nav-collapse" onClick={() => this.toggleMobileNav(expanded)}>
                <span></span>
                <span></span>
                <span></span>
              </button>
              <NavLink onClick={this.closeSideNav} exact to={routes.aboutUs.path} className={"sideBarText"}>{t('About Us')}</NavLink>
              <NavLink onClick={this.closeSideNav} exact to={routes.information.path} className={"sideBarText"}>{t('Information')}</NavLink>
              <img src={`/assets/images/sidebar-image${theme === appTheme.LIGHT || !token ? "-light" : "-dark"}.jpg`} alt="banner" className={"sideBarRightSideBackground"} />
              <span className="copyRightText">&copy; ECS Messaging Systems, 2020</span>
            </div>
          }
      </>
    );
  }
}

export default withTranslation('common')(withRouter(SideBarComponent));
