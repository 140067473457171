import { connect } from 'react-redux';
import ManagePasswordComponent from '../../components/Settings/AccountSettings/ManagePassword';
import { bindActionCreators } from "redux";
import {CHANGE_PASSWORD_PREFIX, changePasswordAction} from "../../reducers/auth";
import {createLoadingSelector} from "../../selectors/loading";
import {createErrorMessageSelector} from "../../selectors/error";

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    user: state.auth.data,
    loading: createLoadingSelector([CHANGE_PASSWORD_PREFIX])(state),
    error: createErrorMessageSelector([CHANGE_PASSWORD_PREFIX])(state),
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  changePasswordAction,
}, dispatch);


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManagePasswordComponent);
