import React from 'react'
import { InputField, SelectField } from '../../../../UIComponents'
import { Button } from 'reactstrap';
import './PasswordUpdate.scss'
import { useAppSettings } from '../../../../../services/app-setting.hook';
import { SettingApiService, ToastrService } from '../../../../../services';
import { useTranslation } from 'react-i18next';

const options = (t) => [
  {
    value: true,
    label: t('Activate')
  },
  {
    value: false,
    label: t('Deactivate')
  }
];

const timeOptions = (t) => [
  {
    value: 'day',
    label: t('Days')
  },
  {
    value: 'week',
    label: t('Weeks')
  },
  {
    value: 'month',
    label: t('Months')
  },
  {
    value: 'year',
    label: t('Years')
  }
];

export const PasswordUpdate = () => {
  const { settings: { passwordUpdate }, updateSettings } = useAppSettings();
  const { t } = useTranslation('common');
  
  const handleFieldChange = (key) => value => {
    updateSettings({
      key: 'passwordUpdate', value: {
        ...passwordUpdate,
        changed: true,
        [key]: value
      }
    })
  }


  const handleSumbit = () => {
    new SettingApiService()
      .updatePasswordPolicy(passwordUpdate)
      .then((res) => {
        ToastrService.success("Password update policy updated successfully")
      })
      .catch((err) => {
        ToastrService.error(err.message)
      })
  }

  return (
    <div className='PasswordUpdate callStatusBulkActionContainer'>
      <div className='content_'>
        <p>{t('admin.req_pass_updt')}</p>
        <div className='schedule_status'>
          <p className='title'>{t('admin.schedule_status')}</p>
          <SelectField options={options(t)}
            key={1}
            className='multiSelectDropdown'
            defaultValue={passwordUpdate.active}
            onChange={handleFieldChange('active')}
          />
        </div>
        <div>
          <p className='title'>{t('admin.set_frequency')}</p>
          <div className={'frequency_inputs' + (!passwordUpdate.active ? ' disabled' : '')}>
            <InputField
              disabled={!passwordUpdate.active}
              className="input_"
              type="number"
              value={passwordUpdate.frequency}
              onKeyPress={(e) => {
                if (!/[0-9]/.test(e.key)) {
                  e.preventDefault()
                }
              }}
              onChange={({ target }) => {
                console.log('target.value', target.value < 0)
                if (target.value.length <= 3 && target.value >= 0) {
                  handleFieldChange('frequency')(target.value)
                }
              }}
            />
            <SelectField
              disabled={!passwordUpdate.active}
              key={2}
              options={timeOptions(t)}
              className='multiSelectDropdown'
              defaultValue={passwordUpdate.period}
              onChange={handleFieldChange('period')}
            />
          </div>
        </div>
        <Button
          disabled={!passwordUpdate.changed}
          className="submitButton"
          onClick={handleSumbit}
        >{t('Submit')}</Button>
      </div>
    </div>
  )
}
