import React, {useMemo, useState} from 'react';
import _ from "lodash";
import {Tooltip} from 'reactstrap';
import TrackComponent from './TrackComponent';
import VIDecline from "../../../assets/icons/video-declined.svg";
import { useTranslation } from 'react-i18next';

const Participant = ({participant, isSmall, isLocalParticipant, callParticipantData, isDirectScreenShare, isSomeoneSharingScreen, isSharedByCurrentUser, forceVoiceToggle, sizes = {}}) => {

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const { t } = useTranslation('common')
  const { height, width, maxHeight, maxWidth } = sizes;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const publications = useMemo(() => Array.from(participant.tracks?.values()), [participant.tracks?.values()]);
  const hasVoiceDSS = callParticipantData?.voiceDuringScreenShare;
  const hasVoice = callParticipantData?.voice;

  const isVoiceEnabled = useMemo(() => isSomeoneSharingScreen ? hasVoiceDSS : hasVoice, [hasVoice, hasVoiceDSS, isSomeoneSharingScreen])

  const audioStream = useMemo(() => publications.find(p => p.kind === "audio"), [publications]);
  const videoStream = useMemo(() => publications.find(p => p.trackName === "screen" && p.kind === "video") || publications.find(p => p.kind === "video"), [publications]);

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  if (isSmall) {
    return !!audioStream &&
      <TrackComponent isMuted={!isVoiceEnabled} isLocalParticipant={isLocalParticipant} publication={audioStream} key={audioStream.trackSid}/>
  }

  return (
    <div className='participant'
         style={{
           height: height + 'px',
           width: width + 'px',
           maxHeight: maxHeight + 'px',
           maxWidth: maxWidth + 'px'
         }}
    >
      {!isLocalParticipant && (!isDirectScreenShare || !isSharedByCurrentUser) &&
      <div className={`participantMuteStatus${isSharedByCurrentUser ? " clickable" : ""}`}
           id="participantMuteStatus"
           onClick={_.throttle(() => forceVoiceToggle(isVoiceEnabled ? "mute" : "unmute"), 1500)}
      >
        <img src='/assets/images/btn-mute.svg' alt='' className={isVoiceEnabled ? "" : "muted"}/>
        {!isVoiceEnabled && <p>{t('Muted')}</p>}
      </div>
      }
      {!videoStream && (
        <div className='noVideoContainer'>
          <div className="noVideoIconContainer">
            <img src={VIDecline} alt={"No video"}/>
          </div>
        </div>
      )}
      {!!videoStream && isDirectScreenShare && isSharedByCurrentUser && (
        <div className='noVideoContainer h-100 w-100 '>
          <div className="noVideoIconContainer d-flex h-100 w-100 align-items-center justify-content-center">
            <p className="m-0 text-center">{t('chat.sharing_your_screen')}</p>
          </div>
        </div>
      )}
      {!!audioStream && <TrackComponent isMuted={!isVoiceEnabled} isLocalParticipant={isLocalParticipant} publication={audioStream}/>}
      {!!videoStream && (!isDirectScreenShare || !isSharedByCurrentUser) && <TrackComponent isLocalParticipant={isLocalParticipant} publication={videoStream}/>}
      {!isLocalParticipant && !isSharedByCurrentUser &&
      <Tooltip placement="top"
               isOpen={tooltipOpen}
               autohide={false}
               target="participantMuteStatus"
               toggle={toggleTooltip}
      >
        {t('chat.you_can_mute_others')}
      </Tooltip>
      }
    </div>
  );
};

export default Participant
