import React, { useMemo } from "react";

const apiUrl = process.env.REACT_APP_API_URL;

export const imgSource = (imgKey) => `${apiUrl}/chat-room/image?key=${imgKey}&${Math.random()}`

export const ImageS3 = ({ imgKey, imgName, ...props }) => {
    const url = useMemo(() => {
        return imgSource(imgKey)
    }, [imgKey])

    return <img alt={imgName} src={url} {...props}/>
}