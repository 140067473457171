import React, { Component } from 'react';
import { Container, Button } from 'reactstrap';
import AccountSettingsComponent from "../../containers/AccountSettings/AccountSettingsContainer";
import AppSettingsComponent from "../../containers/AppSettings/AppSettingsContainer";
import './index.scss';
import { withTranslation } from 'react-i18next';

class SettingsComponent extends Component {
  availableTabs = [{ key: 'set', label: "Settings" }, { key: 'acc', label: "Account" }]
  state = {
    selectedTab: this.availableTabs[0]
  };

  render() {
    const { user, logOut, t } = this.props;
    const { selectedTab } = this.state;

    return (
      <Container fluid className="settingsContainer pageContainer">
        <div className="contentContainer d-flex flex-column px-0">
          <div className="settingsHeaderContainer">
            <div className="d-flex justify-content-between align-items-start">
              <h1>{user.firstName} {user.lastName}</h1>
              <Button onClick={() => logOut({ isManual: true })} className="submitButton">{t('LOGOUT')}</Button>
            </div>
            <div className={"tabsHeadersContainer"}>
              {this.availableTabs.map(tab => <p key={tab.key}
                onClick={() => this.setState({ selectedTab: tab })}
                className={`${selectedTab === tab ? 'selected ' : ''}tabItem`}>{t(tab.label)}</p>)}
            </div>
          </div>
          {selectedTab.key === this.availableTabs[0].key && <AppSettingsComponent />}
          {selectedTab.key === this.availableTabs[1].key && <AccountSettingsComponent />}
        </div>
      </Container>
    );
  }
}

export default withTranslation()(SettingsComponent)
