import { connect } from 'react-redux';
import _ from "lodash";
import { bindActionCreators } from "redux";
import RecentsComponent from '../../components/Recents';
import {
  FETCH_CONTACTS_LIST_PREFIX,
  getContactsList,
} from "../../reducers/contacts";
import {
  CREATE_TWILIO_CHAT_CLIENT_PREFIX,
  createChatConversation,
  joinTwilioConversationById,
  GET_TWILIO_TOKEN_PREFIX,
} from "../../reducers/twilio";
import { createLoadingSelector } from "../../selectors/loading";
import { createErrorMessageSelector } from "../../selectors/error";
import { groupContactsByAlphabetic, groupChatRoomsByDateAndFavorites } from "../../helpers/chat";
import {getChatRoomsList, markRecentTabMessagesAsRead, MUTE_CHAT_ROOM_PREFIX, UNMUTE_CHAT_ROOM_PREFIX} from "../../reducers/chatRooms";
import { changeChattingStatus } from "../../reducers/chat";

const mapStateToProps = state => {
  return {
    user: state.auth.data,
    contacts: groupContactsByAlphabetic(state.contacts.list || []),
    conversationsList: state.twilio.conversationsList,
    groupedChatRooms: groupChatRoomsByDateAndFavorites(state.chatRooms.searchedList || state.chatRooms.list || []),
    chatRooms: _.cloneDeep(state.chatRooms.list),
    selectedChatRoomTWId: state.chatRooms.selectedChatRoomTWId,
    groupMode: state.chat.groupMode,
    loading: createLoadingSelector([FETCH_CONTACTS_LIST_PREFIX, GET_TWILIO_TOKEN_PREFIX, CREATE_TWILIO_CHAT_CLIENT_PREFIX])(state),
    changingMuteStatus: createLoadingSelector([MUTE_CHAT_ROOM_PREFIX, UNMUTE_CHAT_ROOM_PREFIX])(state),
    error: createErrorMessageSelector([FETCH_CONTACTS_LIST_PREFIX])(state),
    twilioClient: state.twilio.client
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getContactsList,
  createChatConversation,
  changeChattingStatus,
  joinTwilioConversationById,
  getChatRoomsList,
  markRecentTabMessagesAsRead
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecentsComponent);
