import CryptoJS from 'crypto-js';
const hashSecret = 'ecs-}$9egvVn5jc<{fM+';

export const encrypthMessage = (message = '') => {
  return CryptoJS.AES.encrypt(message, hashSecret).toString();
};

export const decryptMessage = (message = '') => {
  return CryptoJS.AES.decrypt(message, hashSecret).toString(CryptoJS.enc.Utf8);
};

export const trimMessage = (str = '', length = 100) => {
  return str.length > length ? `${str.substr(0, length)}...` : str;
};
