import React from 'react';
import _ from "lodash";
import UserAvatar from '../../UIComponents/UserAvatar'
import { ReactComponent as SSIncomingVideo } from '../../../assets/icons/direct-screen-share-incoming.svg';
import { ReactComponent as VIIncomingVideo } from '../../../assets/icons/video-incoming.svg';
import { ReactComponent as VIIncomingVoice } from '../../../assets/icons/voice-incoming.svg';
import { ReactComponent as VIDeclineVideo } from '../../../assets/icons/video-declined.svg';
import { ReactComponent as VIDeclineAudio } from '../../../assets/icons/voice-declined.svg';
import { ReactComponent as VIAcceptVideo } from '../../../assets/icons/video-started.svg';
import { ReactComponent as VIAcceptVoice } from '../../../assets/icons/voice-started.svg';
import { ReactComponent as VICallVoice } from '../../../assets/icons/call-voice.svg';
import history from "../../../helpers/history";
import { routes } from "../../../config";
import { withTranslation } from 'react-i18next';

class RingingCallView extends React.Component {

  state = {
    callIsBeingConnected: false
  }

  openChatRoom = () => {
    history.push({
      pathname: routes.recent.path,
      state: { selectedChatRoomTWId: this.props.twId }
    })
  }

  handleAcceptCall = (twId, onlyVoice) => {
    this.setState({ callIsBeingConnected: true })
    this.props.acceptCall(twId, onlyVoice);
  }

  renderBeingConnectedMessage() {
    const { isParallel, isSmall, isDirectScreenShare, t } = this.props
    const { callIsBeingConnected } = this.state
    if (!isParallel && !isSmall && callIsBeingConnected) {
      return (
        <div className='callStartingMessageContainer'>
          <div className='callStartingMessage'>
            <h3>{t('pls_wait')}</h3>
            <p>{isDirectScreenShare ? t('chat.share_being_connected') : t('chat.call_being_connected')}</p>
          </div>
        </div>
      )
    }
    return null
  }

  renderCallHeader() {
    const { isIncoming, callName, callType, isDirectScreenShare, isSmall, t } = this.props;

    const callTitle = isIncoming ? 'chat.incoming_' : 'chat.outgoing_';
    const onlyAudio = callType === 'audio';
    const type = isDirectScreenShare ? "screen_share" : `${callType}_call`;

    return (
      <div className='call-header mb-3'>
        {isDirectScreenShare ?
          <SSIncomingVideo className={`${isSmall ? 'clickable ' : ''} video-icon`}
            onClick={() => isSmall && this.openChatRoom()}
          /> :
          onlyAudio ?
            <VIIncomingVoice className={`${isSmall ? 'clickable ' : ''} video-icon`}
              onClick={() => isSmall && this.openChatRoom()}
            /> :
            <VIIncomingVideo className={`${isSmall ? 'clickable ' : ''} video-icon`}
              onClick={() => isSmall && this.openChatRoom()}
            />
        }
        <div className='call-info'>
          <div className='title'>{t(callTitle + type)}</div>
          <div className='sub-title'>{callName}</div>
        </div>
      </div>
    )
  }

  renderResponseButtons() {
    const { isIncoming, endCallLabel, callType, isParallel, declineCall, isDirectScreenShare, twId, isSmall, t } = this.props;
    const onlyAudio = callType === 'audio';
    return (
      <div className='buttons-wrap'>
        {isIncoming && (
          <button className='accept' onClick={_.throttle(() => this.handleAcceptCall(twId, onlyAudio), 3000)}>
            {isDirectScreenShare ? <img alt="" src='/assets/images/screen-sharing-accept.svg' className="directScreenShareResponseIcon" /> : onlyAudio ? <VIAcceptVoice /> : <VIAcceptVideo />}
            {!isParallel && !isSmall && <div>{t('accept')}</div>}
          </button>
        )}
        {!isDirectScreenShare && (isParallel || isSmall) && isIncoming && !onlyAudio && (
          <button className='primary accept-audio' onClick={_.throttle(() => this.handleAcceptCall(twId, true), 3000)}>
            <VICallVoice />
          </button>
        )}
        <button className='decline' onClick={_.throttle(() => declineCall(twId), 3000)}>
          {isDirectScreenShare ? <img alt="" src='/assets/images/screen-sharing-decline.svg' className="directScreenShareResponseIcon" /> : onlyAudio ? <VIDeclineAudio /> : <VIDeclineVideo />}
          {!isParallel && !isSmall && <div>{endCallLabel}</div>}
        </button>
      </div>
    )
  }

  render() {
    const { isIncoming, callAvatar, callType, isParallel, isDirectScreenShare, twId, isSmall, t } = this.props;
    const onlyAudio = callType === 'audio';
    return (
      <div className={`ringing-call-container position-relative py-0${isParallel || isSmall ? " small" : ""}`}>
        {this.renderBeingConnectedMessage()}
        {this.renderCallHeader()}
        <div className='call-body'>
          {!isParallel && !isSmall && (
            <div className={"incoming-call-avatar-container"}>
              <img src={"/assets/images/inc-call-circle-animation.gif"} width="300" alt="circle animation" className={"circle-animation"} />
              <UserAvatar className={'user-avatar'} user={callAvatar} />
            </div>
          )}
          <div className='call-controls'>
            {this.renderResponseButtons()}
            {!isDirectScreenShare && !isParallel && !isSmall && isIncoming && !onlyAudio && (
              <button className='primary accept-audio' onClick={_.throttle(() => this.handleAcceptCall(twId, true), 3000)}>
                <VICallVoice />
                <div>{t('chat.answer_with_voice')}</div>
              </button>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation('common')(RingingCallView)