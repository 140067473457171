import {ContactsApiService, ToastrService} from '../services';
import {constantMessages} from "../config";
import store from "../store";

// ACTION_TYPES ////////////////////////////////////////////////////////////////

export const FETCH_CONTACTS_LIST_PREFIX = 'contacts/FETCH_CONTACTS_LIST';
export const FETCH_CONTACTS_LIST_REQUEST_ACTION = FETCH_CONTACTS_LIST_PREFIX + '_REQUEST_ACTION';
export const FETCH_CONTACTS_LIST_SUCCESS_ACTION = FETCH_CONTACTS_LIST_PREFIX + '_SUCCESS_ACTION';
export const FETCH_CONTACTS_LIST_FAILURE_ACTION = FETCH_CONTACTS_LIST_PREFIX + '_FAILURE_ACTION';

export const FETCH_CONTACTS_LIST_FOR_GROUP_PREFIX = 'contacts/FETCH_CONTACTS_LIST_FOR_GROUP';
export const FETCH_CONTACTS_LIST_FOR_GROUP_REQUEST_ACTION = FETCH_CONTACTS_LIST_FOR_GROUP_PREFIX + '_REQUEST_ACTION';
export const FETCH_CONTACTS_LIST_FOR_GROUP_SUCCESS_ACTION = FETCH_CONTACTS_LIST_FOR_GROUP_PREFIX + '_SUCCESS_ACTION';
export const FETCH_CONTACTS_LIST_FOR_GROUP_FAILURE_ACTION = FETCH_CONTACTS_LIST_FOR_GROUP_PREFIX + '_FAILURE_ACTION';

export const FETCH_PAGE_MEMBERS_PREFIX = 'contacts/FETCH_PAGE_MEMBERS_PREFIX';
export const FETCH_PAGE_MEMBERS_REQUEST_ACTION = FETCH_PAGE_MEMBERS_PREFIX + '_REQUEST_ACTION';
export const FETCH_PAGE_MEMBERS_SUCCESS_ACTION = FETCH_PAGE_MEMBERS_PREFIX + '_SUCCESS_ACTION';
export const FETCH_PAGE_MEMBERS_FAILURE_ACTION = FETCH_PAGE_MEMBERS_PREFIX + '_FAILURE_ACTION';

export const ADD_NEW_REGISTERED_CONTACT_ACTION = 'contacts/ADD_NEW_REGISTERED_CONTACT_ACTION';

export const UPDATE_PAGE_MEMBERS_ACTION = 'contacts/UPDATE_PAGE_MEMBERS_ACTION';

export const REMOVE_PAGE_MEMBERS_ACTION = 'contacts/REMOVE_PAGE_MEMBERS_ACTION';

export const UPDATE_CONTACT_ACTION = 'contacts/UPDATE_CONTACT_ACTION';

export const FORCE_REMOVE_CONTACT_ACTION = 'contacts/FORCE_REMOVE_CONTACT_ACTION';

export const RESET = 'contacts/RESET';
export const RESET_SEARCHED_FOR_GROUP_RESULTS = 'contacts/RESET_SEARCHED_FOR_GROUP_RESULTS';


// INITIAL STATE ///////////////////////////////////////////////////////////////

const initialState = {
  list: [],
  searchedForGroup: null,
  searchedList: null,
  pageMembers: {}
};

// STATE ///////////////////////////////////////////////////////////////////////
export default (state = initialState, action) => {
  let list = [...state.list];
  let searchedList = state.searchedList && [...state.searchedList];
  switch (action.type) {
    case FETCH_CONTACTS_LIST_SUCCESS_ACTION:
    case FETCH_CONTACTS_LIST_FOR_GROUP_SUCCESS_ACTION:
      let searchedForGroup = state.searchedForGroup && [...state.searchedForGroup];
      if (!!action.payload.search) {
        if (action.payload.isForGroup) {
          searchedForGroup = action.payload.contacts?.filter(contact => !contact.deleted && !!contact.lastLoginDate);
        } else {
          searchedList = action.payload.contacts;
        }
      } else {
        searchedForGroup = null;
        searchedList = null;
        list = action.payload.contacts
      }
      return {
        ...state,
        list,
        searchedList,
        searchedForGroup
      };
    case ADD_NEW_REGISTERED_CONTACT_ACTION:
      const newContactIndex = list.findIndex(contact => contact.id === action.payload.id);
      if (newContactIndex === -1) {
        list = [...list, action.payload]
      }
      return {
        ...state,
        list,
      };
    case UPDATE_CONTACT_ACTION:
      const contactIndex = list.findIndex(contact => contact.id === action.payload.id);
      const searchedIndex = searchedList ? searchedList.findIndex(contact => contact.id === action.payload.id) : -1;
      if(contactIndex !== -1) {
        list[contactIndex] = action.payload.updateAll ? action.payload.contact : {...list[contactIndex], ...action.payload.contact}
      }
      if(searchedIndex !== -1) {
        searchedList[searchedIndex] =  action.payload.updateAll ? action.payload.contact : {...searchedList[searchedIndex], ...action.payload.contact}
      }
      return {
        ...state,
        list,
        searchedList
      };
    case FETCH_PAGE_MEMBERS_SUCCESS_ACTION:
      return {
        ...state,
        pageMembers: {
          ...state.pageMembers,
          [action.payload.id]: [...action.payload.members]
        }
      }
    case UPDATE_PAGE_MEMBERS_ACTION:
      return {
        ...state,
        pageMembers: {
          ...state.pageMembers,
          [action.payload.id]: [...state.pageMembers[action.payload.id], { ...action.payload.member }]
        }
      }
    case REMOVE_PAGE_MEMBERS_ACTION:
      const updatedMembers = state.pageMembers[action.payload.id].filter(m => m.id !== action.payload.member.id)
      return {
        ...state,
        pageMembers: {
          ...state.pageMembers,
          [action.payload.id]: updatedMembers
        }
      }
    case FORCE_REMOVE_CONTACT_ACTION:
      const index = list.findIndex(contact => contact.id === action.payload.id);
      return {
        ...state,
        list: index !== -1 ? [ ...list.slice(0, index), { ...list[index], deleted: true }, ...list.slice(index+1) ] : list,
      };
    case RESET:
      return {
        ...state,
        list: [],
      };
    case RESET_SEARCHED_FOR_GROUP_RESULTS:
      return {
        ...state,
        searchedForGroup: null,
      };
    default:
      return state
  }
}

// ACTIONS /////////////////////////////////////////////////////////////////////

export function getContactsList(searchData, isForGroup) {
  return (dispatch) => {
    dispatch({type: isForGroup ? FETCH_CONTACTS_LIST_FOR_GROUP_REQUEST_ACTION : FETCH_CONTACTS_LIST_REQUEST_ACTION});
    const contactsService = new ContactsApiService();
    return contactsService.getContactsList(searchData)
      .then(({data = {}}) => {
        let {contacts = []} = data;
        if (contacts && contacts.length) {
          const state = store.getState() || {};
          contacts = contacts.filter(contact => contact.id !== state.auth.data.id)
        }
        dispatch({type: isForGroup ? FETCH_CONTACTS_LIST_FOR_GROUP_SUCCESS_ACTION : FETCH_CONTACTS_LIST_SUCCESS_ACTION, payload: {contacts, search: searchData, isForGroup}});
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({type: isForGroup ? FETCH_CONTACTS_LIST_FOR_GROUP_FAILURE_ACTION : FETCH_CONTACTS_LIST_FAILURE_ACTION, payload: {message}});
        ToastrService.error(message)
      })
  }
}

export function getPageMembers(pageName, id, name = '') {
  return dispatch => {
    dispatch({ type: FETCH_PAGE_MEMBERS_REQUEST_ACTION })
    const contactsService = new ContactsApiService();
    return contactsService.getPageMembers({ [pageName]: [id], name })
      .then(({data = {}}) => {
        const members = data.contacts || []
        dispatch({ type: FETCH_PAGE_MEMBERS_SUCCESS_ACTION, payload: { members, id } })
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({type: FETCH_PAGE_MEMBERS_FAILURE_ACTION, payload: {message}});
        ToastrService.error(message)
      })
  }
}

export function updatePageMembers(id, member) {
  return dispatch => {
    dispatch({ type: UPDATE_PAGE_MEMBERS_ACTION, payload: { id, member } })
  }
}

export function removePageMember(id, member) {
  return dispatch => {
    dispatch({ type: REMOVE_PAGE_MEMBERS_ACTION, payload: { id, member } })
  }
}

export function reset() {
  return dispatch => {
    dispatch({type: RESET})
  }
}

