import React, { Component } from 'react';
import _ from "lodash";
import { Redirect, Route as PublicRoute, withRouter } from 'react-router-dom';
import { Container } from "reactstrap";
import { routes } from "../../config";
import { Loader } from '../UIComponents';

class Route extends Component {

  renderOrRedirect = (ownProps) => {
    let { component: Component, token, userRole, path, trialLogInLoading } = this.props;
    if (trialLogInLoading) return null;
    const route = _.values(routes).find(route => route.path === path);

    if (!token || (!!route.users && route.users.findIndex(role => role === userRole) === -1)) {
      if (window.location.search) {
        window.location.href = routes.login.path + window.location.search;
        return <Container fluid className="pageContainer loginLoading bg-light">
          <Loader />
        </Container>
      }

      return <Redirect to={{
        pathname: routes.login.path + window.location.search,
        state: { from: ownProps.location }
      }} />
    }

    return <Component {...ownProps} />
  };

  render() {
    let { path } = this.props;
    return <PublicRoute path={path} render={this.renderOrRedirect} />
  }
}

export default withRouter(Route)
