import { connect } from 'react-redux';
import {bindActionCreators} from "redux";
import AdvancedSearchComponent from '../../components/ContactsListComponent/AdvancedSearchComponent';
import {openModal} from "../../reducers/group";

const mapStateToProps = state => {
  const userSettings = state.auth.data?.settings || {};

  const theme = userSettings?.theme;

  return {
    theme,
    user: state.auth.data,
  };
};


export default connect(
  mapStateToProps,
  {}
)(AdvancedSearchComponent);
