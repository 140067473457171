import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { getPageMembers, removePageMember, updatePageMembers } from "../../../reducers/contacts";
import { updateUser, changeUserStatus } from "../../../reducers/user";
import { deleteSpecialty, getSpecialtyById, updateSpecialty } from "../../../reducers/specialties";
import EditSpecialtyComponent from "../../../components/Admin/Components/Specialties/EditSpecialtyComponent";


const mapStateToProps = state => {
  const specialtyId = state.specialties.specialty.id;
  const selectedTheme = state.auth.data?.settings?.theme;
  return {
    selectedTheme,
    users: state.users.users,
    usersLength: state.users.total,
    specialty: state.specialties.specialty,
    members: state.contacts.pageMembers[specialtyId],
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getSpecialtyById,
  updateSpecialty,
  getPageMembers,
  removePageMember,
  updateUser,
  updatePageMembers,
  changeUserStatus,
  deleteSpecialty
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditSpecialtyComponent);
