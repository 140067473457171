import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import AccountSettingsComponent from '../../components/Settings/AccountSettings';
import {
  CHANGE_AVATAR_PREFIX,
  LOG_OUT_PREFIX,
  logOut,
  setUserAvatar,
  removeUserAvatar, REMOVE_AVATAR_PREFIX
} from "../../reducers/auth";
import {createLoadingSelector} from "../../selectors/loading";

const mapStateToProps = state => {
  return {
    user: state.auth.data,
    loggingOut: createLoadingSelector([LOG_OUT_PREFIX])(state),
    changingAvatar: createLoadingSelector([CHANGE_AVATAR_PREFIX])(state),
    removingAvatar: createLoadingSelector([REMOVE_AVATAR_PREFIX])(state)
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  logOut,
  setUserAvatar,
  removeUserAvatar,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountSettingsComponent);
