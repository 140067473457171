import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import PhoneAndEmail from '../../components/Settings/AccountSettings/PhoneAndEmail';
import {
  CHANGE_PERSONAL_INFORMATION_PREFIX,
  changePhoneAndEmail,
  cancelPhoneAndEmailUpdate,
  VERIFY_EMAIL_PREFIX,
  VERIFY_MOBILE_PREFIX,
  verifyEmailAction,
} from "../../reducers/auth";
import { verifyUpdatedMobileAction as verifyMobileAction } from "../../reducers/user";
import {createLoadingSelector} from "../../selectors/loading";
import {createErrorMessageSelector} from "../../selectors/error";
import { FETCH_CALLING_CODES_PREFIX, getCallingCodesList } from "../../reducers/setting";


const mapStateToProps = state => {
  return {
    token: state.auth.token,
    user: state.auth.data,
    loading: createLoadingSelector([CHANGE_PERSONAL_INFORMATION_PREFIX])(state),
    error: createErrorMessageSelector([CHANGE_PERSONAL_INFORMATION_PREFIX])(state),
    verifyingEmail: createLoadingSelector([VERIFY_EMAIL_PREFIX])(state),
    verifyingPhone: createLoadingSelector([VERIFY_MOBILE_PREFIX])(state),
    verifyingEmailError: createErrorMessageSelector([VERIFY_EMAIL_PREFIX])(state),
    verifyingPhoneError: createErrorMessageSelector([VERIFY_MOBILE_PREFIX])(state),
    callingCodes: state.setting.callingCodes,
    loadingCallingCodes: createLoadingSelector([FETCH_CALLING_CODES_PREFIX])(state),
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getCallingCodesList,
  verifyEmailAction,
  verifyMobileAction,
  updatePhoneAndEmail: changePhoneAndEmail,
  cancelPhoneAndEmailUpdate,
}, dispatch);


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PhoneAndEmail);