import React from 'react';
import { ScaleLoader } from 'react-spinners';

const Loader = (props) => {
  return (
    <div className="loaderContainer">
     <ScaleLoader size={props.size || 200} color={props.color || '#313363'} loading={true}/>
    </div>
  )
};

export default Loader;
