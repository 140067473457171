export class APIError extends Error {
    constructor(message, data) {
        super(message);

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, APIError);
        }

        this.name = 'APIError';
        this.data = data;
    }
}