import React from 'react'
import './index.scss';
import { useTranslation } from 'react-i18next';

const NoChatSelected = () => {
    const { t } = useTranslation('common')
    return (
        <div className="chatSectionContainer pageContainer mx-0 container-fluid hide-mobile">
            <div className="chatMessagesContainer noMessageContainer searchMessage">
                <p className="errorMessage">
                    {t('chat.empty_chat_msg')}
                </p>
            </div>
        </div>
    )
}

export default NoChatSelected
