import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import UserComponent from '../../../components/Admin/Components/User/UserComponent';
import {
  CREATE_USER_PREFIX,
  createUser,
  UPDATE_USER_PREFIX,
  updateUser,
  GET_USER_BY_ID_PREFIX,
  getUserById,
  DELETE_USER_PREFIX,
  deleteUser,
  changeUserAvatar,
  CHANGE_USER_AVATAR_PREFIX,
  removeUserAvatar,
  REMOVE_USER_AVATAR_PREFIX,
  reset
} from "../../../reducers/user";
import {createLoadingSelector} from "../../../selectors/loading";
import {createErrorMessageSelector} from "../../../selectors/error";

const mapStateToProps = state => {
  return {
    user: state.users.user,
    loading: createLoadingSelector([GET_USER_BY_ID_PREFIX])(state),
    deletingUser: createLoadingSelector([DELETE_USER_PREFIX])(state),
    creatingUser: createLoadingSelector([CREATE_USER_PREFIX])(state),
    updatingUser: createLoadingSelector([UPDATE_USER_PREFIX])(state),
    changingUserAvatar: createLoadingSelector([CHANGE_USER_AVATAR_PREFIX])(state),
    removingUserAvatar: createLoadingSelector([REMOVE_USER_AVATAR_PREFIX])(state),
    errorOnCreatingUser: createErrorMessageSelector([CREATE_USER_PREFIX])(state),
    errorOnUpdatingUser: createErrorMessageSelector([UPDATE_USER_PREFIX])(state),
    errorOnDeletingUser: createErrorMessageSelector([DELETE_USER_PREFIX])(state),
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  createUser,
  updateUser,
  getUserById,
  deleteUser,
  changeUserAvatar,
  removeUserAvatar,
  reset
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserComponent);
