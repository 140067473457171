import { connect } from 'react-redux';
import SavedFilters from '../../components/GroupModal/PeopleTab/FiltersBar/SavedFilters';
import {bindActionCreators} from "redux";
import {
  getFiltersList,
  createFilter,
  deleteFilter
} from "../../reducers/filter";

const mapStateToProps = state => {
  return {
    savedFilters: state.filter.list,
    contacts: (state.contacts.searchedList || state.contacts.list || []).map(contact => contact.id),
    chatRoomsBySameParticipants: state.chatRooms.chatRoomsByParticipants,
    selectedChatRoomTWId: state.chatRooms.selectedChatRoomTWId,
  };
};


const mapDispatchToProps = dispatch => bindActionCreators({
  getFiltersList,
  createFilter,
  deleteFilter
}, dispatch);


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SavedFilters);
