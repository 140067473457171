import {constantMessages, userRoles} from "../config";
import ToastrService from "../services/toastrService";
import DepartmentsApiService from "../services/api/departments";

export const FETCH_DEPARTMENTS_LIST_PREFIX = 'departments/FETCH_DEPARTMENTS_LIST_PREFIX';
export const FETCH_DEPARTMENTS_LIST_REQUEST_ACTION = FETCH_DEPARTMENTS_LIST_PREFIX + '_REQUEST_ACTION';
export const FETCH_DEPARTMENTS_LIST_SUCCESS_ACTION = FETCH_DEPARTMENTS_LIST_PREFIX + '_SUCCESS_ACTION';
export const FETCH_DEPARTMENTS_LIST_FAILURE_ACTION = FETCH_DEPARTMENTS_LIST_PREFIX + '_FAILURE_ACTION';

export const FETCH_ALL_DEPARTMENTS_LIST_PREFIX = 'departments/FETCH_ALL_DEPARTMENTS_LIST_PREFIX';
export const FETCH_ALL_DEPARTMENTS_LIST_REQUEST_ACTION = FETCH_ALL_DEPARTMENTS_LIST_PREFIX + '_REQUEST_ACTION';
export const FETCH_ALL_DEPARTMENTS_LIST_SUCCESS_ACTION = FETCH_ALL_DEPARTMENTS_LIST_PREFIX + '_SUCCESS_ACTION';
export const FETCH_ALL_DEPARTMENTS_LIST_FAILURE_ACTION = FETCH_ALL_DEPARTMENTS_LIST_PREFIX + '_FAILURE_ACTION';

export const GET_DEPARTMENT_BY_ID_PREFIX = 'departments/GET_DEPARTMENT_BY_ID_PREFIX';
export const GET_DEPARTMENT_BY_ID_REQUEST_ACTION = GET_DEPARTMENT_BY_ID_PREFIX + '_REQUEST_ACTION';
export const GET_DEPARTMENT_BY_ID_SUCCESS_ACTION = GET_DEPARTMENT_BY_ID_PREFIX + '_SUCCESS_ACTION';
export const GET_DEPARTMENT_BY_ID_FAILURE_ACTION = GET_DEPARTMENT_BY_ID_PREFIX + '_FAILURE_ACTION';

export const CREATE_DEPARTMENT_PREFIX = 'departments/CREATE_DEPARTMENT_PREFIX'
export const CREATE_DEPARTMENT_REQUEST_ACTION = CREATE_DEPARTMENT_PREFIX + '_REQUEST_ACTION';
export const CREATE_DEPARTMENT_SUCCESS_ACTION = CREATE_DEPARTMENT_PREFIX + '_SUCCESS_ACTION';
export const CREATE_DEPARTMENT_FAILURE_ACTION = CREATE_DEPARTMENT_PREFIX + '_FAILURE_ACTION';

export const UPDATE_DEPARTMENT_PREFIX = 'departments/UPDATE_DEPARTMENT_PREFIX'
export const UPDATE_DEPARTMENT_REQUEST_ACTION = UPDATE_DEPARTMENT_PREFIX + '_REQUEST_ACTION';
export const UPDATE_DEPARTMENT_SUCCESS_ACTION = UPDATE_DEPARTMENT_PREFIX + '_SUCCESS_ACTION';
export const UPDATE_DEPARTMENT_FAILURE_ACTION = UPDATE_DEPARTMENT_PREFIX + '_FAILURE_ACTION';

export const DELETE_DEPARTMENT_PREFIX = 'departments/DELETE_DEPARTMENT_PREFIX'
export const DELETE_DEPARTMENT_REQUEST_ACTION = DELETE_DEPARTMENT_PREFIX + '_REQUEST_ACTION';
export const DELETE_DEPARTMENT_SUCCESS_ACTION = DELETE_DEPARTMENT_PREFIX + '_SUCCESS_ACTION';
export const DELETE_DEPARTMENT_FAILURE_ACTION = DELETE_DEPARTMENT_PREFIX + '_FAILURE_ACTION';

const initialState = {
  departmentsList: [],
  allDepartmentsList: [],
  page: null,
  pageSize: null,
  total: null,
  department: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DEPARTMENTS_LIST_SUCCESS_ACTION:
      const data = action.payload.data
      return {
        ...state,
        page: data.page,
        pageSize: data.pageSize,
        departmentsList: [...data.results],
        total: data.total
      }
    case FETCH_ALL_DEPARTMENTS_LIST_SUCCESS_ACTION:
      const allData = action.payload.data
      return {
        ...state,
        allDepartmentsList: allData.results,
      }
    case GET_DEPARTMENT_BY_ID_SUCCESS_ACTION:
      return {
        ...state,
        department: { ...action.payload.department }
      }
    case CREATE_DEPARTMENT_SUCCESS_ACTION:
      return {
        ...state,
        departmentsList: [...state.departmentsList, {...action.payload.department}]
      }
    case UPDATE_DEPARTMENT_SUCCESS_ACTION:
      const departmentIndex = state.departmentsList.findIndex(dep => dep.id === action.payload.department?.id)
      const updatedDepartmentsList = [...state.departmentsList]
      updatedDepartmentsList[departmentIndex] = action.payload.department
      return {
        ...state,
        departmentsList: [...updatedDepartmentsList],
        department: action.payload.department
      }
    case DELETE_DEPARTMENT_SUCCESS_ACTION:
      const departmentsListAfterRemove = state.departmentsList.filter(d => d.id !== action.payload.id)
      return {
        ...state,
        departmentsList: [...departmentsListAfterRemove]
      }
    default:
      return state
  }
}

export function getDepartmentsList(params = { keyword: '', page: 1, pageSize: 10 }) {
  return (dispatch, getState) => {
    const isAllList = params.all === 1;
    dispatch({ type: isAllList ? FETCH_ALL_DEPARTMENTS_LIST_REQUEST_ACTION : FETCH_DEPARTMENTS_LIST_REQUEST_ACTION });
    const departmentsService = new DepartmentsApiService();
    const userData = getState().auth?.data;
    const isAdmin = userData?.role === userRoles.ADMIN || userData?.role?.name === userRoles.ADMIN;
    return departmentsService[isAdmin ? "getDepartments" : "getDepartmentsList"](params)
      .then(({ data = {} }) => {
        const response = data || [];
        dispatch({ type: isAllList ? FETCH_ALL_DEPARTMENTS_LIST_SUCCESS_ACTION : FETCH_DEPARTMENTS_LIST_SUCCESS_ACTION, payload: { data: response } });
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: isAllList ? FETCH_ALL_DEPARTMENTS_LIST_FAILURE_ACTION : FETCH_DEPARTMENTS_LIST_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message)
      })
  }
}

export function getDepartmentById(id) {
  return dispatch => {
    dispatch({ type: GET_DEPARTMENT_BY_ID_REQUEST_ACTION});
    const departmentsService = new DepartmentsApiService();
    return departmentsService.getDepartmentById(id)
      .then(department => {
        dispatch({type: GET_DEPARTMENT_BY_ID_SUCCESS_ACTION, payload: { department } });
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: GET_DEPARTMENT_BY_ID_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message)
      })
  }
}

export function createDepartment(name, params) {
  return dispatch => {
    dispatch({ type: CREATE_DEPARTMENT_REQUEST_ACTION })
    const departmentsService = new DepartmentsApiService();
    return departmentsService.createDepartment({ name })
      .then(({data = {}}) => {
        dispatch(getDepartmentsList(params))
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: CREATE_DEPARTMENT_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message)
      })
  }
}

export function updateDepartment(data) {
  return dispatch => {
    dispatch({ type: UPDATE_DEPARTMENT_REQUEST_ACTION })
    const departmentsService = new DepartmentsApiService();
    return departmentsService.updateDepartment(data)
      .then(({data = {}}) => {
        const department = data || {}
        dispatch({type: UPDATE_DEPARTMENT_SUCCESS_ACTION, payload: { department }})
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: UPDATE_DEPARTMENT_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message)
      })
  }
}

export function deleteDepartment(id, params) {
  return dispatch => {
    dispatch({ type: DELETE_DEPARTMENT_REQUEST_ACTION })
    const departmentsService = new DepartmentsApiService();
    return departmentsService.deleteDepartment(id)
      .then(({data = {}}) => {
        dispatch(getDepartmentsList(params))
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: DELETE_DEPARTMENT_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message)
      })
  }
}
