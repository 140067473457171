import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { contactDisplayName } from '../../../helpers/chat';
import { UserAvatar } from '../../UIComponents';
import { EyeIcon } from './icons';

import './MessageViewList.scss'
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';
import 'moment/locale/fr' 

export const MessageViewList = ({ contacts, seen: seenList, acknowledge: ackList, isGroup }) => {
    const rootRef = useRef(null)
    const [isOpen, setIsOpen] = useState(false)
    const { t } = useTranslation('common');

    useEffect(() => {
        const clickOutsideEvent = (event) => {
            if (!rootRef.current?.contains(event.target)) {
                setIsOpen(false)
            }
        }

        if (isOpen) {
            window.addEventListener('mousedown', clickOutsideEvent)
            return () => {
                window.removeEventListener('mousedown', clickOutsideEvent)
            }
        }
    }, [isOpen])

    const [seen, acknowledge] = useMemo(() => {
        const seen_ = seenList?.filter(x => ackList?.every(y => x.userId !== y.userId) )
        return [seen_, ackList || []];
    }, [seenList, ackList])

    const readList = seen.map((sin) => {
        const user = contacts.find(x => x.id === sin.userId)
        return { ...sin, user }
    })

    const acknowledgeList = acknowledge.map((sin) => {
        const user = contacts.find(x => x.id === sin.userId)
        return { ...sin, user }
    })

    return <div className='MessageViewList' ref={rootRef}>

        {isOpen && <div className='content__'>
            {readList.length > 0 && <b>{t('chat.seen_by')}</b>}
            {readList.map((item, i) => {
                const dt = moment(item.timetoken)
                const date = dt.format("DD MMM YYYY")
                const time = dt.format("h:mm A")
                return <div className='item_' key={i}>
                    <div>
                        <UserAvatar user={item.user}
                            className={`mr-2`} />
                        <div>
                            <b>{contactDisplayName(item.user)}</b>
                            <small>{item.user.professions[0]?.name}</small>
                        </div>
                    </div>
                    <span title={`${date}, ${time}`}>{date} <br /> {time}</span>
                </div>
            })}

            {acknowledgeList.length > 0 && <b>{t('chat.acknowledge_by')}</b>}
            {acknowledgeList.map((item, i) => {
                const dt = moment(item.timetoken)
                const date = dt.locale(i18n.language).format("DD MMM YYYY")
                const time = dt.locale(i18n.language).format("h:mm A")
                return <div className='item_' key={i}>
                    <div>
                        <UserAvatar user={item.user}
                            className={`mr-2`} />
                        <div>
                            <b>{contactDisplayName(item.user)}</b>
                            <small>{item.user.professions[0]?.name}</small>
                        </div>
                    </div>
                    <span title={`${date}, ${time}`}>{date} <br /> {time}</span>
                </div>
            })}
        </div>}
        <button title='View history' onClick={() => setIsOpen(!isOpen)}><EyeIcon /></button>
    </div>
}