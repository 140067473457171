import { connect } from 'react-redux';
import MultiSelectField from '../../components/UIComponents/MultiSelectField';

const mapStateToProps = state => {
  const selectedTheme = state.auth.data?.settings?.theme;
  return {
    selectedTheme,
  };
};

export default connect(
  mapStateToProps,
  null
)(MultiSelectField);
