import React, { useEffect, useState } from "react";
import { Container, Button } from "reactstrap";
import "./AboutTextComponent.scss";
import RichTextEditor from "./RichTextEditor/RichTextEditor";
import { useAppSettings } from "../../../../services/app-setting.hook";
import { SettingApiService, ToastrService } from "../../../../services";
import { useTranslation } from "react-i18next";
import i18n from "../../../../i18n";
import { LanguageSelector } from "../../../UIComponents/LanguageSelector";

const AboutTextComponent = () => {
  const { settings, setSettings } = useAppSettings();
  const [state, setState] = useState({});
  const [lang, setLang] = useState(i18n.language)
  const [t] = useTranslation();

  useEffect(() => {
    setState({ informationText: settings.informationText, aboutUsText: settings.aboutUsText })
  }, [settings])


  const saveData = () => {
    if (state.aboutUsText || state.informationText) {
      new SettingApiService()
        .updateAboutTexts(state)
        .then((res) => {
          setSettings({ ...settings, ...state });
          ToastrService.success(t("admin.text_edit"))
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setState({});
        });
    }
  };

  const editorProps = (key, lang) => ({
    value: state?.[key]?.[lang],
    onChange: (value) => setState({ ...state, [key]: { ...state[key], [lang]: value }, update: true })
  })

  return (
    <Container fluid className="AboutTextComponent">
      <div className="suffixesPage">
        <div className="bodyContent">
          <LanguageSelector onChange={setLang} value={lang} />
          <br />
          <br />
          <div className="text-editor">
            <div className="bodyHeader">
              <h2>{t("admin.about_us")}</h2>
            </div>
            <div className="content">
              <div className="preview">
                {lang === 'en' ? <RichTextEditor key={1} {...editorProps('aboutUsText', 'en')} /> : <RichTextEditor key={2} {...editorProps('aboutUsText', 'fr')} />}
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />
          <div className="text-editor">
            <div className="bodyHeader">
              <h2>{t("admin.info_pg_txt")}</h2>
            </div>
            <div className="content">
              <div className="preview">
                {lang === 'en' ? <RichTextEditor key={1} {...editorProps('informationText', 'en')} /> : <RichTextEditor key={2} {...editorProps('informationText', 'fr')} />}
              </div>
              <br />
              <div className="btn-content">
                <Button
                  className="submitButton"
                  disabled={!state.update}
                  onClick={saveData}
                >
                  Update text
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default AboutTextComponent;
