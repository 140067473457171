import React, { Component } from "react";
import {
  Button,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import moment from "moment";
import _ from "lodash";
import HeaderComponent from "../../containers/Chat/HeaderContainer";
import MessagesComponent from "../../containers/Chat/MessagesContainer";
import FooterComponent from "../../containers/Chat/FooterContainer";
import GroupMembersList from "./GroupMembersList";
import "./index.scss";
import { Loader } from "../UIComponents";
import { ToastrService } from "../../services";
import { appTheme } from "../../config";
import ImportanceModal from "./ImportanceModal/ImportanceModal";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";
import 'moment/locale/fr' 

class ChatComponent extends Component {
  state = {
    loadingMore: false,
    importanceModal: { isOpen: false },
  };

  componentDidMount() {
    this.props.changeSelectedChatRoomTWId(this.props.chatRoom.twId);
    this.props.markChatRoomMessagesAsRead(this.props.chatRoom.twId);
    this.getMessages();
  }

  componentDidUpdate(prevProps) {
    const { chatRoom, loading } = this.props,
      { chatRoom: prevChatRoom, loading: prevLoading } = prevProps;
    if (!loading && prevLoading && this.state.loadingMore) {
      this.setState({ loadingMore: false });
    }
    if (
      (!!chatRoom && chatRoom.twId) !== (!!prevChatRoom && prevChatRoom.twId)
    ) {
      // this.props.getChatRoomById(this.props.chatRoom.twId, this.props.chatRoom.twId)
      this.props.changeSelectedChatRoomTWId(chatRoom.twId);
      this.props.markChatRoomMessagesAsRead(chatRoom.twId);
      this.getMessages();
    }
  }

  componentWillUnmount() {
    this.props.changeSelectedChatRoomTWId();
    this.props.markChatRoomMessagesAsRead(this.props.chatRoom.twId);
  }

  getMessages() {
    const { chatRoom = {}, messages = {} } = this.props;
    this.props.getMessages({
      twId: chatRoom.twId,
      isForce: true,
      msgId: this.props.linkedMessageLog?.msgId
    });
  }

  loadMoreMessages = () => {
    const { chatRoom = {}, messages = {} } = this.props;

    const messagesData = messages.find(
      (item) => item.conversationId === chatRoom.twId
    );
    const messagesList = messagesData ? messagesData.messages : [];

    this.setState(
      {
        loadingMore: true,
      },
      () => {
        this.props.getMessages({
          twId: chatRoom.twId,
          isForce: false,
          before: messagesList[0]?.timetoken,
        });
      }
    );
  };

  prepareMessagesListForRender() {
    const messagesList = this.messagesList();

    if (!messagesList || !messagesList.length) {
      return [];
    }

    messagesList.sort((a, b) => a.timetoken - b.timetoken);

    const groupedMessages = _.groupBy(messagesList, function (message) {
      const startOfDayDate = moment(message.timetoken).startOf("day");
      return !moment().diff(startOfDayDate, "days")
        ? i18n.t("Today")
        : startOfDayDate.locale(i18n.language).format("MMMM DD, YYYY");
    });

    return groupedMessages;
  }

  handleDropped = (e) => {
    const participant = e.dataTransfer.getData("selected_contact");
    let { chatRoom = null, t } = this.props;
    if (chatRoom) {
      if (chatRoom.type === "direct") {
        const newChatRoom = _.cloneDeep(chatRoom);
        const isParticipant = newChatRoom.participants.find(
          (x) => x.id === participant
        );
        if (!isParticipant) {
          newChatRoom.participants.push({ id: participant });
        }
        newChatRoom.type = "group";
        this.props.changeGroupMode("NEW", newChatRoom);
        this.props.toggleGroupModal();
      } else {
        if (this.props.user.id === chatRoom.owner) {
          const isParticipant = chatRoom.participants.find(
            (x) => x.id === participant
          );
          const newChatRoom = _.cloneDeep(chatRoom);
          if (isParticipant) {
            ToastrService.warning(t('chat.user_is_in_group'));
          } else {
            newChatRoom.participants.push({ id: participant });
          }
          this.props.changeGroupMode("EDIT", newChatRoom);
          this.props.toggleGroupModal();
        }
      }
    }
  };

  messagesList() {
    const { messages, chatRoom, t } = this.props;
    if (!messages || !messages.length || !chatRoom) {
      return [];
    }
    const messageData = messages.find(
      (item) => item.conversationId === chatRoom.twId
    );
    return messageData ? messageData.messages : [];
  }

  chatDisableStatus() {
    const { chatRoom, contacts, user } = this.props;

    if (chatRoom?.participants?.length === 2) {
      const chatMember = chatRoom.participants.find(
        (participant) =>
          participant.id !== user.id &&
          contacts.find((contact) => contact.id === participant.id)
      );
      return chatMember?.deleted;
    }
    return false;
  }

  handleGroupDelete = () => {
    this.props.deleteChatRoom(this.props.chatRoom.twId);
  };

  sendImportantMessage = (val) => {
    this.props.markMessageImportant({
      ...val,
      id: this.state.selectedMessage.id,
      from: this.props.user.id,
      conversationId: this.props.chatRoom.twId,
    });
    this.setState({
      importanceModal: { isOpen: false },
      selectedMessageId: undefined,
    });
  };

  handleImportantMessage = (message) => {
    this.props.fetchGroupMembers();
    this.setState({
      importanceModal: { isOpen: true },
      selectedMessage: message,
    });
  };

  render() {
    const {
      chatRoom,
      participants,
      contacts,
      selectedContact = {},
      loading,
      creatingConversation,
      onExitChat,
      className,
      showMembers,
      showGroupMembersToggle,
      user,
      isDeleteModalOpen,
      toggleDeleteModal,
      areCallsRejected,
      selectedTheme,
      t,
    } = this.props;
    const { loadingMore, importanceModal } = this.state;

    if ((loading || creatingConversation) && !loadingMore) {
      return (
        <Container fluid className="chatSectionContainer pageContainer mx-0">
          <Loader color={selectedTheme === appTheme.DARK ? "#6d737b" : ""} />
        </Container>
      );
    }

    const isChatDisabled = this.chatDisableStatus();

    return (
      <Container
        onDrop={this.handleDropped}
        onDragOver={(e) => e.preventDefault()}
        fluid
        className={`chatSectionContainer pageContainer mobile-pres mx-0 ${className}`}
        onClick={
          chatRoom?.unreadCount > 0
            ? () => {
                if (chatRoom?.unreadCount > 0) {
                  this.props.markChatRoomMessagesAsRead(chatRoom.twId);
                }
              }
            : _.debounce(
                () => this.props.markChatRoomMessagesAsRead(chatRoom.twId),
                4000
              )
        }
      >
        <HeaderComponent
          chatRoom={chatRoom}
          onExitChat={onExitChat}
          selectedItemId={selectedContact.id}
          isChatDisabled={isChatDisabled}
          showGroupMembersToggle={showGroupMembersToggle}
          areCallsRejected={areCallsRejected}
        />
        <MessagesComponent
          loadMore={this.loadMoreMessages}
          loadingMore={loadingMore}
          selectedContact={selectedContact}
          chatRoom={chatRoom}
          messages={this.prepareMessagesListForRender()}
          amountOfMessages={this.messagesList()?.length}
          makeMessageImportant={this.handleImportantMessage}
          markChatRoomMessagesAsRead={this.props.markChatRoomMessagesAsRead}
          linkedMessageLog={this.props.linkedMessageLog}
        />
        <FooterComponent
          chatRoom={chatRoom}
          isChatDisabled={isChatDisabled}
          participants={participants}
          user={user}
        />
        {showMembers && chatRoom.type === "group" && (
          <GroupMembersList
            contacts={contacts}
            isOpen={showMembers}
            toggle={showGroupMembersToggle}
            participants={participants}
            user={user}
          />
        )}
        <div className="popup-wrapper">
          <Modal
            isOpen={isDeleteModalOpen}
            toggle={toggleDeleteModal}
            className="groupDeleteModal"
            size="sm"
          >
            <ModalHeader toggle={toggleDeleteModal}>{t('chat.del_group')}</ModalHeader>
            <ModalBody className="d-flex flex-wrap">
              {t('chat.del_group_prompt')}
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleDeleteModal}>
                {t('Cancel')}
              </Button>
              <Button color="primary" onClick={this.handleGroupDelete}>
                {t('Delete')}
              </Button>
            </ModalFooter>
          </Modal>
        </div>
        {importanceModal.isOpen && (
          <ImportanceModal
            toggle={() => {
              this.setState({ importanceModal: { isOpen: false } });
            }}
            isOpen={importanceModal.isOpen}
            user={user}
            participants={participants}
            contacts={contacts}
            isGroup={chatRoom.type === "group"}
            submitMessage={this.sendImportantMessage}
          />
        )}
      </Container>
    );
  }
}

export default withTranslation('common')(ChatComponent)