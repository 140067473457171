import React, {Component} from 'react';
import {Col, Button, ModalHeader, ModalBody, ModalFooter, Modal} from "reactstrap";
import "./index.scss";

import {InputField, Loader, SelectField} from '../UIComponents';
import {extractCountryCodeAndName} from "../../helpers/function";
import { withTranslation } from 'react-i18next';

class UpdatePhoneNumberComponent extends Component {
  state = {
    phoneNumberCountryCode: '(+1) - United States',
    errors: {},
    phone: '',
  };

  onFieldValueChange = (name, value) => {
    this.setState({[name]: value})
  };

  componentDidMount() {
    this.props.getCallingCodesList();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.changingPhoneNumber && !this.props.changingPhoneNumber && !this.props.changingPhoneNumberError){
      this.props.toggle()
    }
  }

  handleChangeClick = () => {
    const { phone, phoneNumberCountryCode } = this.state;
    const { t } = this.props;
    const errors = {};
    const phoneRe = /^\d+$/;
    let isValid = true;

    if (!phone) {
      errors.phone = t('Required');
      isValid = false;
    } else if (!phoneRe.test(phone)) {
      errors.phone = t('auth.err_phone_format');
      isValid = false;
    }

    this.setState({errors});
    if (isValid) {
      const { phoneCountryCode, phoneCountryName } = extractCountryCodeAndName(phoneNumberCountryCode);
      this.props.changePhoneNumber({
        phone,
        phoneCountryCode,
        phoneCountryName
      });
    }

  };

  render() {
    const {phoneNumberCountryCode, phone, errors} = this.state;
    const {toggle, loading, callingCodes, t} = this.props;

    return (
      <Modal isOpen={true} toggle={toggle} className={"notAvailableModal"} size="sm">
        <ModalHeader toggle={toggle}
                     close={<img onClick={toggle}
                                 className="clickable"
                                 src={"/assets/images/btn-close.svg"}
                                 alt="close"/>}
        >
          {t('change_phone_num')}
        </ModalHeader>
        <ModalBody className="d-flex justify-content-center align-items-center px-4 pb-0 ">
          {loading ?
            <Loader/> :
            <Col xs={12} className="px-0 d-flex phoneNumberContainer accountSettingFieldContainer">
              <SelectField
                options={callingCodes.map(callingCode => {
                  const uniqueName = `(${callingCode.code}) - ${callingCode.country}`;
                  return {
                    value: uniqueName,
                    label: `(${callingCode.code})`,
                    optionLabel: uniqueName
                  }
                })}
                defaultValue={phoneNumberCountryCode}
                selectedValue={phoneNumberCountryCode}
                onChange={value => this.setState({phoneNumberCountryCode: value})}
              />
              <InputField
                id="phoneNumber"
                type="tel"
                placeholder={t('auth.phone_num')}
                value={phone}
                error={errors.phone}
                onChange={(event) => this.onFieldValueChange('phone', event.target.value)}
                onKeyPress={(event) => (event.charCode === 13) && this.handleChangeClick()}
              />
            </Col>
          }

        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <Button color="secondary" onClick={toggle}>{t('CANCEL')}</Button>
          <Button color="primary" onClick={this.handleChangeClick}>{t('CHANGE')}</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default withTranslation('common')(UpdatePhoneNumberComponent);
