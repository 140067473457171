import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { changePersonalInformationAction } from "../../reducers/auth";
import LanguageSelectorComponent from '../../components/Settings/AccountSettings/LanguageSelectorComponent';

const mapStateToProps = state => {
  return {
    user: state.auth.data,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  changeLanguageAction: changePersonalInformationAction,
}, dispatch);


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LanguageSelectorComponent);
