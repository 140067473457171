import React, { useEffect, useState } from 'react'
import { ImageS3 } from '../helpers/ImageS3'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import './MediaGalleryModal.scss'

const MediaGalleryModal = ({
	media,
	close,
	isOpen,
	currentIndex,
	setCurrentIndex
}) => {
	const handleClickPrevious = () => {
		setCurrentIndex(
			currentIndex => (currentIndex - 1 + media.length) % media.length
		)
	}

	const handleClickNext = () => {
		setCurrentIndex(currentIndex => (currentIndex + 1) % media.length)
	}


	useEffect(() => {
		const handleKeyDown = e => {
			if (e.key === 'ArrowRight') handleClickPrevious()
			else if (e.key === 'ArrowLeft') handleClickNext()
		}

		window.addEventListener('keydown', handleKeyDown)

		return () => {
			window.removeEventListener('keydown', handleKeyDown)
		}
	}, [])

	const file = media[currentIndex];

	return (
		<Modal
			isOpen={isOpen}
			toggle={close}
			className='MediaGalleryModal'
		>
			<ModalHeader toggle={close}>{file.name}</ModalHeader>

			<ModalBody>
				{media.length > 1 && (
					<div
						className="previous-button"
						role="button"
						tabIndex={0}
						onClick={handleClickPrevious}
					>
						<ChevronIcon />
					</div>
				)}

				
					
				{media?.map((media, i) => file.key === media.key && <ImageS3 key={i} imgName={file.name} imgKey={file.key} />)}

				{media.length > 1 && (
					<div
						className="next-button"
						role="button"
						tabIndex={0}
						onClick={handleClickNext}
					>
						<ChevronIcon />
					</div>
				)}
			</ModalBody>
		</Modal>

	)
}

export default MediaGalleryModal

const ChevronIcon = () => (
	<svg
		viewBox="0 0 512 512"
		height={50}
		width={50}
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="m256 512c-68.378906 0-132.667969-26.628906-181.019531-74.980469-48.351563-48.351562-74.980469-112.640625-74.980469-181.019531s26.628906-132.667969 74.980469-181.019531c48.351562-48.351563 112.640625-74.980469 181.019531-74.980469s132.667969 26.628906 181.019531 74.980469c48.351563 48.351562 74.980469 112.640625 74.980469 181.019531s-26.628906 132.667969-74.980469 181.019531c-48.351562 48.351563-112.640625 74.980469-181.019531 74.980469zm0-472c-119.101562 0-216 96.898438-216 216s96.898438 216 216 216 216-96.898438 216-216-96.898438-216-216-216zm104.285156 216-138.285156-138.285156-28.285156 28.285156 110 110-110 110 28.285156 28.285156zm0 0"
			fill={'white'}
		/>
	</svg>
)

export const useMediaGalleryModal = (index = 0) => {
	const [isOpen, setIsOpen] = useState(false)
	const [currentIndex, setCurrentIndex] = useState(index)

	const open = (currentIndex = null) => {
		if (currentIndex !== null) setCurrentIndex(currentIndex)
		setIsOpen(true)
	}

	const close = () => {
		setIsOpen(false)
	}
	return {
		isOpen,
		open,
		close,
		currentIndex,
		setCurrentIndex
	}
}
