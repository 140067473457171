import { connect } from 'react-redux';
import ResetPasswordComponent from '../../components/Auth/ResetPasswordComponent';
import { bindActionCreators } from "redux";
import {RESET_PASSWORD_PREFIX, resetPasswordAction, setPasswordAction, SET_PASSWORD_PREFIX} from "../../reducers/auth";
import {createLoadingSelector} from "../../selectors/loading";
import {createErrorMessageSelector} from "../../selectors/error";

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    loading: createLoadingSelector([RESET_PASSWORD_PREFIX, SET_PASSWORD_PREFIX])(state),
    error: createErrorMessageSelector([RESET_PASSWORD_PREFIX, SET_PASSWORD_PREFIX])(state),
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  resetPasswordAction,
  setPasswordAction,
}, dispatch);


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordComponent);
