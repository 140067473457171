import React from 'react';
import Checkbox from "rc-checkbox";

const CheckboxComponent = (props) => {
  return (
   <label className={"escCheckboxContainer " + props.className || ''}>
     <Checkbox
       className="escCheckbox"
       defaultChecked={props.defaultChecked}
       onChange={(e) => props.onChange(e.target.checked)}
       disabled={props.disabled}
     />
     <p>{props.label || ""}</p>
   </label>
  )
};

export default CheckboxComponent;
