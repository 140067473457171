import React, { Component } from 'react';
import {
  Container,
  Col,
  Button,
} from 'reactstrap';
import _ from 'lodash';
import '../index.scss';
import { InputField, SelectField } from "../../UIComponents";
import { extractCountryCodeAndName, prepareCountryCodeAndName } from "../../../helpers/function";
import { withTranslation } from 'react-i18next';

class PhoneAndEmailComponent extends Component {
  state = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    sessionTimeout: '',
    verifyEmailToken: '',
    verifyPhoneToken: '',
    errors: {},
    isFacilitiesListOpen: false,
    phoneNumberCountryCode: '(+1) - United States',
  };

  componentDidMount() {
    this.props.getCallingCodesList();
    const { user: { email, phone, phoneCountryCode, phoneCountryName, unverifiedPhone, unverifiedEmail } } = this.props;
    this.setState({
      email: unverifiedEmail || email,
      phone: unverifiedPhone || phone,
      phoneNumberCountryCode: prepareCountryCodeAndName(phoneCountryCode, phoneCountryName),
    });
  }

  componentDidUpdate(prevProps) {
    const {
      user: { sessionTimeout },
      verifyingEmail,
      verifyingPhone,
      verifyingEmailError,
      verifyingPhoneError
    } = this.props;

    const {
      verifyingEmail: prevVerifyingEmail,
      verifyingPhone: prevVerifyingPhone,
      user: { sessionTimeout: prevSessionTimeout }
    } = prevProps;

    if (!prevSessionTimeout && sessionTimeout) {
      this.setState({ sessionTimeout })
    }

    if (!verifyingEmail && prevVerifyingEmail && !verifyingEmailError) {
      this.setState({ verifyEmailToken: '' })
    }

    if (!verifyingPhone && prevVerifyingPhone && !verifyingPhoneError) {
      this.setState({ verifyPhoneToken: '' })
    }

  }

  onFieldValueChange = (name, value) => {
    this.setState({ [name]: value, formUpdated: true })
  };

  onTimeoutFielValueChange = e => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({ 'sessionTimeout': e.target.value })
    } else {
      e.target.value = this.state.sessionTimeout;
    }
  }
  handleCancelClick = () => {
    this.props.cancelPhoneAndEmailUpdate();

    const { user: { email, phone, phoneCountryCode, phoneCountryName } } = this.props;
    
    this.setState({
      email: email,
      phone: phone,
      phoneNumberCountryCode: prepareCountryCodeAndName(phoneCountryCode, phoneCountryName),
    });
  }

  handleUpdateClick = () => {
    const { isSessionTimeout, t } = this.props
    const { phone, email, phoneNumberCountryCode } = this.state,
      errors = {};
    let isValid = true;
    const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const phoneRe = /^\+\d+$/;

    const { phoneCountryCode, phoneCountryName } = extractCountryCodeAndName(phoneNumberCountryCode);

    let sessionTimeout = this.state.sessionTimeout || ""

    if (!email) {
      errors.email = t('Required');
      isValid = false;
    } else if (!re.test(String(email).toLowerCase())) {
      errors.email = 'Invalid email';
      isValid = false;
    }

    if (!phone) {
      errors.phone = t('Required');
      isValid = false;
    } else if (!phoneRe.test(`${phoneCountryCode}${phone}`)) {
      errors.phone = t('auth.err_phone_format');
      isValid = false;
    }

    if (sessionTimeout !== "" && +sessionTimeout <= 0) {
      errors.sessionTimeout = t('auth.min_value_is_1');
      isValid = false;
    }

    let data = {
      email,
      phone,
    }

    if (isSessionTimeout) {
      data['sessionTimeout'] = sessionTimeout
    }

    this.setState({ errors });
    isValid && this.props.updatePhoneAndEmail({ ...data, phone, phoneCountryCode, phoneCountryName });
  };

  handleVerifyEmail = () => {
    const { verifyEmailToken } = this.state;
    this.props.verifyEmailAction({ verifyToken: verifyEmailToken })
  };

  handleVerifyPhone = () => {
    const { verifyPhoneToken } = this.state;
    this.props.verifyMobileAction({ verifyToken: verifyPhoneToken })
  };

  toggle = key => {
    this.setState({ [key]: !this.state[key] })
  }

  render() {
    const { user: { unverifiedPhone, unverifiedEmail, verifyingEmail, verifyingPhone }, isNewUser, isSessionTimeout = false, callingCodes, t } = this.props;
    const { errors, email, phone, sessionTimeout, verifyEmailToken, verifyPhoneToken, phoneNumberCountryCode, formUpdated } = this.state;
    const userManageClassName = isNewUser !== undefined && isNewUser === true ? 'contentContainer d-flex align-items-start flex-wrap mt-0' : 'contentContainer d-flex align-items-start flex-wrap'
    console.log('this.props.user', this.props.user)
    return (
      <>
        <Container fluid className="personalInformationContainer userProfile">
          <div className={userManageClassName}>
            <Col lg={4} sm={12} className="px-0">
              <h1 className="personal-info">Phone and Email</h1>
            </Col>
            <Col className="px-0">
              <Col className="d-flex flex-column align-items-start px-0 form-container">
                <Col xs={12}
                  className="px-0 ieCol d-flex align-items-center accountSettingFieldContainer flex-wrap">
                  <p className={`mr-3 ${errors.phone ? "mb-5" : ""} inputLabel`}>{t('Email')}</p>
                  <InputField
                    id="email"
                    type="email"
                    placeholder={t("Email")}
                    className={"field-column px-0"}
                    value={email}
                    error={errors.email}
                    onChange={(event) => this.onFieldValueChange('email', event.target.value)}
                    onKeyPress={(event) => (event.charCode === 13) && this.handleUpdateClick()}
                  />
                </Col>
                {unverifiedEmail &&
                  <Col xs={12}
                    className={`px-0 ieCol d-flex align-items-center flex-wrap accountSettingFieldContainer`}>
                    <p className={'mr-3 inputLabel fake'} />
                    <div className="form-group field-column">
                      <Col className={"d-flex px-0 align-items-center justify-content-between"}>
                        <InputField
                          id="verifyEmailToken"
                          type="text"
                          placeholder={t('auth.Verify_code')}
                          className={"field-column px-0 mr-2 mb-0"}
                          value={verifyEmailToken}
                          onChange={(event) => this.onFieldValueChange('verifyEmailToken', event.target.value)}
                          onKeyPress={(event) => (event.charCode === 13) && this.handleUpdateClick()}
                        />
                        <Button onClick={this.handleVerifyEmail} disabled={!verifyEmailToken || verifyingEmail}
                          className={`submitButton ml-1`}>{t('Verify')}</Button>
                      </Col>
                      <p className={`mr-3 verifyMessage ${errors.phone ? "mb-5" : ""}`}>{t('auth.enter_code_email_')}</p>
                    </div>
                  </Col>
                }
                <Col xs={12}
                  className="px-0 ieCol d-flex align-items-center accountSettingFieldContainer phoneContainer flex-wrap">
                  <p className={`mr-3 ${errors.phone ? "mb-5" : ""} inputLabel`}>{t('auth.phone_num')}</p>
                  <div className="controls-wrap field-column">
                    <SelectField
                      options={callingCodes.map(callingCode => {
                        const uniqueName = `(${callingCode.code}) - ${callingCode.country}`;
                        return {
                          value: uniqueName,
                          label: `(${callingCode.code})`,
                          optionLabel: uniqueName
                        }
                      })}
                      className='multiSelectDropdown'
                      defaultValue={phoneNumberCountryCode}
                      onChange={value => this.setState({ phoneNumberCountryCode: value })}
                    />
                    <InputField
                      id="phone"
                      type="tel"
                      placeholder={t('auth.phone_number')}
                      className={"px-0 w-100"}
                      value={phone}
                      error={errors.phone}
                      onChange={(event) => this.onFieldValueChange('phone', event.target.value)}
                      onKeyPress={(event) => (event.charCode === 13) && this.handleUpdateClick()}
                    />
                  </div>
                </Col>
                {unverifiedPhone &&
                  <Col xs={12}
                    className={`px-0 ieCol d-flex align-items-center flex-wrap accountSettingFieldContainer`}>
                    <p className={'mr-3 inputLabel fake'} />
                    <div className="field-column">
                      <Col className={"d-flex px-0 align-items-center justify-content-between"}>
                        <InputField
                          id="verifyPhoneToken"
                          type="text"
                          placeholder={t('auth.Verify_code')}
                          className={"field-column px-0 mr-2 mb-0"}
                          value={verifyPhoneToken}
                          onChange={(event) => this.onFieldValueChange('verifyPhoneToken', event.target.value)}
                          onKeyPress={(event) => (event.charCode === 13) && this.handleUpdateClick()}
                        />
                        <Button onClick={this.handleVerifyPhone} disabled={!verifyPhoneToken || verifyingPhone}
                          className={`submitButton ml-1`}>{t('Verify')}</Button>
                      </Col>
                      <p className={`mr-3 verifyMessage ${errors.phone ? "mb-5" : ""}`}>
                        {t('auth.enter_code_sent_')}
                      </p>
                    </div>
                  </Col>
                }
                {isSessionTimeout && (
                  <Col xs={12}
                    className="px-0 ieCol d-flex align-items-center accountSettingFieldContainer flex-wrap">
                    <p className={`mr-3 ${errors.sessionTimeout ? "mb-5" : ""} inputLabel`}>{t('admin.session_timeout')}</p>
                    <InputField
                      id="sessionTimeout"
                      type="text"
                      placeholder={t('auth.enter_session_timeout')}
                      helpText="Set to default timeout if field is empty."
                      className={"field-column px-0"}
                      value={sessionTimeout}
                      error={errors.sessionTimeout}
                      onChange={this.onTimeoutFielValueChange}
                      onKeyPress={(event) => (event.charCode === 13) && this.handleUpdateClick()}
                    />
                  </Col>
                )}

                <Col xs={12}
                  className="px-0 ieCol d-flex align-items-center accountSettingFieldContainer flex-wrap">
                  <p className="mr-3 inputLabel fake">&nbsp;</p>
                  <div className="field-column">
                    {(unverifiedPhone || unverifiedEmail) && <Button className="px-0 mb-2 bg-red updateUserButton"
                      onClick={this.handleCancelClick}>{t('CANCEL')}</Button>}

                    <Button className="px-0 submitButton updateUserButton" disabled={!formUpdated}
                      onClick={this.handleUpdateClick}>{isNewUser ? t('CREATE') : t('UPDATE')}</Button>
                  </div>
                </Col>
              </Col>
            </Col>
          </div>
        </Container>
      </>
    );
  }
}

export default withTranslation()(PhoneAndEmailComponent)