import React, {Component} from 'react';
import {ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem} from "reactstrap";
import {ToastrService} from '../../services';
import i18n from '../../i18n';

export default class SettingsIconDropdown extends Component {
    fileInputRef = null;

    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false
        };
    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    selectFile() {
        this.fileInputRef.click();
    }

    handleFileChange(e) {
        const file = e.target.files[0];
        if (!file) {
            return
        }
        if (file.size > 2000000) {
            ToastrService.error('The maximum image size allowed for uploads is 2MB')
        } else {
            this.props.onUpdate(e.target.files[0])
        }
    }

    render() {
        const {dropdownOpen} = this.state;
        const {onDelete, hasAvatar} = this.props;
        return (
            <>
                <input
                    type="file"
                    ref={inputRef => this.fileInputRef = inputRef}
                    onChange={this.handleFileChange.bind(this)}
                    accept="image/png,image/x-png,image/gif,image/jpeg"
                />
                <ButtonDropdown isOpen={dropdownOpen} toggle={this.toggle.bind(this)}>
                    <DropdownToggle caret>
                        <div className="settingIconContainer">
                            <img src="/assets/images/admin.svg" width="16" alt="setting"/>
                        </div>
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem onClick={this.selectFile.bind(this)}>{i18n.t("Upload")}</DropdownItem>
                        {hasAvatar && <DropdownItem onClick={onDelete} className="remove-button">{i18n.t("Remove")}</DropdownItem>}
                    </DropdownMenu>
                </ButtonDropdown>
            </>
        )
    }
}
