import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';
import './index.scss';
import { withTranslation } from 'react-i18next';

class ErrorPage extends Component {

  render() {
    return (
      <div className={`d-flex flex-column justify-content-center align-items-center ${Object.keys(this.props.user).length > 0 ? 'errorPageContainer' : 'authErrorPageContainer'}`}>
        <p className="errorNumber">404</p>
        <p className="errorMessage">{this.props.t('chat.404')}</p>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.auth.data
});

export default withTranslation('common')(
  connect(mapStateToProps, null)(ErrorPage)
)