import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import LogosComponent from '../../../components/Admin/Components/Logos/LogosComponent';


const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => bindActionCreators({

}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LogosComponent);
