import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { createSpecialty, deleteSpecialty, getSpecialties } from "../../../reducers/specialties";
import SpecialtiesComponent from "../../../components/Admin/Components/Specialties/SpecialtiesComponent";


const mapStateToProps = state => {
  const pageCount = Math.ceil( (state.specialties.total || 0) / (state.specialties.pageSize || 1));
  const selectedTheme = state.auth.data?.settings?.theme;
  return {
    selectedTheme,
    pageCount,
    specialtiesList: state.specialties.specialtiesList,
    members: state.contacts.pageMembers
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getSpecialties,
  createSpecialty,
  deleteSpecialty
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpecialtiesComponent);
