import { connect } from 'react-redux';
import {bindActionCreators} from "redux";
import SettingsFilters from '../../components/GroupModal/PeopleTab/FiltersBar/SettingsFilters';
import {
  FETCH_ALL_FACILITIES_LIST_PREFIX,
  getFacilitiesList,
} from "../../reducers/facilities";
import {
  FETCH_ALL_DEPARTMENTS_LIST_PREFIX,
  getDepartmentsList,
} from "../../reducers/departments";
import {
  FETCH_ALL_PROFESSIONS_LIST_PREFIX,
  getProfessions,
} from "../../reducers/professions";
import {
  FETCH_ALL_SPECIALTIES_LIST_PREFIX,
  getSpecialties,
} from "../../reducers/specialties";
import {createLoadingSelector} from "../../selectors/loading";

const mapStateToProps = state => {

  return {
    allContacts: state.contacts.list || [],
    facilitiesList: state.facilities.allFacilitiesList || [],
    departmentsList: state.departments.allDepartmentsList || [],
    professionsList: state.professions.allProfessionsList || [],
    specialtiesList: state.specialties.allSpecialtiesList || [],
    loadingFacilities: createLoadingSelector([FETCH_ALL_FACILITIES_LIST_PREFIX])(state),
    loadingDepartments: createLoadingSelector([FETCH_ALL_DEPARTMENTS_LIST_PREFIX])(state),
    loadingProfessions: createLoadingSelector([FETCH_ALL_PROFESSIONS_LIST_PREFIX])(state),
    loadingSpecialities: createLoadingSelector([FETCH_ALL_SPECIALTIES_LIST_PREFIX])(state),
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getFacilitiesList,
  getDepartmentsList,
  getProfessions,
  getSpecialties,
}, dispatch);


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsFilters);
