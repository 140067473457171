import { connect } from 'react-redux';
import LoginComponent from '../../components/Auth/LoginComponent';
import { bindActionCreators } from "redux";
import {LOG_IN_PREFIX, logInAction} from "../../reducers/auth";
import {createLoadingSelector} from "../../selectors/loading";
import { getSelfRegistrationStatus, FETCH_SELF_REGISTRATION_PREFIX } from "../../reducers/setting";

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    user: state.auth.data,
    isSelfRegistrationEnabled: state.setting.isSelfRegistrationEnabled,
    loading: createLoadingSelector([LOG_IN_PREFIX, FETCH_SELF_REGISTRATION_PREFIX])(state),
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  logInAction,
  getSelfRegistrationStatus
}, dispatch);


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginComponent);
