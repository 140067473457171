
import { initReactI18next } from "react-i18next";
import common_en from "./translations/en/common.json";
import common_fr from "./translations/fr/common.json";
import i18n from "i18next";
import detector from "i18next-browser-languagedetector";

i18n
.use(detector)
.use(initReactI18next)
.init({
  interpolation: { escapeValue: false },
  fallbackLng: 'en',
  resources: {
    en: {
      common: common_en
    },
    fr: {
      common: common_fr
    },
  },
});

i18n.setDefaultNamespace('common')

export default i18n;