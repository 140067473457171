import React from 'react'
import { Loader } from "./index";
import { Col, Table } from "reactstrap";
import ReactPaginate from "react-paginate";
import { appTheme } from "../../config";
import { withTranslation } from 'react-i18next';

const defaultPageSize = 10;

class TableComponent extends React.Component {
  state = {
    page: 0
  };

  renderTableRows = () => {
    const { listData, renderTableRow, tableHeaders, paginate, pageSize, t } = this.props


    let data = listData;

    if (paginate) {
      const pageItemsSize = pageSize || defaultPageSize;
      const startIndex = this.state.page === 0 ? 0 : this.state.page * pageItemsSize;
      data = listData.slice(startIndex, startIndex + pageItemsSize)
    }

    if (!data || !data.length) {
      if (this.state.page !== 0) {
        this.setState({ page: this.state.page - 1 })
      }
      return <tr>
        <td colSpan={tableHeaders.length}>{t('admin.no_data')}</td>
      </tr>
    }

    return data.map(item => {
      return (
        <tr key={item.id}>
          {renderTableRow(item)}
        </tr>
      )
    })
  };

  handlePageClick = data => {
    let page = data.selected;

    this.setState({ page });
  };

  renderPagination() {
    const { pageSize, listData, t } = this.props;

    const pageCount = Math.ceil(listData.length / (pageSize || defaultPageSize));

    if (pageCount > 1) {
      return (
        <Col container alignItems="center" justify="flex-end">
          <ReactPaginate
            previousLabel={t('PREVIOUS_PAGE')}
            nextLabel={t('NEXT_PAGE')}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={this.handlePageClick}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            initialPage={0}
            disableInitialCallback={true}
          />
        </Col>
      )
    }

    return null

  }

  renderSortButtons(item) {
    const { sortedBy, order, selectedTheme, onSortChange } = this.props;

    if (!item?.sortable) {
      return
    }

    const isSortedByCurrentItem = sortedBy === item?.key;

    return (
      <div className="d-flex flex-column align-items-center ml-2 sortButtonsContainer">
        <img
          src={`/assets/images/arrow-down${selectedTheme === appTheme.DARK ? '-dark-mode' : ''}.svg`}
          alt='arrow'
          className={`${isSortedByCurrentItem && order === "asc" ? "selected" : ""} `}
          onClick={() => onSortChange && onSortChange(item?.key, "asc")}
        />
        <img
          src={`/assets/images/arrow-down${selectedTheme === appTheme.DARK ? '-dark-mode' : ''}.svg`}
          alt='arrow'
          className={`${isSortedByCurrentItem && order === "desc" ? "selected" : ""} `}
          onClick={() => onSortChange && onSortChange(item?.key, "desc")}
        />
      </div>
    )
  }

  render() {
    const { listData, className, tableHeaders, paginate, manualSort } = this.props
    return (
      <>
        {listData ? (
          <>
            <Table className={className} responsive striped>
              <thead>
                <tr>
                  {tableHeaders.map((h, k) =>
                    <th key={k}>
                      <div className="d-flex justify-content-center">
                        {h?.label || h || ""}
                        {manualSort && h?.sortable && this.renderSortButtons(h)}
                      </div>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {this.renderTableRows()}
              </tbody>
            </Table>
            {paginate && this.renderPagination()}
          </>
        ) : <Loader />}
      </>
    )
  }
}


export default withTranslation()(TableComponent)