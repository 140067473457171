import React, {Component} from 'react';
import {Container} from 'reactstrap';
import {withRouter} from 'react-router-dom';
import ContactsListComponent from '../ContactsListComponent';
import ChatComponent from '../../containers/Chat/ChatContainer';
import {directConversationUniqName, contactDisplayName} from '../../helpers/chat';
import {Loader} from '../UIComponents';
import NoChatSelected from '../ErrorPage/NoChatSelected';
import './contacts.scss';
import {routes} from "../../config";

class ContactsComponent extends Component {

  state = {
    selectedContact: null,
    selectedChatRoomId: null,
    isChatting: false
  };

  componentDidMount() {
    this.props.getChatRoomsList();
    this.props.getContactsList();
  }

  componentDidUpdate(prevProps) {
    if ((!this.props.creatingConversation && prevProps.creatingConversation) || (!this.props.changingMuteStatus && prevProps.changingMuteStatus)) {
      const uniqueName = directConversationUniqName(this.props.user.id, this.state.selectedContact?.id);
      const chatRoom = this.props.chatRooms.find(chatRoom => {
        return chatRoom.participants && chatRoom.participants.length === 2 && chatRoom.type === 'direct' && directConversationUniqName(chatRoom.participants[0] && chatRoom.participants[0].id, chatRoom.participants[1] && chatRoom.participants[1].id) === uniqueName
      });

      this.setState({selectedChatRoomId: chatRoom?.twId})
    }

    if (prevProps.location.state?.selectedContactId !== this.props.location.state?.selectedContactId) {
      this.handleContactClick(this.props.location.state?.selectedContactId);
    }
  }

  updateSelectedContact = (selectedContact = {}) => {
    if (!this.state.selectedChatRoomId && this.props.location.state?.selectedContactId) {
      this.handleContactClick(selectedContact.id);
    } else {
      this.props.history.push({
        pathname: routes.contacts.path,
        state: {selectedContactId: selectedContact.id}
      })
    }
  };

  handleContactClick = (selectedContact) => {
    if (!selectedContact) {
      this.setState({
        selectedContact: null,
        selectedChatRoomId: null,
      })
      return
    }
    if (typeof selectedContact === 'string') {
      selectedContact = this.props.contacts.find(contact => contact.id === selectedContact);
    }
    let chatRoom = null;
    if (selectedContact.id !== this.state.selectedContact?.id) {
      const {conversationsList, chatRooms} = this.props;
      const uniqueName = directConversationUniqName(this.props.user.id, selectedContact.id);
      chatRoom = chatRooms.find(chatRoom => chatRoom.participants && chatRoom.type === 'direct' && directConversationUniqName(chatRoom.participants[0] && chatRoom.participants[0].id, chatRoom.participants[1] && chatRoom.participants[1].id) === uniqueName);
      if (!chatRoom) {
        this.props.createChatConversation(uniqueName, contactDisplayName(selectedContact), [selectedContact.id]);
      } else {
        const existingConversation = conversationsList.find(conversation => conversation.uniqueName === uniqueName);
        if (!existingConversation) {
          this.props.joinTwilioConversationById(chatRoom.twId);
        }
      }
      this.setState({selectedContact, selectedChatRoomId: chatRoom?.twId, isChatting: true});
      this.props.changeChattingStatus(true);
    }
  };

  onExitChat = () => {
    this.setState({isChatting: false});
    this.props.changeChattingStatus(false);
    this.setState({selectedChatRoomId: null, selectedContact: null, isChatting: false});
    this.updateSelectedContact({})
  }

  componentWillUnmount() {
    this.props.changeChattingStatus(false);
    this.setState({selectedChatRoomId: null, selectedContact: null, isChatting: false});
  }

  renderChatSection(selectedChatRoom) {
    const {creatingConversation} = this.props;
    const {selectedContact, selectedChatRoomId} = this.state;
    if (selectedContact && selectedChatRoomId) {
      return (
        <ChatComponent
          selectedContact={selectedContact || {}}
          chatRoom={selectedChatRoom}
          creatingConversation={creatingConversation}
          onExitChat={this.onExitChat}
          className={this.state.isChatting ? '' : 'hide-mobile'} />
      )
    }
    return null
  }

  render() {
    const {loading, twilioClient} = this.props;

    if (loading || !twilioClient) {
      return (
        <Container fluid className="pageContainer">
          <div className="contentContainer d-flex px-0 h-100">
            <Loader/>
          </div>
        </Container>
      )
    }

    const {loadingContacts, chatRooms, groupedContacts, getContactsList, user = {}} = this.props;
    const {selectedContact, selectedChatRoomId} = this.state;
    const selectedChatRoom = selectedContact ? {...(chatRooms.find(chatRoom => chatRoom.twId === selectedChatRoomId) || {})} : {};

    return (
      <Container fluid className={this.state.isChatting ? 'pageContainer chatMessageContainer' : 'pageContainer'}>
        <div className="contentContainer d-flex px-0 h-100">
          <ContactsListComponent
            items={groupedContacts}
            onSearch={name => getContactsList({name})}
            user={user}
            loading={loadingContacts}
            onContactClick={this.updateSelectedContact}
            selectedItem={selectedContact}
            withModalSearchIcon={true}
            className={this.state.isChatting ? 'hide-mobile' : ''}
          />
          {this.renderChatSection(selectedChatRoom)}
          {!selectedContact && <NoChatSelected/>}
        </div>
      </Container>
    )
  }
}

export default withRouter(ContactsComponent)
