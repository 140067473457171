import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { createDepartment, deleteDepartment, getDepartmentsList } from "../../../reducers/departments";
import DepartmentsComponent from "../../../components/Admin/Components/Departments/DepartmentsComponent";


const mapStateToProps = state => {
  const pageCount = Math.ceil( (state.departments.total || 0) / (state.departments.pageSize || 1));
  const selectedTheme = state.auth.data?.settings?.theme;
  return {
    selectedTheme,
    pageCount,
    departmentsList: state.departments.departmentsList,
    members: state.contacts.pageMembers
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getDepartmentsList,
  createDepartment,
  deleteDepartment
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DepartmentsComponent);
