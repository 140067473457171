import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import ClinicalRolesComponent from "../../../components/Admin/Components/Roles/ClinicalRolesComponent";
import { getProfessions, createProfession, deleteProfession } from "../../../reducers/professions";

const mapStateToProps = state => {
  const pageCount = Math.ceil( (state.professions.total || 0) / (state.professions.pageSize || 1));
  const selectedTheme = state.auth.data?.settings?.theme;
  return {
    selectedTheme,
    pageCount,
    professionsList: state.professions.professionsList,
    members: state.contacts.pageMembers
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getProfessions,
  createProfession,
  deleteProfession
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClinicalRolesComponent);
