import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { createSuffix, deleteSuffix, getSuffixesList } from "../../../reducers/suffixes";
import SuffixesComponent from "../../../components/Admin/Components/Suffixes/SuffixesComponent";


const mapStateToProps = state => {
  const pageCount = Math.ceil( (state.suffixes.total || 0) / (state.suffixes.pageSize || 1));
  const selectedTheme = state.auth.data?.settings?.theme;
  return {
    selectedTheme,
    pageCount,
    suffixesList: state.suffixes.suffixesList,
    members: state.contacts.pageMembers
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getSuffixesList,
  createSuffix,
  deleteSuffix
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SuffixesComponent);
