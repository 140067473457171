import { connect } from 'react-redux';
import UpdateEmailComponent from '../../components/Auth/UpdateEmailComponent';
import { bindActionCreators } from "redux";
import {createLoadingSelector} from "../../selectors/loading";
import {changeEmail, CHANGE_EMAIL_PREFIX} from "../../reducers/auth";
import {createErrorMessageSelector} from "../../selectors/error";

const mapStateToProps = state => {
  return {
    changingEmail: createLoadingSelector([CHANGE_EMAIL_PREFIX])(state),
    changingEmailError: createErrorMessageSelector([CHANGE_EMAIL_PREFIX])(state),
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  changeEmail,
}, dispatch);


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateEmailComponent);
