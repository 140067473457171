import React from 'react'
import SearchComponent from "../../../UIComponents/SearchComponent";
import { Loader, TableComponent } from "../../../UIComponents";
import { Col } from "reactstrap";
import ReactPaginate from "react-paginate";
import _ from "lodash";
import { withTranslation } from 'react-i18next';

class AllUsersComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      keyword: ''
    }
  }

  componentDidMount() {
    this.updateUsersList()
  }

  updateUsersList = () => {
    const { keyword, page } = this.state;
    const { pageSize } = this.props;

    this.props.getUsersList({
      keyword: _.trim(keyword || ""),
      page,
      pageSize
    })
  };

  handlePageClick = data => {
    let page = data.selected;

    this.setState({ page }, this.updateUsersList);
  };

  updateSearchKeyword = (keyword) => {
    this.setState({keyword}, this.updateUsersList)
  };

  onFieldValueChange = (name, value) => {
    this.setState({ [name]: value })
  };

  render() {
    const { loadingUsersList, pageCount = 1, users, renderTableRow, t } = this.props
    return (
      <>
        <div className="searchContainer px-0">
          <SearchComponent onSearch={this.updateSearchKeyword}
                           placeholder={t("Search...")}
                           className={"inputField"}
          />
        </div>
        {loadingUsersList ? <Loader/> : <TableComponent
          className={'usersTable'}
          listData={users}
          tableHeaders={['', t('Name'), t('Surname'), t('Department'), t('cli_role'), t('Suffix'), t('Specialty'), t('Role'), t('Status'), t('Actions')]}
          renderTableRow={renderTableRow}
        />}
        {pageCount > 1 && (
          <Col container alignItems="center" justify="flex-end">
            <ReactPaginate
              previousLabel={t('PREVIOUS_PAGE')}
              nextLabel={t('NEXT_PAGE')}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={this.handlePageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
              initialPage={0}
              disableInitialCallback={true}
            />
          </Col>
        )}
      </>
    )
  }
}

export default withTranslation('common')(AllUsersComponent)
