import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import ChatComponent from '../../components/ChatComponent';
import { 
  fetchGroupMembers,
  getMessages,
  FETCH_MESSAGES_PREFIX,
  changeSelectedChatRoomTWId,
  markChatRoomMessagesAsRead,
  markMessageImportant,
  deleteChatRoom
} from "../../reducers/chatRooms";
import { JOIN_TWILIO_CONVERSATION_BY_ID_PREFIX } from "../../reducers/twilio";
import { toggleGroupModal, changeGroupMode, addContactsToChatRoom, toggleDeleteModal } from "../../reducers/chat";
import { showGroupMembers as showGroupMembersToggle } from "../../reducers/chatRooms";
import { createLoadingSelector } from "../../selectors/loading";
import { createErrorMessageSelector } from "../../selectors/error";

const mapStateToProps = state => {
  const selectedTheme = state.auth.data?.settings?.theme;
  return {
    selectedTheme,
    showMembers: state.chatRooms.showMembers,
    participants: state.chat.chatRoom.participants,
    user: state.auth.data,
    contacts: state.contacts.list,
    messages: state.chatRooms.messages,
    loading: createLoadingSelector([FETCH_MESSAGES_PREFIX, JOIN_TWILIO_CONVERSATION_BY_ID_PREFIX])(state),
    error: createErrorMessageSelector([FETCH_MESSAGES_PREFIX, JOIN_TWILIO_CONVERSATION_BY_ID_PREFIX])(state),
    isDeleteModalOpen: state.chat.isDeleteModalOpen,
    areCallsRejected: state.calls.areCallsRejected
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getMessages,
  changeSelectedChatRoomTWId,
  markChatRoomMessagesAsRead,
  markMessageImportant,
  toggleGroupModal,
  changeGroupMode,
  addContactsToChatRoom,
  showGroupMembersToggle,
  toggleDeleteModal,
  deleteChatRoom,
  fetchGroupMembers
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatComponent);
