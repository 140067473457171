import React from 'react'
import { Button, Container, Col } from "reactstrap";
import { TableComponent } from "../../../UIComponents";
import SearchComponent from "../../../UIComponents/SearchComponent";
import CreateItemModal from "../Modals/CreateItemModal";
import {appTheme, routes} from "../../../../config";
import { withRouter } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import _ from 'lodash';
import suffixes from "../../../../reducers/suffixes";
import { withTranslation } from 'react-i18next';

class SuffixesComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      page: 1,
      pageSize: 10,
      isCreateModalOpen: false,
    }
  }

  componentDidMount() {
    this.props.getSuffixesList()
  }

  handleCreateSuffix = name => {
    const {keyword, page, pageSize} = this.state;
    this.props.createSuffix(name, { keyword, page, pageSize })
    this.setState({ isCreateModalOpen: false })
  }

  handleDeleteSuffix = id => {
    const {keyword, page, pageSize} = this.state;
    const {suffixesList} = this.props;
    let updatedPage
    if (suffixesList.length === 1) {
      updatedPage = page - 1
      this.setState({ page: page - 1})
    } else {
      updatedPage = page
    }
    this.props.deleteSuffix(id, { keyword, page: updatedPage, pageSize })
  }

  handleEditSuffix = id => {
    this.props.history.push(`${routes.suffixes.path}/${id}`)
  }

  toggleCreateModal = () => {
    this.setState({ isCreateModalOpen: !this.state.isCreateModalOpen })
  }

  updateSuffixesList = () => {
    const {keyword, page, pageSize} = this.state;

    this.props.getSuffixesList({
      keyword: _.trim(keyword || ""),
      page,
      pageSize
    })
  }

  handlePageClick = data => {
    let page = data.selected;

    this.setState({ page: page + 1 }, this.updateSuffixesList);
  };

  updateSearchKeyword = (keyword) => {
    this.setState({keyword}, this.updateSuffixesList)
  };

  renderTableRow = item => {
    const { selectedTheme } = this.props;
    const suffix = this.props.suffixesList.find(s => s.id === item.id);
    return (
      <>
        <td>{suffix.name || '-'}</td>
        <td>{suffix.members || '0'}</td>
        <td>
          <div className="d-flex actions">
            <i
              title="Delete Suffix"
              className="mr-3"
              onClick={() => this.handleDeleteSuffix(item.id)}
            >
              <img src={"/assets/images/icn-delete-user.svg"}/>
            </i>
            <i
              title="Edit Suffix"
              color='info'
              onClick={() => this.handleEditSuffix(item.id)}
            >
              <img src={`/assets/images/edit${selectedTheme === appTheme.DARK ? "-dark" : ""}.svg`}/>
            </i>
          </div>
        </td>
      </>
    )
  }

  render() {
    const { isCreateModalOpen, page } = this.state
    const { suffixesList, pageCount = 1, t } = this.props
    return (
      <Container fluid className='suffixesContainer'>
        <div className='suffixesPage'>
          <div className="bodyHeader">
            <h2>{t('Suffixes')}</h2>
            <Button onClick={() => this.toggleCreateModal()} className="submitButton">{t('admin.ADD_SUFFIX')}</Button>
          </div>
          <div className="suffixesList">
            <div className="searchContainer px-0">
              <SearchComponent onSearch={this.updateSearchKeyword}
                               placeholder={t("Search...")}
                               className={"inputField"}
              />
            </div>
            <TableComponent
              className={'suffixesTable'}
              listData={suffixesList}
              tableHeaders={[t('Name'), t('Members'), t('Actions')]}
              renderTableRow={this.renderTableRow}
            />
            {pageCount > 1 && (
              <Col container alignItems="center" justify="flex-end">
                <ReactPaginate
                  previousLabel={t('PREVIOUS_PAGE')}
                  nextLabel={t('NEXT_PAGE')}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={this.handlePageClick}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                  forcePage	={page - 1}
                  disableInitialCallback={true}
                />
              </Col>
            )}
            <CreateItemModal
              isOpen={isCreateModalOpen}
              toggle={this.toggleCreateModal}
              submit={this.handleCreateSuffix}
              pageName={'Suffixx'}
            />
          </div>
        </div>
      </Container>
    )
  }
}

export default withTranslation('common')(withRouter(SuffixesComponent))
