import React, { Component } from "react";
import { Button } from "reactstrap";
import _ from "lodash";

import { UserAvatar, ShowMore } from "../../../UIComponents";
import { chatStatusClassName } from "../../../../helpers/chat";
import { routes } from "../../../../config";
import history from "../../../../helpers/history";
import { withTranslation } from "react-i18next";

class Contacts extends Component {
  state = {
    contactClicked: false
  }

  handleContactClick = (contactData) => {
    this.setState({ contactClicked: true });
    if (!this.state.contactClicked) {
      const contactElement = document.querySelector(`.contactsListContainer .userTab[id="${contactData.id}"`)
      history.push({ pathname: routes.contacts.path, state: { selectedContactId: contactData.id } });
      this.props.closeModal();
      if (contactElement) {
        contactElement.scrollIntoView({ block: "center", behavior: "smooth" })
      }
    }
  };

 handleItemClick = (contactData) => {
   if (this.props.isOnlySearchMode) {
    this.handleContactClick(contactData)
   } else {
    this.props.onChange(contactData.id)
   }
 }

  render() {
    const { data = [], allContacts, selectedList, isOnlySearchMode, emptyListMessage, t } = this.props;

    if (!data?.length) {
      return emptyListMessage ? <p className="mx-2 my-0"> {emptyListMessage} </p> : null
    }

    return (
      <div className={`${isOnlySearchMode ? 'fullHeight ' : ''}contactsListSection`}>
        {data.map(contact => {
          const contactData = allContacts.find(item => item?.id === (contact?.id || contact)) || {};
          const chatStatus = contactData?.connected ? contactData.chatStatus : "Offline";
          return (
            <div key={contactData.id}
              className={`clickable d-flex mb-3 ml-3 align-items-center justify-content-between contactSectionContainer`}
              onClick={_.throttle(() => this.handleItemClick(contactData) , 5000, { trailing: false })}
            >
              <div className="d-flex contactInfoSectionContainer">
                <div className="d-flex nameAndAvatar">
                  <UserAvatar user={contactData}
                    className={`withStatus mr-2 ${chatStatusClassName(chatStatus)}`} />
                  <div className="contactInfoCellContainer">
                    <p>{contactData.fullName}</p>
                    <span><ShowMore items={contactData.professions?.map(p => p.name)} placeholder="-" header="Professions" /></span>
                  </div>
                </div>
                <div className="contactInfoCellContainer suffix">
                  <p><ShowMore items={contactData.suffixes?.map(p => p.name)} placeholder="-" name={`Suffixes-${contactData.id}`} /></p>
                  <span>{t('Suffix')}</span>
                </div>
                <div className="contactInfoCellContainer group">
                  <p><ShowMore items={contactData.facilities?.map(p => p.name)} placeholder="-" name={`Factilities-${contactData.id}`} /></p>
                  <span>{t('Facility')}</span>
                </div>
                <div className="contactInfoCellContainer department">
                  <p><ShowMore items={contactData.departments?.map(p => p.name)} placeholder="-" name={`Departments-${contactData.id}`} /></p>
                  <span>{t('Department')}</span>
                </div>
                <div className="contactInfoCellContainer position">
                  <p><ShowMore items={contactData.specialties?.map(p => p.name)} placeholder="-" name={`Specialties-${contactData.id}`} /></p>
                  <span>{t('Specialty')}</span>
                </div>
              </div>
              {!isOnlySearchMode &&
                <Button
                  className={`${selectedList ? 'remove' : 'add'}`}
                  // onClick={() => this.props.onChange(contactData.id)}
                />
              }
            </div>
          )

        })}
      </div>
    )
  }
}

export default withTranslation('common')(Contacts)
