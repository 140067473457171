import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import ContactsListComponent from '../ContactsListComponent';
import ChatComponent from '../../containers/Chat/ChatContainer';
import { Loader } from '../UIComponents';
import NoChatSelected from '../ErrorPage/NoChatSelected';
import './recents.scss';
import {routes} from "../../config";
import ChatRoomApiService from '../../services/api/chatRoom';

class RecentComponent extends Component {

  state = {
    selectedChatRoomId: null,
    isChatting: false
  };

  componentDidMount() {
    const selectedChatRoomId = this.props.location.state?.selectedChatRoomTWId || this.props.selectedChatRoomTWId;

    if(selectedChatRoomId) {
      this.setState({selectedChatRoomId})
    }
    this.props.getChatRoomsList();
    this.props.getContactsList();
    this.props.markRecentTabMessagesAsRead();
    this.checkQueryParams()
  }

  componentDidUpdate(prevProps) {
    if(prevProps.location.state?.selectedChatRoomTWId !== this.props.location.state?.selectedChatRoomTWId) {
      this.setState({selectedChatRoomId: this.props.location.state?.selectedChatRoomTWId})
    }
  }

  checkQueryParams = () => {
    const query = new URLSearchParams(window.location.search);
    const logId = query.get('rs');
    if (logId?.length === 24) {
      new ChatRoomApiService().getChatRoomMessageLogs(logId).then(res => {
        if (res.data.log) {
          this.setState({
            selectedChatRoomId: res.data.log.conversationId, 
            linkedMessageLog: res.data.log,
            isChatting: true,
          })
        }
      }).catch(() => {})
    }
  }

  handleContactClick = (selectedChatRoom = {}) => {
    if (selectedChatRoom.twId !== this.state.selectedChatRoomId) {
      const { conversationsList } = this.props;
      const existingConversation = conversationsList.find(conversation => conversation.sid === selectedChatRoom.twId);
      if (!existingConversation) {
        this.props.joinTwilioConversationById(selectedChatRoom.twId);
      }
    }

    this.setState({isChatting: true, linkedMessageLog: null });
    this.props.changeChattingStatus(true);
    this.props.history.push({
      pathname: routes.recent.path,
      state: {selectedChatRoomTWId: selectedChatRoom.twId}
    })
  };

  onExitChat = () => {
    this.setState({ isChatting: false });
    this.props.changeChattingStatus(false);
    this.setState({ selectedChatRoom: null, isChatting: false });
    this.props.history.push({
      pathname: routes.recent.path,
      state: {selectedChatRoomTWId: undefined}
    })
  }

  componentWillUnmount() {
    this.props.changeChattingStatus(false);
    this.setState({ selectedChatRoom: null, isChatting: false });
  }

  render() {
    const { loading, twilioClient, groupedChatRooms, chatRooms, getChatRoomsList } = this.props;
    const { selectedChatRoomId } = this.state;
    const selectedChatRoom = { ...(chatRooms.find(chatRoom => chatRoom.twId === selectedChatRoomId) || {}) };
    return (
      <Container fluid className={this.state.isChatting ? 'pageContainer chatMessageContainer' : 'pageContainer'}>
        <div className="contentContainer d-flex px-0 h-100 position-relative">
          {loading && !chatRooms?.length ? <Loader /> :
            <>
              <ContactsListComponent
                items={groupedChatRooms}
                onSearch={getChatRoomsList}
                onContactClick={this.handleContactClick}
                selectedItem={selectedChatRoom}
                loadingContacts={!twilioClient}
                className={this.state.isChatting ? 'hide-mobile' : ''}
                isRecents
              />
              {!!selectedChatRoomId && !!Object.keys(selectedChatRoom).length && <ChatComponent
                chatRoom={selectedChatRoom}
                onExitChat={this.onExitChat}
                className={this.state.isChatting ? '' : 'hide-mobile'} 
                linkedMessageLog={this.state.linkedMessageLog}/>}
                {Object.keys(selectedChatRoom).length === 0 && <NoChatSelected />}
            </>
          }
        </div>
      </Container>
    )
  }
}

export default withRouter(RecentComponent)
