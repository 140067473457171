import { connect } from 'react-redux';
import {bindActionCreators} from "redux";
import ModalSearchIcon from '../../components/ContactsListComponent/ModalSearchIcon';
import {openModal} from "../../reducers/group";

const mapStateToProps = state => {
  const userSettings = state.auth.data?.settings || {};

  const theme = userSettings?.theme;

  return {
    theme,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  openGroupModal: openModal,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalSearchIcon);
