import React from 'react';
import _ from 'lodash';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { getSelectLabel } from "../../helpers/admin";

const SelectField = (props = {
  id: _.uniqueId(),
  name: '',
  required: false,
  disabled: false,
  placeholder: "Select",
  options: [],
  className: '',
  hideArrow: false
}) => {
  const [ isOpen, setIsOpen ] = React.useState(false);
  const toggle = () => setIsOpen(prevState => !prevState);

  const selected = props.options.find(o => {
    if (typeof o.value === 'boolean') {
      return o.value === props.defaultValue;
    } else {
      return o.value.startsWith((String(props.defaultValue) || '').split('-')[0])
    }
  })

  return (
    <Dropdown isOpen={isOpen} disabled={props.disabled} toggle={toggle} id='select-dropdown' className={props.className}>
      <DropdownToggle>
        <span>{selected?.label || ''}</span>
        {!props.hideArrow && <img src='/assets/images/arrow-down.svg' alt='arrow'/>}
      </DropdownToggle>
      <DropdownMenu>
        <div className="optionsContainer">
          {props.options?.map((option, index) => {
            return <DropdownItem active={props.selectedValue === option.value} key={index} onClick={() => { props.onChange(option.value) }}>{getSelectLabel(option.optionLabel || option.label)}</DropdownItem>
          })}
        </div>
      </DropdownMenu>
    </Dropdown>
  )
};

export default SelectField;
