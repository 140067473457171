import { connect } from 'twilio-video';

export default class TwilioVideoChatService {
  static room;

  static create = async (token, options) => {
    this.room = await connect(token, options);
    return this.room;
  }
}
