import * as toastr from 'toastr';
import history from '../helpers/history';

/**
 * Notification Service is for showing the toasts on the right top corner of the screen
 */
export default class ToastrService {
  static onClickUrl = '';

  static setup() {
    toastr.options.preventDuplicates = true;
    toastr.options.closeDuration = 300;
    toastr.options.timeOut = 10000;
    toastr.options.extendedTimeOut = 10000;
    toastr.options.progressBar = false;
    toastr.options.escapeHtml = false;
    toastr.options.closeButton = true;
    toastr.options.onclick = ToastrService.onClick;
  }

  static onClick = () => {
    if (!!ToastrService.onClickUrl) {
      history.push(ToastrService.onClickUrl);
      ToastrService.onClickUrl = '';
    }
  };

  static success(message) {
    toastr.success('<strong>' + message + '</strong>');
  }

  static info(message, url) {
    ToastrService.onClickUrl = url;
    toastr.info('<strong>' + message + '</strong>');
  }

  static error(message) {
    if (message.includes('LocalParticipant disconnected')) return;
    toastr.error(message);
  }

  static warning(message) {
    toastr.warning(message);
  }
}

ToastrService.setup();
