import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { getPageMembers, removePageMember, updatePageMembers } from "../../../reducers/contacts";
import { deleteProfession, getProfessionById, updateProfession } from "../../../reducers/professions";
import EditClinicalRoleComponent from "../../../components/Admin/Components/Roles/EditClinicalRoleComponent";
import { updateUser, changeUserStatus } from "../../../reducers/user";


const mapStateToProps = state => {
  const professionId = state.professions.profession.id;
  const selectedTheme = state.auth.data?.settings?.theme;
  return {
    selectedTheme,
    users: state.users.users,
    usersLength: state.users.total,
    profession: state.professions.profession,
    members: state.contacts.pageMembers[professionId]
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getProfessionById,
  updateProfession,
  getPageMembers,
  removePageMember,
  updateUser,
  updatePageMembers,
  changeUserStatus,
  deleteProfession
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditClinicalRoleComponent);
