import React from 'react'
import _ from 'lodash';
import VIAddMember from '../../../assets/icons/add-member-white.svg';
import VIDecline from '../../../assets/icons/video-declined.svg';
import VODecline from '../../../assets/icons/voice-declined.svg';
import SSDecline from '../../../assets/icons/screen-sharing-decline.svg';
import VIMicOff from '../../../assets/icons/microphone-off.svg';
import VIMicOn from '../../../assets/icons/microphone-outline.svg';
import VICallVideo from '../../../assets/icons/call-video.svg';
import VICallVideoOff from '../../../assets/icons/call-video-off.svg';
import VIScreenSharing from '../../../assets/icons/screen-sharing.svg';
import VIFullScreen from '../../../assets/icons/fullscreen.svg';
import { useTranslation } from 'react-i18next';

const clickDebounceShortTime = 1500;
const clickDebounceLongTime = 3000;

export default function Controls({
  onDisconnect,
  onEndCallForAll,
  screenSharing,
  isScreenShared,
  isAudioEnabled,
  isVideoEnabled,
  toggleLocalAudio,
  toggleLocalVideo,
  onAddParticipant,
  fullScreenEnabled,
  toggleFullScreen,
  isOwner,
  canEndCallForAll,
  isSmall,
  isOtherParticipantSharingTheScreen,
  isAccepted,
  isDirectScreenShare,
  isVideoCall,
  areAllOtherParticipantsMutedDuringScreenShare,
  forceVoiceToggle,
  isDirectChatRoom,
}) {
  const { t } = useTranslation('common');

  return (
    <div className={`${isSmall ? 'small ' : ''}controls`}>
      {!isSmall && isOwner && onAddParticipant &&
        <button className='btnRounded'
          title={t('chat.add_member')}
          onClick={_.throttle(() => onAddParticipant(), clickDebounceShortTime)}>
          <img src={VIAddMember} alt={t('chat.add_member')} />
        </button>
      }
      {onDisconnect &&
        <button className='btnRounded videoDecline'
          title={isAccepted ? isDirectScreenShare ? t('chat.end_screen_share') : t('chat.end_call') : t("Decline")}
          onClick={_.throttle(() => onDisconnect(), clickDebounceShortTime)}>
          <img src={isDirectScreenShare ? SSDecline : isVideoCall ? VIDecline : VODecline}
            alt={isAccepted ? isDirectScreenShare ? t('chat.end_screen_share') : t('chat.end_call') : t("Decline")}
            className={`${isDirectScreenShare ? "directScreenShareResponseIcon" : ""}`}
          />
        </button>
      }
      {canEndCallForAll && onEndCallForAll &&
        <button className='btnRounded'
          title="End for all"
          onClick={_.throttle(() => onEndCallForAll(), clickDebounceShortTime)}>
          <img src={VIDecline} alt="End for all" />
        </button>
      }
      {!isSmall && toggleFullScreen &&
        <button title={fullScreenEnabled ? t('chat.exit_fs') : t('chat.full_screen')}
          className={`${fullScreenEnabled ? 'active ' : ''} btnMuted`}
          onClick={_.throttle(() => toggleFullScreen(), clickDebounceShortTime)}>
          <img src={VIFullScreen} alt="Full Screen" />
        </button>
      }
      {!isDirectScreenShare && toggleLocalVideo &&
        <button title={isVideoEnabled ? t('chat.hide_video') : t('chat.show_video')}
          className={`${isVideoEnabled ? 'active ' : ''} ${isScreenShared ? 'disabled' : ''} btnMuted`}
          onClick={_.throttle(() => toggleLocalVideo(), clickDebounceLongTime)}
          disabled={isScreenShared}>
          <img src={isVideoEnabled ? VICallVideo : VICallVideoOff} alt={t('chat.toggle_video')} />
        </button>
      }
      {!isDirectScreenShare && screenSharing &&
        <button className={`${isOtherParticipantSharingTheScreen ? 'disabled ' : ''}${isScreenShared ? 'active ' : ''} btnMuted`}
          title={isScreenShared ? t('chat.stop_share') : t('chat.share')}
          onClick={screenSharing}>
          <img src={VIScreenSharing} alt={t('chat.screen_share')} />
        </button>
      }
      {!isDirectScreenShare && toggleLocalAudio &&
        <button className={`${isAudioEnabled ? 'active ' : ''} btnMuted`}
          title={isAudioEnabled ? t('chat.mic_off') : t('chat.mic_off')}
          onClick={_.throttle(() => toggleLocalAudio(), clickDebounceLongTime)}>
          <img src={isAudioEnabled ? VIMicOn : VIMicOff} alt="Mic" />
        </button>
      }
      {!isDirectScreenShare && isScreenShared &&
        <button className={`${areAllOtherParticipantsMutedDuringScreenShare ? 'active ' : ''} btnMuted`}
          title={t((areAllOtherParticipantsMutedDuringScreenShare ? 'unmute' : 'mute') + (isDirectChatRoom ? "_other" : "_all"))}
          onClick={_.throttle(() => forceVoiceToggle(areAllOtherParticipantsMutedDuringScreenShare ? "unmute" : "mute"), clickDebounceLongTime)}>
          <img src={"/assets/images/btn-mute.svg"} alt="mute" />
        </button>
      }
    </div>
  )
}
