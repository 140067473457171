import { ChatApiService } from '../services'

export const SET_USER_CHAT_ROOMS = "SET_USER_CHAT_ROOMS"
export const TOGGLE_GROUP_MODEL = "TOGGLE_GROUP_MODEL"
export const TOGGLE_DELETE_MODAL = "TOGGLE_DELETE_MODAL"
export const SET_GROUP_MODE = "SET_GROUP_MODE"
export const SET_CHAT_ROOM_DATA = "SET_CHAT_ROOM_DATA"
export const SET_IS_CHATTING = "SET_IS_CHATTING"

const initialState = {
    chatRooms: [],
    UserStatuses: [
        { id: 1, value: "Available", class: "available" },
        { id: 2, value: "Away", class: "away" },
        { id: 3, value: "Do not disturb", class: "no-disturb" },
        { id: 4, value: "Offline", class: "offline" },
    ],
    groupModalState: false,
    isChatting: false,
    groupMode: "",
    selectedChatRoom: {},
    chatRoom: { participants: [] },
    isDeleteModalOpen: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_USER_CHAT_ROOMS:
            return {
                ...state,
                chatRooms: action.payload
            }
        case TOGGLE_GROUP_MODEL:
            return {
                ...state,
                groupModalState: !state.groupModalState,
            }
        case TOGGLE_DELETE_MODAL:
            return {
                ...state,
                isDeleteModalOpen: !state.isDeleteModalOpen
            }
        case SET_GROUP_MODE:
            return {
                ...state,
                groupMode: action.payload.mode,
                selectedChatRoom: action.payload.data
            }
        case SET_CHAT_ROOM_DATA:
            return {
                ...state,
                chatRoom: action.payload
            }
        case SET_IS_CHATTING:
            return {
                ...state,
                isChatting: action.payload
            }
        default:
            return state
    }
}

export const getUserChatRooms = () => dispatch => {
    const chatService = new ChatApiService();
    chatService.getUserChatRooms().then(data => {
        return dispatch({
            type: SET_USER_CHAT_ROOMS,
            payload: data.data.chatRooms
        })
    })
}

export const getChatRoomById = (id, twId) => dispatch => {
    const chatService = new ChatApiService();
    chatService.getChatRoomById(id, twId)
        .then(response => {
            return dispatch({ type: SET_CHAT_ROOM_DATA, payload: response.data })
        })
}

export const addContactsToChatRoom = (contacts, groupData) => dispatch => {
    const chatService = new ChatApiService();
    chatService.addContactsToChatRoom(contacts, groupData)
        .then(response => (
            dispatch({ type: SET_CHAT_ROOM_DATA, payload: response.data.chatRoom })
        ))
}

export const removeContactsFromChatRoom = (contacts, groupData) => dispatch => {
    const chatService = new ChatApiService();
    chatService.removeContactsFromChatRoom(contacts, groupData)
        .then(response => (
            dispatch({ type: SET_CHAT_ROOM_DATA, payload: response.data.chatRoom })
        ))
}

export const toggleGroupModal = () => dispatch => {
    return dispatch({ type: TOGGLE_GROUP_MODEL })
}

export const toggleDeleteModal = () => dispatch => {
    return dispatch({ type: TOGGLE_DELETE_MODAL })
}

export const changeChattingStatus = (isChatting) => dispatch => {
    return dispatch({ type: SET_IS_CHATTING, payload: isChatting })
}

export const changeGroupMode = (mode, data) => dispatch => {
    return dispatch({ type: SET_GROUP_MODE, payload: { mode, data } })
}
