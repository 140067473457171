import {constantMessages, userRoles} from "../config";
import ToastrService from "../services/toastrService";
import SpecialtiesApiService from "../services/api/specialties";

export const FETCH_SPECIALTIES_LIST_PREFIX = 'specialties/FETCH_SPECIALTIES_LIST_PREFIX'
export const FETCH_SPECIALTIES_LIST_REQUEST_ACTION = FETCH_SPECIALTIES_LIST_PREFIX + '_REQUEST_ACTION';
export const FETCH_SPECIALTIES_LIST_SUCCESS_ACTION = FETCH_SPECIALTIES_LIST_PREFIX + '_SUCCESS_ACTION';
export const FETCH_SPECIALTIES_LIST_FAILURE_ACTION = FETCH_SPECIALTIES_LIST_PREFIX + '_FAILURE_ACTION';

export const FETCH_ALL_SPECIALTIES_LIST_PREFIX = 'specialties/FETCH_ALL_SPECIALTIES_LIST_PREFIX'
export const FETCH_ALL_SPECIALTIES_LIST_REQUEST_ACTION = FETCH_ALL_SPECIALTIES_LIST_PREFIX + '_REQUEST_ACTION';
export const FETCH_ALL_SPECIALTIES_LIST_SUCCESS_ACTION = FETCH_ALL_SPECIALTIES_LIST_PREFIX + '_SUCCESS_ACTION';
export const FETCH_ALL_SPECIALTIES_LIST_FAILURE_ACTION = FETCH_ALL_SPECIALTIES_LIST_PREFIX + '_FAILURE_ACTION';

export const GET_SPECIALTY_BY_ID_PREFIX = 'specialties/GET_SPECIALTY_BY_ID_PREFIX';
export const GET_SPECIALTY_BY_ID_REQUEST_ACTION = GET_SPECIALTY_BY_ID_PREFIX + '_REQUEST_ACTION';
export const GET_SPECIALTY_BY_ID_SUCCESS_ACTION = GET_SPECIALTY_BY_ID_PREFIX + '_SUCCESS_ACTION';
export const GET_SPECIALTY_BY_ID_FAILURE_ACTION = GET_SPECIALTY_BY_ID_PREFIX + '_FAILURE_ACTION';

export const CREATE_SPECIALTY_PREFIX = 'specialties/CREATE_SPECIALTY_PREFIX'
export const CREATE_SPECIALTY_REQUEST_ACTION = CREATE_SPECIALTY_PREFIX + '_REQUEST_ACTION';
export const CREATE_SPECIALTY_SUCCESS_ACTION = CREATE_SPECIALTY_PREFIX + '_SUCCESS_ACTION';
export const CREATE_SPECIALTY_FAILURE_ACTION = CREATE_SPECIALTY_PREFIX + '_FAILURE_ACTION';

export const UPDATE_SPECIALTY_PREFIX = 'specialties/UPDATE_DSPECIALTYPREFIX'
export const UPDATE_SPECIALTY_REQUEST_ACTION = UPDATE_SPECIALTY_PREFIX + '_REQUEST_ACTION';
export const UPDATE_SPECIALTY_SUCCESS_ACTION = UPDATE_SPECIALTY_PREFIX + '_SUCCESS_ACTION';
export const UPDATE_SPECIALTY_FAILURE_ACTION = UPDATE_SPECIALTY_PREFIX + '_FAILURE_ACTION';

export const DELETE_SPECIALTY_PREFIX = 'specialties/DELETE_SPECIALTY_PREFIX'
export const DELETE_SPECIALTY_REQUEST_ACTION = DELETE_SPECIALTY_PREFIX + '_REQUEST_ACTION';
export const DELETE_SPECIALTY_SUCCESS_ACTION = DELETE_SPECIALTY_PREFIX + '_SUCCESS_ACTION';
export const DELETE_SPECIALTY_FAILURE_ACTION = DELETE_SPECIALTY_PREFIX + '_FAILURE_ACTION';

const initialState = {
  specialtiesList: [],
  allSpecialtiesList: [],
  page: null,
  pageSize: null,
  total: null,
  specialty: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SPECIALTIES_LIST_SUCCESS_ACTION:
      const data = action.payload.data
      return {
        ...state,
        page: data.page,
        pageSize: data.pageSize,
        specialtiesList: [...data.results],
        total: data.total
      }
    case FETCH_ALL_SPECIALTIES_LIST_SUCCESS_ACTION:
      const allData = action.payload.data
      return {
        ...state,
        allSpecialtiesList: allData.results,
      }
    case GET_SPECIALTY_BY_ID_SUCCESS_ACTION:
      return {
        ...state,
        specialty: { ...action.payload.specialty }
      }
    case CREATE_SPECIALTY_SUCCESS_ACTION:
      return {
        ...state,
        specialtiesList: [...state.specialtiesList, {...action.payload.specialty}]
      }
    case UPDATE_SPECIALTY_SUCCESS_ACTION:
      const specialtyIndex = state.specialtiesList.findIndex(spec => spec.id === action.payload.specialty?.id)
      const updatedSpecialtyList = [...state.specialtiesList]
      updatedSpecialtyList[specialtyIndex] = action.payload.specialty
      return {
        ...state,
        specialtiesList: [...updatedSpecialtyList],
        specialty: action.payload.specialty
      }
    case DELETE_SPECIALTY_SUCCESS_ACTION:
      const specialtyListAfterUpdate = state.specialtiesList.filter(d => d.id !== action.payload.id)
      return {
        ...state,
        specialtiesList: [...specialtyListAfterUpdate]
      }
    default:
      return state
  }
}

export function getSpecialties(params = { keyword: '', page: 1, pageSize: 10 }) {
  return (dispatch, getState) => {
    const isAllList = params.all === 1;
    dispatch({ type: isAllList ? FETCH_ALL_SPECIALTIES_LIST_REQUEST_ACTION : FETCH_SPECIALTIES_LIST_REQUEST_ACTION });
    const specialtyService = new SpecialtiesApiService();
    const userData = getState().auth?.data;
    const isAdmin = userData?.role === userRoles.ADMIN || userData?.role?.name === userRoles.ADMIN;
    return specialtyService[isAdmin ? "getSpecialties" : "getSpecialtiesList"](params)
      .then(({ data = {} }) => {
        const response = data || [];
        dispatch({ type: isAllList ? FETCH_ALL_SPECIALTIES_LIST_SUCCESS_ACTION : FETCH_SPECIALTIES_LIST_SUCCESS_ACTION, payload: { data: response } });
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: isAllList ? FETCH_ALL_SPECIALTIES_LIST_FAILURE_ACTION : FETCH_SPECIALTIES_LIST_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message)
      })
  }
}

export function getSpecialtyById(id) {
  return dispatch => {
    dispatch({ type: GET_SPECIALTY_BY_ID_REQUEST_ACTION});
    const specialtyService = new SpecialtiesApiService();
    specialtyService.getSpecialtyById(id)
      .then(({ data = {} }) => {
        const specialties = data.results || {}
        dispatch({type: GET_SPECIALTY_BY_ID_SUCCESS_ACTION, payload: { specialty: specialties.find(d => d.id === id) }});
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: GET_SPECIALTY_BY_ID_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message)
      })
  }
}

export function createSpecialty(name, params) {
  return dispatch => {
    dispatch({ type: CREATE_SPECIALTY_REQUEST_ACTION })
    const specialtyService = new SpecialtiesApiService();
    specialtyService.createSpecialty({ name })
      .then(({data = {}}) => {
        dispatch(getSpecialties(params))
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: CREATE_SPECIALTY_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message)
      })
  }
}

export function updateSpecialty(data) {
  return dispatch => {
    dispatch({ type: UPDATE_SPECIALTY_REQUEST_ACTION })
    const specialtyService = new SpecialtiesApiService();
    specialtyService.updateSpecialty(data)
      .then(({data = {}}) => {
        const specialty = data || {}
        dispatch({type: UPDATE_SPECIALTY_SUCCESS_ACTION, payload: { specialty }})
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: UPDATE_SPECIALTY_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message)
      })
  }
}

export function deleteSpecialty(id, params) {
  return dispatch => {
    dispatch({ type: DELETE_SPECIALTY_REQUEST_ACTION })
    const specialtyService = new SpecialtiesApiService();
    specialtyService.deleteSpecialty(id)
      .then(({data = {}}) => {
        dispatch(getSpecialties(params))
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: DELETE_SPECIALTY_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message)
      })
  }
}
