import BaseApiService from './base';

export default class SuffixesApiService extends BaseApiService {
  getSuffixes(params) {
    return this.request({
      method: 'get',
      url: '/suffix',
      params,
      withCredentials: true,
    });
  }

  getSuffixesList(params) {
    return this.request({
      method: 'get',
      url: '/suffix/list',
      params,
      withCredentials: false,
    });
  }

  getSuffixById(id) {
    return this.request({
      method: 'get',
      url: `/suffix?${id}`,
      withCredentials: true,
    });
  }

  createSuffix(data) {
    return this.request({
      method: 'post',
      url: '/suffix',
      data,
      withCredentials: true
    })
  }

  updateSuffix(data) {
    return this.request({
      method: 'put',
      url: '/suffix',
      data,
      withCredentials: true
    })
  }

  deleteSuffix(id) {
    return this.request({
      method: 'delete',
      url: '/suffix',
      data: {id},
      withCredentials: true
    });
  }
}
