/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react"
import { ReactComponent as Cancel } from '../../../assets/icons/speech-to-text-cancel.svg'
import "./SpeechToText.scss"

import { StorageService, ToastrService } from "../../../services";
import i18n from "../../../i18n";
import { SocketMessageType } from "../../../config";
import { ClipLoader } from "react-spinners";
import SpeechToTextSocket from "../../../services/SpeechToTextSocket";

const SpeechToText = ({ message, onTextChange, onVoiceTotextExit }) => {
  const [loading, setLoading] = useState(true);

  // const mediaRef = useRef(mediaRecorder);
  const messageRef = useRef(message);
  const isMounted = useIsMounted();

  useEffect(() => {
    messageRef.current = message;
  }, [message])

  const startMediaRecording = useCallback(
    () => {
      navigator.mediaDevices
        .getUserMedia({
          audio: {
            echoCancellation: true,
            noiseSuppression: true
          }
        })
        .then((stream) => {
          setLoading(false);
          // setIsRecording(true);
          let recorder = new MediaRecorder(stream, {  });
          // setMediaRecorder(recorder);
          recorder.start(1000);
          // // _startTimer();
          const handler = (ev) => {
            if (ev.data.size > 1000) {
              SpeechToTextSocket.send(SocketMessageType.SendTranscriptionBlobs, ev.data);
            }

            if (!isMounted()) {
              if (recorder) {
                recorder.stop()
                recorder.stream.getTracks().forEach((t) => t.stop());
                recorder.removeEventListener('dataavailable', handler)
                recorder = undefined;
              }
            }
          }

          recorder.addEventListener("dataavailable", handler);
        })
        .catch((err) => {
          ToastrService.error(i18n.t('chat.provide_mic_permission'));
          handleVoiceExit()
        });
    },
    [],
  )


  useEffect(() => {
    SpeechToTextSocket.connect(new StorageService().checkUserToken(), handleVoiceExit)
      .then(() => {
        SpeechToTextSocket.addEventListeners(SocketMessageType.VoiceTranscriptionStarted, startMediaRecording)

        SpeechToTextSocket.addEventListeners(SocketMessageType.VoiceTranscriptionTextData, (voiceText) => {
          onTextChange(messageRef.current + ' ' + voiceText)
        })

        SpeechToTextSocket.addEventListeners(SocketMessageType.VoiceTranscriptionError, (text) => {
          ToastrService.warning(i18n.t(text))
          handleVoiceExit()
        })
      });

    return () => {
      SpeechToTextSocket.disconnect();
    }
  }, [])

  const handleVoiceExit = () => {
    SpeechToTextSocket.disconnect()
    onVoiceTotextExit()
  }

  if (loading) return <div className="SpeechToText">
    <div style={{
      minWidth: 44,
      justifyContent: 'center'
    }}>
      <ClipLoader size={17} color={'#fff'} loading={true} /> </div>
  </div>

  return <div className="SpeechToText">
    <div>
      <button onClick={handleVoiceExit}>
        <Cancel />
      </button>
      <span>Voice to text</span>
      <VoiceAnimationIcon speeking />
    </div>
  </div>
}

export default SpeechToText

const VoiceAnimationIcon = ({ speeking }) => {
  const intervalRef = useRef();
  const barHeightRef = useRef(11)
  const [barHeight, setBarHeight] = useState(2)


  useEffect(() => {
    if (speeking) {
      intervalRef.current = setInterval(() => {
        setBarHeight(barHeightRef.current);
        barHeightRef.current = barHeightRef.current === 2 ? 11 : 2
      }, 700)
    } else if (intervalRef.current) {
      // clearInterval(intervalRef.current)
    }
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current)
    }
  }, [speeking])

  const styles = {
    transition: 'all 0.5s',
    y: barHeight === 2 ? '13' : '8',
    height: barHeight
  }

  return <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="5.5" y="11" width="3" height="6" rx="1.5" fill="white" style={styles} />
    <rect x="11.5" y="8" width="3" height="11" rx="1.5" fill="white" />
    <rect x="17.5" y="10" width="3" height="7" rx="1.5" fill="white" style={styles} />
  </svg>
}

const useIsMounted = () => {
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    }
  })

  return () => isMounted.current;
}