import { ToastrService } from '.';
import {isSafari} from "../helpers/function";

export default class NotificationService {

  static show = async ({title = '', body = '', icon = ''}) => {
    // Don't show notification if the browser tab is active
    if(!document.hidden) {
      return
    }

    // Check if the browser supports notifications
    if (!("Notification" in window)) {
      ToastrService.error("This browser does not support desktop notification");
    }

    // Check whether notification permissions have already been granted
    else if (Notification.permission === "granted") {
      isSafari ? new Notification(title, { body, icon }) :
      navigator.serviceWorker.ready.then(function(registration) {
        console.log("SHOW Notification");
        registration.showNotification(title, { body, icon });
      });
    }

    // Check whether notification permissions have already been granted
    else if (Notification.permission === "default") {
      const permission = await NotificationService.requestPermission();
      if (permission === "granted") {
        isSafari ? new Notification(title, { body, icon }) :
        navigator.serviceWorker.ready.then(function(registration) {
          // console.log("SHOW Notification");
          registration.showNotification(title, { body, icon });
        });

      }
    }
  };

  static requestPermission = async() => {
    return await window.Notification?.requestPermission();
  }

}
