import BaseApiService from './base';

/**
 * Auth Api Service - for making the auth requests and passing results to the action that made the request
 */
export default class AuthApiService extends BaseApiService {
  logIn(data) {
    return this.request({
      method: 'post',
      url: '/auth/login',
      data,
      withCredentials: false
    });
  }

  register(data) {
    return this.request({
      method: 'post',
      url: '/auth/register',
      data,
      withCredentials: false
    });
  }

  sendForgotPasswordEmail(data) {
    return this.request({
      method: 'post',
      url: '/auth/forgot-password',
      data,
      withCredentials: false
    });
  }

  resetPassword(data) {
    return this.request({
      method: 'post',
      url: '/auth/reset-password',
      data,
      withCredentials: false
    });
  }

  setPassword(data) {
    return this.request({
      method: 'post',
      url: '/user/set-password',
      data,
      withCredentials: false
    });
  }

  verifyEmail(data) {
    return this.request({
      method: 'post',
      url: '/auth/verify-email',
      data,
      withCredentials: false
    })
  }

  resendEmailVerify(email) {
    return this.request({
      method: 'post',
      url: '/auth/resend-verify-email',
      data: {
        email
      },
      withCredentials: false
    })
  }

  verifyMobile(data) {
    return this.request({
      method: 'post',
      url: '/auth/verify-phone',
      data,
      withCredentials: false
    })
  }

  resendMobileVerify(data) {
    return this.request({
      method: 'post',
      url: '/auth/resend-verify-phone',
      data,
      withCredentials: false
    })
  }

  refreshToken(data) {
    return this.request({
      method: 'post',
      url: '/auth/refresh-token',
      data,
      withCredentials: true
    })
  }

  logout() {
    return this.request({
      method: 'get',
      url: '/auth/logout',
      withCredentials: true
    })
  }

  changePhone(data, token) {
    return this.request({
      method: 'post',
      url: `/auth/change-phone`,
      data,
      headers: { Authorization: 'Bearer ' + token },
      withCredentials: false
    });
  }

  changeEmail(data, token) {
    return this.request({
      method: 'post',
      url: `/auth/change-email`,
      data,
      headers: { Authorization: 'Bearer ' + token },
      withCredentials: false
    });
  }

}
