import { connect } from 'react-redux';
import GroupModal from '../../components/GroupModal';
import {bindActionCreators} from "redux";
import {closeModal} from "../../reducers/group";

const mapStateToProps = state => {
  const {selectedGroup, isOpen} = state.group;

  const {participants = [], name = "", twId = ""} = selectedGroup || {};

  return {
    isOpen,
    twId,
    participants: participants.filter(participant => participant.id !== state.auth.data.id).map(participant => participant.id),
    groupName: name,
    isOnlySearchMode: state.group.isOnlySearchMode,
  };
};


const mapDispatchToProps = dispatch => bindActionCreators({
  closeModal,
}, dispatch);


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupModal);
