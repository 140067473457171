import React from 'react'
import { Col } from "reactstrap";
import SelectField from './SelectField';
import i18n from '../../i18n';



export const LanguageSelector = ({ onChange, value }) => {
    const languageOption = [
        { value: 'en', label: i18n.t('English') },
        { value: 'fr', label: i18n.t('French') }
    ]
    return (
        <Col xs={12}
            className=" px-0 ieCol d-flex justify-content-center lang-select">
            <div>
                <p className="mr-3">{i18n.t('auth.choose_language')}</p>
                <div className="d-flex flex-column field-column">
                    <SelectField
                        name='profession'
                        className='multiSelectDropdown '
                        options={languageOption}
                        isMulti={false}
                        value={value}
                        defaultValue={value}
                        withoutCheckboxes
                        onChange={onChange}
                    />
                </div>
            </div>
        </Col>
    )
}
