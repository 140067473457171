import io from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';
import store from "../store";
import {handleSocketConnectionReconnectForAllOngoingCalls} from "../reducers/calls";
import {CallApiService} from "./index";
import {SocketMessageType} from "../config";
import { toggleUpdatePasswordModal } from '../reducers/auth';

const uuid = uuidv4();

if (!sessionStorage.getItem("ecsSocketUid") || (window.performance.getEntriesByType("navigation")[0])?.type === 'back_forward') {
  sessionStorage.setItem('ecsSocketUid', uuid);
}

export default {
  // This array holds the events we are currently subscribed to
  callbacks: [],
  // The socket connection object
  connection: null,
  pingInterval: null,

  connect(token) {
    console.log("SocketService::connect()");
    return new Promise((resolve, reject) => {
      // If we are already connected, we should close the current connection
      // We should be careful with the connection, we only need to connect when we are authenticated
      // and everytime we refresh the token
      if (this.connection) {
        this.connection.close();
        this.connection = null;
        this.callbacks = [];
      }

      this.connection = io(
        process.env.REACT_APP_CHAT_SOCKET_URL,
        {
          query: {
            token,
            uuid: sessionStorage.getItem("ecsSocketUid"),
          },
          pingTimeout: 2000,
          reconnection: true,
          reconnectionDelay: 500,
          reconnectionDelayMax : 2000,
          reconnectionAttempts: Infinity,
          path: process.env.REACT_APP_CHAT_SOCKET_PATH,
          allowUpgrades: false,
        }
      )

      this.addEventListeners("connect", function socketConnect() {
        console.log("SocketService: connected successfully.");
        this.handleConnect();
        resolve();
      }.bind(this));

      this.addEventListeners("disconnecting", function socketDisconnect(reason) {
        console.log("SocketService: disconnecting.", reason);
      });

      this.addEventListeners("disconnect", function socketDisconnect(reason) {
        console.log("SocketService: disconnected.", reason);
        this.handleDisconnect();
      }.bind(this));

      this.addEventListeners("connect_error", function socketConnectError(error) {
        console.log(`SocketService: connection_error `, error);
        this.handleDisconnect();
      }.bind(this));

      this.addEventListeners("connect_timeout", function socketConnectTimeout(timeout) {
        console.log(`SocketService: connect_timeout `, timeout);
        this.handleDisconnect();
      }.bind(this));

      this.addEventListeners("reconnect", function socketReconnect(attemptNumber) {
        console.log(`SocketService: Trying to reconnect with attemptNumber=`, attemptNumber);
      });

      this.addEventListeners("reconnect_error", function socketReconnectError(error) {
        console.log(`SocketService: reconnect_error `, error);
      });

      this.addEventListeners("force-password-update", function socketReconnectError(error) {
        store.dispatch(toggleUpdatePasswordModal(true))
      });
    });
  },

  disconnect() {
    console.log(`SocketService::disconnect()`);
    if (!this.connection) {
      return;
    }

    this.connection.close();
    this.connection = null;
    this.callbacks = [];
  },

  handleConnect() {
    console.log(`SocketService::handleConnect()`);
    // accept call with delay in case if reconnection was done faster than our BE handles disconnect event
    setTimeout(() => {
      store.dispatch(handleSocketConnectionReconnectForAllOngoingCalls());
    }, 500);

    const userId = store.getState().auth.data.id;
    const callApiService = new CallApiService();
    if (userId) {
      this.pingInterval = setInterval(() => {
        callApiService.sendPing({userId})
      }, 25000)
    } else {
      console.log("SocketService:NO_USER_ID")
    }
  },

  /**
   * This method should be used to dispatch any needed action when the socket is disconnected
   * At the moment is void since don't do any action when the socket is disconnected
   */
  handleDisconnect() {
    if(this.pingInterval) {
      clearInterval(this.pingInterval);
    }
    console.log(`SocketService::handleDisconnect()`);
  },

  send(name, data) {
    if (name !== SocketMessageType.PING) {
      console.log(`SocketService::send()`, { name, data });
    }
    if(!this.connection) {
      console.log("SocketService::send(). Socket is not created.");
      return;
    }

    if (!this.connection?.connected) {
      console.log("SocketService::send(). Socket is not connected");
      return;
    }

    if (name !== SocketMessageType.PING) {
      console.log("SocketService::send(). Data sent successfully.");
    }
    return this.connection.emit(name, data);
  },

  addEventListeners(name, handler) {
    console.log("SocketService::addEventListeners()", { name });
    if (this.callbacks.includes(name)) {
      console.log(`SocketService::addEventListeners(). Handler for the ${name} event exits.`);
      return;
    }

    this.callbacks.push(name);
    console.log(`SocketService::addEventListeners(). Listening for ${name} events.`);
    this.connection.on(name, handler);
  },

  removeEventListener(name) {
    const indx = this.callbacks.findIndex(x => x === name);
    this.callbacks.splice(indx, 1);
    console.log(`SocketService::removeEventListeners(). Handler for the ${name} event removed.`);
    this.connection.off(name, () => {});
  }
}
