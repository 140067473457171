import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill";

import "react-quill/dist/quill.snow.css";

import "./RichTextEditor.scss";

const RichTextEditors = ({ onChange: change, value: init }) => {
  const [value, setValue] = useState(init);
const editorRef = useRef(null)
  const onChange = (html) => {
    setValue(html);
    change(html);
  };

  useEffect(() => {
    if (!value) setValue(init);
  }, [init, value]);
  

  const myUndo = () => {
    let myEditor = editorRef.current.getEditor();
    return myEditor.history.undo();
}

const myRedo = () => {
    let myEditor = editorRef.current.getEditor();
    return myEditor.history.redo();
}

var icons = Quill.import("ui/icons");
    icons["undo"] = `<svg viewbox="0 0 18 18">
    <polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
    <path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
  </svg>`;
    icons["redo"] = `<svg viewbox="0 0 18 18">
    <polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
    <path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
  </svg>`;

  const modules = useMemo(() => {
    return {
      toolbar: {
        container: [
          ["bold", "italic", "strike", "underline"],
          [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
          [
            { list: "ordered" },
            { list: "bullet" },
            "blockquote"
          ],
          [{ header: "1" }, { header: "2" }],
          [{ size: [] }],
          
          ["link", "image"] ,// "video"],
          ['undo' , 'redo' ],
          // ["clean"],
        ],
        handlers: {
          undo: myUndo,
          redo: myRedo,
          image: function imageHandler() {
            const tooltip = this.quill.theme.tooltip;
            const originalSave = tooltip.save;
            const originalHide = tooltip.hide;

            tooltip.save = function () {
              const range = this.quill.getSelection(true);
              const value = this.textbox.value;
              if (value) {
                this.quill.insertEmbed(range.index, 'image', value, 'user');
              }
            };
            // Called on hide and save.
            tooltip.hide = function () {
              tooltip.save = originalSave;
              tooltip.hide = originalHide;
              tooltip.hide();
            };
            tooltip.edit('image');
            tooltip.textbox.placeholder = 'Embed URL';
          }
        }
      }
    }
  }, [])

  return (
    <ReactQuill
    ref={editorRef}
      className="RichTextEditor"
      value={value}
      onChange={onChange}
      theme="snow"
      modules={modules}
      formats={RichTextEditors.formats}
    />
  );
};

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
RichTextEditors.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "video",
  "image",
  "undo",
  "redo",
  "align"
];

export default RichTextEditors;
