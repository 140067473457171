import React from 'react';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

class CallDuration extends React.Component {
  callTimeOut = null;
  state = {
    callTime: 0,
  };

  componentDidMount() {
    this.callTimeOut = setInterval(() => this.setState({ callTime: this.state.callTime + 1 }), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.callTimeOut)
  }

  render() {
    const { type, t } = this.props;
    const { callTime } = this.state;

    const callDuration = moment.utc(callTime * 1000).format(callTime >= 3600 ? "HH:mm:ss" : "mm:ss");

    return <div className='sub-title'>{callDuration} {type} {t('chat.in_progress')}</div>

  }
}

export default withTranslation('common')(CallDuration);
