// Polyfills
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import "core-js/features/array/find";
import "core-js/features/array/includes";
import "core-js/features/number/is-nan";

import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
import "./setup";
import "./index.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "toastr/toastr.scss";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { AppSettingContextProvider } from "./services/app-setting.hook";

import  "./i18n";


const target = document.querySelector("#root");

render(
    <Provider store={store}>
      <AppSettingContextProvider>
        <App />
      </AppSettingContextProvider>
    </Provider>
 ,
 target
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
