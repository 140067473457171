import { ChatRoomAPIService, ToastrService, SoundService, NotificationService } from '../services';
import { getChatRoomById, SET_CHAT_ROOM_DATA, TOGGLE_DELETE_MODAL } from './chat';
import { leaveConversation, sendSystemMessage } from './twilio';
import { appSounds, constantMessages, routes, systemMessages } from '../config';
import history from "../helpers/history";
import logo from "../assets/icons/ECS-logo-in1.png";
import { Conversation, Message, Participant } from '@twilio/conversations';
import { Dispatch } from 'redux';

// ACTION_TYPES ////////////////////////////////////////////////////////////////

export const FETCH_MESSAGES_PREFIX = 'chatRoom/FETCH_MESSAGES';
export const FETCH_MESSAGES_REQUEST_ACTION = FETCH_MESSAGES_PREFIX + '_REQUEST_ACTION';
export const FETCH_MESSAGES_SUCCESS_ACTION = FETCH_MESSAGES_PREFIX + '_SUCCESS_ACTION';
export const FETCH_MESSAGES_FAILURE_ACTION = FETCH_MESSAGES_PREFIX + '_FAILURE_ACTION';

export const FETCH_CHAT_ROOMS_LIST_PREFIX = 'chatRoom/FETCH_CHAT_ROOMS_LIST';
export const FETCH_CHAT_ROOMS_LIST_REQUEST_ACTION = FETCH_CHAT_ROOMS_LIST_PREFIX + '_REQUEST_ACTION';
export const FETCH_CHAT_ROOMS_LIST_SUCCESS_ACTION = FETCH_CHAT_ROOMS_LIST_PREFIX + '_SUCCESS_ACTION';
export const FETCH_CHAT_ROOMS_LIST_FAILURE_ACTION = FETCH_CHAT_ROOMS_LIST_PREFIX + '_FAILURE_ACTION';

export const CLEAR_CHAT_ROOMS_LIST_BY_PARTICIPANTS_ACTION = 'chatRoom/CLEAR_CHAT_ROOMS_LIST_BY_PARTICIPANTS_ACTION';

export const FETCH_CHAT_ROOMS_LIST_BY_PARTICIPANTS_PREFIX = 'chatRoom/FETCH_CHAT_ROOMS_LIST_BY_PARTICIPANTS';
export const FETCH_CHAT_ROOMS_LIST_BY_PARTICIPANTS_REQUEST_ACTION = FETCH_CHAT_ROOMS_LIST_BY_PARTICIPANTS_PREFIX + '_REQUEST_ACTION';
export const FETCH_CHAT_ROOMS_LIST_BY_PARTICIPANTS_SUCCESS_ACTION = FETCH_CHAT_ROOMS_LIST_BY_PARTICIPANTS_PREFIX + '_SUCCESS_ACTION';
export const FETCH_CHAT_ROOMS_LIST_BY_PARTICIPANTS_FAILURE_ACTION = FETCH_CHAT_ROOMS_LIST_BY_PARTICIPANTS_PREFIX + '_FAILURE_ACTION';

export const MUTE_CHAT_ROOM_PREFIX = 'chatRoom/MUTE_CHAT_ROOM';
export const MUTE_CHAT_ROOM_REQUEST_ACTION = MUTE_CHAT_ROOM_PREFIX + '_REQUEST_ACTION';
export const MUTE_CHAT_ROOM_SUCCESS_ACTION = MUTE_CHAT_ROOM_PREFIX + '_SUCCESS_ACTION';
export const MUTE_CHAT_ROOM_FAILURE_ACTION = MUTE_CHAT_ROOM_PREFIX + '_FAILURE_ACTION';

export const UNMUTE_CHAT_ROOM_PREFIX = 'chatRoom/UNMUTE_CHAT_ROOM';
export const UNMUTE_CHAT_ROOM_REQUEST_ACTION = UNMUTE_CHAT_ROOM_PREFIX + '_REQUEST_ACTION';
export const UNMUTE_CHAT_ROOM_SUCCESS_ACTION = UNMUTE_CHAT_ROOM_PREFIX + '_SUCCESS_ACTION';
export const UNMUTE_CHAT_ROOM_FAILURE_ACTION = UNMUTE_CHAT_ROOM_PREFIX + '_FAILURE_ACTION';

export const UPDATE_CHAT_ROOM_PREFIX = 'chatRoom/UPDATE_CHAT_ROOM';
export const UPDATE_CHAT_ROOM_REQUEST_ACTION = UPDATE_CHAT_ROOM_PREFIX + '_REQUEST_ACTION';
export const UPDATE_CHAT_ROOM_SUCCESS_ACTION = UPDATE_CHAT_ROOM_PREFIX + '_SUCCESS_ACTION';
export const UPDATE_CHAT_ROOM_FAILURE_ACTION = UPDATE_CHAT_ROOM_PREFIX + '_FAILURE_ACTION';

export const DELETE_CHAT_ROOM_PREFIX = 'chatRoom/DELETE_CHAT_ROOM_PREFIX';
export const DELETE_CHAT_ROOM_REQUEST_ACTION = DELETE_CHAT_ROOM_PREFIX + '_REQUEST_ACTION';
export const DELETE_CHAT_ROOM_SUCCESS_ACTION = DELETE_CHAT_ROOM_PREFIX + '_SUCCESS_ACTION';
export const DELETE_CHAT_ROOM_FAILURE_ACTION = DELETE_CHAT_ROOM_PREFIX + '_FAILURE_ACTION';

export const SET_CHAT_ROOM_AS_FAVORITE = "SET_CHAT_ROOM_AS_FAVORITE";
export const SET_CHAT_ROOM_AS_UNFAVORITE = "SET_CHAT_ROOM_AS_UNFAVORITE";

export const CREATE_TWILIO_CHAT_CONVERSATION_PREFIX = 'chatRoom/CREATE_TWILIO_CHAT_CONVERSATION';
export const CREATE_TWILIO_CHAT_CONVERSATION_REQUEST_ACTION = CREATE_TWILIO_CHAT_CONVERSATION_PREFIX + '_REQUEST_ACTION';
export const CREATE_TWILIO_CHAT_CONVERSATION_SUCCESS_ACTION = CREATE_TWILIO_CHAT_CONVERSATION_PREFIX + '_SUCCESS_ACTION';
export const CREATE_TWILIO_CHAT_CONVERSATION_FAILURE_ACTION = CREATE_TWILIO_CHAT_CONVERSATION_PREFIX + '_FAILURE_ACTION';

export const LEAVE_CHAT_ROOM_PREFIX = 'chatRoom/LEAVE_CHAT_ROOM';
export const LEAVE_CHAT_ROOM_REQUEST_ACTION = LEAVE_CHAT_ROOM_PREFIX + '_REQUEST_ACTION';
export const LEAVE_CHAT_ROOM_SUCCESS_ACTION = LEAVE_CHAT_ROOM_PREFIX + '_SUCCESS_ACTION';
export const LEAVE_CHAT_ROOM_FAILURE_ACTION = LEAVE_CHAT_ROOM_PREFIX + '_FAILURE_ACTION';

export const REMOVE_MESSAGE_PREFIX = 'chatRoom/REMOVE_MESSAGE';
export const REMOVE_MESSAGE_REQUEST_ACTION = REMOVE_MESSAGE_PREFIX + '_REQUEST_ACTION';
export const REMOVE_MESSAGE_SUCCESS_ACTION = REMOVE_MESSAGE_PREFIX + '_SUCCESS_ACTION';
export const REMOVE_MESSAGE_FAILURE_ACTION = REMOVE_MESSAGE_PREFIX + '_FAILURE_ACTION';

export const REMOVE_MESSAGE_FROM_CHAT_ROOM = 'chatRoom/REMOVE_MESSAGE_FROM_CHAT_ROOM';

export const ADD_RECEIVED_MESSAGE_ACTION = 'chatRoom/ADD_RECEIVED_MESSAGE_ACTION';

export const MARK_MESSAGE_IMPORTANT = 'chatRoom/MARK_MESSAGE_IMPORTANT';

export const MARK_MESSAGE_IMPORTANT_AS_READ = 'chatRoom/MARK_MESSAGE_IMPORTANT_AS_READ';

export const ACKNOWLEDGE_MESSAGE = 'chatRoom/ACKNOWLEDGE_MESSAGE';

export const CHANGE_TYPING_STATUS_ACTION = 'chatRoom/CHANGE_TYPING_STATUS_ACTION';

export const CHANGE_SELECTED_CHAT_ROOM_TW_ID_ACTION = 'chatRoom/CHANGE_SELECTED_CHAT_ROOM_TW_ID_ACTION';

export const MARK_CHAT_ROOM_MESSAGES_AS_READ_ACTION = 'chatRoom/MARK_CHAT_ROOM_MESSAGES_AS_READ_ACTION';

export const MARK_RECENT_TAB_MESSAGES_AS_READ_ACTION = 'chatRoom/MARK_RECENT_TAB_MESSAGES_AS_READ_ACTION';

export const SHOW_MEMBERS_TOGGLE = 'chatRoom/SHOW_MEMBERS_TOGGLE';

export const UPDATE_UNSENT_MESSAGE = 'chatRoom/UPDATE_UNSENT_MESSAGE';

export const RESET = 'chatRoom/RESET';

// INITIAL STATE ///////////////////////////////////////////////////////////////

const initialState: {
  list: any[],
  searchedList: any,
  messages: any[],
  unsentMessages: any[],
  selectedChatRoomTWId: string,
  showMembers: boolean,
  newMessagesInRecentTab: any[],
  chatRoomsByParticipants: any[]
} = {
  list: [],
  searchedList: null,
  messages: [],
  unsentMessages: [],
  selectedChatRoomTWId: '',
  showMembers: false,
  newMessagesInRecentTab: [],
  chatRoomsByParticipants: []
};

// STATE ///////////////////////////////////////////////////////////////////////
export default (state = initialState, action: any) => {
  let list: any[] = [...state.list], messages: any[] = [...state.messages];
  switch (action.type) {
    case FETCH_CHAT_ROOMS_LIST_SUCCESS_ACTION:
      let searchedList = null;
      if (!!action.payload.keyword) {
        searchedList = action.payload.chatRooms
      } else {
        list = action.payload.chatRooms;
      }
      return {
        ...state,
        list,
        searchedList
      };
    case FETCH_CHAT_ROOMS_LIST_BY_PARTICIPANTS_SUCCESS_ACTION:
      return {
        ...state,
        chatRoomsByParticipants: action.payload.chatRooms,
      };
    case CLEAR_CHAT_ROOMS_LIST_BY_PARTICIPANTS_ACTION:
      return {
        ...state,
        chatRoomsByParticipants: [],
      };
    case CREATE_TWILIO_CHAT_CONVERSATION_SUCCESS_ACTION: {
      if (!!action.payload.chatRoom) {
        list.push(action.payload.chatRoom)
      }
      return {
        ...state,
        list,
        ...(!action.payload.isInvited && { selectedChatRoomTWId: action.payload.chatRoom?.twId })
      }
    }
    case LEAVE_CHAT_ROOM_SUCCESS_ACTION: {
      if (!!action.payload.chatRoom) {
        list = list.filter(chatRoom => chatRoom.twId !== action.payload.chatRoom.twId)
      }
      return {
        ...state,
        list
      }
    }
    case UPDATE_CHAT_ROOM_SUCCESS_ACTION:
    case UNMUTE_CHAT_ROOM_SUCCESS_ACTION:
    case MUTE_CHAT_ROOM_SUCCESS_ACTION: {
      const chatRoomIndex = list.findIndex(chatRoom => chatRoom.twId === action.payload.chatRoom.twId);
      if (chatRoomIndex !== -1) {
        list[chatRoomIndex] = { ...list[chatRoomIndex], ...action.payload.chatRoom }
      }

      return {
        ...state,
        list
      }
    }
    case DELETE_CHAT_ROOM_SUCCESS_ACTION: {
      return {
        ...state,
        list: [...state.list.filter((i: any) => i.twId !== action.payload)]
      }
    }
    case FETCH_MESSAGES_SUCCESS_ACTION:
    case ADD_RECEIVED_MESSAGE_ACTION: {
      let newMessagesInRecentTab = state.newMessagesInRecentTab;
      if (!!action.payload.conversationId) {
        let isNewMsg = true;
        if (!!action.payload.messages) {
          const messagesIndex = messages.findIndex(listOfMessages => listOfMessages.conversationId === action.payload.conversationId);
          if (messagesIndex !== -1) {
            if (action.payload.isForce) {
              messages[messagesIndex].messages = [...action.payload.messages]
            } else {
              let theMessages: any[] = []

              action.payload.messages.forEach((newMsg: any) => {
                const indx = messages[messagesIndex].messages.findIndex((x: any) => x.id === newMsg.id);
                if (indx >= 0) {
                  messages[messagesIndex].messages[indx] = newMsg
                  isNewMsg = false;
                } else {
                  theMessages.push(newMsg)
                }
              })

              messages[messagesIndex].messages = [...messages[messagesIndex].messages, ...theMessages]
            }
          } else {
            messages.push({
              conversationId: action.payload.conversationId,
              messages: [...action.payload.messages]
            })
          }
        }
        if (action.type === ADD_RECEIVED_MESSAGE_ACTION) {
          const chatRoomIndex = list.findIndex(chatRoom => chatRoom.twId === action.payload.conversationId);
          if (chatRoomIndex !== -1) {
            // const notify =  && 
            if (action.payload.conversationId !== state.selectedChatRoomTWId || !document.hasFocus()) {
              if (!list[chatRoomIndex].isMuted) {
                action.payload.playSoundOnNotification && SoundService.play(appSounds.MESSAGE_CALL_SOUND);
                NotificationService.show({ title: "ECS", body: "New Message", icon: logo });
                newMessagesInRecentTab = history.location.pathname === routes.recent.path || state.newMessagesInRecentTab.includes(action.payload.conversationId) ? state.newMessagesInRecentTab : [...state.newMessagesInRecentTab, action.payload.conversationId]
              }

              list[chatRoomIndex] = {
                ...list[chatRoomIndex],
                unreadCount: isNewMsg ? list[chatRoomIndex].unreadCount + 1 : list[chatRoomIndex].unreadCount,
                lastMessage: action.payload.messages[0]
              }
            }
            list[chatRoomIndex] = {
              ...list[chatRoomIndex],
              updatedAt: new Date().toISOString(),
              lastMessage: action.payload.messages[0]
            }
          }
        }

      }
      return {
        ...state,
        messages,
        list,
        newMessagesInRecentTab
      }
    }
    case MARK_MESSAGE_IMPORTANT_AS_READ:
      if (action.payload.twId) {
        const { userId, timetoken, twId: conversationId } = action.payload;

        const inx = messages.findIndex((x) => x.conversationId === conversationId)
        if (inx >= 0) {
          messages[inx].messages = messages[inx].messages.map((message: any) => {
            if (message.attributes?.notify?.includes(userId)) {
              const inx = message.attributes.seen?.findIndex((x: any) => x.userId === userId);
              if (!(inx >= 0)) {
                if (message.attributes.seen) {
                  message.attributes.seen.push({ userId, timetoken })
                } else {
                  message.attributes.seen = [{ userId, timetoken }]
                }
              }
            }

            return message;
          })
        }
      }
      return {
        ...state,
        messages
      }
    case ACKNOWLEDGE_MESSAGE:
      if (action.payload.msgId) {
        const { acknowledge, conversationId, msgId } = action.payload;

        const inx = messages.findIndex((x) => x.conversationId === conversationId)
        if (inx >= 0) {
          messages[inx].messages = messages[inx].messages?.map((msg: any) => msg.id === msgId ? ({
            ...msg,
            attributes: {
              ...msg.attributes,
              acknowledge
            }
          }) : msg)
        }
      }
      return {
        ...state,
        messages
      }
    case MARK_MESSAGE_IMPORTANT:
      if (action.payload.level) {
        const { level, notify, id, conversationId } = action.payload;

        const inx = messages.findIndex((x) => x.conversationId === conversationId)
        if (inx >= 0) {
          const indx = messages[inx].messages.findIndex((x: any) => x.id === id)
          if (indx >= 0) {
            messages[inx].messages[indx].attributes = {
              ...messages[inx].messages[indx].attributes,
              level,
              notify
            };
          }
        }
      }
      return {
        ...state,
        messages
      }
    case CHANGE_TYPING_STATUS_ACTION:
      if (action.payload.member && action.payload.member.conversation && action.payload.member.conversation.sid) {
        const chatRoomIndex = list.findIndex(chatRoom => chatRoom.twId === action.payload.member.conversation.sid);
        if (chatRoomIndex !== -1) {
          if (action.payload.status) {
            if (!list[chatRoomIndex].typingMembers) {
              list[chatRoomIndex].typingMembers = [action.payload.member.identity];
            } else if (!list[chatRoomIndex].typingMembers.find((member: any) => member === action.payload.member.identity)) {
              list[chatRoomIndex].typingMembers.push(action.payload.member.identity)
            }
          } else {
            if (!!list[chatRoomIndex].typingMembers && !!list[chatRoomIndex].typingMembers.find((member: any) => member === action.payload.member.identity)) {
              list[chatRoomIndex].typingMembers = list[chatRoomIndex].typingMembers.filter((member: any) => member !== action.payload.member.identity);
            }
          }
        }
      }
      return {
        ...state,
        list,
      };
    case CHANGE_SELECTED_CHAT_ROOM_TW_ID_ACTION:
      return {
        ...state,
        selectedChatRoomTWId: action.payload.twId,
      };
    case MARK_CHAT_ROOM_MESSAGES_AS_READ_ACTION:
      const index = list.findIndex(chatRoom => chatRoom.twId === action.payload.twId);
      if (index !== -1) {
        list[index] = { ...list[index], unreadCount: 0 }
      }
      return {
        ...state,
        list,
      };
    case RESET:
      return {
        ...state,
        list: [],
      };
    case SET_CHAT_ROOM_AS_FAVORITE:
    case SET_CHAT_ROOM_AS_UNFAVORITE:
      return {
        ...state,
        list: state.list.map(x => x.twId === action.payload.twId ? action.payload : x)
      };
    case UPDATE_UNSENT_MESSAGE:
      const unsentMessageIndex = state.unsentMessages.findIndex(unsentMessage => unsentMessage.twId === action.payload.twId);
      return {
        ...state,
        unsentMessages: unsentMessageIndex !== -1 ?
          [
            ...state.unsentMessages.slice(0, unsentMessageIndex),
            {
              ...state.unsentMessages[unsentMessageIndex],
              message: action.payload.message
            },
            ...state.unsentMessages.slice(unsentMessageIndex + 1)
          ] :
          [
            ...state.unsentMessages.slice(0, state.unsentMessages.length),
            {
              twId: action.payload.twId,
              message: action.payload.message
            },
            ...state.unsentMessages.slice(state.unsentMessages.length + 1)
          ],
      };
    case SHOW_MEMBERS_TOGGLE:
      return {
        ...state,
        showMembers: !state.showMembers
      }
    case MARK_RECENT_TAB_MESSAGES_AS_READ_ACTION:
      return {
        ...state,
        newMessagesInRecentTab: []
      }
    case REMOVE_MESSAGE_FROM_CHAT_ROOM: {
      let newMessagesInRecentTab = state.newMessagesInRecentTab;
      const chatRoomIndex = list.findIndex(chatRoom => chatRoom.twId === action.payload.twId);
      const messagesListIndex = messages.findIndex(messagesList => messagesList.conversationId === action.payload.twId);
      const messageIndexInTheList = messages[messagesListIndex]?.messages.findIndex((messageData: any) => messageData.id === action.payload.messageId);
      let isUnreadMessage = false;
      if (messageIndexInTheList >= (messages[messagesListIndex]?.messages?.length - list[chatRoomIndex].unreadCount)) {
        isUnreadMessage = true
      }
      if (chatRoomIndex !== -1 && messagesListIndex !== -1 && messageIndexInTheList !== -1) {
        const isLastMessage = messageIndexInTheList === messages[messagesListIndex].messages.length - 1;
        messages[messagesListIndex] = {
          ...messages[messagesListIndex],
          messages: [
            ...messages[messagesListIndex].messages.slice(0, messageIndexInTheList),
            ...messages[messagesListIndex].messages.slice(messageIndexInTheList + 1)
          ]
        }
        if (isLastMessage) {
          list[chatRoomIndex] = {
            ...list[chatRoomIndex],
            updatedAt: new Date().toISOString(),
            lastMessage: [...messages[messagesListIndex].messages].sort((a, b) => b.timetoken - a.timetoken)[0] || {
              body: '',
              from: null,
              timetoken: String(list[chatRoomIndex].createdAt).length < 13 ? list[chatRoomIndex].createdAt * 1000 : list[chatRoomIndex].createdAt,
            }
          }
        } else {
          list[chatRoomIndex] = {
            ...list[chatRoomIndex],
            updatedAt: new Date().toISOString(),
          }
        }

        if ((action.payload.twId !== state.selectedChatRoomTWId) && isUnreadMessage) {
          if (list[chatRoomIndex].unreadCount > 0) {
            list[chatRoomIndex] = {
              ...list[chatRoomIndex],
              unreadCount: list[chatRoomIndex].unreadCount - 1,
            }
          }
          if (list[chatRoomIndex].unreadCount === 0 && newMessagesInRecentTab.includes(action.payload.twId)) {
            newMessagesInRecentTab = newMessagesInRecentTab.filter(el => el !== action.payload.twId)
          }
        }
      }
      return {
        ...state,
        list,
        messages,
        newMessagesInRecentTab,
      }
    }
    default:
      return state
  }
}

// ACTIONS /////////////////////////////////////////////////////////////////////

export function getChatRoomsList(keyword?: string) {
  return (dispatch: Dispatch) => {
    dispatch({ type: FETCH_CHAT_ROOMS_LIST_REQUEST_ACTION });
    const chatRoomService = new ChatRoomAPIService();
    return chatRoomService.getChatRoomsList(keyword)
      .then((response) => {
        const chatRooms = response?.data?.chatRooms || [];
        dispatch({ type: FETCH_CHAT_ROOMS_LIST_SUCCESS_ACTION, payload: { chatRooms, keyword } });
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: FETCH_CHAT_ROOMS_LIST_FAILURE_ACTION, payload: { message } });
        console.log('err', err)
        ToastrService.error(message)
      })
  }
}

export function getChatRoomsByParticipants(data: any) {
  return (dispatch: Dispatch) => {
    dispatch({ type: FETCH_CHAT_ROOMS_LIST_BY_PARTICIPANTS_REQUEST_ACTION });
    const chatRoomService = new ChatRoomAPIService();
    return chatRoomService.getChatRoomsByParticipantsList(data)
      .then(({ data = {} }) => {
        const chatRooms = data.chatRooms || [];
        dispatch({ type: FETCH_CHAT_ROOMS_LIST_BY_PARTICIPANTS_SUCCESS_ACTION, payload: { chatRooms } });
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: FETCH_CHAT_ROOMS_LIST_BY_PARTICIPANTS_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message)
      })
  }
}

export function clearChatRoomsByParticipants() {
  return (dispatch: Dispatch) => {
    dispatch({ type: CLEAR_CHAT_ROOMS_LIST_BY_PARTICIPANTS_ACTION });
  }
}

export function updateChatRoom(data: any) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: UPDATE_CHAT_ROOM_REQUEST_ACTION });

    const chatRoomService = new ChatRoomAPIService();
    return chatRoomService.updateChatRoom(data)
      .then(({ data = {} }) => {
        const chatRoom = data.chatRoom || {};
        const contacts = getState().contacts.list
        const conversation = getState().twilio.conversationsList.find((conversation: Conversation) => conversation.sid === chatRoom.twId);
        const oldParticipants = getState().chatRooms.list.find((room: any) => room.twId === chatRoom.twId).participants.map((p: any) => p.id)
        const newParticipants = chatRoom.participants.map((p: any) => p.id)

        let participantsToRemove: any[] = []
        let participantsToAdd: any[] = []

        oldParticipants.forEach((p: any) => {
          let participant
          let fullName
          if (!newParticipants.includes(p)) {
            conversation.participants.forEach((value: any) => {
              if (value.state.identity === p) {
                participant = value
                fullName = contacts.find((c: any) => c.id === value.state.identity).fullName
              }
            })
          }

          if (participant) {
            participantsToRemove.push(p)
            conversation.removeParticipant(participant)
          }
        })

        newParticipants.forEach((p: any) => {
          if (!oldParticipants.includes(p)) {
            participantsToAdd.push(p)
            // conversation.invite(p)
            conversation.add(p)
          }
        })

        if (!!participantsToRemove.length) {
          const eventName = systemMessages.USER_REMOVED
          const event = {
            name: eventName,
            data: {
              twId: conversation.sid,
              initiator: participantsToRemove
            }
          }
          dispatch(sendSystemMessage(eventName, conversation.sid, event))
        }

        if (!!participantsToAdd.length) {
          const eventName = systemMessages.USER_ADDED
          const event = {
            name: eventName,
            data: {
              twId: conversation.sid,
              initiator: participantsToAdd,
            }
          }
          dispatch(sendSystemMessage(eventName, conversation.sid, event))
        }

        dispatch({ type: SET_CHAT_ROOM_DATA, payload: { ...chatRoom } });
        dispatch({ type: UPDATE_CHAT_ROOM_SUCCESS_ACTION, payload: { chatRoom } });
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: UPDATE_CHAT_ROOM_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message)
      })
  }
}

export function deleteChatRoom(twId: string) {
  return (dispatch: Dispatch, getState: any) => {
    dispatch({ type: DELETE_CHAT_ROOM_REQUEST_ACTION })

    const chatRoomService = new ChatRoomAPIService();
    return chatRoomService.deleteChatRoom(twId)
      .then(() => {
        const conversation: Conversation = getState().twilio.conversationsList.find((cn: Conversation) => cn.sid === twId)
        // const groupName = conversation.conversationState.friendlyName
        const groupName = conversation.friendlyName
        conversation.delete();
        if (getState().chat.isDeleteModalOpen) {
          dispatch({ type: TOGGLE_DELETE_MODAL })
        }
        dispatch({ type: DELETE_CHAT_ROOM_SUCCESS_ACTION, payload: twId })
        ToastrService.success(`You have successfully removed ${groupName}`)
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: DELETE_CHAT_ROOM_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message)
      })
  }
}

export function muteChatRoom(twId: string) {
  return (dispatch: Dispatch) => {
    dispatch({ type: MUTE_CHAT_ROOM_REQUEST_ACTION });
    const chatRoomService = new ChatRoomAPIService();
    return chatRoomService.muteChatRoom(twId)
      .then(({ data = {} }) => {
        const chatRoom = data.chatRoom || [];
        dispatch({ type: MUTE_CHAT_ROOM_SUCCESS_ACTION, payload: { chatRoom } });
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: MUTE_CHAT_ROOM_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message)
      })
  }
}

export function unMuteChatRoom(twId: string) {
  return (dispatch: Dispatch) => {
    dispatch({ type: UNMUTE_CHAT_ROOM_REQUEST_ACTION });
    const chatRoomService = new ChatRoomAPIService();
    return chatRoomService.unmuteChatRoom(twId)
      .then(({ data = {} }) => {
        const chatRoom = data.chatRoom || [];
        dispatch({ type: UNMUTE_CHAT_ROOM_SUCCESS_ACTION, payload: { chatRoom } });
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: UNMUTE_CHAT_ROOM_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message)
      })
  }
}

type GetMesageParams = {
  twId: string; isForce: boolean, before: any, limit: number,
  msgId?: string
}

export function getMessages({ twId, isForce = false, before, limit = 100, msgId }: GetMesageParams) {

  return async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_MESSAGES_REQUEST_ACTION });
    const chatRoomService = new ChatRoomAPIService();
    const beforeTimeToken = !!before ? (typeof before === "number" ? before : new Date(before).getTime()) : null;
    try {
      const response = await chatRoomService.getMessages(twId, beforeTimeToken, limit, msgId);
      const { data = {} } = response || {};
      dispatch({
        type: FETCH_MESSAGES_SUCCESS_ACTION, payload: {
          conversationId: twId,
          messages: data.messages || [],
          isForce
        }
      });
    } catch (err: any) {
      const message = err.message || constantMessages.defaultErrorMessage;
      dispatch({ type: FETCH_MESSAGES_FAILURE_ACTION, payload: { message } });
      ToastrService.error(message);
    }
  }
}

export function handleNewMessage(message: Message) {
  return (dispatch: any, getState: any) => {
    if (!message || !message.conversation) {
      return;
    }

    const { settings } = getState().auth.data;

    const playSoundOnNotification = settings?.playNotificationSound;
    // @ts-ignore:: Types diferent from actual object
    if (message.state.attributes.systemMessage) {

      const preparePayload = (message: any) => ({
        conversationId: message.conversation.sid,
        playSoundOnNotification,
        messages: [{
          body: message.body,
          attributes: message.attributes,
          from: { id: message.author },
          id: message.state.attributes.id || message.sid,
          timetoken: message.state.timestamp
        }],
      });

      return dispatch({ type: ADD_RECEIVED_MESSAGE_ACTION, payload: preparePayload(message) });
    }

    dispatch({
      type: ADD_RECEIVED_MESSAGE_ACTION, payload: {
        conversationId: message.conversation.sid,
        playSoundOnNotification,
        messages: [{
          body: message.body,
          attributes: message.attributes,
          from: { id: message.author },
          // @ts-ignore:: Types diferent from actual object
          id: message.state.attributes?.id || message.sid,
          // @ts-ignore:: Types diferent from actual object
          timetoken: message.state.timestamp
        }],
      }
    })

    setTimeout(() => {
      const { selectedChatRoomTWId } = getState().chatRooms;

      if (selectedChatRoomTWId === message.conversation.sid) {
        dispatch(markChatRoomMessagesAsRead(selectedChatRoomTWId))
      }
    }, 500)


  }
}

export function handleTypingStatusChange(member: Participant, status: any) {
  return (dispatch: Dispatch, getState: any) => {
    const { id: userId } = getState().auth.data;
    if (member && member.identity && member.identity !== userId) {
      dispatch({
        type: CHANGE_TYPING_STATUS_ACTION,
        payload: {
          member,
          status,
        }
      })
    }
  }
}

export function markMessageImportant(params: {
  level: 'IMPORTANT' | 'URGENT',
  notify: string[],
  id: string,
  conversationId: string,
  from: string
}) {
  return (dispatch: Dispatch) => {
    const chatRoomService = new ChatRoomAPIService();
    return chatRoomService.markMessageImportant(params).then(() => {
      dispatch({ type: MARK_MESSAGE_IMPORTANT, payload: { ...params } })
    }).catch((err) => {
      const message = err.message || constantMessages.defaultErrorMessage;
      ToastrService.error(message)
    })
  }
}

export function changeSelectedChatRoomTWId(twId = '') {
  return (dispatch: Dispatch) => {
    dispatch({
      type: CHANGE_SELECTED_CHAT_ROOM_TW_ID_ACTION,
      payload: { twId }
    })
  }
}

export function markChatRoomMessagesAsRead(twId = '') {
  return (dispatch: Dispatch, getState: any) => {
    if (!document.hasFocus()) return;
    
    const { id: userId } = getState().auth.data;
    const chatRoomService = new ChatRoomAPIService();
    chatRoomService.readMessage({ userId, twId })
    dispatch({
      type: MARK_CHAT_ROOM_MESSAGES_AS_READ_ACTION,
      payload: { twId }
    })
  }
}

export function reset() {
  return (dispatch: Dispatch) => {
    dispatch({ type: RESET })
  }
}

export function updateUnsentMessage(twId: string, message: any) {
  return (dispatch: Dispatch) => {
    dispatch({ type: UPDATE_UNSENT_MESSAGE, payload: { twId, message } })
  }
}

export function leaveChatRoom(chatRoom: any) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: LEAVE_CHAT_ROOM_REQUEST_ACTION });
    const { id } = getState().auth.data
    const updatedChatRoom = {
      ...chatRoom,
      participants: [...chatRoom.participants.filter((member: any) => member.id !== id)]
    }
    const chatRoomService = new ChatRoomAPIService();
    return chatRoomService.leaveChatRoom(id, chatRoom.twId)
      .then(() => {
        dispatch(leaveConversation(chatRoom.twId));

        dispatch({
          type: LEAVE_CHAT_ROOM_SUCCESS_ACTION, payload: updatedChatRoom
        });
        dispatch(getChatRoomsList())
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: LEAVE_CHAT_ROOM_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message)
      })
  }
}

export function removeMessage(data: any) {
  return (dispatch: Dispatch) => {
    dispatch({ type: REMOVE_MESSAGE_REQUEST_ACTION });
    const chatRoomService = new ChatRoomAPIService();
    return chatRoomService.removeMessage(data)
      .then((data) => {
        dispatch({ type: REMOVE_MESSAGE_SUCCESS_ACTION });
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: REMOVE_MESSAGE_FAILURE_ACTION, payload: { message } });
      })
  }
}

export function acknowledgeMessage(params: { messageId: string, userId: string }) {
  // dispatch({ type: REMOVE_MESSAGE_REQUEST_ACTION });
  return (dispatch: Dispatch) => {
    const chatRoomService = new ChatRoomAPIService();
    chatRoomService.acknowledgeMessage(params)
      .then((data) => {
        // console.log('acknowledge', data)
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        ToastrService.error(message)
      })
  }
}

export const setChatRoomAsFavorite = (chatRoom: any) => (dispatch: Dispatch) => {
  const chatService = new ChatRoomAPIService();

  chatService.setChatRoomToFavorite(chatRoom).then(res => {
    return dispatch({
      type: SET_CHAT_ROOM_AS_FAVORITE,
      payload: res.data.chatRoom
    })
  })
}

export const unsetChatRoomAsFavorite = (chatRoom: any) => (dispatch: Dispatch) => {
  const chatService = new ChatRoomAPIService();

  chatService.unsetChatRoomToFavorite(chatRoom).then(res => {
    dispatch({
      type: SET_CHAT_ROOM_AS_UNFAVORITE,
      payload: res.data.chatRoom
    })
  })


}

export const showGroupMembers = () => (dispatch: any, getState: any) => {
  const { selectedChatRoomTWId, showMembers } = getState().chatRooms
  const { id } = getState().auth.data
  if (!showMembers) {
    dispatch(getChatRoomById(id, selectedChatRoomTWId))
  }
  dispatch({ type: SHOW_MEMBERS_TOGGLE })
}

export const fetchGroupMembers = () => (dispatch: any, getState: any) => {
  const { selectedChatRoomTWId, showMembers } = getState().chatRooms
  const { id } = getState().auth.data
  if (!showMembers) {
    dispatch(getChatRoomById(id, selectedChatRoomTWId))
  }
}


export const markRecentTabMessagesAsRead = () => (dispatch: Dispatch) => {
  dispatch({ type: MARK_RECENT_TAB_MESSAGES_AS_READ_ACTION })
}
