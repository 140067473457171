import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import StatusComponent from '../../components/ContactsListComponent/StatusComponent';
import {changeChatStatusAction} from "../../reducers/user";

const mapStateToProps = state => {
  return {
    user: state.auth.data,
  };
};



const mapDispatchToProps = dispatch => bindActionCreators({
  changeChatStatusAction,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StatusComponent);
