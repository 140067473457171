import React, { PureComponent } from 'react';
import {ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';
import { userStatuses } from '../../config';
import { chatStatusClassName, contactDisplayName } from '../../helpers/chat';
import i18n from '../../i18n';

export default class StatusComponent extends PureComponent {

  state = {
    isOpen: false,
  };

  toggleDropdown = () => {
    this.setState({isOpen: !this.state.isOpen})
  };

  handleStatusChange = (status = {}) => {
    const {changeChatStatusAction, user = {}} = this.props;
    this.toggleDropdown();
    changeChatStatusAction(user.id, status && status.value, true);
  };

  render() {
    const { user = {} } = this.props;
    const { isOpen } = this.state;
    return (
      <div className="statusSectionContainer">
        <h3>{contactDisplayName(user)}</h3>
        <ButtonDropdown isOpen={isOpen} toggle={this.toggleDropdown}>
          <DropdownToggle caret className={chatStatusClassName(user.chatStatus)}>
            {i18n.t(user.chatStatus)}
          </DropdownToggle>
          <DropdownMenu>
            {userStatuses.map((stat) => (
              <div key={stat.id}>
                <DropdownItem className={`item-${chatStatusClassName(stat.class)}`}
                              value={stat} onClick={() => this.handleStatusChange(stat)}>
                  {i18n.t(stat.value)}
                </DropdownItem>
              </div>
            ))}
          </DropdownMenu>
        </ButtonDropdown>
      </div>
    )
  }

}
