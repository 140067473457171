import React, { Component } from 'react';
import _ from 'lodash';
import { Dropdown, DropdownMenu, DropdownToggle, Tooltip } from 'reactstrap';
import { SearchComponent, CheckboxComponent } from '.';
import { appTheme } from "../../config";
import { withTranslation } from 'react-i18next';

class MultiSelectField extends Component {
  state = {
    searchValue: '',
    options: [],
    isMulti: true,
    isOpen: false,
    isTooltipOpen: false
  };

  componentDidMount() {
    if (this.props.isMulti !== undefined) {
      this.setState({ isMulti: this.props.isMulti })
    }
    this.updateOptions();
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.options, prevProps.options)) {
      this.updateOptions();
    }
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen, searchValue: "" }, () => {
      if (!this.state.isOpen) {
        this.updateOptions()
      }
    })
  };

  renderOptionsWithCheckboxes = () => {
    const { options } = this.state;
    const { t } = this.props;

    if (!options?.length) {
      return <p className={'m-0 text-center'}> {t('No_options')} </p>
    }

    return options.map((option, index) => {
      return <CheckboxComponent
        key={`${option.value}-${index}`}
        defaultChecked={option.checked}
        onChange={(checkValue) => this.onCheckboxClick(option.value, checkValue)}
        label={option.label}
      />
    })

  };

  renderOptionsWithoutCheckboxes = () => {
    const { options } = this.state;
    const { t } = this.props;

    if (!options?.length) {
      return <p className={'m-0 text-center'}> {t('No_options')} </p>
    }

    return options.map((option, index) => {
      return <p
        key={`${option.value}-${index}`}
        className={`multiSelectOptionWithoutCheckbox${option.checked ? " checked" : ""}`}
        onClick={() => {
          this.onCheckboxClick(option.value, !option.checked);
          this.setState({ isOpen: false });
        }}
      >{option.label}</p>
    })

  };

  updateOptions(newOptions) {
    let options = newOptions || _.cloneDeep(this.props.options);
    const { searchValue } = this.state;

    if (searchValue) {
      options = options.filter(option => option.label.toLowerCase().includes(searchValue.toLowerCase()))
    }

    options.sort((a, b) => {
      if (a.checked && !b.checked) {
        return -1
      }
      if (!a.checked && b.checked) {
        return 1
      }
      if (a.checked === b.checked) {
        if (a.label.toLowerCase() > b.label.toLowerCase()) {
          return 1
        }
        if (a.label.toLowerCase() < b.label.toLowerCase()) {
          return -1
        }
      }
      return 0
    });

    this.setState({ options })

  }

  onCheckboxClick = (name, value) => {
    const options = [...this.state.options];

    if (!this.state.isMulti) {
      const checkedItem = options.findIndex(option => !!option.checked && option.value !== name);

      if (checkedItem !== -1) {
        options[checkedItem].checked = false;
      }
    }

    const checkboxIndex = options.findIndex(option => option.value === name);

    if (checkboxIndex !== -1) {
      options[checkboxIndex].checked = value;
    }

    this.updateOptions(options);

    this.props.onChange(options);

  };

  handleSearch = (value) => {
    this.setState({ searchValue: value }, () => {
      if (this.props.onInputValueChange) {
        this.props.onInputValueChange(value);
      } else {
        this.updateOptions();
      }
    });
  };

  prepareTitle = () => {
    const { title, options: propsOptions, name, placeholder } = this.props;
    const { options, isTooltipOpen, searchValue } = this.state;

    const targetId = name + 'MultiSelectFieldTitle';
    const checkedItems = (searchValue ? propsOptions : options).filter(option => option.checked).reduce((acc, el) => ([...acc, el.label]), []);

    if (!checkedItems.length) {
      return <p>{placeholder || title || "Select"}</p>
    }

    const checkedItemsNames = checkedItems.join(', ');

    return (
      <>
        <p id={targetId} className='multiSelectTitle'> {checkedItemsNames}</p>
        <Tooltip placement="top"
          isOpen={isTooltipOpen}
          target={targetId}
          toggle={() => this.setState({ isTooltipOpen: !this.state.isTooltipOpen })}
        >
          {checkedItemsNames}
        </Tooltip>
      </>
    )
  };

  render() {
    const { selectedTheme, withoutCheckboxes, t } = this.props;
    const { isOpen, searchValue } = this.state;

    return (
      <Dropdown isOpen={isOpen} toggle={this.toggle} className={`${isOpen ? "open " : ""}multiSelectDropdown`}>
        <DropdownToggle>
          {this.prepareTitle()}
          <img src={`/assets/images/arrow-down${selectedTheme === appTheme.DARK ? '-dark-mode' : ''}.svg`} alt='arrow' />
        </DropdownToggle>
        <DropdownMenu>

          <SearchComponent value={searchValue} onSearch={this.handleSearch} placeholder={t("Search...")} name={"search"} />
          <div className={`optionsContainer${withoutCheckboxes ? " px-0" : " px-3"}`}>
            {withoutCheckboxes ? this.renderOptionsWithoutCheckboxes() : this.renderOptionsWithCheckboxes()}
          </div>
        </DropdownMenu>
      </Dropdown>
    )
  }
}

export default withTranslation('common')(MultiSelectField);
