import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { withRouter } from "react-router-dom";
import { InputField } from "../../../UIComponents";
import _ from 'lodash';
import { withTranslation } from "react-i18next";

class CreateItemModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemName: '',
      nameError: false,
    }
  }

  componentDidUpdate(prevProps) {

    if (!prevProps.isOpen) {
      if (this.state.itemName) {
        this.setState({ itemName: '' })
      }

      if (this.props.isOpen) {
        this.setState({ nameError: '' });
      }
    }
  }

  handleItemNameChange = e => {
    const name = e.target.value;
    const error = !_.trim(name || "") ? this.props.t('Required') : '';
    this.setState({ itemName: name, nameError: error });
  }

  render() {
    const { itemName, nameError } = this.state
    const { isOpen, toggle, pageName, submit, t } = this.props
    return (
      <Modal isOpen={isOpen} toggle={toggle} className="createItemModal" size="sm">
        <ModalHeader toggle={toggle}>{t('Create')} {pageName}</ModalHeader>
        <ModalBody className="d-flex flex-column flex-wrap px-4 pb-0">
          <p className="dataLabel">{t('Name')}</p>
          <InputField
            className='itemCreateInput'
            type='text'
            value={itemName}
            onChange={this.handleItemNameChange}
            placeholder={t("please_enter")}
            error={nameError}
          />
        </ModalBody>
        <ModalFooter className="d-flex">
          <Button color="secondary" onClick={toggle}>{t('CANCEL')}</Button>
          <Button color="primary" disabled={!!nameError || !itemName} onClick={() => {
            submit(itemName)
          }}>{t('CREATE')}</Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default withTranslation()(withRouter(CreateItemModal))