import BaseApiService from "./base";

export default class FilterApiService extends BaseApiService {

  getFilters = () => (
    this.request({
      method: 'get',
      url: '/filter',
      withCredentials: true
    })
  )

  createFilter = (data) => (
    this.request({
      method: 'post',
      url: '/filter',
      data: data,
      withCredentials: true
    })
  )


  deleteFilter = (id) => (
    this.request({
      method: 'delete',
      url: `/filter`,
      data: {id},
      withCredentials: true
    })
  )
}
