import BaseApiService from './base';

export default class FacilitiesApiService extends BaseApiService {
  getFacilities(params) {
    return this.request({
      method: 'get',
      url: '/facility',
      withCredentials: true,
      params
    });
  }

  getFacilitiesList(params) {
    return this.request({
      method: 'get',
      url: '/facility/list',
      params,
      withCredentials: false,
    });
  }

  getFacilityById(id) {
    return this.request({
      method: 'get',
      url: `/facility?${id}`,
      withCredentials: true,
    });
  }

  createFacility(data) {
    return this.request({
      method: 'post',
      url: '/facility',
      data,
      withCredentials: true
    })
  }

  updateFacility(data) {
    return this.request({
      method: 'put',
      url: '/facility',
      data,
      withCredentials: true
    })
  }

  deleteFacility(id) {
    return this.request({
      method: 'delete',
      url: '/facility',
      data: {id},
      withCredentials: true
    });
  }
}