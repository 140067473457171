import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import Settings from '../../components/Settings';
import {
  LOG_OUT_PREFIX,
  logOut,
} from "../../reducers/auth";
import {createLoadingSelector} from "../../selectors/loading";

const mapStateToProps = state => {
  return {
    user: state.auth.data,
    loggingOut: createLoadingSelector([LOG_OUT_PREFIX])(state),
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  logOut,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Settings);
