import BaseApiService from "./base";

export default class ContactsApiService extends BaseApiService {
  getContactsList(data) {
    return this.request({
      method: 'post',
      url: '/contact/list',
      data,
      withCredentials: true
    });
  }

  getPageMembers(data) {
    return this.request({
      method: 'post',
      url: '/contact/list',
      data,
      withCredentials: true
    })
  }

  getFacilityMembers(id) {
    return this.request({
      method: 'post',
      url: '/contact/list',
      data: {facilityId: id},
      withCredentials: true
    })
  }

  getSpecialtyMembers(id) {
    return this.request({
      method: 'post',
      url: '/contact/list',
      data: {specialtyId: id},
      withCredentials: true
    })
  }

  getSuffixMembers(id) {
    return this.request({
      method: 'post',
      url: '/contact/list',
      data: {suffixId: id},
      withCredentials: true
    })
  }

  getProfessionMembers(id) {
    return this.request({
      method: 'post',
      url: '/contact/list',
      data: {professionId: id},
      withCredentials: true
    })
  }
}
