import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Col, Container, Button } from "reactstrap";
import { routes } from '../../config';
import UpdatePhoneNumberComponent from "../../containers/Auth/UpdatePhoneNumberContainer";
import UpdateEmailComponent from "../../containers/Auth/UpdateEmailContainer";
import "./index.scss";

import { InputField, Loader } from '../UIComponents';
import { withTranslation } from 'react-i18next';

class EmailOrMobileConfirmComponent extends Component {
  state = {
    verifyToken: '',
    confirmData: '',
    resendTick: 0,
    phoneNumberCountryCode: '(+1) - United States',
    errors: {},
    phone: '',
    isEmailVerified: false,
    isPhoneVerified: false,
    showUpdatePhoneNumber: false,
    showUpdateEmail: false
  };

  onFieldValueChange = (name, value) => {
    this.setState({ [name]: value })
  };

  toggleUpdatePhoneNumberModal = () => {
    this.setState({ showUpdatePhoneNumber: !this.state.showUpdatePhoneNumber })
  };

  toggleUpdateEmailModal = () => {
    this.setState({ showUpdateEmail: !this.state.showUpdateEmail })
  };

  componentDidMount() {
    const { token, user, location: { pathname } } = this.props;
    !!token && this.props.history.push(routes.recent.path);
    let confirmData = "email";

    if (pathname === routes.mobileConfirm.path) {
      if (!user.tempToken) {
        this.props.history.push(routes.login.path)
      }
      confirmData = "SMS";
    }

    this.setState({ confirmData });
  }

  componentDidUpdate(prevProps) {
    const { token, history, loading, error, user } = this.props, { loading: prevLoading } = prevProps;
    if (!!token) {
      history.push(routes.recent.path);
      return
    }
    if (!loading && prevLoading && !error) {
      if (this.state.confirmData === "email" && user.tempToken && !user?.phoneVerified) {
        this.setState({ verifyToken: '', confirmData: 'SMS' });
        history.push(routes.mobileConfirm.path)
      } else {
        history.push(routes.login.path)
      }
    }
  }

  handleVerifyClick = () => {
    const { verifyToken, confirmData } = this.state;
    if (confirmData === "email") {
      this.props.verifyEmailAction({ verifyToken });
      this.setState({ isEmailVerified: true });
    } else {
      this.props.verifyMobileAction({ verifyToken });
      this.setState({ isEmailVerified: true });
    }
  };

  handleResendClick = () => {
    const { confirmData } = this.state;
    const { user } = this.props;
    this.startResendTimer();
    if (confirmData === "email") {
      this.props.resendEmailVerifyAction(user.email);
    } else {
      this.props.resendPhoneVerifyAction(user);
    }
  };

  startResendTimer = () => {
    this.setState({ resendTick: 10 });
    setTimeout(this.handleResendTimerTick, 1000);
  };

  handleResendTimerTick = () => {
    const currentTick = this.state.resendTick;
    this.setState({ resendTick: currentTick - 1 });
    if (currentTick > 0) {
      setTimeout(this.handleResendTimerTick, 1000);
    }
  };

  checkResendValidity = () => {
    const { confirmData, isEmailVerified, isPhoneVerified } = this.state;
    return (confirmData === "email" && isEmailVerified === true) || (confirmData === "SMS" && isPhoneVerified === true);
  };

  render() {
    const { verifyToken, confirmData, resendTick, showUpdatePhoneNumber, showUpdateEmail } = this.state;
    const { loading, t } = this.props;

    if (loading) {
      return (
        <Container fluid className="pageContainer bg-light">
          <Loader />
        </Container>
      )
    }

    return (
      <Container fluid className="authPageContainer pageContainer bg-light align-items-center pt-64">
        <div className="contentContainer w-100 py-20 py-5 justify-content-center d-flex flex-column align-items-center">
          <h1 className="authPageTitle col-md-8 col-12 text-center">{t('auth.confirm_message')}</h1>
          <p className="authPageSubTitle">{t('auth.enter_code_sent', { confirmData })}</p>

          <Col lg={10} xs={12} className="d-flex flex-column align-items-center">
            <Col md={5} xs={12} className="px-0">
              <InputField
                id="verifyToken"
                key={`verifyToken-${confirmData}`}
                type="text"
                placeholder={t("please_enter_token")}
                value={verifyToken}
                onChange={(event) => this.onFieldValueChange('verifyToken', event.target.value)}
                onKeyPress={(event) => (event.charCode === 13) && this.handleVerifyClick()}
              />
            </Col>

            {confirmData === "email" &&
              <Col md={3} xs={12} className="d-flex px-0 w-100 mt-2 mb-2">
                <Button disabled={showUpdateEmail} className="submitButton w-100"
                  onClick={this.toggleUpdateEmailModal}>{t('auth.change_email')}</Button>
              </Col>
            }

            {confirmData === "SMS" &&
              <Col md={3} xs={12} className="d-flex px-0 w-100 mt-2 mb-2">
                <Button disabled={showUpdatePhoneNumber} className="submitButton w-100"
                  onClick={this.toggleUpdatePhoneNumberModal}>{t('auth.change_phone')}</Button>
              </Col>
            }

            <Col md={3} xs={12} className="d-flex px-0 w-100 mt-2">
              <Button disabled={!(/^[0-9]{6}$/).test(verifyToken)} className="submitButton w-100"
                onClick={this.handleVerifyClick}>{t('NEXT')}</Button>
            </Col>
            <Col md={3} xs={12} className="d-flex px-0 w-100 mt-2">
              <Button disabled={(resendTick > 0) || this.checkResendValidity()} className="submitButton w-100"
                onClick={this.handleResendClick}>{t('RESEND')} {resendTick > 0 ? '(' + resendTick + ')' : ''}</Button>
            </Col>

          </Col>
        </div>
        {showUpdatePhoneNumber &&
          <UpdatePhoneNumberComponent toggle={() => this.toggleUpdatePhoneNumberModal()} />
        }
        {showUpdateEmail &&
          <UpdateEmailComponent toggle={() => this.toggleUpdateEmailModal()} />
        }
      </Container>
    );
  }
}

export default withTranslation('common')(withRouter(EmailOrMobileConfirmComponent));
