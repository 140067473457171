import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { getUserLogsList, exportUserLogsList, setUserLogsPage } from "../../../reducers/user";
import UserLogsComponent from "../../../components/Admin/Components/User/UserLogsComponent";

const mapStateToProps = state => {
  const pageCount = Math.ceil( (state.users.totalLogs || 0) / (state.users.pageSize || 1));
  return {
    pageCount,
    pageSize: state.users.pageSize,
    userLogs: state.users.logs,
    page: state.users.userLogsPage,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getUserLogsList,
  exportUserLogsList,
  setUserLogsPage
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserLogsComponent);
