import React, { useState, useRef, useEffect, useMemo } from 'react'
import "./AudioPlayer.scss";

import pause from '../../../assets/icons/audio-pause.svg'
import play from '../../../assets/icons/audio-play.svg'
import { imgSource } from '../helpers/ImageS3';
import { isSafari } from '../../../helpers/function';

const AudioPlayer: React.FC<{ src: string, duration: number, onClick?: any, isAudioPreview?: boolean }> = ({ src, duration: time, onClick, isAudioPreview }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [duration, setDuration] = useState(time);
    const [currentTime, setCurrentTime] = useState(0);
    useEffect(() => {
        setDuration(time)
    }, [time])


    const audioPlayer = useRef<any>();
    const progressBar = useRef<any>();
    const animationRef = useRef<any>();

    useEffect(() => {
        const seconds = Math.floor(audioPlayer.current.duration);
        if (seconds > 0 && seconds < 10000) {
            setDuration(seconds);
            progressBar.current.max = seconds;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audioPlayer?.current?.loadedmetadata, audioPlayer?.current?.readyState, audioPlayer?.current?.duration]);

    const calculateTime = (secs: any) => {
        const minutes = Math.floor(secs / 60);
        const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
        const seconds = Math.floor(secs % 60);
        const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
        return `${returnedMinutes}:${returnedSeconds}`;
    }

    const togglePlayPause = () => {
        if (isAudioPreview && isSafari) return;

        const prevValue = isPlaying;
        setIsPlaying(!prevValue);
        if (!prevValue) {
            try {
                audioPlayer.current.play();
            } catch { }
            animationRef.current = requestAnimationFrame(whilePlaying)
        } else {
            audioPlayer.current.pause();
            cancelAnimationFrame(animationRef.current);
        }
    }

    const whilePlaying = () => {
        if (!progressBar.current) return;
        progressBar.current.value = audioPlayer.current?.currentTime;
        changePlayerCurrentTime();
        animationRef.current = requestAnimationFrame(whilePlaying);
    }

    const changeRange = () => {
        audioPlayer.current.currentTime = progressBar.current.value;
        changePlayerCurrentTime();
    }

    const changePlayerCurrentTime = () => {
        progressBar.current.style.setProperty('--seek-before-width', `${progressBar.current.value / duration * 100}%`)
        setCurrentTime(progressBar.current.value);
    }

    const handleAudioEnd = () => {
        setIsPlaying(false)
        audioPlayer.current.currentTime = 0;
        changePlayerCurrentTime();
    }

    return (
        <div className="AudioPlayer">
            <audio ref={audioPlayer} src={src} preload="metadata" onEnded={handleAudioEnd} hidden></audio>
            <img
                src={!isPlaying ? play : pause}
                className={"play_pause" + (isSafari && isAudioPreview ? ' disabled' : '')}
                onClick={togglePlayPause}
                title={isPlaying ? "Pause" : "Play"}
                data-testid="ar_pause"
                alt=""
            />

            <div onClick={onClick}>
                <input type="range" onClick={(e) => e.stopPropagation()} className="progress" max={time || 0} defaultValue="0" ref={progressBar} onChange={changeRange} />
            </div>
            <div className="timer" onClick={onClick}>{(currentTime > 0 || isPlaying) ? calculateTime(time - currentTime) : calculateTime(duration)}</div>

            {/* <div className="duration">{(duration && !isNaN(duration)) && calculateTime(duration)}</div> */}
        </div>
    )
}

export const S3AudioPlayer: React.FC<{ s3key: string, duration: number, onClick?: any }> = ({ s3key, duration, onClick }) => {
    const src = useMemo(() => {
        return imgSource(s3key);
    }, [s3key])

    return <AudioPlayer src={src} duration={duration} onClick={onClick} />
}

export default AudioPlayer