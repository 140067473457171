import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Button, Container} from 'reactstrap';
import _ from 'lodash';
import '../../index.scss';
import { Loader, SettingsIconDropdown, UserAvatar } from "../../../UIComponents";
import { routes } from "../../../../config";
import ResetOrDeleteUserModal from "../Modals/ResetOrDeleteUserComponent";
import PersonalInformationComponent from "../../../../containers/Admin/PersonalInformationContainer";
import { withTranslation } from 'react-i18next';

class UserComponent extends Component {
  state = {
    showResetPasswordModal: false,
    showDeletePasswordModal: false
  };

  componentDidMount() {
    const {match: {params: {id = ""}}} = this.props;
    if (id !== 'new') {
      this.props.getUserById(id);
      this.setState({isNewUser: false});
    } else {
      this.setState({isNewUser: true});
    }
  }

  componentWillUnmount() {
    this.props.reset()
  }

  componentDidUpdate(prevProps) {
    const {user, history, updatingUser, errorOnUpdatingUser, deletingUser, errorOnDeletingUser, creatingUser, errorOnCreatingUser} = this.props,
      {user: prevUser, updatingUser: prevUpdatingUser, deletingUser: prevDeletingUser, creatingUser: prevCreatingUser} = prevProps,
      {showResetPasswordModal} = this.state;

    if (showResetPasswordModal && !updatingUser && prevUpdatingUser && !errorOnUpdatingUser) {
      this.toggleResetPasswordModal()
    }

    if (
      (!deletingUser && prevDeletingUser && !errorOnDeletingUser) ||
      (!creatingUser && prevCreatingUser && !errorOnCreatingUser)
    ) {
      history.push(`/admin/users`)
    } else if (user?.id !== prevUser?.id) {
      history.push(`${routes.users.path}/${user.id}`)
    }
  }

  updatePersonalInformation = (data) => {
    const {match: {params: {id = ""}}} = this.props;
    if (id === 'new') {
      this.props.createUser(data)
    } else {
      this.props.updateUser({...data, id})
    }
  };

  toggleResetPasswordModal = () => {
    this.setState({showResetPasswordModal: !this.state.showResetPasswordModal})
  };

  toggleDeleteUserModal = () => {
    this.setState({showDeletePasswordModal: !this.state.showDeletePasswordModal})
  };

  handleUpdateAvatar(file) {
    this.props.changeUserAvatar(file, this.props.user.id);
  }

  handleDeleteAvatar() {
    this.props.removeUserAvatar(this.props.user.id);
  }

  render() {
    const {
      user = {},
      history,
      updatingUser,
      loading,
      changingUserAvatar,
      removingUserAvatar,
      t
    } = this.props;
    const {showResetPasswordModal, showDeletePasswordModal} = this.state;
    return (
      <Container fluid className="pageContainer adminContainer">
        <div className="contentContainer adminComponent d-flex flex-column px-0">
          <div className="headerContainer userProfile">
            <div className="d-flex align-items-start">
              <Button className="font-weight-bold backButton"
                      onClick={() => history.push(routes.users.path)}> <img src={"/assets/images/ui-arrow-back.svg"} alt={"arrow back"}/>
              </Button>
              <h1>{t('admin.user_profile')}</h1>
            </div>
            {!_.isEmpty(user) &&
            <>
              <div className="userPageAvatarContainer">
                <div className="accountSettingsAvatarContainer">
                  {changingUserAvatar || removingUserAvatar ? <Loader/> : <UserAvatar user={user} className="big"/>}
                  <div className="avatarSettingsButton">
                    <SettingsIconDropdown
                      hasAvatar={!!user.avatar}
                      onUpdate={this.handleUpdateAvatar.bind(this)}
                      onDelete={this.handleDeleteAvatar.bind(this)}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column user-action-cont">
                <Button onClick={this.toggleDeleteUserModal}
                        className="linkButton font-weight-bold"
                >
                  <img width="20" height="20" src={"/assets/images/icn-delete-user.svg"} alt=""/>
                  {t('admin.DEL_USER')}
                </Button>
                <Button onClick={this.toggleResetPasswordModal}
                        className="linkButton font-weight-bold"
                >
                  <img width="20" height="20" src={"/assets/images/icn-reset password.svg"} alt=""/>
                  {t('admin.RESET_PASS')}
                </Button>
              </div>
            </>
            }
          </div>
          <PersonalInformationComponent
            user={{...user, unverifiedPhone: false, unverifiedEmail: false}}
            loading={updatingUser || loading}
            isNewUser={this.state.isNewUser}
            updatePersonalInformation={this.updatePersonalInformation}
            isSessionTimeout
          />
          {showResetPasswordModal &&
          <ResetOrDeleteUserModal
            deleteUser={false}
            isOpen={showResetPasswordModal}
            user={user}
            toggle={this.toggleResetPasswordModal}
            submit={() => this.updatePersonalInformation({...user, changePassword: true})}
          />
          }
          {showDeletePasswordModal &&
          <ResetOrDeleteUserModal
            deleteUser={true}
            isOpen={showDeletePasswordModal}
            user={user}
            toggle={this.toggleDeleteUserModal}
            submit={() => this.props.deleteUser(user.id)}
          />
          }
        </div>
      </Container>
    );
  }
}

export default withTranslation('common')(withRouter(UserComponent));