import {constantMessages, userRoles} from "../config";
import ToastrService from "../services/toastrService";
import SuffixesApiService from "../services/api/suffixes";

export const FETCH_SUFFIXES_LIST_PREFIX = 'suffixes/FETCH_SUFFIXES_LIST_PREFIX'
export const FETCH_SUFFIXES_LIST_REQUEST_ACTION = FETCH_SUFFIXES_LIST_PREFIX + '_REQUEST_ACTION';
export const FETCH_SUFFIXES_LIST_SUCCESS_ACTION = FETCH_SUFFIXES_LIST_PREFIX + '_SUCCESS_ACTION';
export const FETCH_SUFFIXES_LIST_FAILURE_ACTION = FETCH_SUFFIXES_LIST_PREFIX + '_FAILURE_ACTION';

export const GET_SUFFIX_BY_ID_PREFIX = 'suffixes/GET_SUFFIX_BY_ID_PREFIX';
export const GET_SUFFIX_BY_ID_REQUEST_ACTION = GET_SUFFIX_BY_ID_PREFIX + '_REQUEST_ACTION';
export const GET_SUFFIX_BY_ID_SUCCESS_ACTION = GET_SUFFIX_BY_ID_PREFIX + '_SUCCESS_ACTION';
export const GET_SUFFIX_BY_ID_FAILURE_ACTION = GET_SUFFIX_BY_ID_PREFIX + '_FAILURE_ACTION';

export const CREATE_SUFFIX_PREFIX = 'suffixes/CREATE_SUFFIX_PREFIX'
export const CREATE_SUFFIX_REQUEST_ACTION = CREATE_SUFFIX_PREFIX + '_REQUEST_ACTION';
export const CREATE_SUFFIX_SUCCESS_ACTION = CREATE_SUFFIX_PREFIX + '_SUCCESS_ACTION';
export const CREATE_SUFFIX_FAILURE_ACTION = CREATE_SUFFIX_PREFIX + '_FAILURE_ACTION';

export const UPDATE_SUFFIX_PREFIX = 'suffixes/UPDATE_SUFFIX_PREFIX'
export const UPDATE_SUFFIX_REQUEST_ACTION = UPDATE_SUFFIX_PREFIX + '_REQUEST_ACTION';
export const UPDATE_SUFFIX_SUCCESS_ACTION = UPDATE_SUFFIX_PREFIX + '_SUCCESS_ACTION';
export const UPDATE_SUFFIX_FAILURE_ACTION = UPDATE_SUFFIX_PREFIX + '_FAILURE_ACTION';

export const DELETE_SUFFIX_PREFIX = 'suffixes/DELETE_SUFFIX_PREFIX'
export const DELETE_SUFFIX_REQUEST_ACTION = DELETE_SUFFIX_PREFIX + '_REQUEST_ACTION';
export const DELETE_SUFFIX_SUCCESS_ACTION = DELETE_SUFFIX_PREFIX + '_SUCCESS_ACTION';
export const DELETE_SUFFIX_FAILURE_ACTION = DELETE_SUFFIX_PREFIX + '_FAILURE_ACTION';

const initialState = {
  suffixesList: [],
  page: null,
  pageSize: null,
  total: null,
  suffix: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SUFFIXES_LIST_SUCCESS_ACTION:
      const data = action.payload.data
      return {
        ...state,
        page: data.page,
        pageSize: data.pageSize,
        suffixesList: [...data.results],
        total: data.total
      }
    case GET_SUFFIX_BY_ID_SUCCESS_ACTION:
      return {
        ...state,
        suffix: { ...action.payload.suffix }
      }
    case CREATE_SUFFIX_SUCCESS_ACTION:
      return {
        ...state,
        suffixesList: [...state.suffixesList, {...action.payload.suffix}]
      }
    case UPDATE_SUFFIX_SUCCESS_ACTION:
      const suffixIndex = state.suffixesList.findIndex(suf => suf.id === action.payload.suffix?.id)
      const updatedSuffixList = [...state.suffixesList]
      updatedSuffixList[suffixIndex] = action.payload.suffix
      return {
        ...state,
        suffixesList: [...updatedSuffixList],
        suffix: action.payload.suffix
      }
    case DELETE_SUFFIX_SUCCESS_ACTION:
      const updatedSuffixesList = state.suffixesList.filter(d => d.id !== action.payload.id)
      return {
        ...state,
        suffixesList: [...updatedSuffixesList]
      }
    default:
      return state
  }
}

export function getSuffixesList(params = { keyword: '', page: 1, pageSize: 10}) {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_SUFFIXES_LIST_REQUEST_ACTION });
    const suffixesService = new SuffixesApiService();
    const userData = getState().auth?.data;
    const isAdmin = userData?.role === userRoles.ADMIN || userData?.role?.name === userRoles.ADMIN;
    suffixesService[isAdmin ? "getSuffixes" : "getSuffixesList"](params)
      .then(({ data = {} }) => {
        const response = data || {};
        dispatch({ type: FETCH_SUFFIXES_LIST_SUCCESS_ACTION, payload: { data: response } });
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: FETCH_SUFFIXES_LIST_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message)
      })
  }
}

export function getSuffixById(id) {
  return dispatch => {
    dispatch({ type: GET_SUFFIX_BY_ID_REQUEST_ACTION});
    const suffixesService = new SuffixesApiService();
    suffixesService.getSuffixById(id)
      .then(({ data = {} }) => {
        const suffixes = data.results || {}
        dispatch({type: GET_SUFFIX_BY_ID_SUCCESS_ACTION, payload: { suffix: suffixes.find(d => d.id === id) }});
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: GET_SUFFIX_BY_ID_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message)
      })
  }
}

export function createSuffix(name, params) {
  return dispatch => {
    dispatch({ type: CREATE_SUFFIX_REQUEST_ACTION })
    const suffixesService = new SuffixesApiService();
    suffixesService.createSuffix({ name })
      .then(({data = {}}) => {
        dispatch(getSuffixesList(params))
        // const {data: suffix} = data || []
        // dispatch({type: CREATE_SUFFIX_SUCCESS_ACTION, payload: { suffix }})
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: CREATE_SUFFIX_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message)
      })
  }
}

export function updateSuffix(data) {
  return dispatch => {
    dispatch({ type: UPDATE_SUFFIX_REQUEST_ACTION })
    const suffixesService = new SuffixesApiService();
    suffixesService.updateSuffix(data)
      .then(({data = {}}) => {
        const suffix = data || {}
        dispatch({type: UPDATE_SUFFIX_SUCCESS_ACTION, payload: { suffix }})
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: UPDATE_SUFFIX_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message)
      })
  }
}

export function deleteSuffix(id, params) {
  return dispatch => {
    dispatch({ type: DELETE_SUFFIX_REQUEST_ACTION })
    const suffixesService = new SuffixesApiService();
    suffixesService.deleteSuffix(id)
      .then(({data = {}}) => {
        // const department = data || {}
        // dispatch({type: DELETE_SUFFIX_SUCCESS_ACTION, payload: { id }})
        dispatch(getSuffixesList(params))
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: DELETE_SUFFIX_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message)
      })
  }
}
