import React, {PureComponent} from 'react';
import {ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';

class StatusElement extends PureComponent {
  state = {
    isOpen: false
  };

  toggleDropdown = () => {
    if (this.props.disabled) return;
    this.setState({isOpen: !this.state.isOpen})
  };

  render() {
    const {
      className = "",
      status = {},
      onChange,
      options = [],
      disabled = false
    } = this.props;
    const {isOpen} = this.state;

    return (
      <div className={`statusButtonContainer ${className || ""}`}>
        <ButtonDropdown
          isOpen={isOpen}
          toggle={this.toggleDropdown}
        >
          <DropdownToggle caret className={`${status.class || ""} ${disabled ? ' disabled' : ''}`}>
            {status.value || "-"}
          </DropdownToggle>
          <DropdownMenu>
            {options.map((option, index) => (
              <div key={index}>
                <DropdownItem
                  className={`item-${option.class}`}
                  value={option}
                  onClick={() => {
                    onChange(option);
                    this.toggleDropdown();
                  }}
                >
                  {option.value}
                </DropdownItem>
              </div>
            ))}
          </DropdownMenu>
        </ButtonDropdown>
      </div>
    )
  }
}

export default StatusElement;
