import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { createLoadingSelector } from "../../../selectors/loading";
import { FETCH_USERS_LIST_PREFIX, getUsersList } from "../../../reducers/user";
import AllUsersComponent from "../../../components/Admin/Components/User/AllUsersComponent";


const mapStateToProps = state => {
  const pageCount = Math.ceil( (state.users.total || 0) / (state.users.pageSize || 1));

  return {
    pageCount,
    users: state.users.users,
    loadingUsersList: createLoadingSelector([FETCH_USERS_LIST_PREFIX])(state)
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getUsersList
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllUsersComponent);
