import React from 'react';
import { getUserAvatarLetters } from "../../utils/function-utils";
import { AVATAR_BASE, STATIC_BASE } from "../../config";

const UserAvatar = ({ user = {}, className = '', showGroupMembersToggle, chatRoomType }) => {
  const onAvatarClick = () => {
    if (chatRoomType === 'group') {
      showGroupMembersToggle()
    }
  }
  let base = STATIC_BASE;
  if (user.avatar && !user.avatar.isGenerated) {
    base = AVATAR_BASE;
  }
  // const avatar = _.get(user, 'avatar.path', user.avatar.path, user.avatar);
  return <div className={`avatarContainer ${className}`}>
    <div className='avatarHolder' onClick={onAvatarClick}>
      {user.avatar ? <img src={`${base}${user.avatar.path || user.avatar}`} alt='' /> : getUserAvatarLetters(user)}
    </div>
  </div>
};

export default UserAvatar;
