import React, { createRef, Component } from 'react';
import VIMicOn from "../../../assets/icons/microphone-outline.svg";
import VIMicOff from "../../../assets/icons/microphone-off.svg";
import _ from "lodash";
import VIFullScreen from "../../../assets/icons/fullscreen.svg";
import { withTranslation } from 'react-i18next';

class TrackComponent extends Component {
  trackRef = createRef(null);

  state = {
    isFullScreen: false
  }

  componentDidMount(){
    console.log("DID MOUNT")
    const { publication } = this.props;
    this.publicationOnSubscribed()
    publication.on('subscribed', this.publicationOnSubscribed);
    publication.on('unsubscribed', this.publicationOnUnsubscribed);
  };

  componentDidUpdate(prevProps){
    const { publication } = this.props;
    const { publication: prevPublication } = prevProps;

    if (prevPublication.trackSid !== publication.trackSid) {
      if (prevPublication && prevPublication.track) {
        prevPublication.track.detach(this.trackRef.current);
        prevPublication.off('subscribed', this.publicationOnSubscribed);
        prevPublication.off('unsubscribed', this.publicationOnUnsubscribed);
      }
      this.publicationOnSubscribed()
      publication.on('subscribed', this.publicationOnSubscribed);
      publication.on('unsubscribed', this.publicationOnUnsubscribed);
    }
  };

  componentWillUnmount(){
    const { publication } = this.props;
    publication.off('subscribed', this.publicationOnSubscribed);
    publication.off('unsubscribed', this.publicationOnUnsubscribed);
  }

  publicationOnSubscribed = () => {
    const { publication } = this.props;
    if (publication && publication.track) {
      const el = this.trackRef.current;
      if (publication.kind === 'video') {
        el.muted = true;
      }
      publication.track.attach(el);
    }
  }

  publicationOnUnsubscribed = () => {
    const { publication } = this.props;
    if (publication && publication.track) {
      publication.track.detach(this.trackRef.current);
    }
  }

  toggleFullScreen = () => {
    const { publication } = this.props;
    if (publication && publication.track) {
      const el = this.trackRef.current;
      if (publication.kind === 'video') {
        if (el.webkitEnterFullScreen) {
          el.webkitEnterFullScreen();
        } else if (el.requestFullscreen) {
          el.requestFullscreen();
        } else if (el.mozRequestFullScreen) {
          el.mozRequestFullScreen();
        } else if (el.webkitRequestFullscreen) {
          el.webkitRequestFullscreen();
        } else if (el.msRequestFullscreen) {
          el.msRequestFullscreen();
        }
      }
    }
  }

  renderParticipantControlButtons = () => {
    if(this.props.isLocalParticipant) {
      return
    }
    const { publication = {} } = this.props;

    const {isFullScreen} = this.state;

    return (
      <div className="participantControlButtonsContainer">
        {
          publication.kind === 'video' &&
          <button title={`${isFullScreen ? "Exit from full screen" : "Full Screen"}`}
                  className={`${isFullScreen ? 'active ' : ''} btnMuted`}
                  onClick={_.throttle(() => this.toggleFullScreen(), 1000)}>
            <img src={VIFullScreen} alt="Full Screen" />
          </button>
        }
      </div>
    )
  };

  render () {
    const { publication = {}, isMuted = false } = this.props;
    const className = publication.trackName === 'screen' ? 'screen' : publication.kind;
    return (
      <>
      {
        publication.kind === 'video' ?
          <video muted={true} ref={this.trackRef} className={className} />
         :
          <audio muted={isMuted} ref={this.trackRef} className={className} />
      }
        {this.renderParticipantControlButtons()}
      </>
    )
  }
}


export default withTranslation('common')(TrackComponent)