import {constantMessages, userRoles} from "../config";
import ToastrService from "../services/toastrService";
import ProfessionApiService from "../services/api/professions";

export const FETCH_PROFESSIONS_LIST_PREFIX = 'professions/FETCH_PROFESSIONS_LIST_PREFIX'
export const FETCH_PROFESSIONS_LIST_REQUEST_ACTION = FETCH_PROFESSIONS_LIST_PREFIX + '_REQUEST_ACTION';
export const FETCH_PROFESSIONS_LIST_SUCCESS_ACTION = FETCH_PROFESSIONS_LIST_PREFIX + '_SUCCESS_ACTION';
export const FETCH_PROFESSIONS_LIST_FAILURE_ACTION = FETCH_PROFESSIONS_LIST_PREFIX + '_FAILURE_ACTION';

export const FETCH_ALL_PROFESSIONS_LIST_PREFIX = 'professions/FETCH_ALL_PROFESSIONS_LIST_PREFIX'
export const FETCH_ALL_PROFESSIONS_LIST_REQUEST_ACTION = FETCH_ALL_PROFESSIONS_LIST_PREFIX + '_REQUEST_ACTION';
export const FETCH_ALL_PROFESSIONS_LIST_SUCCESS_ACTION = FETCH_ALL_PROFESSIONS_LIST_PREFIX + '_SUCCESS_ACTION';
export const FETCH_ALL_PROFESSIONS_LIST_FAILURE_ACTION = FETCH_ALL_PROFESSIONS_LIST_PREFIX + '_FAILURE_ACTION';

export const GET_PROFESSION_BY_ID_PREFIX = 'professions/GET_PROFESSION_BY_ID_PREFIX';
export const GET_PROFESSION_BY_ID_REQUEST_ACTION = GET_PROFESSION_BY_ID_PREFIX + '_REQUEST_ACTION';
export const GET_PROFESSION_BY_ID_SUCCESS_ACTION = GET_PROFESSION_BY_ID_PREFIX + '_SUCCESS_ACTION';
export const GET_PROFESSION_BY_ID_FAILURE_ACTION = GET_PROFESSION_BY_ID_PREFIX + '_FAILURE_ACTION';

export const CREATE_PROFESSION_PREFIX = 'professions/CREATE_PROFESSION_PREFIX'
export const CREATE_PROFESSION_REQUEST_ACTION = CREATE_PROFESSION_PREFIX + '_REQUEST_ACTION';
export const CREATE_PROFESSION_SUCCESS_ACTION = CREATE_PROFESSION_PREFIX + '_SUCCESS_ACTION';
export const CREATE_PROFESSION_FAILURE_ACTION = CREATE_PROFESSION_PREFIX + '_FAILURE_ACTION';

export const UPDATE_PROFESSION_PREFIX = 'professions/UPDATE_PROFESSION_PREFIX'
export const UPDATE_PROFESSION_REQUEST_ACTION = UPDATE_PROFESSION_PREFIX + '_REQUEST_ACTION';
export const UPDATE_PROFESSION_SUCCESS_ACTION = UPDATE_PROFESSION_PREFIX + '_SUCCESS_ACTION';
export const UPDATE_PROFESSION_FAILURE_ACTION = UPDATE_PROFESSION_PREFIX + '_FAILURE_ACTION';

export const DELETE_PROFESSION_PREFIX = 'professions/DELETE_PROFESSION_PREFIX'
export const DELETE_PROFESSION_REQUEST_ACTION = DELETE_PROFESSION_PREFIX + '_REQUEST_ACTION';
export const DELETE_PROFESSION_SUCCESS_ACTION = DELETE_PROFESSION_PREFIX + '_SUCCESS_ACTION';
export const DELETE_PROFESSION_FAILURE_ACTION = DELETE_PROFESSION_PREFIX + '_FAILURE_ACTION';

const initialState = {
  professionsList: [],
  allProfessionsList: [],
  page: null,
  pageSize: null,
  total: null,
  profession: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROFESSIONS_LIST_SUCCESS_ACTION:
      const data = action.payload.data
      return {
        ...state,
        page: data.page,
        pageSize: data.pageSize,
        professionsList: [...data.results],
        total: data.total
      }
    case FETCH_ALL_PROFESSIONS_LIST_SUCCESS_ACTION:
      const allData = action.payload.data
      return {
        ...state,
        allProfessionsList: allData.results,
      }
    case GET_PROFESSION_BY_ID_SUCCESS_ACTION:
      return {
        ...state,
        profession: { ...action.payload.profession }
      }
    case CREATE_PROFESSION_SUCCESS_ACTION:
      return {
        ...state,
        professionsList: [...state.professionsList, {...action.payload.profession}]
      }
    case UPDATE_PROFESSION_SUCCESS_ACTION:
      const professionIndex = state.professionsList.findIndex(prof => prof.id === action.payload.profession?.id)
      const updatedProfessionsList = [...state.professionsList]
      updatedProfessionsList[professionIndex] = action.payload.profession
      return {
        ...state,
        professionsList: [...updatedProfessionsList],
        profession: action.payload.profession
      }
    case DELETE_PROFESSION_SUCCESS_ACTION:
      const professionsListAfterRemove = state.professionsList.filter(d => d.id !== action.payload.id)
      return {
        ...state,
        professionsList: [...professionsListAfterRemove]
      }
    default:
      return state
  }
}

export function getProfessions(params = { keyword: '', page: 1, pageSize: 10 }) {
  return (dispatch, getState) => {
    const isAllList = params.all === 1;
    dispatch({ type: isAllList ? FETCH_ALL_PROFESSIONS_LIST_REQUEST_ACTION : FETCH_PROFESSIONS_LIST_REQUEST_ACTION });
    const professionsService = new ProfessionApiService();
    const userData = getState().auth?.data;
    const isAdmin = userData?.role === userRoles.ADMIN || userData?.role?.name === userRoles.ADMIN;
    return professionsService[isAdmin ? "getProfessions" : "getProfessionsList"](params)
      .then(({ data = {} }) => {
        const response = data || [];
        dispatch({ type: isAllList ? FETCH_ALL_PROFESSIONS_LIST_SUCCESS_ACTION : FETCH_PROFESSIONS_LIST_SUCCESS_ACTION, payload: { data: response } });
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: isAllList ? FETCH_ALL_PROFESSIONS_LIST_FAILURE_ACTION : FETCH_PROFESSIONS_LIST_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message)
      })
  }
}

export function getProfessionById(id) {
  return dispatch => {
    dispatch({ type: GET_PROFESSION_BY_ID_REQUEST_ACTION});
    const professionsService = new ProfessionApiService();
    professionsService.getProfessionById(id)
      .then(({ data = {} }) => {
        const professions = data.results || {}
        dispatch({type: GET_PROFESSION_BY_ID_SUCCESS_ACTION, payload: { profession: professions.find(d => d.id === id) }});
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: GET_PROFESSION_BY_ID_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message)
      })
  }
}

export function createProfession(name, params) {
  return dispatch => {
    dispatch({ type: CREATE_PROFESSION_REQUEST_ACTION })
    const professionsService = new ProfessionApiService();
    professionsService.createProfession({ name })
      .then(({data = {}}) => {
        dispatch(getProfessions(params))
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: CREATE_PROFESSION_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message)
      })
  }
}

export function updateProfession(data) {
  return dispatch => {
    dispatch({ type: UPDATE_PROFESSION_REQUEST_ACTION })
    const professionsService = new ProfessionApiService();
    professionsService.updateProfession(data)
      .then(({data = {}}) => {
        const profession = data || {}
        dispatch({type: UPDATE_PROFESSION_SUCCESS_ACTION, payload: { profession }})
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: UPDATE_PROFESSION_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message)
      })
  }
}

export function deleteProfession(id, params) {
  return dispatch => {
    dispatch({ type: DELETE_PROFESSION_REQUEST_ACTION })
    const professionsService = new ProfessionApiService();
    professionsService.deleteProfession(id)
      .then(({data = {}}) => {
        dispatch(getProfessions(params))
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: DELETE_PROFESSION_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message)
      })
  }
}
