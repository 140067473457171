import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { deleteDepartment, getDepartmentById, updateDepartment } from "../../../reducers/departments";
import EditDepartmentComponent from "../../../components/Admin/Components/Departments/EditDepartmentComponent";
import { getPageMembers, removePageMember, updatePageMembers } from "../../../reducers/contacts";
import { updateUser, changeUserStatus } from "../../../reducers/user";


const mapStateToProps = state => {
  const departmentId = state.departments.department.id;
  const selectedTheme = state.auth.data?.settings?.theme;
  return {
    selectedTheme,
    users: state.users.users,
    usersLength: state.users.total,
    department: state.departments.department,
    members: state.contacts.pageMembers[departmentId],
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getDepartmentById,
  updateDepartment,
  getPageMembers,
  removePageMember,
  updateUser,
  updatePageMembers,
  changeUserStatus,
  deleteDepartment
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditDepartmentComponent);
