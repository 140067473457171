import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { createFacility, deleteFacility, getFacilitiesList } from "../../../reducers/facilities";
import FacilitiesComponent from "../../../components/Admin/Components/Facilities/FacilitiesComponent";


const mapStateToProps = state => {
  const pageCount = Math.ceil( (state.facilities.total || 0) / (state.facilities.pageSize || 1));
  const selectedTheme = state.auth.data?.settings?.theme;
  return {
    selectedTheme,
    pageCount,
    facilitiesList: state.facilities.facilitiesList,
    members: state.contacts.pageMembers
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getFacilitiesList,
  createFacility,
  deleteFacility
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FacilitiesComponent);
