import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import EditFacilityComponent from "../../../components/Admin/Components/Facilities/EditFacilityComponent";
import { deleteFacility, getFacilityById, updateFacility } from "../../../reducers/facilities";
import { getPageMembers, removePageMember, updatePageMembers } from "../../../reducers/contacts";
import { updateUser, changeUserStatus } from "../../../reducers/user";

const mapStateToProps = state => {
  const facilityId = state.facilities.facility.id;
  const selectedTheme = state.auth.data?.settings?.theme;
  return {
    selectedTheme,
    users: state.users.users,
    usersLength: state.users.total,
    facility: state.facilities.facility,
    members: state.contacts.pageMembers[facilityId],
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getFacilityById,
  updateFacility,
  getPageMembers,
  updateUser,
  updatePageMembers,
  removePageMember,
  changeUserStatus,
  deleteFacility
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditFacilityComponent);
