import { connect } from 'react-redux';
import HeaderComponent from '../../components/ContactsListComponent/HeaderComponent';

const mapStateToProps = state => {
  const selectedTheme = state.auth.data?.settings?.theme;

  return {
    selectedTheme,
  };
};

export default connect(
  mapStateToProps,
  null
)(HeaderComponent);
