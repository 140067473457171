import axios from 'axios';
import { constantMessages } from "../../config";
import store from '../../store';
import { logOut } from '../../reducers/auth';
import { APIError } from "../../errors/APIError";
import ToastrService from "../toastrService";
import i18n from '../../i18n';

/**
 * Base API Service is for handling all api requests, also
 * responses and errors from that requests
 *
 *
 * All other services in /services/api folder will extend from
 * BaseApiService and will use it's methods to make the requests
 */
export default class BaseApiService {
  handleResponse(response) {
    return response?.data || {};
  }

  handleError(error) {
    if (error.response && (error.response.status === 407 || error.response.status === 401)) {
      store.dispatch(logOut({ isTokenExpired: true }));
    }
    if (error.response?.status !== 401) {
      let message = (error.response && error.response.data && error.response.data.error && (error.response.data.message || error.response.data.error.message)) || constantMessages.defaultErrorMessage;
      if (message?.includes("jwt expired")) {
        message = constantMessages.jwtExpiredErrorMessage;
      }
      throw new APIError(message, error.response?.data?.data);
    } else {
      ToastrService.info(constantMessages.jwtExpiredErrorMessage);
    }
  }

  request = ({ method, url, params, data = {}, withCredentials = true, headers, responseType, onUploadProgress }) => {
    const baseURL = this.getBaseServiceURL();
    const lang = i18n.language
    const header = withCredentials ? this.getCredentialHeader() : (headers || {});
    return axios({
      baseURL: baseURL,
      timeout: 60000,
      maxContentLength: 5000,
      method: method,
      url: url,
      params: params,
      data: data,
      onUploadProgress: onUploadProgress,
      headers: { ...header, ...{ "Content-Type": "application/json", lang } },
      ...(responseType ? {responseType} : {})
    }).then(this.handleResponse, this.handleError);
  };

  getCredentialHeader() {
    return { Authorization: 'Bearer ' + BaseApiService.token }
  };

  getBaseServiceURL() {
    return BaseApiService.baseApiUrl
  }
}
