import React, {createRef} from 'react'
import RingingCallView from './components/RingingCallView';
import CallRoom from './components/CallRoom';
import './index.scss';
import {callRoomEventType, callStatusesForParticipant, routes} from '../../config';
import {SocketService} from "../../services";
import history from "../../helpers/history";
import {isMobile} from "../../helpers/function";
import ReactDraggable from 'react-draggable';
import { withTranslation } from 'react-i18next';

class CallComponent extends React.Component {
  draggableElementRef = createRef(null);

  state = {
    fullScreenEnabled: false,
    screenOrientation: '',
  }

  componentDidMount() {
    this.orientationListener = window.addEventListener('deviceorientation', this.updateScreenOrientation);
  }

  componentWillUnmount() {
    if (this.orientationListener) this.orientationListener.removeEventListener()
  }

  toggleFullScreen = () => this.setState({fullScreenEnabled: !this.state.fullScreenEnabled})

  updateScreenOrientation = () => this.setState({ screenOrientation: (window.screen.orientation || {}).type || window.screen.mozOrientation || window.screen.msOrientation })

  addMembersToCall = (call) => {
    const {chatRooms = [], openGroupModal} = this.props;
    const chatRoom = chatRooms.find(c => c.twId === call.twId)

    if (chatRoom?.type === 'direct') {
      return
    } else {
      openGroupModal({selectedGroup: chatRoom});
    }
  };

  renderRingingCalls = (ringingCalls, isSmall) => {
    if (!ringingCalls?.length) {
      return
    }

    return (
      <>
        {this.renderRingingCall(ringingCalls[0], false, isSmall)}
        <div className={isSmall ? "" : "parallel-ringing-calls"}>
          {ringingCalls.slice(1).map(call => this.renderRingingCall(call, true))}
        </div>
      </>
    );
  }

  renderRingingCall = (call, isParallel, isSmall) => {
    const {
      me,
      chatRooms,
      acceptCall,
      declineCall,
      t
    } = this.props

    const chatRoom = chatRooms.find(chatRoom => chatRoom.twId === call.twId) || call.chatRoom

    const isDirectScreenShare = call.type === callRoomEventType.SCREEN_SHARE;
    const isIncoming = call.initializerId !== me?.id;
    const type = isDirectScreenShare ? callRoomEventType.SCREEN_SHARE :
      !!call.participants?.find(participant => participant.id === (call.initializerId || call.author))?.video ? 'video' : 'audio';
    let callName = chatRoom?.name;
    let callAvatar = {avatar: chatRoom?.avatar};

    const endCallLabel = isIncoming ? t('decline') : t('abort');

    if (chatRoom?.type === 'direct') {
      const secondParticipant = chatRoom.participants.find(participant => participant.id !== me?.id);
      callName = secondParticipant?.fullName;
      callAvatar.avatar = secondParticipant?.avatar;
      callAvatar.firstName = secondParticipant?.firstName;
      callAvatar.lastName = secondParticipant?.lastName;
    }

    const props = {
      endCallLabel,
      isIncoming,
      isDirectScreenShare,
      isSmall,
      acceptCall,
      twId: call?.twId || call?.callRoomId,
      callName,
      callAvatar,
      callType: type,
      isParallel,
      declineCall
    }

    return <RingingCallView {...props} />
  }

  renderCallRoom = (isSmall, acceptedCall) => {
    const {
      contacts,
      me,
      calls,
      endCall,
      endCallForAll,
      toggleTrack,
      startShareScreen,
      endShareScreen,
      chatRooms,
      localTracks,
      handleVoiceToggle,
      forceVoiceToggle
    } = this.props
    const callParticipantContacts = acceptedCall?.participants?.filter(participant => participant?.status === callStatusesForParticipant.ACCEPTED)
      .map(participant => {
      return me.id === participant.id ? me : contacts.find(contact => contact.id === participant.id)
    })

    const chatRoom = chatRooms.find(chatRoom => chatRoom.twId === acceptedCall.twId)

    let callName = chatRoom?.name;

    if (chatRoom?.type === 'direct') {
      const secondParticipant = chatRoom.participants.find(participant => participant.id !== me?.id);
      callName = secondParticipant?.fullName;
    }

    const isOwner = chatRoom.owner === me.id

    const isCallInitializer = acceptedCall.initializerId === me.id

    const isDirectScreenShare = acceptedCall.type === callRoomEventType.SCREEN_SHARE;
    const isVideoCall = acceptedCall.type === callRoomEventType.VIDEO;

    const props = {
      acceptedCall,
      endCall,
      endCallForAll,
      callParticipantContacts,
      isSmall,
      callName,
      toggleFullScreen: this.toggleFullScreen,
      fullScreenEnabled: this.state.fullScreenEnabled,
      me,
      isDirectScreenShare,
      isVideoCall,
      toggleTrack,
      startShareScreen,
      endShareScreen,
      localTracks,
      onAddParticipant: this.addMembersToCall,
      isOwner,
      isCallInitializer,
      chatRoom,
      handleVoiceToggle,
      forceVoiceToggle
    }

    const ringingCalls = calls?.filter(call => call.participants?.find(participant => participant.id === me?.id)?.status === callStatusesForParticipant.RINGING)

    return (
      <>
        <CallRoom {...props} />
        <div className="parallel-ringing-calls">
          {ringingCalls.map(call => this.renderRingingCall(call, true))}
        </div>
      </>
    )
  }

  render() {
    const {calls, selectedChatRoomTWId, me} = this.props;
    const {fullScreenEnabled, screenOrientation} = this.state;
    if (!calls?.length) return null;
    const socketId = SocketService.connection?.io?.opts?.query?.uuid;

    const { location } = history;

    const acceptedCall = calls.find(call => call.participants?.find(participant => participant.id === me.id && participant.status === callStatusesForParticipant.ACCEPTED && participant.socketId === socketId))
    const ringingCalls = calls?.filter(call => call.participants?.find(participant => participant.id === me?.id)?.status === callStatusesForParticipant.RINGING)
    if (!ringingCalls?.length && !acceptedCall) return null;

    const isAccepted = !!acceptedCall;

    if(isAccepted) {
      console.log("CALL ID", acceptedCall.twId);
      console.log("ROOM ID", acceptedCall.callRoom?.sid);
    }

    const isContactsOrRecentPage = location.pathname === routes.recent.path || location.pathname === routes.contacts.path;

    const isSmall = !fullScreenEnabled && (!isContactsOrRecentPage || (isAccepted && acceptedCall.twId !== selectedChatRoomTWId) ||
      (isContactsOrRecentPage && !isAccepted && !ringingCalls.some(call => call.twId === selectedChatRoomTWId) ));
    const isDraggable = isSmall && isAccepted;
    const callClassName = `call-container${isSmall ? ' small' : ''}${isDraggable ? ' canDrag' : ' canNotDrag'}${isAccepted ? ' active' : ''}${fullScreenEnabled ? ' full-screen' : ''}${isContactsOrRecentPage && !isAccepted && !isSmall ? " short-width" : ""}`;

    if (isMobile()) {
      const chatFooterHeight = document.querySelector('.chatFooterContainer')?.offsetHeight
      const lastMessageHeight = document.querySelector('.chatMessagesContainer > div:last-child .systemMessageContainer:last-child')?.offsetHeight
      const height = chatFooterHeight + lastMessageHeight + 'px'

      return (
        <div style={{ height: isAccepted ? (screenOrientation.includes('landscape') ? '100vh' : `calc(100% - ${height})`) : 'auto' }} className={callClassName}>
          {isAccepted ? this.renderCallRoom(isSmall, acceptedCall) : this.renderRingingCalls(ringingCalls, isSmall)}
        </div>
      )
    }

    return (
      <ReactDraggable onStart={() => isDraggable} bounds="parent">
        <div className={callClassName}>
          {isAccepted ? this.renderCallRoom(isSmall, acceptedCall) : this.renderRingingCalls(ringingCalls, isSmall)}
        </div>
      </ReactDraggable>
    )
  }
}

export default withTranslation()(CallComponent)
