import { combineReducers } from 'redux';
import auth, { LOG_OUT_SUCCESS_ACTION } from "./auth";
import error from "./error";
import loading from "./loading";
import chat from './chat'
import users from './user'
import twilio from './twilio'
import contacts from './contacts'
import chatRooms from './chatRooms'
import calls from './calls'
import group from './group'
import facilities from "./facilities";
import departments from "./departments";
import professions from "./professions";
import specialties from "./specialties";
import suffixes from "./suffixes";
import filter from "./filter";
import setting from "./setting";

const appReducer = combineReducers({
  auth,
  error,
  loading,
  chat,
  users,
  twilio,
  facilities,
  departments,
  professions,
  specialties,
  suffixes,
  contacts,
  chatRooms,
  calls,
  group,
  filter,
  setting,
});

const rootReducer = (state, action) => {
  if (action.type === LOG_OUT_SUCCESS_ACTION) {
    state = {}
  }

  return appReducer(state, action)
};

export default rootReducer
