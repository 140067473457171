export default class AudioService {
  constructor(url) {
    this.audio = new Audio(url)

    // IOS sound compatibility
    const handler = () => {
      this.audio.play()
      this.audio.pause()

      document.removeEventListener('click', handler)
      document.removeEventListener('touchstart', handler)
    }

    document.addEventListener('click', handler, { once: true })
    document.addEventListener('touchstart', handler, { once: true })
  }

  play() {
    this.audio.play();
  }

  playLoop() {
    this.audio.loop = true;
    this.audio
      .play()
      .then((_) => {
        // console.log("audio played auto");
      })
      .catch((error) => {
        console.log("playback prevented", error);
      });
  }

  pause() {
    this.audio.pause();
    this.audio.currentTime = 0;
  }
}
