import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import {CHANGE_PASSWORD_PREFIX, changePasswordAction, toggleUpdatePasswordModal} from "../../reducers/auth";
import {createLoadingSelector} from "../../selectors/loading";
import {createErrorMessageSelector} from "../../selectors/error";
import PasswordUpdateModalComponent from '../../components/PasswordUpdateModal/PasswordUpdateModal';

const mapStateToProps = state => {
  return {
    user: state.auth.data,
	  isPaswordUpdateModalOpen: state.auth.isPaswordUpdateModalOpen,
	  isPaswordUpdated: state.auth.isPaswordUpdated,
    loading: createLoadingSelector([CHANGE_PASSWORD_PREFIX])(state),
    error: createErrorMessageSelector([CHANGE_PASSWORD_PREFIX])(state),
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  changePasswordAction,
  toggleUpdatePasswordModal
}, dispatch);


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordUpdateModalComponent);