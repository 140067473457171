/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement } from "react";

import './AudioRecorder.scss';

import trash from '../../../assets/icons/audio-trash.svg'
import trashDark from '../../../assets/icons/audio-trash-dark.svg'
import { S3AudioPlayer } from "./AudioPlayer";

const AudioRecordViewer: (props: Record<string, any>) => ReactElement = ({
    isDarkTheme,
    onDiscard,
    copiedVoice
}) => {


    return (
        <div className={`AudioRecorder`} >
            <img
                src={isDarkTheme ? trashDark : trash}
                className="options"
                onClick={onDiscard}
                title="Discard"
                alt=""
            />

            <S3AudioPlayer s3key={copiedVoice.key} duration={copiedVoice.duration} />
        </div>
    );
};

export default AudioRecordViewer;