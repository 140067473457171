import {
  TwilioApiService,
  ToastrService,
  getTwilioClient,
  ChatRoomAPIService,
  SocketService, SoundService,
} from '../services';
import {
  constantMessages,
  SocketMessageType,
  systemMessages,
  routes,
  callStatusesForParticipant,
  appSounds
} from '../config';
import {
  handleNewMessage,
  handleTypingStatusChange,
  getChatRoomsList,
  CREATE_TWILIO_CHAT_CONVERSATION_SUCCESS_ACTION,
  CREATE_TWILIO_CHAT_CONVERSATION_FAILURE_ACTION,
  CREATE_TWILIO_CHAT_CONVERSATION_REQUEST_ACTION,
  CHANGE_SELECTED_CHAT_ROOM_TW_ID_ACTION, UPDATE_UNSENT_MESSAGE, UPDATE_CHAT_ROOM_SUCCESS_ACTION,
} from './chatRooms';
import { getContactsList } from './contacts';
import { encrypthMessage } from '../helpers/messages';
import history from "../helpers/history";
import { contactDisplayName } from "../helpers/chat";
import { SET_IS_CHATTING } from './chat';
import { END_CALL, endCall, UPDATE_CALL_TWID } from "./calls";
import store from "../store";
import { generateToken } from "../utils/function-utils";
import i18n from '../i18n';
import { Conversation } from '@twilio/conversations';


// ACTION_TYPES ////////////////////////////////////////////////////////////////

export const GET_TWILIO_TOKEN_PREFIX = 'twilio/GET_TWILIO_TOKEN';
export const GET_TWILIO_TOKEN_REQUEST_ACTION = GET_TWILIO_TOKEN_PREFIX + '_REQUEST_ACTION';
export const GET_TWILIO_TOKEN_SUCCESS_ACTION = GET_TWILIO_TOKEN_PREFIX + '_SUCCESS_ACTION';
export const GET_TWILIO_TOKEN_FAILURE_ACTION = GET_TWILIO_TOKEN_PREFIX + '_FAILURE_ACTION';

export const GET_TWILIO_VIDEO_TOKEN_PREFIX = 'twilio/GET_TWILIO_VIDEO_TOKEN';
export const GET_TWILIO_VIDEO_TOKEN_REQUEST_ACTION = GET_TWILIO_VIDEO_TOKEN_PREFIX + '_REQUEST_ACTION';
export const GET_TWILIO_VIDEO_TOKEN_SUCCESS_ACTION = GET_TWILIO_VIDEO_TOKEN_PREFIX + '_SUCCESS_ACTION';
export const GET_TWILIO_VIDEO_TOKEN_FAILURE_ACTION = GET_TWILIO_VIDEO_TOKEN_PREFIX + '_FAILURE_ACTION';


export const GET_SUBSCRIBED_CONVERSATIONS_LIST_PREFIX = 'twilio/GET_SUBSCRIBED_CONVERSATIONS_LIST';
export const GET_SUBSCRIBED_CONVERSATIONS_LIST_REQUEST_ACTION = GET_SUBSCRIBED_CONVERSATIONS_LIST_PREFIX + '_REQUEST_ACTION';
export const GET_SUBSCRIBED_CONVERSATIONS_LIST_SUCCESS_ACTION = GET_SUBSCRIBED_CONVERSATIONS_LIST_PREFIX + '_SUCCESS_ACTION';
export const GET_SUBSCRIBED_CONVERSATIONS_LIST_FAILURE_ACTION = GET_SUBSCRIBED_CONVERSATIONS_LIST_PREFIX + '_FAILURE_ACTION';

export const CREATE_TWILIO_CHAT_CLIENT_PREFIX = 'twilio/CREATE_TWILIO_CHAT_CLIENT';
export const CREATE_TWILIO_CHAT_CLIENT_REQUEST_ACTION = CREATE_TWILIO_CHAT_CLIENT_PREFIX + '_REQUEST_ACTION';
export const CREATE_TWILIO_CHAT_CLIENT_SUCCESS_ACTION = CREATE_TWILIO_CHAT_CLIENT_PREFIX + '_SUCCESS_ACTION';
export const CREATE_TWILIO_CHAT_CLIENT_FAILURE_ACTION = CREATE_TWILIO_CHAT_CLIENT_PREFIX + '_FAILURE_ACTION';

export const UPDATE_TWILIO_CHAT_CLIENT_PREFIX = 'twilio/UPDATE_TWILIO_CHAT_CLIENT';
export const UPDATE_TWILIO_CHAT_CLIENT_REQUEST_ACTION = UPDATE_TWILIO_CHAT_CLIENT_PREFIX + '_REQUEST_ACTION';
export const UPDATE_TWILIO_CHAT_CLIENT_SUCCESS_ACTION = UPDATE_TWILIO_CHAT_CLIENT_PREFIX + '_SUCCESS_ACTION';
export const UPDATE_TWILIO_CHAT_CLIENT_FAILURE_ACTION = UPDATE_TWILIO_CHAT_CLIENT_PREFIX + '_FAILURE_ACTION';

export const JOIN_TWILIO_CONVERSATION_BY_ID_PREFIX = 'twilio/JOIN_TWILIO_CONVERSATION_BY_ID';
export const JOIN_TWILIO_CONVERSATION_BY_ID_REQUEST_ACTION = JOIN_TWILIO_CONVERSATION_BY_ID_PREFIX + '_REQUEST_ACTION';
export const JOIN_TWILIO_CONVERSATION_BY_ID_SUCCESS_ACTION = JOIN_TWILIO_CONVERSATION_BY_ID_PREFIX + '_SUCCESS_ACTION';
export const JOIN_TWILIO_CONVERSATION_BY_ID_FAILURE_ACTION = JOIN_TWILIO_CONVERSATION_BY_ID_PREFIX + '_FAILURE_ACTION';

export const LEAVE_CONVERSATION_PREFIX = 'twilio/LEAVE_CONVERSATION';
export const LEAVE_CONVERSATION_REQUEST_ACTION = LEAVE_CONVERSATION_PREFIX + '_REQUEST_ACTION';
export const LEAVE_CONVERSATION_SUCCESS_ACTION = LEAVE_CONVERSATION_PREFIX + '_SUCCESS_ACTION';
export const LEAVE_CONVERSATION_FAILURE_ACTION = LEAVE_CONVERSATION_PREFIX + '_FAILURE_ACTION';

export const SEND_MESSAGE_PREFIX = 'twilio/SEND_MESSAGE_CLIENT';
export const SEND_MESSAGE_REQUEST_ACTION = SEND_MESSAGE_PREFIX + '_REQUEST_ACTION';
export const SEND_MESSAGE_SUCCESS_ACTION = SEND_MESSAGE_PREFIX + '_SUCCESS_ACTION';
export const SEND_MESSAGE_FAILURE_ACTION = SEND_MESSAGE_PREFIX + '_FAILURE_ACTION';

export const ADD_NEW_CONVERSATION_ACTION = 'twilio/ADD_NEW_CONVERSATION_ACTION';


// INITIAL STATE ///////////////////////////////////////////////////////////////

const initialState = {
  token: "",
  conversationsList: [],
  client: null,
}

// STATE ///////////////////////////////////////////////////////////////////////
export default (state = initialState, action: any) => {
  let conversationsList = [...state.conversationsList];
  switch (action.type) {
    case GET_TWILIO_TOKEN_SUCCESS_ACTION: {
      return {
        ...state,
        token: action.payload.token,
      }
    }
    case GET_TWILIO_VIDEO_TOKEN_SUCCESS_ACTION: {
      return {
        ...state,
        videoToken: action.payload.videoToken,
      }
    }
    case CREATE_TWILIO_CHAT_CLIENT_SUCCESS_ACTION:
    case UPDATE_TWILIO_CHAT_CLIENT_SUCCESS_ACTION: {
      return {
        ...state,
        client: action.payload.client,
      }
    }
    case GET_SUBSCRIBED_CONVERSATIONS_LIST_SUCCESS_ACTION: {
      return {
        ...state,
        conversationsList: action.payload.conversations,
      }
    }
    case LEAVE_CONVERSATION_SUCCESS_ACTION: {
      if (!!action.payload.id) {
        conversationsList = conversationsList.filter((conversation: any) => conversation.sid !== action.payload.id)
      }
      return {
        ...state,
        conversationsList,
      }
    }
    case ADD_NEW_CONVERSATION_ACTION:
    case JOIN_TWILIO_CONVERSATION_BY_ID_SUCCESS_ACTION: {
      return {
        ...state,
        conversationsList: [...state.conversationsList, action.payload.conversation],
      }
    }
    default:
      return state
  }
}

// ACTIONS /////////////////////////////////////////////////////////////////////

export function getTwilioToken(hideErrorNotification: any, isNewConnection: boolean) {
  return (dispatch: any) => {
    dispatch({ type: GET_TWILIO_TOKEN_REQUEST_ACTION });
    const twilioService = new TwilioApiService();
    return twilioService.getTwilioToken()
      .then(({ data = {} }) => {
        dispatch({ type: GET_TWILIO_TOKEN_SUCCESS_ACTION, payload: { token: data.jwt || "" } });
        isNewConnection ? dispatch(createChatClient(data.jwt)) : dispatch(updateChatClientToken(data.jwt))
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: GET_TWILIO_TOKEN_FAILURE_ACTION, payload: { message } });
        !hideErrorNotification && ToastrService.error(message)
      })
  }
}

export function getTwilioVideoToken(hideErrorNotification: any) {
  return (dispatch: any) => {
    dispatch({ type: GET_TWILIO_VIDEO_TOKEN_REQUEST_ACTION });
    const twilioService = new TwilioApiService();
    return twilioService.getTwilioVideoToken()
      .then(({ data = {} }) => {
        dispatch({ type: GET_TWILIO_VIDEO_TOKEN_SUCCESS_ACTION, payload: { videoToken: data.jwt || "" } });
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: GET_TWILIO_VIDEO_TOKEN_FAILURE_ACTION, payload: { message } });
        !hideErrorNotification && ToastrService.error(message)
      })
  }
}

export function updateChatClientToken(token: string) {
  return (dispatch: any) => {
    dispatch({ type: UPDATE_TWILIO_CHAT_CLIENT_REQUEST_ACTION });
    const { client } = getTwilioClient();
    if (client) {
      return getTwilioClient().updateToken(token)
        .then(newClient => {
          dispatch(setupChatClientEvents());
          dispatch({ type: UPDATE_TWILIO_CHAT_CLIENT_SUCCESS_ACTION, payload: { client: newClient } });
        }).catch((err = {}) => {
          const message = err.message || constantMessages.defaultErrorMessage;
          dispatch({ type: UPDATE_TWILIO_CHAT_CLIENT_FAILURE_ACTION, payload: { message } });
        });
    } else {
      dispatch({ type: UPDATE_TWILIO_CHAT_CLIENT_FAILURE_ACTION });
    }
  }
}

export function leaveConversation(id: string) {
  return async (dispatch: any, getState: any) => {
    dispatch({ type: LEAVE_CONVERSATION_REQUEST_ACTION });
    getTwilioClient().getConversationBySid(id)?.then(conversation => {
      const contact = getState().auth.data
      const fullName = contactDisplayName(contact)
      const eventName = systemMessages.USER_LEFT
      const event = {
        name: eventName,
        data: {
          twId: conversation.sid,
          initiator: contact.id,
          message: fullName + ' ' + eventName
        }
      }
      dispatch(sendSystemMessage(eventName, conversation.sid, event))
      conversation.leave()
        .then(() => {
          dispatch({ type: LEAVE_CONVERSATION_SUCCESS_ACTION, payload: { id } });
          dispatch({ type: SET_IS_CHATTING, payload: false })
          dispatch({ type: CHANGE_SELECTED_CHAT_ROOM_TW_ID_ACTION, payload: { twId: '' } })
        })
        .catch(err => {
          const message = err.message || constantMessages.defaultErrorMessage;
          dispatch({ type: LEAVE_CONVERSATION_FAILURE_ACTION, payload: { message } });
        })
    })
  }
}

export function createChatClient(token: string) {
  return (dispatch: any) => {
    dispatch({ type: CREATE_TWILIO_CHAT_CLIENT_REQUEST_ACTION });
    return getTwilioClient().create(token)
      .then((client) => {
        dispatch(getChatRoomsList());
        dispatch(getSubscribedConversations());
        dispatch(setupChatClientEvents());
        dispatch({ type: CREATE_TWILIO_CHAT_CLIENT_SUCCESS_ACTION, payload: { client } });
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: CREATE_TWILIO_CHAT_CLIENT_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message)
      })
  }
}

function setupChatClientEvents() {
  return (dispatch: any, getState: any) => {
    const { client } = getTwilioClient();
    client?.on('conversationJoined', conversation => {
      dispatch({ type: JOIN_TWILIO_CONVERSATION_BY_ID_SUCCESS_ACTION, payload: { conversation } });
      conversation.on('messageAdded', (message) => dispatch(handleNewMessage(message)));
      conversation.on('typingStarted', (member) => dispatch(handleTypingStatusChange(member, true)));
      conversation.on('typingEnded', (member) => dispatch(handleTypingStatusChange(member, false)));
    })
    client?.on('conversationRemoved', conversation => {
      const userId = getState().auth.data.id;
      const { calls } = getState().calls;
      const { selectedChatRoomTWId, list: chatRooms } = getState().chatRooms;
      const chatRoom = chatRooms.find((c: any) => c.twId === conversation.sid)
      // const groupName = conversation.channelState.friendlyName;
      const groupName = conversation.friendlyName;
      if (chatRoom && chatRoom.owner !== userId) {
        const groupCreator = chatRoom ? chatRoom.participants?.find((p: any) => p.id === chatRoom.owner)?.fullName : ''
        ToastrService.info(i18n.t('misc.group_archived', { groupName, groupCreator }))
      }
      if (calls.find((call: any) => call.twId === conversation.sid)) {
        dispatch(endCall(conversation.sid));
        dispatch({ type: UPDATE_CALL_TWID, payload: null })
        dispatch({ type: END_CALL, payload: { data: { twId: conversation.sid } } });
      }
      if (selectedChatRoomTWId === conversation.sid) {
        dispatch({ type: CHANGE_SELECTED_CHAT_ROOM_TW_ID_ACTION, payload: { twId: "" } })
      }
      dispatch(getContactsList());
      dispatch(getChatRoomsList());
    });
    client?.on('tokenAboutToExpire', async () => {
      dispatch(getTwilioToken(true, false));
      dispatch(getTwilioVideoToken(true));
    });
    client?.on('connectionError', async (data) => {
      console.log("TWILIO CONNECTION ERROR EVENT: ", data)
      const { calls, localTracks } = store.getState().calls

      if (calls.length) {
        calls.forEach((call: any) => {
          if (call.type === "direct" || call.participants?.filter((participant: any) => participant.status === callStatusesForParticipant.ACCEPTED)?.length === 2) {
            store.dispatch({ type: UPDATE_CHAT_ROOM_SUCCESS_ACTION, payload: { chatRoom: { twId: call.twId, call: null } } })
            dispatch({ type: END_CALL, payload: { data: call } });
          }
        })
        SoundService.stop(appSounds.CALL_SOUND);
        localTracks.forEach((localTrack: any) => localTrack?.stop());
        store.dispatch({ type: UPDATE_CALL_TWID, payload: null })
      }
    });
  }
}

export function sendSystemMessage(message: any, sid: any, event: any, to: any[] = []) {
  return (dispatch: any, getState: any) => {
    const { data = {} } = getState().auth;
    const attributes: any = {
      toAll: false,
      systemMessage: true,
      event,
      to,
      from: []
    };
    switch (message) {
      case systemMessages.CALL_DECLINED:
      case systemMessages.CALL_ABORTED:
      case systemMessages.VIDEO_CALL_ABORTED:
      case systemMessages.DIRECT_SCREEN_SHARE_ABORTED:
      case systemMessages.CALL_LEFT:
      case systemMessages.VIDEO_CALL_LEFT:
      case systemMessages.DIRECT_SCREEN_SHARE_LEFT:
      case systemMessages.CALL_JOINED:
      case systemMessages.VIDEO_CALL_JOINED:
      case systemMessages.DIRECT_SCREEN_SHARE_JOINED:
      case systemMessages.SCREEN_SHARING_STARTED:
      case systemMessages.SCREEN_SHARING_STOPPED:
      case systemMessages.USER_ADDED:
      case systemMessages.USER_JOINED:
      case systemMessages.USER_LEFT:
      case systemMessages.USER_REMOVED:
      case systemMessages.DIRECT_SCREEN_SHARE_ACCEPTED:
      case systemMessages.VIDEO_CALL_ACCEPTED:
      case systemMessages.CALL_ACCEPTED:
      case systemMessages.VIDEO_CALL_DECLINED:
      case systemMessages.DIRECT_SCREEN_SHARE_DECLINED: {
        return;
      }
      case systemMessages.CALL_MISSED:
      case systemMessages.DIRECT_SCREEN_SHARE_ENDED:
      case systemMessages.CALL_ENDED:
      case systemMessages.VIDEO_CALL_ENDED: {
        attributes.toAll = true;
        break;
      }
      case systemMessages.VIDEO_OUT_CALL_INIT:
      case systemMessages.CALL_OUT_INIT:
      case systemMessages.DIRECT_SCREEN_SHARE_OUT_INIT: {
        attributes.from.push(data.id);
        attributes.toAll = true;
        break;
      }
      case systemMessages.DIRECT_SCREEN_SHARE_INIT:
      case systemMessages.CALL_INIT:
      case systemMessages.VIDEO_CALL_INIT: {
        return;
      }
      default:
        return
    }
    dispatch(sendMessage(message, sid, attributes, true))
  }
}

export function sendMessage(message: any, sid: string, attributes = {}, systemMessage?: any) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: SEND_MESSAGE_REQUEST_ACTION });
    const { conversationsList } = getState().twilio;
    const { isAppDisabled } = getState().auth;
    const conversation = conversationsList.find((item: any) => item.sid === sid);
    const encryptedMessage = encrypthMessage(message);
    attributes = { ...attributes, id: generateToken() };
    if (!isAppDisabled && conversation) {
      return conversation.sendMessage(encryptedMessage, attributes)
        .then(() => {
          const socketData: any = {
            message: { body: encryptedMessage, attributes },
            twId: sid,
          };
          if (!systemMessage) {
            const { data = {} } = getState().auth;
            socketData.from = data.id
          }
          SocketService.send(SocketMessageType.SEND_MESSAGE, socketData);
          dispatch({ type: SEND_MESSAGE_SUCCESS_ACTION });
          dispatch({ type: UPDATE_UNSENT_MESSAGE, payload: { twId: sid, message: '' } });
        }).catch((err: any = {}) => {
          const message = err.message || constantMessages.defaultErrorMessage;
          dispatch({ type: SEND_MESSAGE_FAILURE_ACTION, payload: { message } });
          ToastrService.error(message)
        })
    } else {
      dispatch({ type: SEND_MESSAGE_FAILURE_ACTION, payload: { message: "Conversation is not available, please try again." } });
    }

  }
}

export function joinTwilioConversationById(sid: string) {
  return (dispatch: any) => {
    dispatch({ type: JOIN_TWILIO_CONVERSATION_BY_ID_REQUEST_ACTION });
    return getTwilioClient().getConversationBySid(sid)?.then((conversation = {} as any) => {
      if (conversation.status !== 'joined') {
        conversation.join().then(conversation => {
          console.log("Joining conversation" + conversation.friendlyName)
        });
      }
      dispatch({ type: JOIN_TWILIO_CONVERSATION_BY_ID_SUCCESS_ACTION, payload: { conversation } });
    }
    ).catch((err) => {
      const message = err.message || constantMessages.defaultErrorMessage;
      dispatch({ type: JOIN_TWILIO_CONVERSATION_BY_ID_FAILURE_ACTION, payload: { message } });
    })
  }
}

export function getSubscribedConversations() {
  return (dispatch: any) => {
    dispatch({ type: GET_SUBSCRIBED_CONVERSATIONS_LIST_REQUEST_ACTION });
    return getTwilioClient().getSubscribedConversations()?.then((data = {} as any) => {
      const conversations = data.items || [];
      dispatch({ type: GET_SUBSCRIBED_CONVERSATIONS_LIST_SUCCESS_ACTION, payload: { conversations } });
    }
    ).catch((err) => {
      const message = err.message || constantMessages.defaultErrorMessage;
      dispatch({ type: GET_SUBSCRIBED_CONVERSATIONS_LIST_FAILURE_ACTION, payload: { message } });
    })
  }
}

export function createChatConversation(uniqueName: string, friendlyName: string, participants: any[], type = 'direct', isPrivate = true) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: CREATE_TWILIO_CHAT_CONVERSATION_REQUEST_ACTION });
    return new Promise(async () => {
      const chosenConversation = await createOrSearchConversation(dispatch, getState, uniqueName, friendlyName)
      if (!chosenConversation) return;

      chosenConversation.join().then(conversation => {
        console.log("Joining conversation" + conversation.friendlyName)
      }).catch(err => {});
      const chatRoomService = new ChatRoomAPIService();
      participants.forEach(participant => {
        chosenConversation.add(participant)
          .then(() => console.log('Add Success'))
          .catch(error => { console.error('ADD ERROR-> ', error) })
      });

      const { data = {} } = getState().auth;
      dispatch({ type: ADD_NEW_CONVERSATION_ACTION, payload: { conversation: chosenConversation } });
      
      try {
        const datas = await chatRoomService.createChatRoom({
          twId: chosenConversation.sid,
          participants: [...participants, data.id],
          type,
          name: friendlyName,
        });

        const chatRoom = (datas.data && datas.data.chatRoom) || null;
        dispatch({ type: CREATE_TWILIO_CHAT_CONVERSATION_SUCCESS_ACTION, payload: { chatRoom } });

      } catch (err: any) {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: CREATE_TWILIO_CHAT_CONVERSATION_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message)
        history.push(routes.recent.path);
      }
    })
  }
}

const createOrSearchConversation = async (dispatch: any, getState: any, uniqueName: string, friendlyName: string) => {
  try {
    const conversation = await getTwilioClient().createConversation({ uniqueName, friendlyName })
    return conversation;
  } catch (err: any) {
    let message = err.body?.message || err.message || constantMessages.defaultErrorMessage;
    if (message?.includes("Friendly name too long")) {
      message = constantMessages.chatRoomLongFriendlyNameErrorMessage;
    } else if (message?.includes("Conversation with provided unique name already exists")) {
      // Search message in twilio converstion list
      const conversation: Conversation | undefined = getState().twilio.conversationsList?.find(((x: any) => x.channelState?.uniqueName === uniqueName))
      if (conversation) return conversation;
      // End search
      message = constantMessages.chatRoomNameExistErrorMessage;
    }
    dispatch({ type: CREATE_TWILIO_CHAT_CONVERSATION_FAILURE_ACTION, payload: { message } });
    ToastrService.error(message)
    history.push(routes.recent.path);

    return undefined;
  }
}



