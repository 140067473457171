import _ from "lodash";
import moment from "moment";
import {systemMessages} from "../config";
import i18n from "../i18n";

export const groupContactsByAlphabetic = (contacts = []) => {
  const result = {};
  if (!contacts.length) {
    return result;
  }

  const contactsList = contacts.sort((a = {}, b = {}) => {
    const nameA = `${a.firstName || ''}${a.lastName || ''}`;
    const nameB = `${b.firstName || ''}${b.lastName || ''}`;
    return nameA.localeCompare(nameB)
  });

  contactsList.forEach((contact = {}) => {
    if (contact.deleted || !contact.lastLoginDate) {
      return
    }
    const name = `${contact.firstName || ''}${contact.lastName || ''}`;
    const correspondingLetter = name[0] && name[0].toUpperCase();
    if (result[correspondingLetter]) {
      result[correspondingLetter].push(contact)
    } else {
      result[correspondingLetter] = [contact]
    }
  });

  return result;
};

const sortByLastMessageOrUpdateDate = (messages = []) => {
  return messages.sort((a, b) => {
    if (!!a?.lastMessage?.timetoken && !!b?.lastMessage?.timetoken) {
      return b.lastMessage.timetoken - a.lastMessage.timetoken
    } else if (!a?.lastMessage?.timetoken && !b?.lastMessage?.timetoken) {
      return new Date(b.updatedAt) - new Date(a.updatedAt)
    } else {
      return b?.lastMessage?.timetoken || new Date(b.updatedAt) - a?.lastMessage?.timetoken || new Date(a.updatedAt)
    }
  });
}

export const groupChatRoomsByDateAndFavorites = (chatRoomsList = [], showEmptyRooms = false) => {
  const favoriteChatRooms = [];

  const chatRooms = showEmptyRooms ? chatRoomsList : chatRoomsList.filter(chatRoom => chatRoom && chatRoom.lastMessage && chatRoom.lastMessage.timetoken);

  // Take out favorite chat rooms
  const chatRoomsWithoutFavorites = sortByLastMessageOrUpdateDate(chatRooms.filter(chatRoom => {
    if (chatRoom.isFavorite) {
      favoriteChatRooms.push({...chatRoom})
    }
    return !chatRoom.isFavorite
  }));

  const groupedChatRooms = _.groupBy(chatRoomsWithoutFavorites, function (chatRoom = {}) {
    const startOfDayDate = moment(chatRoom.lastMessage?.timetoken || chatRoom.updatedAt).startOf('day');

    if (!moment().diff(startOfDayDate, 'days')) {
      return i18n.t('Today')
    }

    if (moment().diff(startOfDayDate, 'days') === 1) {
      return i18n.t('Yesterday')
    }

    return startOfDayDate.locale(i18n.language).format('MMMM DD, YYYY');
  });


  if (favoriteChatRooms.length) {
    groupedChatRooms['Favorites'] = sortByLastMessageOrUpdateDate(favoriteChatRooms);
  }

  return groupedChatRooms;
};

export const chatStatusClassName = (status = '') => {
  return status.toLowerCase().replace(/\s/g, '-');
};

export const directConversationUniqName = (firstMemberId = '', secondMemberId = '') => {
  return [firstMemberId, secondMemberId].sort().join('_')
};

export const contactDisplayName = (contact = {}) => {
  return `${contact.firstName || ""}${contact.firstName ? " " : ""}${contact.lastName || ""}`
};

export const renderSystemMessageText = (message, messageSender, sentByUser, messageAttributes) => {
  const messageSenderName = sentByUser
    ? i18n.t("You")
    : Array.isArray(messageSender)
      ? messageSender.map(u => `${u?.firstName}  ${u?.lastName}`)?.join(', ')
      : `${messageSender?.firstName} ${messageSender?.lastName}`;
  switch (message) {
    case systemMessages.DIRECT_SCREEN_SHARE_DECLINED:
    case systemMessages.CALL_DECLINED:
    case systemMessages.VIDEO_CALL_DECLINED:
    case systemMessages.CALL_LEFT:
    case systemMessages.VIDEO_CALL_LEFT:
    case systemMessages.DIRECT_SCREEN_SHARE_LEFT:
      return `${messageSenderName} ${i18n.t(`sys_message.${message}`)}`;
    case systemMessages.CALL_ACCEPTED:
    case systemMessages.VIDEO_CALL_ACCEPTED:
    case systemMessages.DIRECT_SCREEN_SHARE_ACCEPTED:
    case systemMessages.CALL_JOINED:
    case systemMessages.VIDEO_CALL_JOINED:
    case systemMessages.DIRECT_SCREEN_SHARE_JOINED:
    case systemMessages.CALL_ABORTED:
    case systemMessages.VIDEO_CALL_ABORTED:
    case systemMessages.DIRECT_SCREEN_SHARE_ABORTED:
    case systemMessages.SCREEN_SHARING_STARTED:
    case systemMessages.SCREEN_SHARING_STOPPED:
    case systemMessages.USER_ADDED:
    case systemMessages.USER_REMOVED:
    case systemMessages.USER_JOINED:
    case systemMessages.USER_LEFT:
      return `${messageSenderName} ${(sentByUser || (Array.isArray(messageSender) && messageSender.length > 1)) ? "Have" : "Has"} ${i18n.t(`sys_message.${message}`)}`;
    case systemMessages.CALL_ENDED:
    case systemMessages.VIDEO_CALL_ENDED:
    case systemMessages.DIRECT_SCREEN_SHARE_ENDED:
      const callDuration = messageAttributes?.event?.data?.callDuration;
      return `${i18n.t(`sys_message.${message}`)}${callDuration ? " - " + callDuration : ""} `;
    case systemMessages.VIDEO_OUT_CALL_INIT:
    case systemMessages.CALL_OUT_INIT:
    case systemMessages.DIRECT_SCREEN_SHARE_OUT_INIT: {
      if (messageAttributes.from && !sentByUser) {
        return i18n.t(`sys_message.${message}`).replace('Outgoing', 'Incoming')
      }
      return i18n.t(`sys_message.${message}`);
    }
    default:
      return i18n.t(`sys_message.${message}`)
  }
};
