import { connect } from 'react-redux';
import PersonalInformationComponent from '../../components/Settings/AccountSettings/PersonalInformation';
import { bindActionCreators } from "redux";
import {
  CHANGE_PERSONAL_INFORMATION_PREFIX,
  changePersonalInformationAction,
} from "../../reducers/auth";
import {createLoadingSelector} from "../../selectors/loading";
import {createErrorMessageSelector} from "../../selectors/error";
import { getFacilitiesList } from "../../reducers/facilities";
import { getDepartmentsList } from "../../reducers/departments";
import { getProfessions } from "../../reducers/professions";
import { getSpecialties } from "../../reducers/specialties";
import { getSuffixesList } from "../../reducers/suffixes";
import { FETCH_CALLING_CODES_PREFIX, getCallingCodesList } from "../../reducers/setting";

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    user: state.auth.data,
    loading: createLoadingSelector([CHANGE_PERSONAL_INFORMATION_PREFIX])(state),
    error: createErrorMessageSelector([CHANGE_PERSONAL_INFORMATION_PREFIX])(state),
    facilitiesList: state.facilities.facilitiesList,
    departmentsList: state.departments.departmentsList,
    professionsList: state.professions.professionsList,
    specialtiesList: state.specialties.specialtiesList,
    suffixesList: state.suffixes.suffixesList,
    callingCodes: state.setting.callingCodes,
    loadingCallingCodes: createLoadingSelector([FETCH_CALLING_CODES_PREFIX])(state),
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getCallingCodesList,
  updatePersonalInformation: changePersonalInformationAction,
  getFacilitiesList,
  getDepartmentsList,
  getProfessions,
  getSpecialties,
  getSuffixesList
}, dispatch);


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalInformationComponent);