import React, { Component } from 'react';
import { Col, Button, ModalHeader, ModalBody, ModalFooter, Modal } from "reactstrap";
import "./index.scss";

import { InputField } from '../UIComponents';
import { withTranslation } from 'react-i18next';

class UpdateEmailComponent extends Component {
  state = {
    errors: {},
    email: '',
  };

  onFieldValueChange = (name, value) => {
    this.setState({ [name]: value })
  };

  componentDidUpdate(prevProps) {
    if (prevProps.changingEmail && !this.props.changingEmail && !this.props.changingEmailError) {
      this.props.toggle()
    }
  }

  handleChangeClick = () => {
    const { email } = this.state;
    const { t } = this.props;

    const errors = {};
    const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    let isValid = true;

    if (!email) {
      errors.email = this.props.t('Required');
      isValid = false;
    } else if (!re.test(email)) {
      errors.email = t('invalid_email');
      isValid = false;
    }

    this.setState({ errors });
    if (isValid) {
      this.props.changeEmail({ email });
    }

  };

  render() {
    const { email, errors } = this.state;
    const { toggle, t } = this.props;

    return (
      <Modal isOpen={true} toggle={toggle} className={"notAvailableModal"} size="sm">
        <ModalHeader toggle={toggle}
          close={<img onClick={toggle}
            className="clickable"
            src={"/assets/images/btn-close.svg"}
            alt="close" />}
        >
          {t('auth.change_email_')}
        </ModalHeader>
        <ModalBody className="d-flex justify-content-center align-items-center px-4 pb-0 ">
          <Col xs={12} className="px-0 accountSettingFieldContainer">
            <InputField
              id="email"
              type="email"
              placeholder={t("Email")}
              value={email}
              error={errors.email}
              onChange={(event) => this.onFieldValueChange('email', event.target.value)}
              onKeyPress={(event) => (event.charCode === 13) && this.handleChangeClick()}
            />
          </Col>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <Button color="secondary" onClick={toggle}>{t('CANCEL')}</Button>
          <Button color="primary" onClick={this.handleChangeClick}>{t('CHANGE')}</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default withTranslation('common')(UpdateEmailComponent);
