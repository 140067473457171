import React, { Component } from 'react';
import {
  Container,
  Col,
  Button,
} from 'reactstrap';
import _ from 'lodash';
import '../index.scss';
import { InputField } from "../../UIComponents";
import MultiSelectField from "../../../containers/UIComponents/MultiSelectField";
import { checkProvidedOptions } from "../../../utils/function-utils";
import { withTranslation } from 'react-i18next';

class PersonalInformationComponent extends Component {
  state = {
    firstName: '',
    lastName: '',
    email: '',
    sessionTimeout: '',
    facility: '',
    department: '',
    profession: '',
    specialty: '',
    suffix: '',
    errors: {},
    isFacilitiesListOpen: false,
    isDepartmentsOpen: false,
    isProfessionOpen: false,
    isSpecialtyOpen: false,
    isSuffixOpen: false,
  };

  componentDidMount() {
    const params = { pageSize: 10000 }
    this.props.getCallingCodesList();
    this.props.getFacilitiesList(params)
    this.props.getDepartmentsList(params)
    this.props.getProfessions(params)
    this.props.getSpecialties(params)
    this.props.getSuffixesList(params)
    const { user: { firstName, lastName, email, sessionTimeout, unverifiedEmail, facilityId, departmentId, specialtyId, professionId, suffixId } } = this.props;
    this.setState({
      firstName,
      lastName,
      email: unverifiedEmail || email,
      sessionTimeout,
      facility: facilityId,
      department: departmentId,
      specialty: specialtyId,
      profession: professionId,
      suffix: suffixId,
    });
  }

  componentDidUpdate(prevProps) {
    const {
      loading,
      error,
      user: { firstName, lastName, sessionTimeout, facilityId, departmentId, specialtyId, professionId, suffixId },
    } = this.props;

    const {
      loading: prevLoading,
      user: { sessionTimeout: prevSessionTimeout, facilityId: prevFacilityId, departmentId: prevDepartmentId, specialtyId: prevSpecialtyId, professionId: prevProfessionId, suffixId: prevSuffixId }
    } = prevProps;

    if (!prevFacilityId && facilityId) {
      this.setState({ facility: facilityId })
    }

    if (!prevDepartmentId && departmentId) {
      this.setState({ department: departmentId })
    }

    if (!prevSpecialtyId && specialtyId) {
      this.setState({ specialty: specialtyId })
    }

    if (!prevProfessionId && professionId) {
      this.setState({ profession: professionId })
    }

    if (!prevSuffixId && suffixId) {
      this.setState({ suffix: suffixId })
    }

    if (!prevSessionTimeout && sessionTimeout) {
      this.setState({ sessionTimeout })
    }

    if (!loading && prevLoading && !error) {
      this.setState({ firstName, lastName })
    }

  }

  onFieldValueChange = (name, value) => {
    this.setState({ [name]: value })
  };

  onTimeoutFielValueChange = e => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({ 'sessionTimeout': e.target.value })
    } else {
      e.target.value = this.state.sessionTimeout;
    }
  }
  handleUpdateClick = () => {
    const { isSessionTimeout, t } = this.props
    const { firstName, lastName, facility, department, profession, specialty, suffix } = this.state,
      errors = {};
    let isValid = true;

    let sessionTimeout = this.state.sessionTimeout || ""
    if (!_.trim(firstName || "")) {
      errors.firstName = t('Required');
      isValid = false;
    }

    if (!_.trim(lastName || "")) {
      errors.lastName = t('Required');
      isValid = false;
    }

    if (!facility?.length) {
      errors.facility = t('Required');
      isValid = false;
    }

    if (!department?.length) {
      errors.department = t('Required');
      isValid = false;
    }

    if (!profession.length) {
      errors.profession = t('Required');
      isValid = false;
    }

    if (!specialty?.length) {
      errors.specialty = t('Required');
      isValid = false;
    }

    if (sessionTimeout !== "" && +sessionTimeout <= 0) {
      errors.sessionTimeout = t('auth.min_value_is_1');
      isValid = false;
    }

    let data = {
      firstName,
      lastName,
      facilityId: facility,
      departmentId: department,
      professionId: profession,
      specialtyId: specialty,
      suffixId: suffix
    }

    if (isSessionTimeout) {
      data['sessionTimeout'] = sessionTimeout
    }

    this.setState({ errors });
    isValid && this.props.updatePersonalInformation(data);
  };

  handleVerifyEmail = () => {
    const { verifyEmailToken } = this.state;
    this.props.verifyEmailAction({ verifyToken: verifyEmailToken })
  };


  toggle = key => {
    this.setState({ [key]: !this.state[key] })
  }

  render() {
    const { isNewUser, facilitiesList, departmentsList, professionsList, specialtiesList, suffixesList, isSessionTimeout = false, t } = this.props;
    const { errors, firstName, lastName, sessionTimeout, facility, department, profession, specialty, suffix } = this.state;
    const userManageClassName = isNewUser !== undefined && isNewUser === true ? 'contentContainer d-flex align-items-start flex-wrap mt-0' : 'contentContainer d-flex align-items-start flex-wrap'

    const facilityOptions = checkProvidedOptions(facilitiesList.map(f => ({ value: f.id, label: f.name })), facility);
    const departmentOptions = checkProvidedOptions(departmentsList.map(f => ({ value: f.id, label: f.name })), department);
    const professionOptions = checkProvidedOptions(professionsList.map(f => ({ value: f.id, label: f.name })), profession);
    const specialityOptions = checkProvidedOptions(specialtiesList.map(f => ({ value: f.id, label: f.name })), specialty);
    const suffixOptions = checkProvidedOptions(suffixesList.map(f => ({ value: f.id, label: f.name })), suffix);

    return (
      <>
        <Container fluid className="personalInformationContainer userProfile">
          <div className={userManageClassName}>
            <Col lg={4} sm={12} className="px-0">
              <h1 className="personal-info">{t('auth.personal_info')}</h1>
            </Col>
            <Col className="px-0">
              <Col className="d-flex flex-column align-items-start px-0 form-container">
                <Col xs={12}
                  className="px-0 ieCol d-flex align-items-center accountSettingFieldContainer flex-wrap"
                >
                  <p className={`mr-3 ${errors.firstName ? "mb-5" : ""} inputLabel`}>{t('Name')}</p>
                  <InputField
                    id="name"
                    type="text"
                    placeholder={t("Name")}
                    className={"field-column px-0"}
                    value={firstName}
                    error={errors.firstName}
                    onChange={(event) => this.onFieldValueChange('firstName', event.target.value)}
                    onKeyPress={(event) => (event.charCode === 13) && this.handleUpdateClick()}
                  />
                </Col>
                <Col xs={12}
                  className="px-0 ieCol d-flex align-items-center  accountSettingFieldContainer flex-wrap">
                  <p className={`mr-3 ${errors.lastName ? "mb-5" : ""} inputLabel`}>{t('Surname')}</p>
                  <InputField
                    id="surname"
                    type="text"
                    placeholder={t("Surname")}
                    className={"field-column px-0"}
                    value={lastName}
                    error={errors.lastName}
                    onChange={(event) => this.onFieldValueChange('lastName', event.target.value)}
                    onKeyPress={(event) => (event.charCode === 13) && this.handleUpdateClick()}
                  />
                </Col>
                {isSessionTimeout && (
                  <Col xs={12}
                    className="px-0 ieCol d-flex align-items-center accountSettingFieldContainer flex-wrap">
                    <p className={`mr-3 ${errors.sessionTimeout ? "mb-5" : ""} inputLabel`}>{t('admin.session_timeout')}</p>
                    <InputField
                      id="sessionTimeout"
                      type="text"
                      placeholder={t('auth.enter_session_timeout')}
                      helpText="Set to default timeout if field is empty."
                      className={"field-column px-0"}
                      value={sessionTimeout}
                      error={errors.sessionTimeout}
                      onChange={this.onTimeoutFielValueChange}
                      onKeyPress={(event) => (event.charCode === 13) && this.handleUpdateClick()}
                    />
                  </Col>
                )}
              </Col>
            </Col>
          </div>
        </Container>
        {/* <div className='divider' /> */}
        <Container fluid className="professionalInformationContainer userProfile">
          <div className={userManageClassName}>
            <Col lg={4} sm={12} className="px-0">
              <h1 className="personal-info">{t('auth.professional_info')}</h1>
            </Col>
            <Col className="px-0">
              <Col className="d-flex flex-column align-items-start px-0 form-container">
                <Col xs={12}
                  className="px-0 ieCol d-flex align-items-center accountSettingFieldContainer flex-wrap">
                  <p className={`mr-3 ${errors.facility ? "mb-5" : ""} inputLabel`}>{t('Facility')}</p>
                  <div className="d-flex flex-column field-column">
                    <MultiSelectField
                      name={'facility'}
                      options={facilityOptions}
                      onChange={(newOptions) => this.onFieldValueChange('facility', newOptions.filter(option => option.checked).map(option => option.value))}
                    />
                    {errors.facility && <p className="errorText pl-2 mt-1">{errors.facility}</p>}
                  </div>
                </Col>
                <Col xs={12}
                  className="px-0 ieCol d-flex align-items-center accountSettingFieldContainer flex-wrap">
                  <p className={`mr-3 ${errors.department ? "mb-5" : ""} inputLabel`}>{t('Department')}</p>
                  <div className="d-flex flex-column field-column">
                    <MultiSelectField
                      name='department'
                      options={departmentOptions}
                      onChange={(newOptions) => this.onFieldValueChange('department', newOptions.filter(option => option.checked).map(option => option.value))}
                    />
                    {errors.department && <p className="errorText pl-2 mt-1">{errors.department}</p>}
                  </div>
                </Col>
                <Col xs={12}
                  className="px-0 ieCol d-flex align-items-center accountSettingFieldContainer flex-wrap">
                  <p className={`mr-3 ${errors.profession ? "mb-5" : ""} inputLabel`}>{t('admin.cli_role')}</p>
                  <div className="d-flex flex-column field-column">
                    <MultiSelectField
                      name='profession'
                      options={professionOptions}
                      isMulti={false}
                      withoutCheckboxes
                      onChange={(newOptions) => this.onFieldValueChange('profession', newOptions.filter(option => option.checked).map(option => option.value))}
                    />
                    {errors.profession && <p className="errorText pl-2 mt-1">{errors.profession}</p>}
                  </div>
                </Col>
                <Col xs={12}
                  className="px-0 ieCol d-flex align-items-center accountSettingFieldContainer flex-wrap">
                  <p className={`mr-3 ${errors.specialty ? "mb-5" : ""} inputLabel`}>{t('Specialty')}</p>
                  <div className="d-flex flex-column field-column">
                    <MultiSelectField
                      name='specialty'
                      options={specialityOptions}
                      onChange={(newOptions) => this.onFieldValueChange('specialty', newOptions.filter(option => option.checked).map(option => option.value))}
                    />
                    {errors.specialty && <p className="errorText pl-2 mt-1">{errors.specialty}</p>}
                  </div>
                </Col>
                <Col xs={12}
                  className="px-0 ieCol d-flex align-items-center accountSettingFieldContainer flex-wrap">
                  <p className={`mr-3 ${errors.suffix ? "mb-5" : ""} inputLabel`}>{t('Suffix')}</p>
                  <div className="d-flex flex-column field-column">
                    <MultiSelectField
                      name='suffix'
                      options={suffixOptions}
                      isMulti={false}
                      withoutCheckboxes
                      onChange={(newOptions) => this.onFieldValueChange('suffix', newOptions.filter(option => option.checked).map(option => option.value))}
                    />
                    {errors.suffix && <p className="errorText pl-2 mt-1">{errors.suffix}</p>}
                  </div>
                </Col>
                <Col xs={12}
                  className="px-0 ieCol d-flex align-items-center accountSettingFieldContainer flex-wrap">
                  <p className="mr-3 inputLabel fake">&nbsp;</p>
                  <div className="field-column">
                    <Button className="px-0 submitButton updateUserButton"
                      onClick={this.handleUpdateClick}>{isNewUser ? t('CREATE') : t('UPDATE')}</Button>
                  </div>
                </Col>
              </Col>
            </Col>
          </div>
        </Container>
      </>
    );
  }
}

export default withTranslation()(PersonalInformationComponent)