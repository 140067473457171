import React, {PureComponent} from 'react';
import {Modal, ModalHeader, ModalBody} from 'reactstrap';
import _ from 'lodash';
import {chatStatusClassName} from "../../helpers/chat";
import {UserAvatar} from "../UIComponents";
import { withTranslation } from 'react-i18next';

class GroupMemberList extends PureComponent {

  state = {
    participants: this.props.participants || []
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.participants, this.props.participants)) {
      this.setState({participants: this.props.participants})
    }
  }

  handleParticipantSearch = value => {
    this.setState({participants: this.props.participants.filter(participant => participant?.fullName?.toLowerCase().includes(value?.toLowerCase()))})
  }

  render() {
    const {isOpen, toggle, contacts, user, t} = this.props;
    const {participants} = this.state;

    return (
      <Modal isOpen={isOpen} toggle={toggle} className='group-members-modal'>
        <ModalHeader toggle={toggle}>{t('Members')}</ModalHeader>
        <ModalBody>
          <div className=" col-md-12 d-flex align-items-stretch">
            <div className="user-bar col-12">
              <div className="form-group searchSectionContainer">
                <label><img alt="" src="/assets/images/Oval.svg"/></label>
                <input type="text" placeholder="Search participants..."
                       onChange={event => this.handleParticipantSearch(_.trim(event.target.value || ""))}/>
              </div>
              <div className="group-scroll">
                {(participants).map((chat, index) => {
                  const contact = user?.id === chat.id ? {...user, connected: true} : contacts.find(contact => contact.id === chat.id);

                  return (
                    <div key={index} className={`user-tab ${chat.notifications > 0 ? 'active' : ""} d-flex mb-3 align-items-center`}>
                      <UserAvatar user={contact}
                                  className={`withStatus mr-2 ${contact?.connected && contact?.chatStatus? chatStatusClassName(contact.chatStatus) : ''}`} />
                      <div className="user-detail d-flex align-items-center">
                        <div className="user-name">
                          <h5 className={"mb-0"}>{`${contact.firstName || ""} ${contact.lastName || ""}`}</h5>
                        </div>
                        <p>{chat.lastMessage}</p>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    )
  }
}

export default withTranslation('common')(GroupMemberList)