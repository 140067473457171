import React, { Component } from 'react';
import Checkbox from "rc-checkbox";
import { Button, Col } from "reactstrap";
import { appTheme } from "../../../config"
import { withTranslation } from 'react-i18next';

class AppSettings extends Component {

  render() {
    const { playNotificationSoundValue, selectedTheme, updateSetting, userId, t } = this.props;

    return (
      <Col xl={{ size: 4, offset: 4 }} lg={{ size: 6, offset: 3 }} xs={{ size: 12, offset: 0 }} className="appSettingsContainer">
        <p className="appSettingsSubTitle mb-4">{t('auth.other_settings')}</p>
        <div className="d-flex align-items-center mb-4 playNotificationContainer">
          <Checkbox
            className="escCheckbox"
            defaultChecked={playNotificationSoundValue}
            onChange={() => updateSetting({ id: userId, settings: { playNotificationSound: !playNotificationSoundValue } })}
          />
          <p className="mb-0 ml-2">{t('auth.pplay_sound')}</p>
        </div>
        <div className="themeButtonsContainer">
          <Button className={`${!selectedTheme || selectedTheme === appTheme.LIGHT ? "selected" : ""}`}
            onClick={() => updateSetting({ id: userId, settings: { theme: appTheme.LIGHT } })}>
            {t('auth.LIGHT_THEME')}
          </Button>
          <Button className={`${selectedTheme === appTheme.DARK ? "selected" : ""}`}
            onClick={() => updateSetting({ id: userId, settings: { theme: appTheme.DARK } })}>
            {t('auth.DARK_THEME')}
          </Button>
        </div>
      </Col>
    );
  }
}

export default withTranslation('common')(AppSettings)
