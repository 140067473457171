import { connect } from 'react-redux';
import Contacts from '../../components/GroupModal/PeopleTab/Contacts';
import {bindActionCreators} from "redux";
import {closeModal} from "../../reducers/group";

const mapStateToProps = state => {
  return {
    allContacts: state.contacts.list || [],
    isOnlySearchMode: state.group.isOnlySearchMode,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  closeModal,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Contacts);
