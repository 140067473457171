import React, {Component} from "react";
import {
  Button,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter, ModalHeader,
} from "reactstrap";
import {InputField} from "../../../UIComponents";
import {appTheme} from "../../../../config";
import { withTranslation } from "react-i18next";

class SavedFilters extends Component {
  state = {
    isSelectListOpen: false,
    isSaveFilterModalOpen: false,
    isDeleteModalOpen: false,
    selectedFilterId: "",
    selectedFilter: {},
    name: ''
  };

  componentDidMount() {
    this.props.getFiltersList();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.selectedSavedFilter !== this.props.selectedSavedFilter && this.props.selectedSavedFilter?.name !== this.state.selectedFilter?.name) {
      this.setState({selectedFilter: this.props.selectedSavedFilter || {}})
    }
  }

  toggle = (name) => {
    this.setState({
      [name]: !this.state[name],
      ...(name === 'isSaveFilterModalOpen' ? {name: ""} : {}),
      ...(name === 'isDeleteModalOpen' ? {selectedFilterId: ""} : {})
    })
  };

  toggleDeleteModal = (id) => {
    const {isDeleteModalOpen} = this.state;
    if (!isDeleteModalOpen) {
      this.setState({isDeleteModalOpen: true, selectedFilterId: id})
    } else {
      this.setState({isDeleteModalOpen: false})
    }
  };

  prepareTitle = () => {
    const { selectedFilter } = this.state;
    const { t } = this.props;

    return selectedFilter?.name || t('chat.select_saved_filter')
  };

  selectFilter = (filter) => {
    this.setState({selectedFilter: filter, isSelectListOpen: false})
    this.props.onSelectedFilterChange(filter)
  };

  renderOptions = () => {
    const {savedFilters, t} = this.props;

    if (!savedFilters?.length) {
      return <p className='m-0 text-center'>{t('chat.no_saved_filter')}</p>
    }

    return savedFilters.map((filter) => (
      <div key={filter.id} className="savedFilterItemContainer" onClick={() => this.selectFilter(filter)}>
        <p>{filter.name}</p>
        <i title="Delete" onClick={(e) => {
          e.stopPropagation();
          this.toggleDeleteModal(filter.id)
        }}><img
          src={"/assets/images/icn-delete-user.svg"} alt={"delete"}/></i>
      </div>
    ))
  };

  saveFilter = () => {
    const {selectedFilters: {facility, department, profession, specialty}} = this.props;
    this.props.createFilter({
      name: this.state.name,
      params: {facility, department, profession, specialty}
    });
    this.toggle("isSaveFilterModalOpen");
  };

  handleFilterDelete = () => {
    const {selectedFilterId, selectedFilter} = this.state;
    this.props.deleteFilter(selectedFilterId);
    if (selectedFilter?.id === selectedFilterId) {
      this.setState({selectedFilter: {}})
    }
    this.toggleDeleteModal();
  };

  render() {
    const {selectedFilters: {facility, department, profession, specialty}, selectedTheme, t} = this.props;
    const {isSelectListOpen, isSaveFilterModalOpen, name, isDeleteModalOpen} = this.state;

    const hasAppliedFilter = facility.length || department.length || profession.length || specialty.length;

    return (
      <div className='d-flex filtersContainer mb-3'>
        <Dropdown isOpen={isSelectListOpen} toggle={() => this.toggle("isSelectListOpen")}
                  className={`${isSelectListOpen ? "open " : ""}multiSelectDropdown unrestricted  mr-2`}>
          <DropdownToggle>
            {this.prepareTitle()}
            <img src={`/assets/images/arrow-down${selectedTheme === appTheme.DARK ? '-dark-mode' : ''}.svg`} alt='arrow'/>
          </DropdownToggle>
          <DropdownMenu>
            <div className="optionsContainer">
              {this.renderOptions()}
            </div>
          </DropdownMenu>
        </Dropdown>
        <Button disabled={!hasAppliedFilter} className={'settingsFiltersSubmitButton save'}
                onClick={() => this.toggle("isSaveFilterModalOpen")}>
          {t('chat.SAVE_FILTER')}
        </Button>

        <Modal isOpen={isSaveFilterModalOpen} toggle={() => this.toggle("isSaveFilterModalOpen")}
               className={"notAvailableModal"} size="sm">
          <ModalHeader toggle={() => this.toggle("isSaveFilterModalOpen")}>{t('chat.Save_Filter')}</ModalHeader>
          <ModalBody className="d-flex justify-content-center align-items-center px-4 pb-0 ">
            <div className="w-100 my-0 searchContainer">
              <InputField onChange={(e) => this.setState({name: e.target.value})} value={name}
                          placeholder={t("chat.filters_name")}
                          className={"inputField searchSectionContainer px-0"}
              />
            </div>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-center">
            <Button color="secondary" onClick={() => this.toggle("isSaveFilterModalOpen")}>{t('CANCEL')}</Button>
            <Button color="primary" onClick={this.saveFilter}>{t('SAVE')}</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={isDeleteModalOpen} toggle={this.toggleDeleteModal} className='notAvailableModal' size="sm">
          <ModalHeader toggle={this.toggleDeleteModal}>{t('chat.del_saved_filter')}</ModalHeader>
          <ModalBody className="d-flex flex-wrap px-3">{t('chat.sure_to_delet_filter')}</ModalBody>
          <ModalFooter className="d-flex justify-content-center">
            <Button color="secondary" onClick={this.toggleDeleteModal}>{t('Cancel')}</Button>
            <Button color="primary" onClick={this.handleFilterDelete}>
              {t('Delete')}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}

export default withTranslation('common')(SavedFilters)