import { connect } from 'react-redux';
import PeopleTab from '../../components/GroupModal/PeopleTab';
import {bindActionCreators} from "redux";
import {closeModal, changeOnlySearchMode} from "../../reducers/group";
import {createLoadingSelector} from "../../selectors/loading";
import {
  FETCH_CONTACTS_LIST_PREFIX,
  getContactsList,
} from "../../reducers/contacts";
import {
  CREATE_TWILIO_CHAT_CONVERSATION_PREFIX,
  getChatRoomsByParticipants,
  FETCH_CHAT_ROOMS_LIST_BY_PARTICIPANTS_PREFIX,
  UPDATE_CHAT_ROOM_PREFIX,
  updateChatRoom
} from "../../reducers/chatRooms";
import {createErrorMessageSelector} from "../../selectors/error";
import {
  createChatConversation,
} from "../../reducers/twilio";

const mapStateToProps = state => {
  return {
    user: state.auth.data,
    contacts: (state.contacts.searchedForGroup || state.contacts.list || []).filter(contact => !contact.deleted && !!contact.lastLoginDate).map(contact => contact.id),
    chatRoomsBySameParticipants: state.chatRooms.chatRoomsByParticipants,
    selectedChatRoomTWId: state.chatRooms.selectedChatRoomTWId,
    isOnlySearchMode: state.group.isOnlySearchMode,
    loadingContacts: createLoadingSelector([FETCH_CONTACTS_LIST_PREFIX])(state),
    updatingConversation: createLoadingSelector([UPDATE_CHAT_ROOM_PREFIX])(state),
    updatingConversationError: createErrorMessageSelector([UPDATE_CHAT_ROOM_PREFIX])(state),
    creatingConversation: createLoadingSelector([CREATE_TWILIO_CHAT_CONVERSATION_PREFIX])(state),
    creatingConversationError: createErrorMessageSelector([CREATE_TWILIO_CHAT_CONVERSATION_PREFIX])(state),
    gettingChatRoomsWithTheSameParticipants: createLoadingSelector([FETCH_CHAT_ROOMS_LIST_BY_PARTICIPANTS_PREFIX])(state),
    gettingChatRoomsWithTheSameParticipantsError: createErrorMessageSelector([FETCH_CHAT_ROOMS_LIST_BY_PARTICIPANTS_PREFIX])(state),
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  closeModal,
  getContactsList,
  getChatRoomsByParticipants,
  createChatConversation,
  updateChatRoom,
  changeOnlySearchMode,
}, dispatch);


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PeopleTab);
