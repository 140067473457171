import React, { Component } from 'react';
import { Button, Col, Container } from 'reactstrap';
import '../index.scss';
import { InputField, Loader } from "../../UIComponents";
import { withTranslation } from 'react-i18next';

class ManagePasswordComponent extends Component {
  state = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
    errors: {}
  };

  componentDidUpdate(prevProps) {
    const { loading, error } = this.props;
    const { loading: prevLoading } = prevProps;
    if (!loading && prevLoading && !error) {
      this.setState({ currentPassword: "", newPassword: "", confirmPassword: "" })
    }
  }

  onFieldValueChange = (name, value) => {
    this.setState({ [name]: value })
  };

  handleSubmitClick = () => {
    const { currentPassword, newPassword, confirmPassword } = this.state, errors = {};
    const { t } = this.props;

    let isValid = true;

    if (!currentPassword) {
      errors.currentPassword = t('Required');
      isValid = false;
    }

    if (!newPassword) {
      errors.newPassword = t('Required');
      isValid = false;
    } else if (newPassword.length < 6) {
      errors.newPassword = t('auth.err_pass_lent');
      isValid = false;
    } else if (!(/.*[A-Z].*/).test(newPassword)) {
      errors.newPassword = t('auth.err_pass_uppercase');
      isValid = false;
    } else if (!(/.*[a-z].*/).test(newPassword)) {
      errors.newPassword = t('auth.err_password_lower');
      isValid = false;
    } else if (!(/.*\d.*/).test(newPassword)) {
      errors.newPassword = t('auth.err_pass_num');
      isValid = false;
    }

    if (!confirmPassword) {
      errors.confirmPassword = t('Required');
      isValid = false;
    } else if (confirmPassword !== newPassword) {
      errors.confirmPassword = t('auth.mismatch');
      isValid = false;
    }

    this.setState({ errors });
    isValid && this.props.changePasswordAction({ currentPassword, newPassword });
  };

  render() {
    const { loading, t } = this.props;
    const { errors, currentPassword, newPassword, confirmPassword } = this.state;

    return (
      <Container fluid className="managePasswordContainer">
        <div className="contentContainer d-flex align-items-start flex-wrap">
          <Col lg={4} sm={12} className="pl-0">
            <h1>{t('auth.manage_password')}</h1>
          </Col>
          <Col className="pl-0 controls-wrap">
            <Col xs={12} className="d-flex flex-column align-items-start px-0 form-container">
              {loading ? <Loader /> :
                <>
                  <Col xs={12}
                    className="px-0 ieCol d-flex align-items-center accountSettingFieldContainer flex-wrap">
                    <p className="mr-3 inputLabel">{t('auth.current_password')}</p>
                    <InputField
                      id="currentPassword"
                      type="password"
                      placeholder={t('auth.current_password')}
                      value={currentPassword}
                      error={errors.currentPassword}
                      className={"field-column px-0"}
                      onChange={(event) => this.onFieldValueChange('currentPassword', event.target.value)}
                      onKeyPress={(event) => (event.charCode === 13) && this.handleSubmitClick()}
                    />
                  </Col>
                  <Col xs={12}
                    className="px-0 ieCol d-flex align-items-center accountSettingFieldContainer flex-wrap">
                    <p className="mr-3 inputLabel fake" />
                    <InputField
                      id="newPassword"
                      type="password"
                      placeholder={t('auth.New_Pass')}
                      value={newPassword}
                      error={errors.newPassword}
                      className={"field-column px-0"}
                      onChange={(event) => this.onFieldValueChange('newPassword', event.target.value)}
                      onKeyPress={(event) => (event.charCode === 13) && this.handleSubmitClick()}
                    />
                  </Col>
                  <Col xs={12}
                    className="px-0 ieCol d-flex align-items-center accountSettingFieldContainer flex-wrap">
                    <p className="mr-3 inputLabel fake" />
                    <InputField
                      id="confirmPassword"
                      type="password"
                      placeholder={t('auth.Confirm_New_Pass')}
                      value={confirmPassword}
                      error={errors.confirmPassword}
                      className={"field-column px-0"}
                      onChange={(event) => this.onFieldValueChange('confirmPassword', event.target.value)}
                      onKeyPress={(event) => (event.charCode === 13) && this.handleSubmitClick()}
                    />
                  </Col>
                  <Col xs={12}
                    className="px-0 ieCol d-flex align-items-center accountSettingFieldContainer flex-wrap">
                    <p className="mr-3 inputLabel" />
                    <div className="field-column">
                      <Button className="submitButton col-lg-5 col-md-6 col-8 px-0" onClick={this.handleSubmitClick}>
                        {t('auth.CHANGE_PASSWORD')}
                      </Button>
                    </div>
                  </Col>
                </>
              }
            </Col>
          </Col>
        </div>
      </Container>
    );
  }
}


export default withTranslation()(ManagePasswordComponent)