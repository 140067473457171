import BaseApiService from "./base";

export default class ChatApiService extends BaseApiService {
    createChatRoom = (data) => (
        this.request({
            method: 'post',
            url: '/chat-room',
            data: data,
            withCredentials: true
        })
    )

    getUserChatRooms = () => (
        this.request({
            method: 'get',
            url: '/chat-room',
            withCredentials: true
        })
    )

    getChatRoomById = (id, twId) => (
        this.request({
            method: 'get',
            url: `chat-room/details?id=${id}&twId=${twId}`,
            withCredentials: true
        })
    )

    addContactsToChatRoom = (contacts, groupData) => (
        this.request({
            method: 'post',
            url: `/chat-room/add-contacts?id=${groupData.id}&twId=${groupData.twId}`,
            data: { contacts },
            withCredentials: true
        })
    )

    removeContactsFromChatRoom = (contacts, groupData) => (
        this.request({
            method: 'post',
            url: `/chat-room/remove-contacts?id=${groupData.id}&twId=${groupData.twId}`,
            data: { contacts },
            withCredentials: true
        })
    )
}
