import { connect } from 'react-redux';
import {bindActionCreators} from "redux";
import FiltersBar from '../../components/GroupModal/PeopleTab/FiltersBar';
import {
  getContactsList,
} from "../../reducers/contacts";

const mapStateToProps = state => {
  const selectedTheme = state.auth.data?.settings?.theme;
  return {
    selectedTheme,
    allContacts: state.contacts.list || [],
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getContactsList,
}, dispatch);


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FiltersBar);
