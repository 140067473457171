import { connect } from 'react-redux';
import SearchComponent from '../../components/UIComponents/SearchComponent';

const mapStateToProps = state => {
  const selectedTheme = state.auth.data?.settings?.theme;
  return {
    selectedTheme,
  };
};

export default connect(
  mapStateToProps,
  null
)(SearchComponent);
