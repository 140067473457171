import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import HeaderComponent from '../../components/ChatComponent/Header';
import { changeGroupMode, toggleDeleteModal, toggleGroupModal } from "../../reducers/chat";
import {
  setChatRoomAsFavorite,
  unsetChatRoomAsFavorite,
  muteChatRoom,
  unMuteChatRoom,
  leaveChatRoom,
  MUTE_CHAT_ROOM_PREFIX,
  UNMUTE_CHAT_ROOM_PREFIX,
  deleteChatRoom
} from "../../reducers/chatRooms";
import { createLoadingSelector } from "../../selectors/loading";
import { initializeCall, initializeDirectScreenShare, acceptCall } from "../../reducers/calls";
import {openModal} from "../../reducers/group";

const mapStateToProps = state => {
  const theme = state.auth.data?.settings?.theme;

  return {
    theme,
    contacts: state.contacts.list,
    calls: state.calls.calls,
    user: state.auth.data,
    chatRooms: state.chatRooms.list,
    changingMuteStatus: createLoadingSelector([MUTE_CHAT_ROOM_PREFIX, UNMUTE_CHAT_ROOM_PREFIX])(state),
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  leaveChatRoom,
  setChatRoomAsFavorite,
  unsetChatRoomAsFavorite,
  muteChatRoom,
  unMuteChatRoom,
  changeGroupMode,
  toggleGroupModal,
  initializeCall,
  initializeDirectScreenShare,
  deleteChatRoom,
  acceptCall,
  toggleDeleteModal,
  openGroupModal: openModal
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderComponent);
