import BaseApiService from './base';

export default class DepartmentsApiService extends BaseApiService {
  getDepartments(params) {
    return this.request({
      method: 'get',
      url: '/department',
      params,
      withCredentials: true,
    });
  }

  getDepartmentsList(params) {
    return this.request({
      method: 'get',
      url: '/department/list',
      params,
      withCredentials: false,
    });
  }

  getDepartmentById(id) {
    return this.request({
      method: 'get',
      url: `/department?${id}`,
      withCredentials: true,
    })
      .then(({ data = {}}) => {
        const departments = data.results
        return departments.find(d => d.id === id)
      });
  }

  createDepartment(data) {
    return this.request({
      method: 'post',
      url: '/department',
      data,
      withCredentials: true
    })
  }

  updateDepartment(data) {
    return this.request({
      method: 'put',
      url: '/department',
      data,
      withCredentials: true
    })
  }

  deleteDepartment(id) {
    return this.request({
      method: 'delete',
      url: '/department',
      data: {id},
      withCredentials: true
    });
  }
}