import React, {PureComponent} from 'react';
import {Badge, Button, UncontrolledCollapse} from "reactstrap";
import moment from "moment";
import {chatStatusClassName, contactDisplayName, renderSystemMessageText} from '../../helpers/chat';
import { ShowMore, UserAvatar } from '../UIComponents';
import {decryptMessage, trimMessage} from "../../helpers/messages";
import {ENABLE_DRAG_CONTACTS} from "../../config";
import i18n from '../../i18n';

class ContactsComponent extends PureComponent {

  state = {
    selected: null
  };

  handleDrag = (event) => {
    const target = event.target;
    event.dataTransfer.setData('selected_contact', target.id);
  }

  handleDragOver = e => {
    e.stopPropagation();
  }

  renderLastMessage = (chatLastMessage) => {
      let lastMessage = chatLastMessage && decryptMessage(chatLastMessage.body || '');

      if (chatLastMessage?.attributes?.systemMessage) {
          const {contacts, user} = this.props;
          const initiator = chatLastMessage.attributes.event?.data?.initiator
          const sentByUser = initiator === user?.id || initiator?.includes(user?.id);
          const messageSender = sentByUser
            ? user
            : Array.isArray(initiator)
              ? initiator.map(id => contacts.find(c => c.id === id))
              : contacts.find(contact => contact.id === initiator);
          lastMessage = renderSystemMessageText(lastMessage, messageSender, sentByUser, chatLastMessage.attributes)
      }

      return trimMessage(lastMessage, 40)
  }

  render() {
    const { items = {}, selectedItem = {}, contacts = [], user, isRecents = false } = this.props;
    return (
      Object.keys(items).map((sectionName, id) => {
        const itemsInSection = items[sectionName] || [];
        const sectionIdentifier = `item-${sectionName.replace(/[,\s]+/g, '-').replace(/[^a-zA-Z0-9 -]/, '-')}-${id}`;
        return (
          <div className="accordian-wrapper" key={id} id={sectionName}>
            <UncontrolledCollapse toggler={sectionIdentifier} defaultOpen={true}>
              {itemsInSection.map((chat = {}, index) => {
                const isDirect = chat.type === 'direct' || !chat.participants;
                let chatName = chat.name;
                let chatStatus = chat.chatStatus;
                let directContact = {};
                let isSelected = selectedItem && ((selectedItem.twId && selectedItem.twId === chat.twId) || (selectedItem.id && selectedItem.id === chat.id));
                if (isDirect) {
                  directContact = !chat.participants ? chat : contacts.find(contact => contact.id !== user.id && chat.participants.find(participant => participant && participant.id === contact.id));
                  if (directContact) {
                    chatName = contactDisplayName(directContact);
                    chatStatus = directContact?.connected ? directContact.chatStatus : "Offline";
                  }
                }
                const lastMessageTime = chat.lastMessage && chat.lastMessage.timetoken && moment(chat.lastMessage.timetoken).format(i18n.language === 'fr' ? "HH:mm" : "h:mm A");

                const hasUnreadMessages = chat.unreadCount > 0;
                return (
                  <div key={index} onDragStart={this.handleDrag} onDragOver={this.handleDragOver} draggable={ENABLE_DRAG_CONTACTS && isDirect && directContact ? "true" : "false"}
                    id={isDirect && directContact ? directContact.id : chat.twId}
                    className={`userTab${isSelected ? ' selected' : ''}${chat.notifications > 0 ? ' active' : ""}${chat.isMuted ? ' muted' : ''}`}
                    onClick={() => this.props.onContactClick(chat)}>
                    <UserAvatar user={isDirect ? directContact : chat}
                      className={`${isDirect ? 'withStatus ' : ''}mr-2 ${chatStatus ? chatStatusClassName(chatStatus) : ''}`} />
                    <div className="userDetail">
                      <div className="userName">
                        <div className='d-flex'>
                          <h4 className={hasUnreadMessages ? 'font-weight-bold' : ''}>{chatName}</h4>
                          {hasUnreadMessages && <Badge>{chat.unreadCount}</Badge>}
                        </div>
                        <span className="time">{lastMessageTime}</span>
                      </div>
                      {
                        !isRecents &&
                        directContact &&
                        directContact.professions &&
                        <p><ShowMore items={directContact.professions.map(i => i.name)} name={`professions-${directContact.id}`}/></p>
                      }
                      {chat.typingMembers?.length ? <img src="/assets/images/typing-chat.gif" width="35" alt="typing" /> : <p>{this.renderLastMessage(chat.lastMessage)}</p>}
                    </div>
                  </div>
                )
              })}
            </UncontrolledCollapse>
            <Button className="toggleListButton" id={sectionIdentifier}>
              {sectionName}
            </Button>
          </div>
        )
      })
    )
  }
}

export default ContactsComponent;
