import React, { Component } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import PeopleTab from "../../containers/GroupModal/PeopleTabContainer";
import GroupsTab from "../../containers/GroupModal/GroupsTabContainer";
import "./index.scss";
import { withTranslation } from "react-i18next";



class GroupModal extends Component {
  tabs = [
    {
      id: 'people',
      label: this.props.t("chat.People")
    },
    {
      id: 'groups',
      label: this.props.t("chat.Groups")
    }
  ];
  state = {
    selectedTab: this.tabs[0].id
  };

  renderTabContent(isEditMode) {
    const { closeModal, participants, groupName, twId } = this.props;
    const { selectedTab } = this.state;


    switch (selectedTab) {
      case this.tabs[0].id:
        return <PeopleTab closeModal={closeModal} participants={participants} groupName={groupName} isEditMode={isEditMode} twId={twId} />;
      case this.tabs[1].id:
        return <GroupsTab closeModal={closeModal} />;
      default:
        return null;
    }
  }

  renderTitle(isEditMode) {
    const { isOnlySearchMode, t } = this.props;

    if (isOnlySearchMode) {
      return t('chat.extend_group')
    }

    if (isEditMode) {
      return t('chat.edit_group')
    }

    return t('chat.Create_Group')

  }

  render() {
    const { selectedTab } = this.state;
    const { isOpen, closeModal, groupName, participants, twId } = this.props;

    const isEditMode = groupName && participants?.length && twId;

    return (
      <div>
        <Modal className='groupModal' isOpen={isOpen} toggle={closeModal} unmountOnClose>
          <ModalHeader toggle={closeModal}
            close={<img onClick={closeModal}
              className="clickable"
              src={"/assets/images/btn-close.svg"}
              alt="close" />}
          >
            {this.renderTitle(isEditMode)}
          </ModalHeader>
          <ModalBody className="d-flex p-3 flex-column justify-content-start">
            <div className={"tabsHeadersContainer"}>
              {this.tabs.map(tab => <p key={tab.id}
                onClick={() => this.setState({ selectedTab: tab.id })}
                className={`${selectedTab === tab.id ? 'selected ' : ''}tabItem`}>{tab.label}</p>)}
            </div>
            {this.renderTabContent(isEditMode)}
          </ModalBody>
        </Modal>
      </div>
    )
  }
}

export default withTranslation('common')(GroupModal)
