import React, { Component } from 'react';
import { Button, Col, Container } from 'reactstrap';
import '../index.scss';
import { withTranslation } from 'react-i18next';
import { SelectField } from "../../UIComponents";

class LanguageSelectorComponent extends Component {
  state = {
    language: 'en',
  };

  componentDidMount() {
    const { user } = this.props;
    if (user.language) {
      this.setState({ language: user.language })
    }
  }

  handleValueChange = (lang) => {
    // const { i18n } = this.props;
    // console.log('i18n', i18n)
    // i18n.changeLanguage(lang)
    this.setState({ language: lang })
  }

  handleSubmitClick = () => {
    const { language } = this.state;
    const { user, changeLanguageAction } = this.props;
    if (user.language !== language) {
      // do something with this language
      changeLanguageAction({ language })
    }
  };

  render() {
    const { t } = this.props;
    const { language } = this.state;

    const languageOption = [
      { value: 'en', label: t('English') },
      { value: 'fr', label: t('French') }
    ]

    return (
      <Container fluid className="LanguageSelector_">
        <div className="contentContainer d-flex align-items-start flex-wrap">
          <Col lg={4} sm={12} className="pl-0">
            <h1 className='personal-info'>{t('auth.Language')}</h1>
          </Col>
          <Col className="pl-0 controls-wrap">
            <Col xs={12} className="d-flex flex-column align-items-start px-0 form-container">

                  <Col xs={12}
                    className="px-0 ieCol d-flex align-items-center accountSettingFieldContainer flex-wrap">
                    <p className="mr-3 inputLabel">{t('auth.choose_language')}</p>
                    <div className="d-flex flex-column field-column">
                      <SelectField
                        name='profession'
                        className='multiSelectDropdown'
                        options={languageOption}
                        isMulti={false}
                        defaultValue={language}
                        value={language}
                        withoutCheckboxes
                        onChange={this.handleValueChange}
                      />
                    </div>
                  </Col>
                  <Col xs={12}
                    className="px-0 ieCol d-flex align-items-center accountSettingFieldContainer flex-wrap">
                    <p className="mr-3 inputLabel fake" />
                    <div className="field-column">
                      <Button className="submitButton col-lg-5 col-md-6 col-8 px-0" onClick={this.handleSubmitClick}>
                        {t('Submit')}
                      </Button>
                    </div>
                  </Col>
            </Col>
          </Col>
        </div>
      </Container>
    );
  }
}


export default withTranslation()(LanguageSelectorComponent)