import { Client } from '@twilio/conversations';

let mainInstance: TwilioChatService | null;

export default class TwilioChatService {
  constructor() {
    this.client = null
  }
  client: Client | null;

  create = async (token: string) => {
    if (!this.client) {
      this.client = await new Client(token);
    }
    return this.client;
  };

  updateToken = async (token: string) => {
    this.client = await this.client!.updateToken(token);
    return this.client;
  };

  disconnect() {
    if (!!this.client) {
      this.client.removeAllListeners();
      // this.client.conversations && this.client.conversations.removeAllListeners();
      // this.client.options && this.client.options.twilsockClient && this.client.options.twilsockClient.removeAllListeners();
      this.client = null;
      mainInstance = null;
    }
  }

  getConversationBySid = (sId: string) => {
    if(!this.client) {
      return
    }
    return this.client.getConversationBySid(sId)
  };

  getSubscribedConversations = () => {
    if(!this.client) {
      return
    }
    return this.client.getSubscribedConversations()
  };

  createConversation = async ({ uniqueName, friendlyName }: { uniqueName: string, friendlyName: string }) => {
    if(!this.client) {
      return
    }
    return this.client.createConversation({ uniqueName, friendlyName })
  };
}


export const getTwilioClient = (): TwilioChatService => {
  if (!mainInstance) {
    mainInstance = new TwilioChatService();
  }
  return mainInstance || {};
};
