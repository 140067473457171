import React, { useContext, useEffect, useState } from "react";
import { STATIC_BASE } from "../config";
import SettingApiService from "./api/setting";

export const LOGO_BASE = STATIC_BASE + "logos/";

export const AppSettingContext = React.createContext({});

export const AppSettingContextProvider = ({ children }) => {
  const [settings, setSettings] = useState({
    aboutUsHeader: null,
    whiteLogoBig: null,
    squareLogo: null,
    squareLogoDark: null,
    recentLogo: null,
    recentLogoDark: null,
    moreLogo: null,
    moreLogoDark: null,
    aboutUsText: '',
    informationText: '',
    bannerMessage: {
      text: '',
      show: false
    },
    passwordUpdate: {
      lastUpdate: new Date().toISOString(),
      active: false,
      frequency: 90,
      period: 'day',
      changed: false
    }
  });

  useEffect(() => {
    new SettingApiService().getAppSettings().then((res) => {
      if (res.data.appSettings) {
        setSettings({...settings, ...res.data.appSettings});
        sessionStorage.setItem("logo-data", JSON.stringify(res.data.appSettings));
      }
    });
  }, []);

  const updateSettings = ({ key, value }) => {
    console.log('key', key, value)
    setSettings({ ...settings, [key]: value });
  };

  return (
    <AppSettingContext.Provider
      value={{
        settings,
        setSettings,
        updateSettings,
      }}
    >
      {children}
    </AppSettingContext.Provider>
  );
};

export const LogoImage = ({ src, ...props }) => {
  return (
    <AppSettingContext.Consumer>
      {({ settings }) => (
        <>
          {settings[src] ? (
            <img alt="" {...props} src={LOGO_BASE + settings[src]} />
          ) : (
            <span className="LOGO_NOT_LOADED" />
          )}
        </>
      )}
    </AppSettingContext.Consumer>
  );
};

export const useAppSettings = () => useContext(AppSettingContext)