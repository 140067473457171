import { connect } from 'react-redux';
import TableComponent from '../../components/UIComponents/TableComponent';

const mapStateToProps = state => {
  const selectedTheme = state.auth.data?.settings?.theme;
  return {
    selectedTheme,
  };
};

export default connect(
  mapStateToProps,
  null
)(TableComponent);
