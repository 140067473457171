import React from "react";
import { Container, Button } from "reactstrap";
import { withRouter } from "react-router-dom";
import "./LogosComponent.scss";

import { AppSettingContext, LogoImage } from "../../../../services/app-setting.hook";
import { SettingApiService, ToastrService } from "../../../../services";
import { withTranslation } from "react-i18next";

class LogosComponent extends React.Component {
  static contextType = AppSettingContext

  constructor(props) {
    super(props)
    this.state = {
      editing: "",
    };

    this.fileInputRef = React.createRef();
  }

  handlefileInput = (e) => {
    const { t } = this.props;
    const file = e.target.files.item(0);

    if (file.size >= (1024 * 1024 * 10)) {
      ToastrService.error(t("admin.logo_too_large"));
    } else {
      new SettingApiService()
        .updateLogos(file, this.state.editing)
        .then((res) => {
          if (res.data) {
            this.context.updateSettings(res.data.logo)
          }
          ToastrService.info(t("admin.success_logo"))
        }).catch((e) => {
          // const message = e?.message || 'Logo was not uploaded successfully.'

          ToastrService.error(t("admin.error_file_upload"));

        });
    }
    e.target.value = "";
  };

  render() {
    const { t } = this.props;
    
    return (
      <Container fluid className="LogosComponent">
        <input
          type="file"
          hidden
          accept="image/png,image/x-png,image/gif,image/jpeg,image/svg+xml"
          ref={(ref) => (this.fileInputRef = ref)}
          onChange={this.handlefileInput}
        />
        <div className="suffixesPage">
          <div className="bodyContent">
            <div className="logo logo-main">
              <div className="bodyHeader">
                <h2>{t("admin.main_logo")}</h2>
              </div>
              <div className="content">
                <Button
                  className="submitButton"
                  onClick={() => {
                    this.setState({ editing: "squareLogo" });
                    this.fileInputRef.click();
                  }}
                >
                  {t("Upload")}
                </Button>

                <div className="preview">
                  <LogoImage src="squareLogo" />
                </div>
              </div>
            </div>

            <div className="logo logo-main-dark">
              <div className="bodyHeader">
                <h2>{t("admin.main_logo_dark")}</h2>
              </div>
              <div className="content">
                <Button
                  className="submitButton"
                  onClick={() => {
                    this.setState({ editing: "squareLogoDark" });
                    this.fileInputRef.click();
                  }}
                >
                  {t("Upload")}
                </Button>

                <div className="preview">
                  <LogoImage src="squareLogoDark" />
                </div>
              </div>
            </div>

            <div className="logo logo-large">
              <div className="bodyHeader">
                <h2>{t("admin.recent_logo")}</h2>
              </div>
              <div className="content">
                <Button
                  className="submitButton"
                  onClick={() => {
                    this.setState({ editing: "recentLogo" });
                    this.fileInputRef.click();
                  }}
                >
                  {t("Upload")}
                </Button>

                <div className="preview">
                  <LogoImage src="recentLogo" />
                </div>
              </div>
            </div>

            <div className="logo logo-large-dark">
              <div className="bodyHeader">
                <h2>{t("admin.recent_logo_dark")}</h2>
              </div>
              <div className="content">
                <Button
                  className="submitButton"
                  onClick={() => {
                    this.setState({ editing: "recentLogoDark" });
                    this.fileInputRef.click();
                  }}
                >
                  {t("Upload")}
                </Button>

                <div className="preview">
                  <LogoImage src="recentLogoDark" />
                </div>
              </div>
            </div>

            <div className="logo logo-large">
              <div className="bodyHeader">
                <h2>{t("admin.more_logo")}</h2>
              </div>
              <div className="content">
                <Button
                  className="submitButton"
                  onClick={() => {
                    this.setState({ editing: "moreLogo" });
                    this.fileInputRef.click();
                  }}
                >
                  {t("Upload")}
                </Button>

                <div className="preview" style={{ background: "#313363" }}>
                  <LogoImage src="moreLogo" />
                </div>
              </div>
            </div>

            <div className="logo more-logo-dark">
              <div className="bodyHeader">
                <h2>{t("admin.more_logo_dark")}</h2>
              </div>
              <div className="content">
                <Button
                  className="submitButton"
                  onClick={() => {
                    this.setState({ editing: "moreLogoDark" });
                    this.fileInputRef.click();
                  }}
                >
                  {t("Upload")}
                </Button>

                <div className="preview">
                  <LogoImage src="moreLogoDark" />
                </div>
              </div>
            </div>

            <div className="logo logo-aboutus">
              <div className="bodyHeader">
                <h2>{t("admin.abt_sqaure_logo")}</h2>
              </div>
              <div className="content">
                <Button
                  className="submitButton"
                  onClick={() => {
                    this.setState({ editing: "whiteLogoBig" });
                    this.fileInputRef.click();
                  }}
                >
                  {t("Upload")}
                </Button>

                <div className="preview">
                  <LogoImage src="whiteLogoBig" className="square" />
                  <LogoImage src="aboutUsHeader" className="rectangle" />
                </div>
              </div>
            </div>
            <div className="logo logo-aboutus">
              <div className="bodyHeader">
                <h2>{t("admin.about_header_logo")}</h2>
              </div>
              <div className="content">
                <Button
                  className="submitButton"
                  onClick={() => {
                    this.setState({ editing: "aboutUsHeader" });
                    this.fileInputRef.click();
                  }}
                >
                  {t("Upload")}
                </Button>

                <div className="preview">
                  <LogoImage src="whiteLogoBig" className="square" />
                  <LogoImage src="aboutUsHeader" className="rectangle" />
                </div>
              </div>
            </div>

          </div>
        </div>
      </Container>
    );
  }
}

export default withTranslation('common')(withRouter(LogosComponent));
