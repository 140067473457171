import { connect } from 'react-redux';
import EmailOrMobileConfirmComponent from '../../components/Auth/EmailOrMobileConfirmComponent';
import { bindActionCreators } from "redux";
import {VERIFY_EMAIL_PREFIX, VERIFY_MOBILE_PREFIX, verifyEmailAction, verifyMobileAction, resendEmailVerifyAction, resendPhoneVerifyAction} from "../../reducers/auth";
import {createLoadingSelector} from "../../selectors/loading";
import {createErrorMessageSelector} from "../../selectors/error";
import {getCallingCodesList} from "../../reducers/setting";

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    user: state.auth.data,
    callingCodes: state.setting.callingCodes,
    loading: createLoadingSelector([VERIFY_EMAIL_PREFIX, VERIFY_MOBILE_PREFIX])(state),
    error: createErrorMessageSelector([VERIFY_EMAIL_PREFIX, VERIFY_MOBILE_PREFIX])(state),
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  verifyEmailAction,
  verifyMobileAction,
  resendEmailVerifyAction,
  resendPhoneVerifyAction,
  getCallingCodesList,
}, dispatch);


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailOrMobileConfirmComponent);
