import { constantMessages, userRoles } from "../config";
import i18n from "../i18n";
import FacilitiesApiService from "../services/api/facilities";
import ToastrService from "../services/toastrService";

export const FETCH_FACILITIES_LIST_PREFIX = 'facilities/FETCH_FACILITIES_LIST_PREFIX'
export const FETCH_FACILITIES_LIST_REQUEST_ACTION = FETCH_FACILITIES_LIST_PREFIX + '_REQUEST_ACTION';
export const FETCH_FACILITIES_LIST_SUCCESS_ACTION = FETCH_FACILITIES_LIST_PREFIX + '_SUCCESS_ACTION';
export const FETCH_FACILITIES_LIST_FAILURE_ACTION = FETCH_FACILITIES_LIST_PREFIX + '_FAILURE_ACTION';

export const FETCH_ALL_FACILITIES_LIST_PREFIX = 'facilities/FETCH_ALL_FACILITIES_LIST_PREFIX'
export const FETCH_ALL_FACILITIES_LIST_REQUEST_ACTION = FETCH_ALL_FACILITIES_LIST_PREFIX + '_REQUEST_ACTION';
export const FETCH_ALL_FACILITIES_LIST_SUCCESS_ACTION = FETCH_ALL_FACILITIES_LIST_PREFIX + '_SUCCESS_ACTION';
export const FETCH_ALL_FACILITIES_LIST_FAILURE_ACTION = FETCH_ALL_FACILITIES_LIST_PREFIX + '_FAILURE_ACTION';

export const GET_FACILITIES_BY_ID_PREFIX = 'facilities/GET_FACILITIES_BY_ID_PREFIX';
export const GET_FACILITIES_BY_ID_REQUEST_ACTION = GET_FACILITIES_BY_ID_PREFIX + '_REQUEST_ACTION';
export const GET_FACILITIES_BY_ID_SUCCESS_ACTION = GET_FACILITIES_BY_ID_PREFIX + '_SUCCESS_ACTION';
export const GET_FACILITIES_BY_ID_FAILURE_ACTION = GET_FACILITIES_BY_ID_PREFIX + '_FAILURE_ACTION';

export const CREATE_FACILITIES_PREFIX = 'facilities/CREATE_FACILITIES_PREFIX'
export const CREATE_FACILITIES_REQUEST_ACTION = CREATE_FACILITIES_PREFIX + '_REQUEST_ACTION';
export const CREATE_FACILITIES_SUCCESS_ACTION = CREATE_FACILITIES_PREFIX + '_SUCCESS_ACTION';
export const CREATE_FACILITIES_FAILURE_ACTION = CREATE_FACILITIES_PREFIX + '_FAILURE_ACTION';

export const UPDATE_FACILITY_PREFIX = 'facilities/UPDATE_FACILITY_PREFIX'
export const UPDATE_FACILITY_REQUEST_ACTION = UPDATE_FACILITY_PREFIX + '_REQUEST_ACTION';
export const UPDATE_FACILITY_SUCCESS_ACTION = UPDATE_FACILITY_PREFIX + '_SUCCESS_ACTION';
export const UPDATE_FACILITY_FAILURE_ACTION = UPDATE_FACILITY_PREFIX + '_FAILURE_ACTION';

export const DELETE_FACILITIES_PREFIX = 'facilities/DELETE_FACILITIES_PREFIX'
export const DELETE_FACILITIES_REQUEST_ACTION = DELETE_FACILITIES_PREFIX + '_REQUEST_ACTION';
export const DELETE_FACILITIES_SUCCESS_ACTION = DELETE_FACILITIES_PREFIX + '_SUCCESS_ACTION';
export const DELETE_FACILITIES_FAILURE_ACTION = DELETE_FACILITIES_PREFIX + '_FAILURE_ACTION';

const initialState = {
  facilitiesList: [],
  allFacilitiesList: [],
  page: null,
  pageSize: null,
  total: null,
  facility: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FACILITIES_LIST_SUCCESS_ACTION:
      const data = action.payload.data
      return {
        ...state,
        page: data.page,
        pageSize: data.pageSize,
        facilitiesList: [...data.results],
        total: data.total
      }
    case FETCH_ALL_FACILITIES_LIST_SUCCESS_ACTION:
      return {
        ...state,
        allFacilitiesList: action.payload.data.results,
      }
    case GET_FACILITIES_BY_ID_SUCCESS_ACTION:
      return {
        ...state,
        facility: { ...action.payload.facility }
      }
    case CREATE_FACILITIES_SUCCESS_ACTION:
      return {
        ...state,
        facilitiesList: [...state.facilitiesList, { ...action.payload.facility }]
      }
    case UPDATE_FACILITY_SUCCESS_ACTION:
      const facilityIndex = state.facilitiesList.findIndex(fac => fac.id === action.payload.facility?.id)
      const updatedFacilitiesList = [...state.facilitiesList]
      updatedFacilitiesList[facilityIndex] = action.payload.facility
      return {
        ...state,
        facilitiesList: [...updatedFacilitiesList],
        facility: action.payload.facility
      }
    case DELETE_FACILITIES_SUCCESS_ACTION:
      const facilitiesListAfterRemove = state.facilitiesList.filter(d => d.id !== action.payload.id)
      return {
        ...state,
        facilitiesList: [...facilitiesListAfterRemove]
      }
    default:
      return state
  }
}

export function getFacilitiesList(params = { keyword: '', page: 1, pageSize: 10 }) {
  return (dispatch, getState) => {
    const isAllList = params.all === 1;
    dispatch({ type: isAllList ? FETCH_ALL_FACILITIES_LIST_REQUEST_ACTION : FETCH_FACILITIES_LIST_REQUEST_ACTION });
    const facilitiesService = new FacilitiesApiService();
    const userData = getState().auth?.data;
    const isAdmin = userData?.role === userRoles.ADMIN || userData?.role?.name === userRoles.ADMIN;
    return facilitiesService[isAdmin ? "getFacilities" : "getFacilitiesList"](params)
      .then(({ data = {} }) => {
        const response = data || {};
        dispatch({ type: isAllList ? FETCH_ALL_FACILITIES_LIST_SUCCESS_ACTION : FETCH_FACILITIES_LIST_SUCCESS_ACTION, payload: { data: response } });
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: isAllList ? FETCH_ALL_FACILITIES_LIST_FAILURE_ACTION : FETCH_FACILITIES_LIST_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message)
      })
  }
}

export function getFacilityById(id) {
  return dispatch => {
    dispatch({ type: GET_FACILITIES_BY_ID_REQUEST_ACTION });
    const facilitiesService = new FacilitiesApiService();
    return facilitiesService.getFacilityById(id)
      .then(({ data = {} }) => {
        const facility = data.results || []
        dispatch({ type: GET_FACILITIES_BY_ID_SUCCESS_ACTION, payload: { facility: facility.find(d => d.id === id) } });
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: GET_FACILITIES_BY_ID_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message)
      })
  }
}

export function updateFacility(data) {
  return dispatch => {
    dispatch({ type: UPDATE_FACILITY_REQUEST_ACTION })
    const facilitiesService = new FacilitiesApiService();
    return facilitiesService.updateFacility(data)
      .then(({ data = {} }) => {
        const facility = data.data || {}
        dispatch({ type: UPDATE_FACILITY_SUCCESS_ACTION, payload: { facility } })
        ToastrService.success(i18n.t('misc.success_facility_update'))
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: UPDATE_FACILITY_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message)
      })
  }
}

export function createFacility(name, params) {
  return dispatch => {
    dispatch({ type: CREATE_FACILITIES_REQUEST_ACTION })
    const facilitiesService = new FacilitiesApiService();
    return facilitiesService.createFacility({ name })
      .then(({ data = {} }) => {
        dispatch(getFacilitiesList(params))
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: CREATE_FACILITIES_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message)
      })
  }
}

export function deleteFacility(id, params) {
  return dispatch => {
    dispatch({ type: DELETE_FACILITIES_REQUEST_ACTION })
    const facilitiesService = new FacilitiesApiService();
    return facilitiesService.deleteFacility(id)
      .then(({ data = {} }) => {
        dispatch(getFacilitiesList(params))
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({ type: DELETE_FACILITIES_FAILURE_ACTION, payload: { message } });
        ToastrService.error(message)
      })
  }
}
