import { appSounds, soundsPath } from '../config'
import AudioService from '../utils/classes-utils'

const chatMessaging = new AudioService(soundsPath.MESSAGE_SOUND_PATH)
const videoMessaging = new AudioService(soundsPath.VIDEO_SOUND_PATH)
// const voiceMessaging = new AudioService(soundsPath.VOICE_SOUND_PATH)

const DEFAULT = 'DEFAULT'

const playService = {
  [appSounds.MESSAGE_CALL_SOUND]: {
    play: () => chatMessaging.play(),
    stop: () => chatMessaging.pause(),
  },
  [appSounds.CALL_SOUND]: {
    play: () => videoMessaging.playLoop(),
    stop: () => videoMessaging.pause(),
  },
  [DEFAULT]: {
    play: () => null,
    stop: () => null,
  },
}

const SoundService = {
  play: path => (playService[path] || playService[DEFAULT]).play(),
  stop: path => (playService[path] || playService[DEFAULT]).stop(),
}

export default SoundService
