import React from "react";

export const SMSIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5 3.33301H4.16667C3.24619 3.33301 2.5 4.0792 2.5 4.99967V16.6663C2.5 17.5868 3.24619 18.333 4.16667 18.333H10.8333C11.7538 18.333 12.5 17.5868 12.5 16.6663V9.16634"
      stroke="#9BAFCA"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66666 15.833H6.67499H8.33332"
      stroke="#9BAFCA"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.5 3.6665C7.5 2.56193 8.39543 1.6665 9.5 1.6665H15.5C16.6046 1.6665 17.5 2.56193 17.5 3.6665V7.1665C17.5 8.27107 16.6046 9.1665 15.5 9.1665H9.5L7.5 10.8332L7.5 9.1665L7.5 3.6665Z"
      stroke="#9BAFCA"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ImportantIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    style={{
      transform: "rotate(180deg)",
    }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 6L12 14"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 18H12.01"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="12"
      cy="12"
      r="10"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const UrgentIcon = () => (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.36333 1.95728L1.25653 16.017C1.08938 16.3177 1.00094 16.6587 1.00001 17.0059C0.999072 17.3531 1.08567 17.6946 1.25119 17.9963C1.41671 18.2979 1.65538 18.5493 1.94346 18.7254C2.23153 18.9015 2.55897 18.9962 2.8932 19H19.1068C19.441 18.9962 19.7685 18.9015 20.0565 18.7254C20.3446 18.5493 20.5833 18.2979 20.7488 17.9963C20.9143 17.6946 21.0009 17.3531 21 17.0059C20.9991 16.6587 20.9106 16.3177 20.7435 16.017L12.6367 1.95728C12.466 1.66505 12.2258 1.42345 11.9391 1.25577C11.6524 1.08809 11.329 1 11 1C10.671 1 10.3476 1.08809 10.0609 1.25577C9.7742 1.42345 9.53395 1.66505 9.36333 1.95728V1.95728Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 7V11"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 15H11.01"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const EyeIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1146_38578)">
      <path
        d="M0.833496 9.99992C0.833496 9.99992 4.16683 3.33325 10.0002 3.33325C15.8335 3.33325 19.1668 9.99992 19.1668 9.99992C19.1668 9.99992 15.8335 16.6666 10.0002 16.6666C4.16683 16.6666 0.833496 9.99992 0.833496 9.99992Z"
        stroke="#9BAFCA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z"
        stroke="#9BAFCA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1146_38578">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const DeleteIcon = () => (
  <svg
    width="18"
    height="21"
    viewBox="0 0 18 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 5.5H2.77778H17"
      stroke="#9BAFCA"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 5C3.55228 5 4 5.44772 4 6V18.25C4 18.6838 4.33913 19 4.71429 19H13.2857C13.6609 19 14 18.6838 14 18.25V6C14 5.44772 14.4477 5 15 5C15.5523 5 16 5.44772 16 6V18.25C16 19.7492 14.8041 21 13.2857 21H4.71429C3.19589 21 2 19.7492 2 18.25V6C2 5.44772 2.44772 5 3 5ZM7.09679 2.58927C7.09279 2.59235 7 2.66365 7 3V5C7 5.55228 6.55228 6 6 6C5.44772 6 5 5.55228 5 5V3C5 2.2375 5.23981 1.55543 5.78176 1.0824C6.29886 0.631054 6.94421 0.5 7.5 0.5H10.5C11.0558 0.5 11.7011 0.631054 12.2182 1.0824C12.7602 1.55543 13 2.2375 13 3V5C13 5.55228 12.5523 6 12 6C11.4477 6 11 5.55228 11 5V3C11 2.66365 10.9072 2.59235 10.9032 2.58927C10.9031 2.58922 10.9031 2.58918 10.9031 2.58917C10.8773 2.56666 10.7726 2.5 10.5 2.5H7.5C7.22736 2.5 7.12271 2.56666 7.09692 2.58917C7.09691 2.58918 7.09686 2.58922 7.09679 2.58927Z"
      fill="#9BAFCA"
    />
    <path
      d="M7.5 10V15"
      stroke="#9BAFCA"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5 10V15"
      stroke="#9BAFCA"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ArrowUp = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 15V1"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 8L8 1L15 8"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CopyIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="7"
      y="7"
      width="10"
      height="10"
      rx="2"
      stroke="#9BAFCA"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.30769 11H2.53846C1.68879 11 1 10.3112 1 9.46154V2.53846C1 1.68879 1.68879 1 2.53846 1H9.46154C10.3112 1 11 1.68879 11 2.53846V3.30769"
      stroke="#9BAFCA"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Check = () => <svg xmlns="http://www.w3.org/2000/svg" height="21" width="18" viewBox="0 0 448 512">
  {/* <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--> */}
  <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" fill="#fff"/></svg>