import { connect } from 'react-redux';
import RegisterComponent from '../../components/Auth/RegisterComponent';
import { bindActionCreators } from "redux";
import {REGISTER_PREFIX, registerAction} from "../../reducers/auth";
import {FETCH_CALLING_CODES_PREFIX, getCallingCodesList} from "../../reducers/setting";
import {createLoadingSelector} from "../../selectors/loading";
import {createErrorMessageSelector} from "../../selectors/error";
import { getFacilitiesList } from "../../reducers/facilities";
import { getDepartmentsList } from "../../reducers/departments";
import { getProfessions } from "../../reducers/professions";
import { getSpecialties } from "../../reducers/specialties";
import { getSuffixesList } from "../../reducers/suffixes";
import { getSelfRegistrationStatus, FETCH_SELF_REGISTRATION_PREFIX } from "../../reducers/setting";

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    tempToken: state.auth.data.tempToken,
    user: state.auth.data,
    callingCodes: state.setting.callingCodes,
    loadingCallingCodes: createLoadingSelector([FETCH_CALLING_CODES_PREFIX])(state),
    loading: createLoadingSelector([REGISTER_PREFIX, FETCH_SELF_REGISTRATION_PREFIX])(state),
    error: createErrorMessageSelector([REGISTER_PREFIX])(state),
    facilitiesList: state.facilities.facilitiesList,
    departmentsList: state.departments.departmentsList,
    professionsList: state.professions.professionsList,
    specialtiesList: state.specialties.specialtiesList,
    suffixesList: state.suffixes.suffixesList,
    isSelfRegistrationEnabled: state.setting.isSelfRegistrationEnabled,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  registerAction,
  getCallingCodesList,
  getFacilitiesList,
  getDepartmentsList,
  getProfessions,
  getSpecialties,
  getSuffixesList,
  getSelfRegistrationStatus
}, dispatch);


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterComponent);
