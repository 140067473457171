import io from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';
import i18n from '../i18n';

const uuid = uuidv4();

if (!sessionStorage.getItem("ecsSocketUid") || (window.performance.getEntriesByType("navigation")[0])?.type === 'back_forward') {
  sessionStorage.setItem('ecsSocketUid', uuid);
}

export default {
  // This array holds the events we are currently subscribed to
  callbacks: [],
  // The socket connection object
  connection: null,

  connect(token, onDisconnect) {
    return new Promise((resolve, reject) => {
      // If we are already connected, we should close the current connection
      // We should be careful with the connection, we only need to connect when we are authenticated
      // and everytime we refresh the token
      if (this.connection) {
        this.connection.close();
        this.connection = null;
        this.callbacks = [];
      }

      this.connection = io(
        process.env.REACT_APP_CHAT_SOCKET_URL,
        {
          query: {
            token,
            uuid: sessionStorage.getItem("ecsSocketUid"),
            lang: i18n.language
          },
          pingTimeout: 2000,
          reconnection: true,
          reconnectionDelay: 500,
          reconnectionDelayMax : 2000,
          reconnectionAttempts: Infinity,
          path: process.env.REACT_APP_SPEECH_SOCKET_PATH,
          allowUpgrades: false,
        }
      )

      this.addEventListeners("connect", function socketConnect() {
        console.log("SocketService: connected successfully.");
        resolve();
      });

      this.addEventListeners("disconnect", function socketConnect() {
        onDisconnect()
      });
    });
  },

  disconnect() {
    console.log(`SocketService::disconnect()`);
    if (!this.connection) {
      return;
    }

    this.connection.close();
    this.connection = null;
    this.callbacks = [];
  },

  /**
   * This method should be used to dispatch any needed action when the socket is disconnected
   * At the moment is void since don't do any action when the socket is disconnected
   */

  send(name, data) {
    return this.connection?.emit(name, data);
  },

  addEventListeners(name, handler) {
    if (!this.connection) return;
    console.log("SpeechToTextSocket::addEventListeners()", { name });
    if (this.callbacks.includes(name)) {
      console.log(`SpeechToTextSocket::addEventListeners(). Handler for the ${name} event exits.`);
      return;
    }

    this.callbacks.push(name);
    console.log(`SpeechToTextSocket::addEventListeners(). Listening for ${name} events.`);
    this.connection.on(name, handler);
  },

  removeEventListener(name) {
    if (!this.connection) return;
    const indx = this.callbacks.findIndex(x => x === name);
    this.callbacks.splice(indx, 1);
    console.log(`SpeechToTextSocket::removeEventListeners(). Handler for the ${name} event removed.`);
    this.connection.off(name, () => {});
  }
}
