import {FilterApiService, ToastrService} from '../services';
import {constantMessages} from "../config";

// ACTION_TYPES ////////////////////////////////////////////////////////////////

export const FETCH_FILTERS_LIST_PREFIX = 'filter/FETCH_FILTERS_LIST';
export const FETCH_FILTERS_LIST_REQUEST_ACTION = FETCH_FILTERS_LIST_PREFIX + '_REQUEST_ACTION';
export const FETCH_FILTERS_LIST_SUCCESS_ACTION = FETCH_FILTERS_LIST_PREFIX + '_SUCCESS_ACTION';
export const FETCH_FILTERS_LIST_FAILURE_ACTION = FETCH_FILTERS_LIST_PREFIX + '_FAILURE_ACTION';

export const CREATE_FILTER_PREFIX = 'filter/CREATE_FILTER';
export const CREATE_FILTER_REQUEST_ACTION = CREATE_FILTER_PREFIX + '_REQUEST_ACTION';
export const CREATE_FILTER_SUCCESS_ACTION = CREATE_FILTER_PREFIX + '_SUCCESS_ACTION';
export const CREATE_FILTER_FAILURE_ACTION = CREATE_FILTER_PREFIX + '_FAILURE_ACTION';

export const DELETE_FILTER_PREFIX = 'filter/DELETE_FILTER';
export const DELETE_FILTER_REQUEST_ACTION = DELETE_FILTER_PREFIX + '_REQUEST_ACTION';
export const DELETE_FILTER_SUCCESS_ACTION = DELETE_FILTER_PREFIX + '_SUCCESS_ACTION';
export const DELETE_FILTER_FAILURE_ACTION = DELETE_FILTER_PREFIX + '_FAILURE_ACTION';

export const RESET = 'filter/RESET';

// INITIAL STATE ///////////////////////////////////////////////////////////////

const initialState = {
  list: [],
};

// STATE ///////////////////////////////////////////////////////////////////////
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FILTERS_LIST_SUCCESS_ACTION:
      return {
        ...state,
        list: action.payload.list || [],
      };
    case CREATE_FILTER_SUCCESS_ACTION:
      return {
        ...state,
        list: [...state.list, action.payload.filter]
      };
    case DELETE_FILTER_SUCCESS_ACTION:
      return {
        ...state,
        list: state.list.filter(filter => filter.id !== action.payload.id)
      };
    case RESET:
      return {
        ...state,
        list: [],
      };
    default:
      return state
  }
}

// ACTIONS /////////////////////////////////////////////////////////////////////

export function getFiltersList() {
  return dispatch => {
    dispatch({type: FETCH_FILTERS_LIST_REQUEST_ACTION});
    const filterService = new FilterApiService();
    return filterService.getFilters()
      .then(({data = {}}) => {
        const {results: list} = data;
        dispatch({type: FETCH_FILTERS_LIST_SUCCESS_ACTION, payload: {list}});
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({type: FETCH_FILTERS_LIST_FAILURE_ACTION, payload: {message}});
        ToastrService.error(message)
      })
  }
}

export function createFilter(data) {
  return dispatch => {
    dispatch({type: CREATE_FILTER_REQUEST_ACTION});
    const filterService = new FilterApiService();
    return filterService.createFilter(data)
      .then(({data = {}}) => {
        dispatch({type: CREATE_FILTER_SUCCESS_ACTION, payload: {filter: data?.data || {}}});
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({type: CREATE_FILTER_FAILURE_ACTION, payload: {message}});
        ToastrService.error(message)
      })
  }
}

export function deleteFilter(id) {
  return dispatch => {
    dispatch({type: DELETE_FILTER_REQUEST_ACTION});
    const filterService = new FilterApiService();
    return filterService.deleteFilter(id)
      .then(() => {
        dispatch({type: DELETE_FILTER_SUCCESS_ACTION, payload: {id}});
      }).catch((err = {}) => {
        const message = err.message || constantMessages.defaultErrorMessage;
        dispatch({type: DELETE_FILTER_FAILURE_ACTION, payload: {message}});
        ToastrService.error(message)
      })
  }
}

export function reset() {
  return dispatch => {
    dispatch({type: RESET})
  }
}

