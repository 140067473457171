import BaseApiService from './base';

export default class TwilioApiService extends BaseApiService {
  getTwilioToken() {
    return this.request({
      method: 'get',
      url: '/twilio/token',
      withCredentials: true
    });
  }

  getTwilioVideoToken() {
    return this.request({
      method: 'get',
      url: '/twilio/video-token',
      withCredentials: true
    });
  }
}
