import React, {PureComponent} from 'react';
import {Input} from 'reactstrap';
import _ from 'lodash';
import {appTheme} from "../../config";
import ModalSearchIcon from "../../containers/ContactsListContainer/ModalSearchIconContainer";

export default class SearchComponent extends PureComponent {
  state = {
    value: ''
  };

  goSearch = _.debounce(() => {
    this.props.onSearch(_.trim(this.state.value || ""));
  }, 500)

  handleSearchChange = value => {
    this.setState({value}, () => this.goSearch(value));
  };

  handleKeyPress = event => {
    if (this.props.onKeyPress) {
      this.props.onKeyPress(event)
    }
  }

  render() {
    const {placeholder = "", className = "", disabled=false, hideIcon, selectedTheme, withModalSearchIcon} = this.props;
    const {value} = this.state;

    return (
      <div className={`${className} form-group searchSectionContainer`}>
        {value && <div className="close-btn" onClick={() => this.handleSearchChange('')} >
          <img src='/assets/images/cancel.svg' />
        </div>}
        {!hideIcon && !value && <label><img alt="" src={`/assets/images/Oval${selectedTheme === appTheme.DARK ? '-dark' : ''}.svg`}/></label>}
        <Input value={value}
               disabled={disabled}
               type="text"
               name={this.props.name || ""}
               placeholder={placeholder}
               onChange={(event) => this.handleSearchChange(event.target.value)}
               onKeyPress={this.handleKeyPress}
        />
        {!!withModalSearchIcon && <ModalSearchIcon />}
      </div>
    )
  }
};
