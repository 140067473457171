import { connect } from 'react-redux';
import SideBarComponent from '../components/SideBarComponent';
import {createLoadingSelector} from "../selectors/loading";
import {TRIAL_LOG_IN_PREFIX} from "../reducers/auth";
import {openModal} from "../reducers/group";
import {bindActionCreators} from "redux";

const mapStateToProps = state => {
  const theme = state.auth.data?.settings?.theme;

  return {
    theme,
    token: state.auth.token,
    userRole: state.auth.role,
    user: state.auth.data,
    newMessagesInRecentTab: state.chatRooms.newMessagesInRecentTab?.length,
    isChatting: state.chat.isChatting,
    trialLogInLoading: createLoadingSelector([TRIAL_LOG_IN_PREFIX])(state),
  };
};


const mapDispatchToProps = dispatch => bindActionCreators({
  openGroupModal: openModal,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SideBarComponent);
