/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, ReactElement, Suspense, useRef, Fragment } from "react";
import useAudioRecorder from "./useAudioRecorder";

import './AudioRecorder.scss';

import { LiveAudioVisualizer } from "react-audio-visualize";
import pause from '../../../assets/icons/audio-pause.svg'
import play from '../../../assets/icons/audio-play.svg'
import trash from '../../../assets/icons/audio-trash.svg'
import trashDark from '../../../assets/icons/audio-trash-dark.svg'
import { S3AudioPlayer } from "./AudioPlayer";
import { ToastrService } from "../../../services";
import { useTranslation } from "react-i18next";
import { ClipLoader } from "react-spinners";

const AudioRecorder: (props: { audioTrackConstraints: MediaTrackConstraints } & Record<string, any>) => ReactElement = ({
    onRecordingComplete,
    onNotAllowedOrFound,
    audioTrackConstraints,
    isDarkTheme,
    saveRecord,
    preview,
    setPreview
}) => {
    const { t } = useTranslation('common')
    const {
        startRecording,
        stopRecording,
        recordingBlob,
        isPaused,
        isRecording,
        recordingTime,
        mediaRecorder,
    } = useAudioRecorder(audioTrackConstraints, () => {
        ToastrService.error(t('chat.provide_mic_permission'))
        onNotAllowedOrFound?.();
        handleDiscardClick();
    });

    const shouldSaveRef = useRef(false);
    const showPreviewRef = useRef(false);
    const durationRef = useRef(0);
    const stopAudioRecorder: (save?: boolean) => void = (
        save: boolean = true
    ) => {
        shouldSaveRef.current = save
        stopRecording();
    };

    const handleDiscardClick = () => {
        stopAudioRecorder(false)
        onRecordingComplete()
    }

    const handleStopPlaying = () => {
        showPreviewRef.current = true;
        durationRef.current = recordingTime;
        stopRecording();
    }

    useEffect(() => {
        if (!isRecording) {
            startRecording();
        }
    }, [])

    useEffect(() => {
        if (recordingTime >= 60) {
            handleStopPlaying()
        }
    }, [recordingTime])


    useEffect(() => {
        if (saveRecord) {
            if (isRecording) {
                shouldSaveRef.current = true;
                durationRef.current = recordingTime;
                stopRecording();
            } else if (recordingBlob) {
                onRecordingComplete({
                    blob: recordingBlob,
                    duration: durationRef.current
                });
            }
        }

    }, [saveRecord])

    useEffect(() => {
        if (shouldSaveRef.current && onRecordingComplete != null && recordingBlob !== null) {
            onRecordingComplete({
                blob: recordingBlob,
                duration: durationRef.current
            });
        }

        if (recordingBlob && showPreviewRef.current) {
            setPreview({ blob: recordingBlob, duration: durationRef.current })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordingBlob]);

    return (
        <div className={`AudioRecorder`} >
            <img
                src={isDarkTheme ? trashDark : trash}
                className="options"
                onClick={handleDiscardClick}
                title="Discard"
                alt=""
            />
            {preview?.loading && <ClipLoader size={17} color={'#AAA'} loading={true} />}
            {!!preview && !preview.loading && <S3AudioPlayer s3key={preview?.key} duration={preview?.duration} />}
            {!preview && <Fragment>
                <img
                    src={isPaused ? play : pause}
                    className={`options pause`}
                    onClick={handleStopPlaying}
                    title={isPaused ? "Resume" : "Pause"}
                    alt=""
                />
                <span
                    className={`status ${isPaused ? 'pause' : 'play'}`}
                >
                    <span className="status-dot"></span>
                </span>
                <span
                    className={`timer`}
                >
                    {Math.floor(recordingTime / 60)}:
                    {String(recordingTime % 60).padStart(2, "0")}
                </span>
                <span
                    className={`visualizer`}
                >
                    {mediaRecorder && (
                        <Suspense fallback={<></>}>
                            <LiveAudioVisualizer
                                mediaRecorder={mediaRecorder}
                                barWidth={2}
                                barColor={isDarkTheme ? 'rgba(255, 255, 255, 0.5)' : '#6D737B'}
                                gap={3}
                                width={140}
                                height={30}
                                fftSize={512}
                                maxDecibels={-10}
                                minDecibels={-80}
                                smoothingTimeConstant={0.4}
                            />
                        </Suspense>
                    )}
                </span>

            </Fragment>}

        </div>
    );
};

export default AudioRecorder;