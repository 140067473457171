import BaseApiService from './base';

export default class ProfessionApiService extends BaseApiService {
  getProfessions(params) {
    return this.request({
      method: 'get',
      url: '/profession',
      params,
      withCredentials: true,
    });
  }

  getProfessionsList(params) {
    return this.request({
      method: 'get',
      url: '/profession/list',
      params,
      withCredentials: false,
    });
  }

  getProfessionById(id) {
    return this.request({
      method: 'get',
      url: `/profession?${id}`,
      withCredentials: true,
    });
  }

  createProfession(data) {
    return this.request({
      method: 'post',
      url: '/profession',
      data,
      withCredentials: true
    })
  }

  updateProfession(data) {
    return this.request({
      method: 'put',
      url: '/profession',
      data,
      withCredentials: true
    })
  }

  deleteProfession(id) {
    return this.request({
      method: 'delete',
      url: '/profession',
      data: {id},
      withCredentials: true
    });
  }
}