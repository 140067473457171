import React, { useEffect, useState } from "react"
import { Button, Container } from "reactstrap";
import { SettingApiService, ToastrService } from "../../../../services";
import { useAppSettings } from "../../../../services/app-setting.hook";

import "./BannerMessage.scss"
import { useTranslation } from "react-i18next";
import { LanguageSelector } from "../../../UIComponents/LanguageSelector";

const BannerMessage = () => {
	const { settings, setSettings } = useAppSettings();
	const [text, setText] = useState({})
	const [t] = useTranslation('common');
	const [lang, setLang] = useState('en');

	useEffect(() => {
		setText({ text_en: settings.bannerMessage?.text_en, text_fr: settings.bannerMessage?.text_fr })
	}, [settings.bannerMessage])


	const sendText = (show = false) => {
		new SettingApiService()
			.updateBannerMessage({ ...text, show })
			.then((res) => {
				setSettings({ ...settings, ...{ bannerMessage: { ...text, show } } });
				if (settings.bannerMessage.show && !show) {
					ToastrService.success(t("admin.banner_success_des"))
				} else if (!settings.bannerMessage.show && show) {
					ToastrService.success(t("admin.banner_success_act"))
				} else {
					ToastrService.success(t("admin.banner_success_updt"))
				}
			})
			.catch((err) => {
				ToastrService.error(err)
			})
	}

	return <Container className="BannerMessage">
		<div className="bodyHeader">
			<h2>{t("admin.banner_message")} <small className={settings.bannerMessage.show ? 'active' : 'inactive'}>({settings.bannerMessage.show ? t('Active') : t('Inactive')})</small></h2>
		</div>

		<LanguageSelector onChange={setLang} value={lang} />

		<span>{t("your_message")}</span>
		<div className="text">
			{lang === 'en' ? <textarea
			key={1}
				className="userSessionTimeout"
				type="textarea"
				placeholder={t("admin.banner_placeholder")}
				style={{}}
				maxLength={500}
				onChange={({ target }) => setText({ ...text, text_en: target.value })}
				value={text.text_en}
			// error={text}
			>{text.text_en}</textarea>
				:
				<textarea
				key={2}
					className="userSessionTimeout"
					type="textarea"
					placeholder={t("admin.banner_placeholder")}
					style={{}}
					maxLength={500}
					onChange={({ target }) => setText({ ...text, text_fr: target.value })}
					value={text.text_fr}
				// error={text}
				>{text.text_fr}</textarea>
			}
			<span>{text[`text${lang}`]?.length || 0}/500</span>
		</div>

		<div className="buttons">
			<Button onClick={() => { sendText(!settings.bannerMessage.show) }} className="submitButton deactivate">{settings.bannerMessage.show ? t('DEACTIVATE') : t('ACTIVATE')}</Button>
			<Button onClick={() => { sendText(settings.bannerMessage.show) }} className="submitButton activate">{t('UPDATE')}</Button>
		</div>
	</Container>
}

export default BannerMessage
