import React from 'react'
import { Button, Col, Container } from "reactstrap";
import { appTheme, routes } from "../../../../config";
import { withRouter } from "react-router-dom";
import { InputField, UserAvatar, StatusElement, ShowMore } from "../../../UIComponents";
import _ from "lodash";
import AllUsersComponent from "../../../../containers/Admin/User/AllUsersContainer";
import TableComponent from "../../../UIComponents/TableComponent";
import { getName } from "../../../../helpers/admin";
import SearchComponent from "../../../UIComponents/SearchComponent";
import { withTranslation } from 'react-i18next';
import i18n from '../../../../i18n';

class EditDepartmentComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isNewDepartment: false,
      errors: {},
      name: '',
      keyword: ''
    }
  }

  componentDidMount() {
    const { location: { pathname }, getDepartmentById, getPageMembers } = this.props
    const locationArr = pathname.split('/')
    const id = locationArr[locationArr.length - 1]
    if (id === 'new') {
      this.setState({ isNewDepartment: true })
    } else {
      getPageMembers('departmentId', id)
      getDepartmentById(id)
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { department } = this.props
    if (department && (prevProps.department.name !== department.name)) {
      this.setState({ name: department.name })
    }
  }

  onFieldValueChange = (name, value) => {
    this.setState({ [name]: value })
  };

  handleUpdateClick = () => {
    const { name } = this.state, { department: { id }, updateDepartment } = this.props, errors = {};
    let isValid = true;

    if (!_.trim(name || "")) {
      errors.name = 'Required';
      isValid = false;
    }

    this.setState({ errors });
    isValid && updateDepartment({ id, name })
  };

  handleStatusChange = (status, user) => {
    this.props.changeUserStatus(user.id, status.value.toLowerCase())
  };

  updateMembersList = () => {
    const { keyword } = this.state;
    const { id } = this.props.department;

    this.props.getPageMembers('departmentId', id, _.trim(keyword || ""))
  };

  updateSearchKeyword = (keyword) => {
    this.setState({keyword}, this.updateMembersList)
  };

  handleDeleteDepartment = () => {
    const { department: { id }, deleteDepartment, history } = this.props
    deleteDepartment(id)
    history.push(routes.departments.path)
  }

  renderTableRow = (user, isMember) => {
    const statusOptions = [
      {
        class: 'success',
        value: 'Active'
      },
      {
        class: 'danger',
        value: 'Inactive'
      }
    ];

    let roleClass = 'regular';
    if (user.role && user.role.name === 'Facility Admin') roleClass = 'facilityAdmin';
    else if (user.role && user.role.name === 'Admin') roleClass = 'admin';

    const { selectedTheme, updateUser, department, updatePageMembers, removePageMember, match: { params } } = this.props
    const { id } = params
    const actionBool = isMember || user.departmentId?.includes(id);
    return <>
      <td className="avatar-cell">
        <div className="avatar">
          <div className="avatar-img-container">
            <UserAvatar user={user} className="small"/>
          </div>
        </div>
      </td>
      <td>{user.firstName}</td>
      <td>{user.lastName}</td>
      <td>
        <ShowMore items={user.facilities.map(i => i.name)} name={`facilities-${user.id}`}/>
      </td>
      <td>
        <ShowMore items={user.professions.map(i => i.name)} name={`professions-${user.id}`} />
      </td>
      <td>
        <ShowMore items={user.suffixes.map(i => i.name)} name={`suffixes-${user.id}`} />
      </td>
      <td>
        <ShowMore items={user.specialties.map(i => i.name)} name={`specialties-${user.id}`} />
      </td>
      <td>
        <span className="edit-roles">
          <div className={roleClass} />
          {((user.role) && getName(user.role)) || 'Regular'}
        </span>
      </td>
      <td>
        <StatusElement
          options={statusOptions}
          status={user.active ? statusOptions[0] : statusOptions[1]}
          onChange={(status) => this.handleStatusChange(status, user)}
        />
      </td>
      <td>
        <div className="d-flex actions">
          {actionBool
            ? <i
              title={i18n.t('Remove_from') + department.name}
              className="mr-3"
              onClick={() => {
                updateUser({ ...user, departmentId: user.departments?.filter(dep => (dep._id || dep.id) !== department.id).map(dep => (dep._id || dep.id)) })
                removePageMember(id, user)
              }}
            ><img src={"/assets/images/remove-red.svg"} alt="remove"/></i>
            : <i
              title={i18n.t("Add_to") + department.name}
              className="mr-3"
              color='info'
              onClick={() => {
                updateUser({ ...user, departmentId: [...(user.departments || []), {id}].map(dep => (dep._id || dep.id))})
                updatePageMembers(id, user)
              }}
            ><img src={`/assets/images/add-member${selectedTheme === appTheme.DARK ? "-white" : "-blue"}.svg`} alt="Add"/></i>
          }
        </div>
      </td>
    </>
  }

  render() {
    const { history, usersLength, members = [], selectedTheme, t } = this.props
    const { isNewDepartment, errors, name } = this.state
    return (
      <Container fluid className="pageContainer adminContainer editContainer">
        <div className="contentContainer d-flex flex-column px-0">
          <div className="adminHeaderContainer">
            <div className="d-flex align-items-start">
              <Button className="linkButton font-weight-bold backButton"
                      onClick={() => history.push(routes.departments.path)}> <img
                src={`/assets/images/btn-arrow-left${selectedTheme === appTheme.DARK ? "-grey" : "-blue"}.svg`} alt={"arrow back"}/>
              </Button>
              <h1>{t("admin.edit_departement")}</h1>
            </div>
            {!isNewDepartment && <div className="d-flex flex-column user-action-cont">
              <Button className="linkButton font-weight-bold" onClick={this.handleDeleteDepartment}>
                <img width="20" height="20" src={"/assets/images/icn-delete-user.svg"} alt="" className="delete-btn-icon"/>
                {t("admin.del_department")}
              </Button>
            </div>}
          </div>
          <div className='editName'>
            <div className='sectionTitle'>{t("admin.dep_details")}</div>
            <div className='editFieldsWrap'>
              <span>{t("name")}</span>
              <div className='controlsWrap'>
                <Col xs={12}
                     className="px-0 d-flex align-items-center flex-wrap">
                  <InputField
                    id="name"
                    type="name"
                    placeholder="Name"
                    className={"field-column px-0"}
                    value={name}
                    error={errors.name}
                    onChange={(event) => this.onFieldValueChange('name', event.target.value)}
                    onKeyPress={(event) => (event.charCode === 13) && this.handleUpdateClick()}
                  />
                </Col>
                <Col xs={12}
                     className="px-0 d-flex align-items-center justify-content-between accountSettingFieldContainer flex-wrap">
                  <div className="field-column">
                    <Button onClick={this.handleUpdateClick}
                            className="px-0 submitButton">{isNewDepartment ? t('CREATE') : t('UPDATE')}</Button>
                  </div>
                </Col>
              </div>
            </div>
          </div>
          <div className='divider'/>
          <div className='addUsers'>
            <div className='sectionTitle'>{t("members")} ({members.length})</div>
            <div className="searchContainer px-0">
              <SearchComponent onSearch={this.updateSearchKeyword}
                               placeholder={t("Search...")}
                               className={"inputField"}
              />
            </div>
            <TableComponent
              className={'membersTable'}
              listData={members}
              tableHeaders={['', t('Name'), t('Surname'), t('Facility'), t('cli_role'), t('Suffix'), t('Specialty'), t('Role'), t('Status'), t('Actions')]}
              renderTableRow={user => this.renderTableRow(user, true)}
              paginate
              pageSize={5}
            />
          </div>
          <div className='divider'/>
          <div className='addUsers'>
            <div className='sectionTitle'>{t("admin.available_users")} ({usersLength})</div>
            <AllUsersComponent renderTableRow={this.renderTableRow} pageSize={5} />
          </div>
        </div>
      </Container>
    )
  }
}

export default withTranslation('common')(withRouter(EditDepartmentComponent))
