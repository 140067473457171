import React from 'react';
import _ from 'lodash';
import {FormGroup, Input} from 'reactstrap';

const InputField = (props = {
  id: _.uniqueId(),
  type: 'text',
  name: '',
  maxLength: 100,
  disabled: false,
  placeholder: '',
  required: false,
  helpText: '',
  children: null
}) => {
  return (
    <FormGroup className={props.className || ""}>
      <Input type={props.type}
             name={props.name}
             id={props.id}
             defaultValue={props.defaultValue}
             value={props.value}
             maxLength={props.maxLength}
             placeholder={props.placeholder}
             disabled={props.disabled}
             className={`inputField ${ props.inputClasses || ''}`}
             onKeyPress={props.onKeyPress}
             onChange={props.onChange}
             onBlur={props.onBlur}
      >
        {props.children}
      </Input>
      {props.helpText && <p className="pl-2 mt-1">{props.helpText}</p>}
      {props.error && <p className="errorText pl-2 mt-1">{props.error}</p>}
    </FormGroup>
  )
};

export default InputField;
