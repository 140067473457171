import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import ContactsComponent from '../../components/Contacts';
import {
  FETCH_CONTACTS_LIST_PREFIX,
  getContactsList,
} from "../../reducers/contacts";
import {
  CREATE_TWILIO_CHAT_CONVERSATION_PREFIX, MUTE_CHAT_ROOM_PREFIX,
  UNMUTE_CHAT_ROOM_PREFIX, getChatRoomsList
} from "../../reducers/chatRooms";
import {
  CREATE_TWILIO_CHAT_CLIENT_PREFIX,
  createChatConversation,
  joinTwilioConversationById,
  GET_TWILIO_TOKEN_PREFIX,
} from "../../reducers/twilio";
import {createLoadingSelector} from "../../selectors/loading";
import {createErrorMessageSelector} from "../../selectors/error";
import { changeChattingStatus } from "../../reducers/chat";
import { groupContactsByAlphabetic } from "../../helpers/chat";

const mapStateToProps = state => {
  return {
    user: state.auth.data,
    groupedContacts: groupContactsByAlphabetic(state.contacts.searchedList || state.contacts.list || []),
    contacts: state.contacts.searchedList || state.contacts.list || [],
    conversationsList: state.twilio.conversationsList,
    chatRooms: state.chatRooms.list,
    groupMode: state.chat.groupMode,
    loading: createLoadingSelector([GET_TWILIO_TOKEN_PREFIX, CREATE_TWILIO_CHAT_CLIENT_PREFIX])(state),
    creatingConversation: createLoadingSelector([CREATE_TWILIO_CHAT_CONVERSATION_PREFIX])(state),
    loadingContacts: createLoadingSelector([FETCH_CONTACTS_LIST_PREFIX])(state),
    changingMuteStatus: createLoadingSelector([MUTE_CHAT_ROOM_PREFIX, UNMUTE_CHAT_ROOM_PREFIX])(state),
    error: createErrorMessageSelector([FETCH_CONTACTS_LIST_PREFIX])(state),
    twilioClient: state.twilio.client
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getContactsList,
  createChatConversation,
  getChatRoomsList,
  joinTwilioConversationById,
  changeChattingStatus
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactsComponent);
