import { v4 as uuidv4 } from 'uuid';

export const getUserAvatarLetters = (user) => {
  let letters = "";
  if (!!user) {
    if (!!user.firstName) {
      letters += user.firstName[0].toUpperCase();
    }
    if (!!user.lastName) {
      letters += user.lastName[0].toUpperCase();
    }
  }
  return letters;
};

export const getIsShared = localTracks => {
  return !!localTracks.find(localTrack =>
    localTrack.label?.includes('screen') ||
    localTrack.label?.includes('window') ||
    localTrack.label?.includes('web-contents-media-stream') ||
    localTrack.truckPublication?.trackName?.includes('screen')
  )
}

export const convertUserSettingsIntoMultiSelectOptions = settings => {
  return settings.map(setting => ({
    value: setting.id,
    label: setting.name,
    checked: false
  }))
}

export const uncheckAllOptions = options => {
  return options.map(option => ({...option, checked: false}))
}

export const checkProvidedOptions = (allOptions = [], checkOptions = []) => {
  return allOptions.map(item => checkOptions.includes(item.value) ? {...item, checked: true} : item)
}

export function generateToken() {
  return uuidv4() + uuidv4();
}
