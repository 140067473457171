import { isMobile } from "../helpers/function";
import { CALL_PARTICIPANT_RATIO } from "../config";

export const calculateRemoteParticipantSize = (amountOfRows, amountOfParticipantsOnTheSameRow, totalAmount, index, totalWidth, totalHeight) => {
  // needed ratio
  let itemsPerRow;
  let maxWidth;
  let maxHeight;
  // we have two amount of participants per row - 1 or 2
  if (totalAmount === 1 || (totalAmount === 2 && isMobile()) || (totalAmount % 2 !== 0 && index + 1 === totalAmount)) {
    // case when there is one participant or totalAmount is odd and its last participant
    itemsPerRow = 1
  } else {
    // other cases
    itemsPerRow = 2
  }

  maxWidth = totalWidth / itemsPerRow
  maxHeight = totalHeight / amountOfRows

  // current ratio
  const sizesCoef = maxWidth / maxHeight

  // if current ratio not equal to needed - we need to decrease width or height to make it appropriate
  // then we will get needed aspect ratio
  if (sizesCoef > CALL_PARTICIPANT_RATIO) {
    // decreasing width
    maxWidth = maxWidth * (CALL_PARTICIPANT_RATIO / sizesCoef)
  } else {
    // decreasing height
    maxHeight = maxHeight * (sizesCoef / CALL_PARTICIPANT_RATIO)
  }

  return {
    maxHeight,
    maxWidth
  }
};

export const getAmountOfRowsAndParticipantsOnTheSameRow = (remoteParticipants) => {
  let amountOfRows, amountOfParticipantsOnTheSameRow;
  if (remoteParticipants.length === 2 && isMobile()) {
    amountOfRows = 2;
    amountOfParticipantsOnTheSameRow = 1;
  } else {
    amountOfParticipantsOnTheSameRow = remoteParticipants.length === 1 ? 1 : 2
    amountOfRows = Math.ceil(remoteParticipants.length / amountOfParticipantsOnTheSameRow)
  }
  return {
    amountOfRows, amountOfParticipantsOnTheSameRow
  }
}