import BaseApiService from "./base";

export default class SettingApiService extends BaseApiService {
  getCallingCodes() {
    return this.request({
      method: "get",
      url: "/setting/calling-codes",
      withCredentials: false,
    });
  }

  getSelfRegistrationStatus() {
    return this.request({
      method: "get",
      url: "/setting/self-registration",
      withCredentials: false,
    });
  }

  getAppSettings() {
    return this.request({
      method: "get",
      url: "/setting/app-settings",
      withCredentials: false,
    });
  }

  updateLogos(file, key) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("key", key);

    return this.request({
      method: "POST",
      url: "/setting/logos",
      withCredentials: true,
      data: formData,
    });
  }

  updateAboutTexts(data) {
    return this.request({
      method: "POST",
      url: "/setting/about-texts",
      withCredentials: true,
      data,
    });
  }

  updateBannerMessage(data) {
    return this.request({
      method: "POST",
      url: "/setting/banner-message",
      withCredentials: true,
      data,
    });
  }

  updatePasswordPolicy(data) {
    return this.request({
      method: "POST",
      url: "/setting/password-policy",
      withCredentials: true,
      data,
    });
  }
}
