import { connect } from 'react-redux';
import Route from '../../components/Auth/Route';
import { TRIAL_LOG_IN_PREFIX, FETCH_CURRENT_USER_PREFIX } from "../../reducers/auth";
import { createLoadingSelector } from "../../selectors/loading";

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    userRole: state.auth.role,
    trialLogInLoading: createLoadingSelector([TRIAL_LOG_IN_PREFIX, FETCH_CURRENT_USER_PREFIX])(state),
  };
};

export default connect(
  mapStateToProps,
  null
)(Route);
