import BaseApiService from './base';

export default class SpecialtiesApiService extends BaseApiService {
  getSpecialties(params) {
    return this.request({
      method: 'get',
      url: '/specialty',
      params,
      withCredentials: true,
    });
  }

  getSpecialtiesList(params) {
    return this.request({
      method: 'get',
      url: '/specialty/list',
      params,
      withCredentials: false,
    });
  }

  getSpecialtyById(id) {
    return this.request({
      method: 'get',
      url: `/specialty?${id}`,
      withCredentials: true,
    });
  }

  createSpecialty(data) {
    return this.request({
      method: 'post',
      url: '/specialty',
      data,
      withCredentials: true
    })
  }

  updateSpecialty(data) {
    return this.request({
      method: 'put',
      url: '/specialty',
      data,
      withCredentials: true
    })
  }

  deleteSpecialty(id) {
    return this.request({
      method: 'delete',
      url: '/specialty',
      data: {id},
      withCredentials: true
    });
  }
}